import { Fragment, useEffect, useMemo, useState } from "react";
import PageContainer from "../../components/page-container";
import SelectInput from "../../components/select-input";
import { AnimalType, EmissionVariant, Month, YesNo } from "../../constants";
import { ActivityInfo, FarmInfo } from "../../types";
import Button from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import Checkbox from "../../components/checkbox-input";
import { convertBooleanToYesNo } from "../../helpers/convertBooleanToYesNo";
import { convertYesNoToBoolean } from "../../helpers/convertYesNoToBoolean";
import { Transition, Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Loading from "../../components/loading";
import useProjectEmissionActivityInfo from "../../hooks/emission/useProjectEmissionActivityInfo";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";
import useFarmInfo from "../../hooks/emission/useFarmInfo";
import formatDate from "../../helpers/format-date";
import useEmissionActivity from "../../hooks/emission/useEmissionActivity";
import OverlayLoading from "../../components/overlay-loading";

interface ActivityInfoPageProps {
  variant?: EmissionVariant;
}

export default function ActivityInfoPage({ variant }: ActivityInfoPageProps) {
  const [farmInfo, setFarmInfo] = useState<FarmInfo>();
  const [activityInfo, setActivityInfo] = useState<ActivityInfo>();
  const [showWarning, setShowWarning] = useState(false);
  const { projectId, emissionActivityId } = useParams();
  const emissionActivity = useEmissionActivity();
  const { emissionActivityInfo, loading: emissionActivityInfoLoading } =
    useProjectEmissionActivityInfo(projectId, emissionActivityId);
  const navigate = useNavigate();
  const farmInfoData = useFarmInfo();

  useMemo(() => {
    function saveActivityInfo() {
      if (!activityInfo) return;
      localStorage.setItem(
        "ActivityInfo",
        JSON.stringify({
          ...activityInfo,
          animalType: activityInfo.livestock ? activityInfo.animalType : [],
          gridElectricity: activityInfo.irrigation
            ? activityInfo.gridElectricity
            : undefined,
        })
      );
    }
    saveActivityInfo();
  }, [activityInfo]);

  useEffect(() => {
    setFarmInfo(farmInfoData);

    setActivityInfo(
      emissionActivityInfo ?? {
        activityYears: [],
        animalType: [],
      }
    );
  }, [emissionActivityInfo, farmInfoData]);

  async function onClickContinue() {
    if (!activityInfo || !projectId || !emissionActivityId) return;
    const response = await api.postActivityInfo(
      +projectId,
      +emissionActivityId,
      activityInfo
    );
    if (response instanceof FetchError) {
      console.log(response);
    } else {
      navigate(
        `/projects/${projectId}/emission-calculator/${emissionActivityId}/calculations`,
        {
          replace: true,
        }
      );
    }
  }

  function handleActivityYearsChange(e: React.ChangeEvent<HTMLInputElement>) {
    const year = +e.target.value;
    if (!activityInfo) return;
    if (e.target.checked) {
      setActivityInfo({
        ...activityInfo,
        activityYears: [...(activityInfo.activityYears ?? []), year],
      });
    } else {
      setActivityInfo({
        ...activityInfo,
        activityYears: activityInfo.activityYears?.filter((y) => y !== year),
      });
    }
  }

  function checkForm() {
    if (!activityInfo) return false;

    if (
      (!activityInfo.activityYears || !activityInfo.activityYears.length) &&
      variant !== EmissionVariant.OnGoing
    )
      return false;
    if (
      !convertBooleanToYesNo(activityInfo.carbonBuilderProduct) &&
      variant === EmissionVariant.OnGoing
    )
      return false;
    if (!convertBooleanToYesNo(activityInfo.irrigation)) return false;
    if (!convertBooleanToYesNo(activityInfo.limeApplication)) return false;
    if (!convertBooleanToYesNo(activityInfo.syntheticFertilizer)) return false;
    if (!convertBooleanToYesNo(activityInfo.soilLandscape)) return false;
    if (!convertBooleanToYesNo(activityInfo.growCrop)) return false;
    if (activityInfo.growCrop) {
      if (!convertBooleanToYesNo(activityInfo.tillCrop)) return false;
    }
    if (!convertBooleanToYesNo(activityInfo.coverCrop)) return false;
    if (!convertBooleanToYesNo(activityInfo.tillPasture)) return false;
    if (!convertBooleanToYesNo(activityInfo.livestock)) return false;
    if (activityInfo.livestock) {
      if (!activityInfo.animalType.length) return false;
      if (
        !convertBooleanToYesNo(activityInfo.historicalStockRate) &&
        variant !== EmissionVariant.OnGoing
      )
        return false;
    }
    if (activityInfo.irrigation) {
      if (!convertBooleanToYesNo(activityInfo.gridElectricity)) return false;
    }

    return true;
  }

  function WarningView() {
    return (
      <Transition.Root show={showWarning} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setShowWarning}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-950 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-5 w-5 text-yellow-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-700"
                      >
                        Attention!
                      </Dialog.Title>
                      <div className="mt-2 text-gray-500">
                        <p className="text-sm">
                          It is crucial to provide all requested information to
                          avoid potential negative impact on your future ACCU
                          amount.
                        </p>
                        <p className="text-sm mt-3 font-semibold">
                          Are you sure you want to continue?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {/* <Link
                            to={`/projects/${projectId}/baseline-emission-calculator/calculations`}
                            reloadDocument
                          > */}
                    <button
                      onClick={() => {
                        onClickContinue();
                        setShowWarning(false);
                      }}
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-apple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-apple-500 sm:ml-3 sm:w-auto"
                    >
                      Continue
                    </button>
                    {/* </Link> */}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-apple-600 shadow-sm ring-2 ring-inset ring-apple-600 hover:bg-apple-100 sm:mt-0 sm:w-auto"
                      onClick={() => setShowWarning(false)}
                    >
                      Go Back
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  const month = farmInfo?.carbonRegMonth;
  const year = farmInfo?.carbonRegYear;

  return (
    <PageContainer>
      <div className="flex flex-col py-4 sm:py-8 items-center w-full">
        {!activityInfo ||
        !emissionActivity ||
        !farmInfo ||
        !month ||
        !year ||
        !projectId ? (
          <div className="flex flex-col items-center justify-center h-full w-full">
            <Loading />
          </div>
        ) : (
          <div className="w-full lg:w-3/4 xl:w-5/12">
            <div className="border-b border-gray-200 pb-4 lg:pb-8">
              <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
                Activity Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Activity information on your farm.
              </p>
            </div>
            <OverlayLoading open={emissionActivityInfoLoading} />
            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
              <WarningView />
              {variant === EmissionVariant.OnGoing ? (
                <div className="sm:col-span-full">
                  <label className="block text-sm font-medium  text-gray-900">
                    Did you apply{" "}
                    <strong className="text-apple-700">CarbonBuilder</strong>{" "}
                    product(s) from{" "}
                    {formatDate(emissionActivity.activityStartDate)} -{" "}
                    {formatDate(emissionActivity.activityEndDate)}?
                  </label>
                  <SelectInput
                    value={convertBooleanToYesNo(
                      activityInfo.carbonBuilderProduct
                    )}
                    onChange={(e) =>
                      setActivityInfo({
                        ...activityInfo,
                        carbonBuilderProduct: convertYesNoToBoolean(
                          e.target.value
                        ),
                      })
                    }
                    error={
                      convertBooleanToYesNo(
                        activityInfo.carbonBuilderProduct
                      ) === undefined
                        ? 1
                        : undefined
                    }
                  >
                    <option value={""}></option>
                    {Object.entries(YesNo).map(([key, value]) => (
                      <option key={key} value={value}>
                        {key}
                      </option>
                    ))}
                  </SelectInput>
                </div>
              ) : (
                <div className="sm:col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    <strong className="text-apple-700">
                      {Month[(farmInfo.carbonRegMonth ?? 0) - 1]} {year}
                    </strong>{" "}
                    has been chosen as your carbon project registration date. In
                    order to calculate your baseline greenhouse gas emissions,
                    we need to collect data on project area activities for 5
                    years prior to your registration year.
                    <br />
                    <br />
                    Please select the years that you have activity data
                    available.
                  </label>
                  {Array.from({ length: 5 }, (_, i) => year - i).map((y) => {
                    return (
                      <div className="mt-2" key={y}>
                        <Checkbox
                          label={`${Month[month === 12 ? 0 : month]} ${
                            month === 12 ? y : y - 1
                          } - ${Month[month - 1]} ${y}`}
                          checked={
                            activityInfo.activityYears?.includes(y)
                              ? true
                              : false
                          }
                          value={y}
                          onChange={(e) => {
                            handleActivityYearsChange(e);
                          }}
                        />
                      </div>
                    );
                  })}
                  {activityInfo.activityYears?.length === 0 ? (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      This field is required!
                    </p>
                  ) : null}
                </div>
              )}
              <div className="sm:col-span-full">
                <SelectInput
                  label={
                    variant !== EmissionVariant.OnGoing
                      ? `Did you irrigate your project area from ${
                          year - 5
                        } - ${year}?`
                      : `Did you irrigate your project area from ${formatDate(
                          emissionActivity.activityStartDate
                        )} - ${formatDate(emissionActivity.activityEndDate)}?`
                  }
                  value={convertBooleanToYesNo(activityInfo.irrigation)}
                  onChange={(e) =>
                    setActivityInfo({
                      ...activityInfo,
                      irrigation: convertYesNoToBoolean(e.target.value),
                    })
                  }
                  error={
                    convertBooleanToYesNo(activityInfo.irrigation) === undefined
                      ? 1
                      : undefined
                  }
                >
                  <option value={""}></option>
                  {Object.entries(YesNo).map(([key, value]) => (
                    <option key={key} value={value}>
                      {key}
                    </option>
                  ))}
                </SelectInput>
              </div>
              {activityInfo.irrigation ? (
                <div className="col-span-full">
                  <SelectInput
                    label={`Is your electricity obtained from an electricity grid?`}
                    value={convertBooleanToYesNo(activityInfo?.gridElectricity)}
                    onChange={(e) => {
                      setActivityInfo({
                        ...activityInfo,
                        gridElectricity: convertYesNoToBoolean(e.target.value),
                      });
                    }}
                    error={
                      convertBooleanToYesNo(activityInfo.gridElectricity) ===
                      undefined
                        ? 1
                        : undefined
                    }
                  >
                    <option value={""}></option>
                    {Object.entries(YesNo).map(([key, value]) => (
                      <option key={key} value={value}>
                        {key}
                      </option>
                    ))}
                  </SelectInput>
                </div>
              ) : null}
              <div className="sm:col-span-full">
                <SelectInput
                  label={
                    variant !== EmissionVariant.OnGoing
                      ? `Did you apply dolomite or lime to your project area from ${
                          year - 5
                        } - ${year}?`
                      : `Did you apply dolomite or lime to your project area from ${formatDate(
                          emissionActivity.activityStartDate
                        )} - ${formatDate(emissionActivity.activityEndDate)}?`
                  }
                  value={convertBooleanToYesNo(activityInfo.limeApplication)}
                  onChange={(e) =>
                    setActivityInfo({
                      ...activityInfo,
                      limeApplication: convertYesNoToBoolean(e.target.value),
                    })
                  }
                  error={
                    convertBooleanToYesNo(activityInfo.limeApplication) ===
                    undefined
                      ? 1
                      : undefined
                  }
                >
                  <option value={""}></option>
                  {Object.entries(YesNo).map(([key, value]) => (
                    <option key={key} value={value}>
                      {key}
                    </option>
                  ))}
                </SelectInput>
              </div>
              <div className="sm:col-span-full">
                <SelectInput
                  label={
                    variant !== EmissionVariant.OnGoing
                      ? `Did you apply synthetic fertilizer (i.e., MAP, PB, or urea as a synthetic fertilizer) to your project area from  ${
                          year - 5
                        } - ${year}?`
                      : `Did you apply synthetic fertilizer (i.e., MAP, PB, or urea as a synthetic fertilizer) to your project area from  ${formatDate(
                          emissionActivity.activityStartDate
                        )} - ${formatDate(emissionActivity.activityEndDate)}?`
                  }
                  value={convertBooleanToYesNo(
                    activityInfo.syntheticFertilizer
                  )}
                  onChange={(e) =>
                    setActivityInfo({
                      ...activityInfo,
                      syntheticFertilizer: convertYesNoToBoolean(
                        e.target.value
                      ),
                    })
                  }
                  error={
                    convertBooleanToYesNo(activityInfo.syntheticFertilizer) ===
                    undefined
                      ? 1
                      : undefined
                  }
                >
                  <option value={""}></option>
                  {Object.entries(YesNo).map(([key, value]) => (
                    <option key={key} value={value}>
                      {key}
                    </option>
                  ))}
                </SelectInput>
              </div>
              <div className="sm:col-span-full">
                <SelectInput
                  label={
                    variant !== EmissionVariant.OnGoing
                      ? `Did you modify your soil landscape in your project area from ${
                          year - 5
                        } - ${year}?`
                      : `Did you modify your soil landscape in your project area from ${formatDate(
                          emissionActivity.activityStartDate
                        )} - ${formatDate(emissionActivity.activityEndDate)}?`
                  }
                  value={convertBooleanToYesNo(activityInfo.soilLandscape)}
                  onChange={(e) =>
                    setActivityInfo({
                      ...activityInfo,
                      soilLandscape: convertYesNoToBoolean(e.target.value),
                    })
                  }
                  error={
                    convertBooleanToYesNo(activityInfo.soilLandscape) ===
                    undefined
                      ? 1
                      : undefined
                  }
                >
                  <option value={""}></option>
                  {Object.entries(YesNo).map(([key, value]) => (
                    <option key={key} value={value}>
                      {key}
                    </option>
                  ))}
                </SelectInput>
              </div>
              <div className="sm:col-span-full">
                <SelectInput
                  label={
                    variant !== EmissionVariant.OnGoing
                      ? `Did you grow crops in your project area from ${
                          year - 5
                        } - ${year}?`
                      : `Did you grow crops in your project area from ${formatDate(
                          emissionActivity.activityStartDate
                        )} - ${formatDate(emissionActivity.activityEndDate)}?`
                  }
                  value={convertBooleanToYesNo(activityInfo.growCrop)}
                  onChange={(e) =>
                    setActivityInfo({
                      ...activityInfo,
                      growCrop: convertYesNoToBoolean(e.target.value),
                      tillCrop: !convertYesNoToBoolean(e.target.value)
                        ? undefined
                        : activityInfo.tillCrop,
                    })
                  }
                  error={
                    convertBooleanToYesNo(activityInfo.growCrop) === undefined
                      ? 1
                      : undefined
                  }
                >
                  <option value={""}></option>
                  {Object.entries(YesNo).map(([key, value]) => (
                    <option key={key} value={value}>
                      {key}
                    </option>
                  ))}
                </SelectInput>
              </div>
              {activityInfo.growCrop ? (
                <div className="sm:col-span-full">
                  <SelectInput
                    label={
                      variant !== EmissionVariant.OnGoing
                        ? ` Did you till your crops in your project area from ${
                            year - 5
                          } - ${year}?`
                        : ` Did you till your crops in your project area from ${formatDate(
                            emissionActivity.activityStartDate
                          )} - ${formatDate(emissionActivity.activityEndDate)}?`
                    }
                    value={convertBooleanToYesNo(activityInfo.tillCrop)}
                    onChange={(e) =>
                      setActivityInfo({
                        ...activityInfo,
                        tillCrop: convertYesNoToBoolean(e.target.value),
                      })
                    }
                    error={
                      convertBooleanToYesNo(activityInfo.tillCrop) === undefined
                        ? 1
                        : undefined
                    }
                  >
                    <option value={""}></option>
                    {Object.entries(YesNo).map(([key, value]) => (
                      <option key={key} value={value}>
                        {key}
                      </option>
                    ))}
                  </SelectInput>
                </div>
              ) : null}
              <div className="sm:col-span-full">
                <SelectInput
                  label={
                    variant !== EmissionVariant.OnGoing
                      ? `Did you grow cover crops in your project area from ${
                          year - 5
                        } - ${year}?`
                      : `Did you grow cover crops in your project area from ${formatDate(
                          emissionActivity.activityStartDate
                        )} - ${formatDate(emissionActivity.activityEndDate)}?`
                  }
                  value={convertBooleanToYesNo(activityInfo.coverCrop)}
                  onChange={(e) =>
                    setActivityInfo({
                      ...activityInfo,
                      coverCrop: convertYesNoToBoolean(e.target.value),
                    })
                  }
                  error={
                    convertBooleanToYesNo(activityInfo.coverCrop) === undefined
                      ? 1
                      : undefined
                  }
                >
                  <option value={""}></option>
                  {Object.entries(YesNo).map(([key, value]) => (
                    <option key={key} value={value}>
                      {key}
                    </option>
                  ))}
                </SelectInput>
              </div>
              <div className="sm:col-span-full">
                <SelectInput
                  label={
                    variant !== EmissionVariant.OnGoing
                      ? `Did you till your pastures in your project area from ${
                          year - 5
                        } - ${year}?`
                      : `Did you till your pastures in your project area from ${formatDate(
                          emissionActivity.activityStartDate
                        )} - ${formatDate(emissionActivity.activityEndDate)}?`
                  }
                  value={convertBooleanToYesNo(activityInfo.tillPasture)}
                  onChange={(e) =>
                    setActivityInfo({
                      ...activityInfo,
                      tillPasture: convertYesNoToBoolean(e.target.value),
                    })
                  }
                  error={
                    convertBooleanToYesNo(activityInfo.tillPasture) ===
                    undefined
                      ? 1
                      : undefined
                  }
                >
                  <option value={""}></option>
                  {Object.entries(YesNo).map(([key, value]) => (
                    <option key={key} value={value}>
                      {key}
                    </option>
                  ))}
                </SelectInput>
              </div>
              <div className="sm:col-span-full">
                <SelectInput
                  label={
                    variant !== EmissionVariant.OnGoing
                      ? `Did you keep any livestock in your project area from ${
                          year - 5
                        } - ${year}?`
                      : `Did you keep any livestock in your project area from ${formatDate(
                          emissionActivity.activityStartDate
                        )} - ${formatDate(emissionActivity.activityEndDate)}?`
                  }
                  value={convertBooleanToYesNo(activityInfo.livestock)}
                  onChange={(e) =>
                    setActivityInfo({
                      ...activityInfo,
                      livestock: convertYesNoToBoolean(e.target.value),
                      animalType: !convertYesNoToBoolean(e.target.value)
                        ? []
                        : activityInfo.animalType,
                      historicalStockRate: !convertYesNoToBoolean(
                        e.target.value
                      )
                        ? undefined
                        : activityInfo.historicalStockRate,
                    })
                  }
                  error={
                    convertBooleanToYesNo(activityInfo.livestock) === undefined
                      ? 1
                      : undefined
                  }
                >
                  <option value={""}></option>
                  {Object.entries(YesNo).map(([key, value]) => (
                    <option key={key} value={value}>
                      {key}
                    </option>
                  ))}
                </SelectInput>
              </div>
              {activityInfo.livestock ? (
                <>
                  <div className="col-span-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      What livestock do you farm?
                    </label>
                    <div className="grid grid-cols-2 gap-2 mt-3">
                      {Object.entries(AnimalType).map(([key, value]) => (
                        <div
                          key={key}
                          className="flex flex-row items-center w-full col-span-1"
                        >
                          <Checkbox
                            checked={activityInfo.animalType.includes(
                              key as AnimalType
                            )}
                            value={key}
                            label={value}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setActivityInfo({
                                  ...activityInfo,
                                  animalType: [
                                    ...activityInfo.animalType,
                                    e.target.value as AnimalType,
                                  ],
                                });
                              } else {
                                setActivityInfo({
                                  ...activityInfo,
                                  animalType: activityInfo.animalType.filter(
                                    (animal) => animal !== key
                                  ),
                                });
                              }
                            }}
                          />
                        </div>
                      ))}
                    </div>

                    {activityInfo.animalType.length === 0 ? (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        This field is required!
                      </p>
                    ) : null}
                  </div>

                  {variant !== EmissionVariant.OnGoing && (
                    <div className="sm:col-span-full">
                      <SelectInput
                        label={`Do you know the historical stock rate for the livestock you kept between ${
                          year - 5
                        } - ${year}?`}
                        value={convertBooleanToYesNo(
                          activityInfo.historicalStockRate
                        )}
                        onChange={(e) =>
                          setActivityInfo({
                            ...activityInfo,
                            historicalStockRate: convertYesNoToBoolean(
                              e.target.value
                            ),
                          })
                        }
                        error={
                          convertBooleanToYesNo(
                            activityInfo.historicalStockRate
                          ) === undefined
                            ? 1
                            : undefined
                        }
                      >
                        <option value={""}></option>
                        {Object.entries(YesNo).map(([key, value]) => (
                          <option key={key} value={value}>
                            {key}
                          </option>
                        ))}
                      </SelectInput>
                    </div>
                  )}
                </>
              ) : null}
            </div>

            <div className="flex flex-col items-center mt-14 py-8">
              {checkForm() ? null : (
                <p
                  className="text-sm font-semibold text-red-600 pb-4"
                  id="email-error"
                >
                  Please fill all required fields to continue!
                </p>
              )}
              <div className="flex flex-row gap-x-4 items-center">
                {!checkForm() ? null : variant !== EmissionVariant.OnGoing &&
                  activityInfo.activityYears &&
                  activityInfo.activityYears.length < 5 ? (
                  <Button onClick={() => setShowWarning(true)}>Continue</Button>
                ) : (
                  <Button onClick={() => onClickContinue()}> Continue</Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </PageContainer>
  );
}
