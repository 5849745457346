interface DetailItemProps {
  icon: JSX.Element;
  title: string;
  details: string;
}

export default function DetailItem({ icon, title, details }: DetailItemProps) {
  return (
    <div className="flex flex-row items-center gap-x-1">
      {icon}
      <p className="text-sm leading-6 text-gray-500 ">
        <span className="text-shark-200">{title}:</span> {details}
      </p>
    </div>
  );
}
