import { Fragment, useMemo, useState } from "react";
import Button from "../../../components/button";
import CalculationSteps from "../../../helpers/baseline-emission/calculation-steps";
import { stepValidation } from "../../../helpers/baseline-emission/input-checker";
import { EmissionData, SummaryEmissionData } from "../../../types";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import useActivityInfo from "../../../hooks/emission/useActivityInfo";
import { api } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { FetchError } from "../../../lib/fetcher";
import OverlayLoading from "../../../components/overlay-loading";
import useProjectCalculatedEmissionData from "../../../hooks/emission/useProjectCalculatedEmissionData";

export default function Calaculations() {
  const [currentStep, setCurrentStep] = useState(0);
  const [cropInfoCurrentStep, setCropInfoCurrentStep] = useState(0);
  const [coverCropCurrentStep, setCoverCropCurrentStep] = useState(0);
  const [pastureCropCurrentStep, setPastureCropCurrentStep] = useState(0);
  const [syntheticFertilizerCurrentStep, setSyntheticFertilizerCurrentStep] =
    useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const [livestockCurrentStep, setLivestockCurrentStep] = useState(0);
  const Steps = CalculationSteps();
  const activityInfo = useActivityInfo();
  const { projectId, emissionActivityId } = useParams();
  const navigate = useNavigate();

  const {
    projectCalculatedEmissionData,
    loading: projectCalculatedEmissionDataLoading,
  } = useProjectCalculatedEmissionData(projectId, emissionActivityId);

  function AlertDialog() {
    return (
      <Transition.Root show={showWarning} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setShowWarning}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-950 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-5 w-5 text-yellow-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-700"
                      >
                        Attention!
                      </Dialog.Title>
                      <div className="mt-2 text-gray-500">
                        <p className="text-sm">
                          It is crucial to provide all requested information to
                          avoid potential negative impact on your future ACCU
                          amount.
                        </p>
                        <p className="text-sm mt-3 font-semibold">
                          Are you sure you want to continue?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-apple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-apple-500 sm:ml-3 sm:w-auto"
                      onClick={onClickContinue}
                    >
                      Continue
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-apple-600 shadow-sm ring-2 ring-inset ring-apple-600 hover:bg-apple-100 sm:mt-0 sm:w-auto"
                      onClick={() => {
                        setShowWarning(false);
                        setLoading(false);
                      }}
                    >
                      Go Back
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  function AreYouSure() {
    return (
      <Transition.Root show={areYouSure} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setAreYouSure}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-950 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-5 w-5 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-700"
                      >
                        Attention!
                      </Dialog.Title>
                      <div className="mt-2 text-red-500">
                        <p className="text-sm">
                          Once you submit your baseline emission data, you will
                          not be able to edit it.
                        </p>
                        <p className="text-sm mt-3 font-semibold">
                          Are you sure you want to submit?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-apple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-apple-500 sm:ml-3 sm:w-auto"
                      onClick={onClickSubmit}
                    >
                      Yes, I want to submit
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-apple-600 shadow-sm ring-2 ring-inset ring-apple-600 hover:bg-apple-100 sm:mt-0 sm:w-auto"
                      onClick={() => {
                        setAreYouSure(false);
                        setLoading(false);
                      }}
                    >
                      Go Back
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  function showPreviousButton() {
    if (
      currentStep === 0 &&
      cropInfoCurrentStep === 0 &&
      coverCropCurrentStep === 0 &&
      pastureCropCurrentStep === 0 &&
      syntheticFertilizerCurrentStep === 0 &&
      livestockCurrentStep === 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  const RenderComponent = useMemo(() => {
    if (Steps.length === 0) return <div>No Data</div>;

    switch (Steps[currentStep].title) {
      case "Crop Information":
        return (Steps[currentStep].component as JSX.Element[])[
          cropInfoCurrentStep
        ];
      case "Cover Crop":
        return (Steps[currentStep].component as JSX.Element[])[
          coverCropCurrentStep
        ];
      case "Pasture":
        return (Steps[currentStep].component as JSX.Element[])[
          pastureCropCurrentStep
        ];
      case "Synthetic Fertilizer":
        return (Steps[currentStep].component as JSX.Element[])[
          syntheticFertilizerCurrentStep
        ];
      case "Livestock":
        return (Steps[currentStep].component as JSX.Element[])[
          livestockCurrentStep
        ];
      default:
        return Steps[currentStep].component;
    }
  }, [
    Steps,
    coverCropCurrentStep,
    pastureCropCurrentStep,
    cropInfoCurrentStep,
    currentStep,
    syntheticFertilizerCurrentStep,
    livestockCurrentStep,
  ]);

  function onClickContinue() {
    const element = document.getElementById("scrollToMe");
    element?.scrollIntoView();

    setLoading(false);

    setShowWarning(false);
    switch (Steps[currentStep].title) {
      case "Crop Information":
        if (
          cropInfoCurrentStep ===
          (Steps[currentStep].component as JSX.Element[]).length - 1
        ) {
          setCurrentStep(currentStep + 1);
        } else {
          setCropInfoCurrentStep(cropInfoCurrentStep + 1);
        }

        break;

      case "Cover Crop":
        if (
          coverCropCurrentStep ===
          (Steps[currentStep].component as JSX.Element[]).length - 1
        ) {
          setCurrentStep(currentStep + 1);
        } else {
          setCoverCropCurrentStep(coverCropCurrentStep + 1);
        }
        break;

      case "Pasture":
        if (
          pastureCropCurrentStep ===
          (Steps[currentStep].component as JSX.Element[]).length - 1
        ) {
          setCurrentStep(currentStep + 1);
        } else {
          setPastureCropCurrentStep(pastureCropCurrentStep + 1);
        }
        break;

      case "Synthetic Fertilizer":
        if (
          syntheticFertilizerCurrentStep ===
          (Steps[currentStep].component as JSX.Element[]).length - 1
        ) {
          setCurrentStep(currentStep + 1);
        } else {
          setSyntheticFertilizerCurrentStep(syntheticFertilizerCurrentStep + 1);
        }
        break;

      case "Livestock":
        if (
          livestockCurrentStep ===
          (Steps[currentStep].component as JSX.Element[]).length - 1
        ) {
          setCurrentStep(currentStep + 1);
        } else {
          setLivestockCurrentStep(livestockCurrentStep + 1);
        }
        break;

      default:
        setCurrentStep(currentStep + 1);

        break;
    }
  }

  async function onClickNext() {
    setLoading(true);
    if (!projectId || !emissionActivityId) {
      return;
    }
    const data = localStorage.getItem("EmissionData");
    let parsedData: EmissionData | undefined = undefined;
    if (data) {
      parsedData = JSON.parse(data) as EmissionData;
    }

    switch (Steps[currentStep].title) {
      case "Irrigation Energy": {
        const irrigationEnergyData = parsedData?.irrigationEnergy;
        if (
          !irrigationEnergyData ||
          !irrigationEnergyData.length ||
          irrigationEnergyData.length < 1
        ) {
          setLoading(false);
          // return;
        } else {
          const response = await api.postIrrigationEnergy(
            +projectId,
            +emissionActivityId,
            irrigationEnergyData
          );
          if (response instanceof FetchError) {
            alert(response.error);
            setLoading(false);
            return;
          }
        }

        // Save Data

        const isValid = stepValidation({
          stepname: Steps[currentStep].title,
          data: irrigationEnergyData,
        });

        if (!isValid || !irrigationEnergyData || !irrigationEnergyData.length) {
          setShowWarning(true);
        } else {
          const element = document.getElementById("scrollToMe");
          element?.scrollIntoView();
          setCurrentStep(currentStep + 1);
          setLoading(false);
        }

        break;
      }

      case "Liming": {
        const limingData = parsedData?.limingEnergy;
        if (!limingData || !limingData.length || limingData.length < 1) {
          setLoading(false);
        } else {
          const response = await api.postLimingEnergy(
            +projectId,
            +emissionActivityId,
            limingData
          );
          if (response instanceof FetchError) {
            alert(response.error);
            return;
          }
        }

        const isValid = stepValidation({
          stepname: Steps[currentStep].title,
          data: limingData,
        });

        if (!isValid || !limingData || !limingData.length) {
          setShowWarning(true);
        } else {
          const element = document.getElementById("scrollToMe");
          element?.scrollIntoView();
          setCurrentStep(currentStep + 1);
          setLoading(false);
        }
        break;
      }

      case "Soil Landscape Modification": {
        const soilLandscapeData = parsedData?.soilLandscape;
        if (
          !soilLandscapeData ||
          !soilLandscapeData.length ||
          soilLandscapeData.length < 1
        ) {
          setLoading(false);
        } else {
          const response = await api.postSoilLandscape(
            +projectId,
            +emissionActivityId,
            soilLandscapeData
          );
          if (response instanceof FetchError) {
            alert(response.error);
            return;
          }
        }

        const isValid = stepValidation({
          stepname: Steps[currentStep].title,
          data: soilLandscapeData,
        });

        if (!isValid || !soilLandscapeData || !soilLandscapeData.length) {
          setShowWarning(true);
        } else {
          const element = document.getElementById("scrollToMe");
          element?.scrollIntoView();
          setCurrentStep(currentStep + 1);
          setLoading(false);
        }
        break;
      }

      case "Crop Tillage": {
        const cropTillageData = parsedData?.cropTillage;
        if (
          !cropTillageData ||
          !cropTillageData.length ||
          cropTillageData.length < 1
        ) {
          setLoading(false);
        } else {
          const response = await api.postCropTillage(
            +projectId,
            +emissionActivityId,
            cropTillageData
          );
          if (response instanceof FetchError) {
            alert(response.error);
            return;
          }
        }

        const isValid = stepValidation({
          stepname: Steps[currentStep].title,
          data: cropTillageData,
        });

        if (!isValid || !cropTillageData || !cropTillageData.length) {
          setShowWarning(true);
        } else {
          const element = document.getElementById("scrollToMe");
          element?.scrollIntoView();
          setCurrentStep(currentStep + 1);
          setLoading(false);
        }
        break;
      }

      case "Crop Information": {
        const cropInfoData = parsedData?.cropInformation;
        if (!cropInfoData) {
          setLoading(false);
          return;
        }

        // Save Data
        const response = await api.postCropInformation(
          +projectId,
          +emissionActivityId,
          cropInfoData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          return;
        }
        const cropInfoValid = stepValidation({
          stepname: "Crop Information",
          year: activityInfo.activityYears.sort((a, b) => b - a)[
            cropInfoCurrentStep
          ],
          data: parsedData?.cropInformation,
        });

        if (
          cropInfoCurrentStep ===
          (Steps[currentStep].component as JSX.Element[]).length - 1
        ) {
          cropInfoValid
            ? setCurrentStep(currentStep + 1)
            : setShowWarning(true);
          const element = document.getElementById("scrollToMe");
          element?.scrollIntoView();
          setLoading(false);
        } else {
          cropInfoValid
            ? setCropInfoCurrentStep(cropInfoCurrentStep + 1)
            : setShowWarning(true);
          const element = document.getElementById("scrollToMe");
          element?.scrollIntoView();
          setLoading(false);
        }

        break;
      }

      case "Cover Crop": {
        const coverCropData = parsedData?.coverCrop;
        if (!coverCropData) return;

        // Save Data
        const response = await api.postCoverCrop(
          +projectId,
          +emissionActivityId,
          coverCropData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          return;
        }

        const coverCropValid = stepValidation({
          stepname: "Cover Crop",
          year: activityInfo.activityYears.sort((a, b) => b - a)[
            coverCropCurrentStep
          ],
          data: coverCropData,
        });

        if (
          coverCropCurrentStep ===
          (Steps[currentStep].component as JSX.Element[]).length - 1
        ) {
          coverCropValid
            ? setCurrentStep(currentStep + 1)
            : setShowWarning(true);
          const element = document.getElementById("scrollToMe");
          element?.scrollIntoView();
          setLoading(false);
        } else {
          coverCropValid
            ? setCoverCropCurrentStep(coverCropCurrentStep + 1)
            : setShowWarning(true);
          const element = document.getElementById("scrollToMe");
          element?.scrollIntoView();
          setLoading(false);
        }
        break;
      }

      case "Pasture": {
        const pastureCropData = parsedData?.pastureCrop;
        if (!pastureCropData) return;

        // Save Data
        const response = await api.postPastureCrop(
          +projectId,
          +emissionActivityId,
          pastureCropData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          return;
        }

        const pastureCropValid = stepValidation({
          stepname: "Pasture",
          year: activityInfo.activityYears.sort((a, b) => b - a)[
            pastureCropCurrentStep
          ],
          data: pastureCropData,
        });

        if (
          pastureCropCurrentStep ===
          (Steps[currentStep].component as JSX.Element[]).length - 1
        ) {
          pastureCropValid
            ? setCurrentStep(currentStep + 1)
            : setShowWarning(true);
          const element = document.getElementById("scrollToMe");
          element?.scrollIntoView();
          setLoading(false);
        } else {
          pastureCropValid
            ? setPastureCropCurrentStep(pastureCropCurrentStep + 1)
            : setShowWarning(true);
          const element = document.getElementById("scrollToMe");
          element?.scrollIntoView();
          setLoading(false);
        }
        break;
      }

      case "Synthetic Fertilizer": {
        const syntheticFertilizerData = parsedData?.syntheticFertilizer;
        if (!syntheticFertilizerData) {
          setLoading(false);
          return;
        }

        // Save Data
        const response = await api.postSyntheticFertilizer(
          +projectId,
          +emissionActivityId,
          syntheticFertilizerData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          return;
        }

        const syntheticValid = stepValidation({
          stepname: "Synthetic Fertilizer",
          year: activityInfo.activityYears.sort((a, b) => b - a)[
            syntheticFertilizerCurrentStep
          ],
          data: parsedData?.syntheticFertilizer,
        });
        if (
          syntheticFertilizerCurrentStep ===
          (Steps[currentStep].component as JSX.Element[]).length - 1
        ) {
          syntheticValid
            ? setCurrentStep(currentStep + 1)
            : setShowWarning(true);
          const element = document.getElementById("scrollToMe");
          element?.scrollIntoView();
          setLoading(false);
        } else {
          syntheticValid
            ? setSyntheticFertilizerCurrentStep(
                syntheticFertilizerCurrentStep + 1
              )
            : setShowWarning(true);
          const element = document.getElementById("scrollToMe");
          element?.scrollIntoView();
          setLoading(false);
        }
        break;
      }

      case "Livestock": {
        const livestockData = parsedData?.livestock;
        if (!livestockData) return;

        // Save Data
        const response = await api.postLivestock(
          +projectId,
          +emissionActivityId,
          livestockData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          return;
        }

        setShowWarning(true);
        const element = document.getElementById("scrollToMe");
        element?.scrollIntoView();
        setLoading(false);
        break;
      }

      default: {
        setCurrentStep(currentStep + 1);
        const element = document.getElementById("scrollToMe");
        element?.scrollIntoView();
        setLoading(false);
        break;
      }
    }
  }

  function onClickPrevious() {
    const element = document.getElementById("scrollToMe");
    element?.scrollIntoView(true);

    switch (Steps[currentStep].title) {
      case "Crop Information":
        if (cropInfoCurrentStep === 0) {
          setCurrentStep(currentStep - 1);
        } else {
          setCropInfoCurrentStep(cropInfoCurrentStep - 1);
        }
        break;

      case "Cover Crop":
        if (coverCropCurrentStep === 0) {
          setCurrentStep(currentStep - 1);
        } else {
          setCoverCropCurrentStep(coverCropCurrentStep - 1);
        }
        break;

      case "Pasture":
        if (pastureCropCurrentStep === 0) {
          setCurrentStep(currentStep - 1);
        } else {
          setPastureCropCurrentStep(pastureCropCurrentStep - 1);
        }
        break;

      case "Synthetic Fertilizer":
        if (syntheticFertilizerCurrentStep === 0) {
          setCurrentStep(currentStep - 1);
        } else {
          setSyntheticFertilizerCurrentStep(syntheticFertilizerCurrentStep - 1);
        }
        break;

      case "Livestock":
        if (livestockCurrentStep === 0) {
          setCurrentStep(currentStep - 1);
        } else {
          setLivestockCurrentStep(livestockCurrentStep - 1);
        }
        break;

      default:
        setCurrentStep(currentStep - 1);
        break;
    }
  }

  async function onClickSubmit() {
    setAreYouSure(false);
    const calculatedData = localStorage.getItem("CalculatedEmissionData");
    if (!calculatedData || !projectId || !emissionActivityId) return;

    const parsedData = JSON.parse(calculatedData) as SummaryEmissionData;

    const response = await api.postSummaryData(
      +projectId,
      +emissionActivityId,
      parsedData
    );

    if (response instanceof FetchError) {
      alert(response.error);
      setLoading(false);
      return;
    }
    setLoading(false);
    localStorage.clear();
    navigate(
      `/projects/${projectId}/emission-calculator/${emissionActivityId}/finish`
    );
  }

  return (
    <div className="flex flex-1 flex-col">
      <AlertDialog />
      <AreYouSure />
      <OverlayLoading open={loading || projectCalculatedEmissionDataLoading} />
      <div className="flex flex-col w-full ">
        {!projectCalculatedEmissionData ? (
          <div className="px-2 sm:px-4 lg:px-8 pt-4 sm:pt-4 lg:pt-8 flex flex-row justify-between w-full">
            {Steps.map((step, index) => (
              <div
                key={index}
                onClick={() => {
                  if (index < Steps.length - 1) {
                    setCurrentStep(index);
                  }
                }}
                className={`w-full pb-2  ${
                  index < Steps.length - 1 ? "pr-1 cursor-pointer" : ""
                }`}
              >
                <div
                  className={`flex flex-col w-full rounded-full h-1.5 ${
                    currentStep === index ? "bg-apple-500" : "bg-gray-200"
                  }`}
                >
                  <div className="hidden sm:flex text-xxs font-medium text-gray-600 sm:pt-3">
                    {step.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
        <div className="flex flex-col flex-1 items-center mx-2 my-4 lg:my-8 lg:mx-8">
          {RenderComponent}
        </div>
        <div className="flex flex-col items-center mx-0 lg:mx-40 my-14">
          <div className="flex flex-col items-center ">
            <div className="flex flex-row w-full gap-x-4 justify-center items-center">
              {!showPreviousButton() ? null : (
                <Button
                  variant="outline"
                  onClick={() => {
                    onClickPrevious();
                  }}
                >
                  Previous
                </Button>
              )}

              {projectCalculatedEmissionData ? null : Steps.length ===
                currentStep + 1 ? (
                <Button
                  onClick={() => {
                    setLoading(true);
                    setAreYouSure(true);
                  }}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    onClickNext();
                  }}
                >
                  Continue
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
