import { useEffect, useState } from "react";
import { KYB } from "../types";
import { FetchError } from "../lib/fetcher";
import { api } from "../api";

export default function useKYBs(userId?: number) {
  const [kybs, setKybs] = useState<KYB[]>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    async function getProject() {
      if (!userId) {
        return;
      }
      const response = await api.getKYBs(userId);
      if (response instanceof FetchError) {
        alert(response.message);
        setLoading(false);
        setError(response.message);
        return;
      }
      setKybs(response.kybs);
      setLoading(false);
    }

    getProject(); // remove the return statement
  }, [userId]);

  return { kybs, loading, error };
}
