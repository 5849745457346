import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG || ""
);

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
