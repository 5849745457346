import { useMemo, useState } from "react";
import { formatMonthYear } from "../../helpers/format-month-year";
import EmptyPlaceholder from "../../components/empty-placeholder";
import Modal from "../../components/modal";
import { EmissionAttachment, EmissionActivityInfo } from "../../types";
import SelectInput from "../../components/select-input";
import {
  CropTillageAttachmentType,
  EmissionAttachmentType,
  EmissionVariant,
  IrrigationEnergyAttachmentType,
  LimingEnergyAttachmentType,
  SoilLandscapeAttachmentType,
} from "../../constants";
import { PaperClipIcon, PhotoIcon } from "@heroicons/react/24/outline";
import Button from "../../components/button";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";
import formatDate from "../../helpers/format-date";
import OverlayLoading from "../../components/overlay-loading";

interface BaselineEmissionAttachmentsProps {
  variant?: EmissionVariant;
  emissionActivity?: EmissionActivityInfo;
  month?: number;
  projectId: number;
  emissionActivityId: number;
  year: number;
  title: string;
  attachments?: EmissionAttachment[];
  attachmentKind?: EmissionAttachmentType;
  mutateAttachments: (attachment: EmissionAttachment) => void;
  attachmentTypes?: string[];
  uploadRequired?: boolean;
}

export default function EmissionAttachments({
  variant,
  emissionActivity,
  month,
  year,
  projectId,
  emissionActivityId,
  title,
  attachments,
  attachmentKind,
  mutateAttachments,
  attachmentTypes,
  uploadRequired,
}: BaselineEmissionAttachmentsProps) {
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [attachment, setAttachment] = useState<EmissionAttachment>();
  const [filteredAttachmentsType, setFilteredAttachmentsType] = useState<
    [string, string][]
  >([]);
  const [filteredAttachments, setFilteredAttachments] =
    useState<EmissionAttachment[]>();
  const [loadingAttachment, setLoadingAttachment] = useState(false);

  useMemo(() => {
    if (!attachmentKind) return;

    switch (attachmentKind) {
      case EmissionAttachmentType.IrrigationEnergy:
        setFilteredAttachmentsType(
          attachmentTypes
            ? attachmentTypes.map((type) => [type, type])
            : Object.entries(IrrigationEnergyAttachmentType)
        );
        break;
      case EmissionAttachmentType.LimingEnergy:
        setFilteredAttachmentsType(Object.entries(LimingEnergyAttachmentType));
        break;

      case EmissionAttachmentType.SoilLandscape:
        setFilteredAttachmentsType(Object.entries(SoilLandscapeAttachmentType));
        break;

      case EmissionAttachmentType.CropTillage:
        setFilteredAttachmentsType(Object.entries(CropTillageAttachmentType));
        break;

      default:
        setFilteredAttachmentsType(
          attachmentTypes ? attachmentTypes.map((type) => [type, type]) : []
        );
        break;
    }
  }, [attachmentKind, attachmentTypes]);

  useMemo(() => {
    if (!attachments || !year) {
      return;
    }
    setFilteredAttachments(
      attachments.filter(
        (attachment) =>
          attachment.activityYear && attachment.activityYear === year
      )
    );
  }, [attachments, year]);

  async function getAttachmentUrl(id?: number) {
    if (!projectId || !emissionActivityId || !id) return;

    setLoadingAttachment(true);
    const response = await api.getEmissionAttachmentUrlById(
      +projectId,
      +emissionActivityId,
      id
    );

    if (response instanceof FetchError) {
      alert("There was an error fetching the attachment");
      console.log(response);
      setLoadingAttachment(false);
      return;
    }
    setLoadingAttachment(false);
    window.open(response.url, "_blank");
  }

  async function uploadAttachment() {
    if (!attachment) return;
    if (
      !attachment.attachmentType ||
      !attachment.attachmentUrl ||
      !attachment.activityYear ||
      !attachment.attachmentKind ||
      !attachment.attachmentName ||
      !projectId ||
      !emissionActivityId
    )
      return;
    setLoadingAttachment(true);
    const response = await api.postEmissionAttachment(
      projectId,
      emissionActivityId,
      attachment
    );
    if (response instanceof FetchError) {
      alert("There was an error uploading the attachment. Please try again");
      console.log(response);
      setLoadingAttachment(false);
      return;
    }
    mutateAttachments(response.baselineEmissionAttachment);
    setAttachment(undefined);
    setLoadingAttachment(false);
  }

  function AddAttachmentModal() {
    return (
      <Modal
        open={openAttachmentModal}
        setOpen={setOpenAttachmentModal}
        title={`Upload ${
          variant !== EmissionVariant.OnGoing ? year : ""
        } ${title} Attachment`}
        actionButtonName="Upload"
        actionButtonType={
          !attachment || !attachment.attachmentType || !attachment.attachmentUrl
            ? "disabled"
            : "primary"
        }
        onPressCancel={() => {
          setOpenAttachmentModal(false);
          setAttachment(undefined);
        }}
        onPressAdd={() => {
          if (!attachment) return;

          if (!attachment.attachmentType || !attachment.attachmentUrl) return;
          setOpenAttachmentModal(false);
          uploadAttachment();
        }}
      >
        <div className="flex flex-1 flex-col w-full">
          <div className="">
            {filteredAttachmentsType.length ? (
              <SelectInput
                label="Proof of Activity"
                onChange={(e) => {
                  setAttachment({
                    ...attachment,
                    activityYear: year,
                    attachmentKind: attachmentKind,
                    attachmentType: e.target.value,
                  });
                }}
                error={!attachment?.attachmentType ? 1 : undefined}
                value={attachment?.attachmentType ?? ""}
              >
                <option value={""}></option>
                {filteredAttachmentsType.map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </SelectInput>
            ) : null}
          </div>
          <div className="mt-2">
            <label
              htmlFor="cover-photo"
              className="block text-xs font-medium text-gray-900"
            >
              File
            </label>

            {attachment && attachment.attachmentUrl ? (
              <div className="divide-y divide-gray-100 rounded-md border border-gray-200">
                <div className="flex items-center justify-between p-2 text-sm leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex flex-col">
                      <div className="flex min-w-0 flex-1 gap-2">
                        <span className="truncate font-medium">
                          {attachment?.attachmentType}
                        </span>
                      </div>
                      <p className="text-xs text-gray-500">
                        {attachment.attachmentName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="mt-1 flex justify-center rounded-xl border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center items-center">
                    <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 flex flex-row justify-center items-center text-sm leading-6 text-gray-600 text-center">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer text-center rounded-md bg-white font-semibold text-apple-600 py-1 px-2 border-2 border-apple-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-apple-600 focus-within:ring-offset-2 hover:text-apple-500"
                      >
                        <span className="text-center">Add a file</span>
                        <input
                          onChange={(e) => {
                            if (!e.target.files) {
                              alert("Please select a file");
                              return;
                            }
                            if (e.target.files.length > 1) {
                              alert("Please select only one file");
                              return;
                            }
                            if (!e.target.files[0]) {
                              alert("Please select a file");
                              return;
                            }
                            if (e.target.files[0].size > 5242880) {
                              alert("File size should be less than 5MB");
                              return;
                            }
                            const newFile = new FileReader();
                            const file = e.target.files[0];
                            newFile.readAsDataURL(file);
                            newFile.onload = () => {
                              setAttachment({
                                ...attachment,
                                activityYear: year,
                                attachmentName: file.name,
                                attachmentUrl: newFile.result as string,
                              });
                            };
                          }}
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          accept="image/*,.doc,.docx,.pdf"
                        />
                      </label>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      Image, Word, PDF up to 5MB
                    </p>
                  </div>
                </div>
                <p className="mt-0.5 text-xs text-red-600" id="email-error">
                  Please add a file to upload!
                </p>
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }

  return uploadRequired !== false ? (
    <>
      <OverlayLoading open={loadingAttachment} />
      {openAttachmentModal && <AddAttachmentModal />}
      <div className="flex-col w-full pt-2">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="w-2/3">
            <h2 className="text-md sm:text-lg font-semibold leading-7 text-apple-700">
              {variant !== EmissionVariant.OnGoing ? year : ""} {title}{" "}
              Attachments
            </h2>
          </div>
          <div>
            <Button
              size="sm"
              variant="outline"
              onClick={() => setOpenAttachmentModal(true)}
            >
              Upload File
            </Button>
          </div>
        </div>
        <p className="mt-1 text-xs text-gray-500">
          Please upload any supporting documents for <strong>{title}</strong>{" "}
          between{" "}
          <strong>
            {variant !== EmissionVariant.OnGoing ? (
              formatMonthYear(month ?? 1, year)
            ) : (
              <span>
                {formatDate(emissionActivity?.activityStartDate)} -{" "}
                {formatDate(emissionActivity?.activityEndDate)}
              </span>
            )}
          </strong>
          .
        </p>
      </div>

      <div className="mt-4 w-full grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-12 px-2 sm:px-4">
        {filteredAttachments && filteredAttachments.length ? (
          filteredAttachments.map((attachment, index) => (
            <div
              key={index}
              className="sm:col-span-6 rounded-xl border border-gray-200 bg-gray-50"
            >
              <div className="flex items-center justify-between p-2 text-sm">
                <div className="flex w-0 flex-1 items-center">
                  <PaperClipIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <div className="ml-4 flex flex-col">
                    <div className="flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">
                        {attachment.attachmentType}
                      </span>
                    </div>
                    <p className="text-xs text-gray-500">
                      {attachment.attachmentName}
                    </p>
                  </div>
                </div>

                <div className="ml-4 flex-shrink-0">
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => getAttachmentUrl(attachment.id)}
                  >
                    View
                  </Button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-full">
            <EmptyPlaceholder>No file uploaded yet.</EmptyPlaceholder>
          </div>
        )}
      </div>
    </>
  ) : null;
}
