import { useEffect, useState } from "react";
import { EmissionActivityInfo } from "../../types";

export default function useEmissionActivity() {
  const [emissionActivity, setEmissionActivity] =
    useState<EmissionActivityInfo>();

  useEffect(() => {
    const data = localStorage.getItem("EmissionActivity");

    if (data) {
      const parsedData = JSON.parse(data);
      const emissionActivity = parsedData as EmissionActivityInfo;

      setEmissionActivity(emissionActivity);
    }
  }, []);

  return emissionActivity;
}
