import { useEffect, useState } from "react";
import { EmissionActivityInfo } from "../../types";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";

export default function useEmissionActivity(
  projectId?: string,
  emissionActivityId?: string
) {
  const [emissionActivity, setEmissionActivity] = useState<
    EmissionActivityInfo | undefined
  >({} as EmissionActivityInfo);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    async function getEmissionActivityInfo() {
      if (!projectId || !emissionActivityId) {
        setLoading(false);
        return;
      }
      const response = await api.getEmissionActivityInfo(
        +projectId,
        +emissionActivityId
      );
      if (response instanceof FetchError) {
        setLoading(false);
        setError(response.message);
        setEmissionActivity(undefined);
        return;
      }
      setEmissionActivity(response.emissionActivity);
      setLoading(false);
    }

    getEmissionActivityInfo();
  }, [emissionActivityId, projectId]);

  return { emissionActivity, loading, error };
}
