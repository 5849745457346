import { Player } from "@lottiefiles/react-lottie-player";
import LoadingAnimation from "../assets/lottie/loading.json";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

interface OverlayLoadingProps {
  open: boolean;
}

export default function OverlayLoading({ open }: OverlayLoadingProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          return;
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-apple-100 bg-opacity-80 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden bg-transparent px-4 pb-4 pt-5 text-left transition-all sm:my-8 w-full sm:max-w-2xl sm:p-6">
                <div className="flex flex-1 flex-col justify-center bg-transparent">
                  <Player
                    autoplay
                    loop
                    controls
                    src={LoadingAnimation}
                    style={{
                      height: "300px",
                      width: "300px",
                    }}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
