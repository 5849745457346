import { Link, useParams } from "react-router-dom";
import useProject from "../hooks/useProject";
import Loading from "../components/loading";
import DetailItem from "../components/detail-item";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { EmissionActivityStatus, EmissionVariant, Month } from "../constants";
import useProjectEmissionActivities from "../hooks/emission/useProjectEmissionActivities";
import { useMemo } from "react";
import EmptyPlaceholder from "../components/empty-placeholder";
import Badge from "../components/badge";
import Button from "../components/button";
import formatDate from "../helpers/format-date";
import Divider from "../components/divider";
import EmptyPage from "../components/empty-page";

export default function EmissionCalculator() {
  const { projectId } = useParams();
  const { project, loading: projectLoading, error } = useProject(projectId);
  const { allEmissionActivity, loading: loadingAllEmissionActivity } =
    useProjectEmissionActivities(projectId);

  const baselineEmissions = useMemo(() => {
    if (!allEmissionActivity) {
      return [];
    }
    const baselineEmission = allEmissionActivity.filter(
      (emission) => emission.activityType === EmissionVariant.Baseline
    );
    if (!baselineEmission[0]) return [];
    localStorage.setItem(
      "baselineEmissionId",
      baselineEmission[0].id?.toString() ?? ""
    );
    return baselineEmission;
  }, [allEmissionActivity]);

  const ongoingEmissions = useMemo(() => {
    if (!allEmissionActivity) {
      return [];
    }
    return allEmissionActivity
      .filter((emission) => emission.activityType === EmissionVariant.OnGoing)
      .sort((a, b) => {
        const dateA = new Date(a.activityStartDate ?? "");
        const dateB = new Date(b.activityStartDate ?? "");
        return dateB.getTime() - dateA.getTime();
      });
  }, [allEmissionActivity]);

  return (
    <div className="flex flex-col w-full pb-4">
      {projectLoading || loadingAllEmissionActivity ? (
        <Loading />
      ) : project ? (
        <>
          <header className="relative isolate">
            <div
              className="absolute inset-0 -z-10 overflow-hidden items-center justify-center"
              aria-hidden="true"
            >
              <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
                <div
                  className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-apple-500 to-[#9089FC]"
                  style={{
                    clipPath:
                      "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
                  }}
                />
              </div>

              <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
            </div>
            <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
              <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                <div className="flex flex-col items-start gap-x-6">
                  <h3 className="text-lg lg:text-2xl font-semibold leading-6 text-gray-900 text-ellipsis">
                    {project.projectInfo.name}
                  </h3>
                  <div className="flex flex-col sm:flex-row sm:items-center gap-y-1 sm:gap-x-4 ">
                    <DetailItem
                      icon={
                        <CalendarDaysIcon className="h-4 w-4  text-apple-500" />
                      }
                      title="Registration Date"
                      details={`${
                        Month[(project.projectInfo.carbonRegMonth ?? 0) - 1]
                      } ${project.projectInfo.carbonRegYear}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </header>

          <main className="flex flex-col flex-1 px-8 h-full overflow-y-hidden">
            <div className="flex flex-col mt-4 w-full">
              {loadingAllEmissionActivity ? (
                <Loading />
              ) : (
                <div className="flex flex-col mb-8 flex-1 w-full">
                  {!baselineEmissions.length && !ongoingEmissions.length ? (
                    <div className="flex flex-col flex-1 w-full text-center">
                      <EmptyPage
                        text={
                          <>
                            <p className="text-sm sm:text-lg font-medium text-gray-900 text-center">
                              No Emission Activity at this time.
                            </p>
                            <p className="text-xs sm:text-sm text-gray-500 text-center">
                              Please check back later.
                            </p>
                          </>
                        }
                      />
                    </div>
                  ) : null}
                  {ongoingEmissions && ongoingEmissions.length > 0 ? (
                    <>
                      <h1 className="text-base font-semibold text-shark-200">
                        Ongoing Emission
                      </h1>
                      <>
                        {ongoingEmissions.length > 0 ? (
                          <div className="mx-auto mt-4 px-4 grid max-w-2xl auto-rows-fr grid-cols-1 gap-4 lg:gap-8 lg:mx-0 lg:max-w-none sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            {ongoingEmissions.map((emission, i) => (
                              <div
                                key={i}
                                className="block overflow-hidden rounded-xl bg-apple-200/10 border border-apple-700/20 px-8 py-8 shadow-sm hover:bg-apple-300/10 hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-11"
                              >
                                <Link
                                  to={`/projects/${projectId}/emission-calculator/${emission.id}`}
                                >
                                  <div className="flex flex-col items-start gap-y-2">
                                    <p className="text-xxs text-gray-600 font-medium">
                                      {emission.activityType}
                                    </p>
                                    <p className="text-2xl font-semibold leading-6">
                                      {formatDate(emission.activityStartDate)} -{" "}
                                      {formatDate(emission.activityEndDate)}
                                    </p>

                                    <Badge
                                      variant={
                                        emission.activityStatus ===
                                        EmissionActivityStatus.InProgress
                                          ? "warning"
                                          : "success"
                                      }
                                    >
                                      {emission.activityStatus}
                                    </Badge>

                                    <Button variant="outline" size="sm">
                                      View
                                    </Button>
                                  </div>
                                </Link>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="flex flex-col flex-1 w-full text-center p-12">
                            <EmptyPlaceholder>
                              No Ongoing Emission Activity. Request an On-Going
                              Emission Activity to get started.
                            </EmptyPlaceholder>
                          </div>
                        )}
                      </>
                      <Divider />
                    </>
                  ) : null}

                  {baselineEmissions && baselineEmissions.length > 0 ? (
                    <>
                      <h1 className="text-base font-semibold text-shark-200">
                        Baseline Emission
                      </h1>
                      <div className="mx-auto mt-4 px-4 grid max-w-2xl auto-rows-fr grid-cols-1 gap-4 lg:gap-8 lg:mx-0 lg:max-w-none sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                        {baselineEmissions.map((emission, i) => (
                          <Link
                            key={i}
                            to={`/projects/${projectId}/emission-calculator/${emission.id}`}
                          >
                            <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-xl bg-apple-200/10 border border-apple-700/20 px-8 py-8 shadow-sm hover:bg-apple-300/10 hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-11">
                              <div className="flex flex-col items-start gap-y-2">
                                <p className="text-xxs text-gray-600 font-medium">
                                  {
                                    Month[
                                      (project?.projectInfo.carbonRegMonth ??
                                        0) - 1
                                    ]
                                  }{" "}
                                  {project?.projectInfo.carbonRegYear}
                                </p>
                                <p className="text-3xl pt-6  font-semibold leading-6">
                                  {emission.activityType}
                                </p>

                                <Badge>{emission.activityStatus}</Badge>

                                <Button variant="outline" size="sm">
                                  View
                                </Button>
                              </div>
                            </article>
                          </Link>
                        ))}
                      </div>
                    </>
                  ) : null}
                </div>
              )}
            </div>
          </main>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          {/* <h1 className="text-2xl font-semibold">Project not found</h1> */}
          <p className="text-gray-500">{error}</p>
        </div>
      )}
    </div>
  );
}
