import {
  CoverCrop,
  CropInformation,
  CropTillage,
  IrrigationEnergy,
  LimingEnergy,
  PastureCrop,
  SoilLandscape,
  SyntheticFerterlizer,
} from "../../types";

interface CalculationStepValidation {
  stepname: string;
  data?:
    | CoverCrop[]
    | CropInformation[]
    | CropTillage[]
    | IrrigationEnergy[]
    | LimingEnergy[]
    | PastureCrop[]
    | SoilLandscape[]
    | SyntheticFerterlizer[];
  year?: number;
}

export function stepValidation({
  stepname,
  year,
  data,
}: CalculationStepValidation) {
  switch (stepname) {
    case "Irrigation Energy": {
      const irrigationEnergy = data as IrrigationEnergy[];
      if (!irrigationEnergy) return false;
      const validInput = irrigationEnergy.map((item) => {
        if (!item) return false;
        if (item.electrictyQuantity === undefined) return false;
        if (item.fuelQuantity === undefined) return false;
        return true;
      });
      return validInput.filter((item) => item === false).length === 0;
    }

    case "Liming": {
      const limeApplication = data as LimingEnergy[];
      if (!limeApplication) return false;
      const validLimeApplication = limeApplication.map((item) => {
        if (!item) return false;
        if (item.dolomiteQuantity === undefined) return false;
        if (item.limestoneQuantity === undefined) return false;
        return true;
      });
      return validLimeApplication.filter((item) => item === false).length === 0;
    }

    case "Synthetic Fertilizer": {
      const syntheticFertilizer = data as SyntheticFerterlizer[];
      if (!syntheticFertilizer) return false;

      const syntheticFertilizerYearData = syntheticFertilizer.find(
        (item) => item.activityYear === year
      );
      if (
        !syntheticFertilizerYearData ||
        !syntheticFertilizerYearData.ureaQuantity ||
        !syntheticFertilizerYearData.ferterlizerLocationData.length
      )
        return false;

      const validSyntheticFertilizer =
        syntheticFertilizerYearData.ferterlizerLocationData.map((item) => {
          if (!item) return false;
          if (!item.location) return false;
          if (!item.locationData) return false;
          const t = Object.values(item.locationData).map((location) => {
            if (Object.values(location).length === 1) return false;
            return true;
          });

          if (t.filter((item) => item === false).length > 0) return false;

          return true;
        });

      return (
        validSyntheticFertilizer.filter((item) => item === false).length === 0
      );
    }

    case "Crop Information": {
      const cropInformation = data as CropInformation[];
      if (!cropInformation) return false;

      const cropInformationYearData = cropInformation.find(
        (item) => item.activityYear === year
      );
      if (
        !cropInformationYearData ||
        !cropInformationYearData.cropInformationData ||
        !cropInformationYearData.cropInformationData.length
      )
        return false;

      const validCropInformation =
        cropInformationYearData.cropInformationData.map((item) => {
          if (!item) return false;
          if (!item.crop) return false;
          if (!item.harvestSize) return false;
          if (item.burnResidue === undefined) return false;
          return true;
        });
      return validCropInformation.filter((item) => item === false).length === 0;
    }

    case "Cover Crop": {
      const coverCrop = data as CoverCrop[];
      if (!coverCrop) return false;

      const coverCropYearData = coverCrop.find(
        (item) => item.activityYear === year
      );
      if (
        !coverCropYearData ||
        !coverCropYearData.coverCropData ||
        !coverCropYearData.coverCropData.length
      )
        return false;

      const validCoverCrop = coverCropYearData.coverCropData.map((item) => {
        if (!item) return false;
        if (!item.areaSown) return false;
        return true;
      });
      return validCoverCrop.filter((item) => item === false).length === 0;
    }

    case "Pasture": {
      const pastureCrop = data as PastureCrop[];
      if (!pastureCrop) return false;

      const pastureCropYearData = pastureCrop.find(
        (item) => item.activityYear === year
      );
      if (
        !pastureCropYearData ||
        !pastureCropYearData.pastureCropData ||
        !pastureCropYearData.pastureCropData.length
      )
        return false;

      const validPastureCrop = pastureCropYearData.pastureCropData.map(
        (item) => {
          if (!item) return false;
          if (!item.areaTilled) return false;
          return true;
        }
      );
      return validPastureCrop.filter((item) => item === false).length === 0;
    }

    case "Soil Landscape Modification": {
      const soilLandscapeData = data as SoilLandscape[];
      if (!soilLandscapeData) return false;

      const validSoil = soilLandscapeData.map((item) => {
        if (!item) return false;
        if (!item.dieselQuantity) return false;
        return true;
      });
      return validSoil.filter((item) => item === false).length === 0;
    }

    case "Crop Tillage": {
      const cropTillage = data as CropTillage[];
      if (!cropTillage) return false;

      const validCropTillage = cropTillage.map((item) => {
        if (!item) return false;
        if (!item.areaTilled) return false;
        return true;
      });
      return validCropTillage.filter((item) => item === false).length === 0;
    }
    default:
      return true;
  }
}
