import { Player } from "@lottiefiles/react-lottie-player";
import LoadingAnimation from "../../assets/lottie/finish.json";
import { useParams } from "react-router-dom";
import useKyb from "../../hooks/useKyb";
import Loading from "../../components/loading";
import { CustomerVerificationEmail } from "../../constants";

export default function FinishPage() {
  const { kybId } = useParams();
  const { kyb } = useKyb(kybId);
  return !kyb ? (
    <Loading />
  ) : (
    <div className="flex flex-col h-full justify-center px-6 sm:px-20">
      <div className="flex flex-1 mt-4 lg:mt-20 flex-col-reverse lg:flex-row justify-between items-end">
        <div className="flex flex-col justify-center items-left lg:pr-8">
          <p className="text-base lg:text-lg leading-6  text-gray-700">
            Thank you very much for providing us with the required information
            in this Know Your Business (KYB) data collection form for{" "}
            <strong className="text-apple-700">{kyb.kybYear}.</strong> If there
            are additional parties indicated in this form that Loam Carbon has
            to collect additional Know your Customer (KYC) information on, we
            will reach out to them separately.
          </p>

          <p className="mt-4 text-base lg:text-lg leading-6  text-gray-700">
            We understand that some of this information is not always easily
            accessible. The form sits within your custom grower portal and you
            will be able to access it anytime until you hit the “Submit” button
            below. As you gather the information you need, please revisit this
            form with updates. Once you are confident that all the information
            has been filled into the form, please hit the “
            <strong>Submit</strong>” button.
          </p>

          <p className="mt-4 text-base lg:text-lg leading-6  text-gray-700">
            After you hit “<strong>Submit</strong>”, the Loam Financial
            Compliance team will work to verify the information and documents
            you have provided in this form. We will notify you via email to
            confirm the completion of your KYB check. As always, please feel
            free to reach out to{" "}
            <a
              className="font-semibold text-apple-700"
              href={`mailto:${CustomerVerificationEmail}`}
            >
              financial compliance
            </a>{" "}
            with any questions.
          </p>
        </div>
        <Player
          className="w-full lg:pl-8"
          autoplay
          direction={-1}
          loop
          controls
          src={LoadingAnimation}
          style={{
            height: "auto",
            width: "300px",
          }}
        />
      </div>
    </div>
  );
}
