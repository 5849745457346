import { useNavigate } from "react-router-dom";
import Button from "../../components/button";

export default function Finish() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <div className="mt-10 px-4">
          <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
            Thank You.
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 mb-8">
            Thank you for filling out our greenhouse emission calculator!
          </p>
          <h2 className="text-base font-semibold leading-7 text-apple-700">
            What's Next?
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            The Loam team will review your responses and reach out if we have
            any questions. Your average annual emissions calculated here will be
            incorporated into your carbon project documents and submitted to the
            Clean Energy Regulator.
          </p>

          <p className="mt-2 text-sm leading-6 text-gray-600">
            If you have any questions, please don't hesitate to reach out to
            your Loam Carbon Agronomist.
          </p>

          <p className="mt-2 text-sm leading-6 text-gray-600">
            Thanks so much for taking the time,
          </p>

          <p className="mt-2 font-semibold text-sm leading-6 text-gray-600">
            The Loam Team.
          </p>
        </div>
      </div>
      <Button
        size="sm"
        variant="black-outline"
        onClick={() => navigate("/projects")}
      >
        Exit
      </Button>
    </div>
  );
}
