interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: boolean;
  image?: string;
}

export default function Checkbox({ label, ...props }: Props) {
  return (
    <div className="relative flex items-center">
      <div className="flex flex-row h-8 items-center">
        <input
          {...props}
          id={label}
          name={label}
          type="checkbox"
          className={`h-5 w-5 rounded border-gray-300 text-apple-500 focus:ring-apple-500 ${
            props.disabled ? "bg-gray-100" : "bg-white cursor-pointer"
          }`}
        />
      </div>
      <div className="ml-3 w-full text-sm sm:text-base leading-6 ">
        <label
          htmlFor={label}
          className={`text-sm font-medium leading-4 text-gray-700 inline-block ${
            props.disabled ? "text-gray-400" : "text-gray-700 cursor-pointer "
          }`}
        >
          {label}
        </label>
      </div>
    </div>
  );
}
