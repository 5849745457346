import { Link, useParams } from "react-router-dom";
import image from "../../assets/background2.jpg";
import useProject from "../../hooks/useProject";
import Loading from "../../components/loading";
import { useEffect } from "react";
import { CalendarDaysIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { Month } from "../../constants";
import DetailItem from "../../components/detail-item";

export default function Project() {
  const { projectId } = useParams();
  const { project, loading, error } = useProject(projectId);

  useEffect(() => {
    if (!project) return;
    localStorage.setItem(
      "FarmInfo",
      JSON.stringify({
        state: project.projectInfo.address.stateProvince,
        region: project.projectInfo.address.australianRegion,
        carbonRegMonth: project.projectInfo.carbonRegMonth,
        carbonRegYear: project.projectInfo.carbonRegYear,
      })
    );
  }, [project]);

  return (
    <div className="flex flex-col w-full pb-4">
      {loading ? (
        <Loading />
      ) : project ? (
        <>
          <header className="relative isolate">
            <div
              className="absolute inset-0 -z-10 overflow-hidden items-center justify-center"
              aria-hidden="true"
            >
              <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
                <div
                  className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-apple-500 to-[#9089FC]"
                  style={{
                    clipPath:
                      "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
                  }}
                />
              </div>

              <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
            </div>
            <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
              <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                <div className="flex flex-col items-start gap-x-6">
                  <h3 className="text-2xl lg:text-4xl font-semibold leading-6 text-gray-900">
                    {project.projectInfo.name}
                  </h3>
                  <div className="flex flex-col sm:flex-row sm:items-center gap-y-1 sm:gap-x-4 mt-2">
                    <DetailItem
                      icon={
                        <CalendarDaysIcon className="h-4 w-4  text-apple-500" />
                      }
                      title="Registration Date"
                      details={`${
                        Month[(project.projectInfo.carbonRegMonth ?? 0) - 1]
                      } ${project.projectInfo.carbonRegYear}`}
                    />

                    <DetailItem
                      icon={<MapPinIcon className="h-4 w-4  text-apple-500" />}
                      title="Location"
                      details={`${project.projectInfo.address.stateProvince}, ${project.projectInfo.address.country}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </header>
          <main>
            <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
              <div className="flex-1 w-full h-full">
                <div className="mx-auto grid max-w-2xl auto-rows-fr grid-cols-1 gap-4 lg:gap-8 lg:mx-0 lg:max-w-none sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl px-8 pb-8 pt-60 sm:pt-48 lg:pt-60 hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-11">
                    <img
                      loading="lazy"
                      src={image}
                      alt=""
                      className="absolute inset-0 -z-10 h-full w-full object-cover"
                    />
                    <div className="absolute inset-0 -z-10 bg-gradient-to-t from-apple-950 via-apple-950/40" />
                    <div className="absolute inset-0 -z-10 rounded-xl ring-1 ring-inset ring-gray-900/10" />

                    <div className="flex flex-col items-start gap-y-1 text-sm leading-6 text-apple-300">
                      <div className="flex items-center">
                        <p className="flex font-semibold">Baseline / Ongoing</p>
                      </div>
                    </div>
                    <h3 className="mt-1 text-lg lg:text-2xl font-semibold leading-6 text-white">
                      <Link to={`/projects/${projectId}/emission-calculator`}>
                        <span className="absolute inset-0" />
                        <span className="relative">Emission Calculator</span>
                      </Link>
                    </h3>
                  </article>
                </div>
              </div>
            </div>
          </main>
        </>
      ) : (
        <p>{error}</p>
      )}
    </div>
  );
}
