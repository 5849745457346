import {
  AnimalType,
  BeefCattleType,
  CropsPlanted,
  DairyCattleType,
  PastureCrops,
  Region,
  SheepType,
  AustralianState,
  SyntheticFertilizerLocation,
} from "../../constants";
import {
  beefCattleEmissionFactors,
  coverCropFactors,
  cropResidueFactors,
  dairyCattleEmissionFactors,
  dieselFactors,
  electrictyFactors,
  genericLivestockEmissionFactors,
  limingFactors,
  nitrousOxideFactors,
  residueDecompositionFactor,
  sheepEmissionFactors,
  tillageFuelQuantity,
  ureaFactor,
} from "../../factors";
import {
  BeefCattleData,
  CoverCrop,
  CoverCropData,
  CropInformation,
  CropInformationData,
  CropTillage,
  DairyCattleData,
  LivestockTypeData,
  PastureCrop,
  PastureCropData,
  SheepData,
  SoilLandscape,
  SyntheticFertilizerLocationData,
} from "../../types";
import { GetObjectValue } from "../getObjectValue";

export default new (class calculationFunctions {
  public calculateLimeEmission(limeQuantity: number) {
    return (
      limeQuantity *
      limingFactors.limestone.carbonateContent *
      limingFactors.limestone.emissionFactor
    );
  }

  public calculateDolomiteEmission(dolomiteQuantity: number) {
    return (
      dolomiteQuantity *
      limingFactors.dolomite.carbonateContent *
      limingFactors.dolomite.emissionFactor
    );
  }

  public calculateFuelEmission(fuelQuantity: number) {
    return (
      (fuelQuantity *
        dieselFactors.energyContentFactor *
        dieselFactors.emissionFactor) /
      1000
    );
  }

  public calculateElectricityEmission(
    electricityQuantity: number,
    gridElectricity: boolean
  ) {
    return (
      electricityQuantity *
      ((gridElectricity
        ? electrictyFactors.on_grid
        : electrictyFactors.off_grid) /
        1000)
    );
  }

  public calculateNitriousOxideEmission(
    state: AustralianState,
    locationData: SyntheticFertilizerLocationData
  ) {
    const stateFactor = nitrousOxideFactors.find(
      (item) => item.state === state
    );
    const fertiliserLocation = Object.keys(SyntheticFertilizerLocation)[
      Object.values(SyntheticFertilizerLocation).indexOf(
        locationData.location as unknown as SyntheticFertilizerLocation
      )
    ] as keyof typeof SyntheticFertilizerLocation;

    const factor = stateFactor?.factors[fertiliserLocation];

    if (!factor || !locationData.locationData) {
      return 0;
    }

    const nitrousEmission = Object.values(locationData.locationData).map(
      (item) => {
        return (
          ((item?.nitrogenContent || 0) / 100) *
          (item?.quantityApplied || 0) *
          factor
        );
      }
    );
    return nitrousEmission.reduce((a, b) => a + b, 0);
  }

  public ureaEmission(nitrousEmission: number[], ureaQuantity: number) {
    const ureaMultiplier = (ureaQuantity || 0) * ureaFactor;
    return nitrousEmission.reduce((a, b) => a + b, 0) + ureaMultiplier;
  }

  public calculateTillageAndSoilLandscapeEmission(
    soilLandscape: SoilLandscape,
    cropTillage: CropTillage
  ) {
    const numeratorA =
      (cropTillage.areaTilled || 0) *
      tillageFuelQuantity *
      dieselFactors.energyContentFactor *
      dieselFactors.emissionFactorMoving;

    const numeratorB =
      (soilLandscape.dieselQuantity || 0) *
      dieselFactors.energyContentFactor *
      dieselFactors.emissionFactorMoving;

    const A = numeratorA / 1000;
    const B = numeratorB / 1000;

    return A + B;
  }

  public calculateCropInformationEmission(
    cropInformation: CropInformationData
  ) {
    const cropFactor = cropResidueFactors.find(
      (item) =>
        item.cropType ===
        GetObjectValue(CropsPlanted, cropInformation.crop as CropsPlanted)
    );

    if (!cropFactor) return 0;

    const A =
      (cropInformation.harvestSize || 0) *
      (cropFactor?.factors.rcv || 0) *
      (cropFactor?.factors.dmv || 0) *
      residueDecompositionFactor;
    const B1A =
      1 - (cropInformation.burnResidue ? cropFactor?.factors.frrbv || 0 : 0);
    const B1B = 1 - (cropFactor?.factors.rfv || 0);
    const B1 = B1A * B1B * (cropFactor?.factors.ncvab || 0);
    const B2 =
      (cropFactor?.factors.bgabrrv || 0) * (cropFactor?.factors.ncvbg || 0);

    const C =
      (cropInformation.harvestSize || 0) *
      (cropFactor?.factors.dmv || 0) *
      (cropFactor?.factors.rcv || 0) *
      B1B *
      (cropInformation.burnResidue ? cropFactor?.factors.frrbv || 0 : 0) *
      (cropFactor?.factors.efv || 0);

    const B = B1 + B2;

    const preTotal = A * B;

    return preTotal + C;
  }

  public calculateAllCropResidueEmission(cropInformation: CropInformation) {
    if (!cropInformation.cropInformationData) return 0;
    const total = cropInformation.cropInformationData.reduce((a, b) => {
      return a + this.calculateCropInformationEmission(b);
    }, 0);

    return total;
  }

  public calculateCoverCropEmission(coverCrop: CoverCropData) {
    const coverCropFactor = coverCropFactors.find(
      (item) => item.coverCrop === (coverCrop.pastureCrop as PastureCrops)
    );

    if (!coverCropFactor) return 0;

    const A =
      (coverCropFactor.factors.dm || 0) *
      (coverCrop.areaSown || 0) *
      residueDecompositionFactor;
    const B1A = 1 - (coverCropFactor.factors.rfv || 0);
    const B2 =
      (coverCropFactor.factors.ncbg || 0) *
      (coverCropFactor.factors.bgabrrv || 0);
    const B1 = B1A * (coverCropFactor.factors.ncab || 0);

    const B = B1 + B2;

    return A * B;
  }

  public calculatePastureCropEmission(pasturecrop: PastureCropData) {
    const pastureCropFactor = coverCropFactors.find(
      (item) => item.coverCrop === (pasturecrop.pastureCrop as PastureCrops)
    );

    if (!pastureCropFactor) return 0;

    const A =
      (pastureCropFactor.factors.dm || 0) *
      (pasturecrop.areaTilled || 0) *
      residueDecompositionFactor;
    const B1A = 1 - (pastureCropFactor.factors.rfv || 0);
    const B2 =
      (pastureCropFactor.factors.ncbg || 0) *
      (pastureCropFactor.factors.bgabrrv || 0);
    const B1 = B1A * (pastureCropFactor.factors.ncab || 0);

    const B = B1 + B2;

    return A * B;
  }

  public calculateAllCoverCropEmission(coverCrop: CoverCrop) {
    if (!coverCrop.coverCropData) return 0;
    const total = coverCrop.coverCropData.reduce((a, b) => {
      return a + this.calculateCoverCropEmission(b);
    }, 0);

    return total;
  }

  public calculateAllPastureCropEmission(pastureCrop: PastureCrop) {
    if (!pastureCrop.pastureCropData) return 0;
    const total = pastureCrop.pastureCropData.reduce((a, b) => {
      return a + this.calculatePastureCropEmission(b);
    }, 0);

    return total;
  }

  public calculateLivestockEmission(
    animalQuantity?: number,
    numOfDays?: number,
    emissionFactor?: number
  ) {
    if (!animalQuantity || !numOfDays || !emissionFactor) return 0;
    const C = emissionFactor / 1000;

    return animalQuantity * numOfDays * C;
  }

  public calculateSheepEmission(sheepData: SheepData, state: AustralianState) {
    const stateFactor = sheepEmissionFactors.find(
      (item) => item.state === state
    );

    const seasonFactors = stateFactor?.stateFactors.find(
      (item) =>
        item.sheepType ===
        GetObjectValue(SheepType, sheepData.sheepType as SheepType)
    )?.factors;

    if (!seasonFactors || !sheepData.seasonsData) return 0;

    const seasonEmission = Object.entries(sheepData.seasonsData).map((item) => {
      const season = item[0];
      const data: LivestockTypeData = item[1];

      if (!data) return 0;

      const seasonFactorNum = Object.entries(seasonFactors).find(
        (item) => item[0] === season
      )?.[1];

      return this.calculateLivestockEmission(
        data.numOfLivestock,
        data.numOfDays,
        seasonFactorNum
      );
    });

    return seasonEmission.reduce((a, b) => a + b, 0);
  }

  public calculateBeefCattleEmission(
    beefCattleData: BeefCattleData,
    state: AustralianState,
    region?: Region
  ) {
    const stateFactor = region
      ? beefCattleEmissionFactors.find(
          (item) =>
            item.state === state &&
            item.region === GetObjectValue(Region, region as Region)
        )
      : beefCattleEmissionFactors.find((item) => item.state === state);

    const seasonFactors = stateFactor?.stateFactors.find(
      (item) =>
        item.beefCattleType ===
        GetObjectValue(
          BeefCattleType,
          beefCattleData.beefType as BeefCattleType
        )
    )?.factors;

    if (!seasonFactors || !beefCattleData.seasonsData) return 0;

    const seasonEmission = Object.entries(beefCattleData.seasonsData).map(
      (item) => {
        const season = item[0];
        const data: LivestockTypeData = item[1];

        if (!data) return 0;

        const seasonFactorNum = Object.entries(seasonFactors).find(
          (item) => item[0] === season
        )?.[1];

        return this.calculateLivestockEmission(
          data.numOfLivestock,
          data.numOfDays,
          seasonFactorNum
        );
      }
    );
    return seasonEmission.reduce((a, b) => a + b, 0);
  }

  public calculateDairyCattleEmission(
    dairyCattleData: DairyCattleData,
    state: AustralianState
  ) {
    const stateFactor = dairyCattleEmissionFactors.find(
      (item) => item.state === state
    );
    const dairyCattleFactor = stateFactor?.stateFactors.find(
      (item) =>
        item.dairyCattleType ===
        GetObjectValue(
          DairyCattleType,
          dairyCattleData.dairyCattleType as DairyCattleType
        )
    )?.factor;

    if (!dairyCattleFactor || !dairyCattleData.dairyCattleData) return 0;

    const seasonEmission = this.calculateLivestockEmission(
      dairyCattleData.dairyCattleData.numOfLivestock,
      dairyCattleData.dairyCattleData.numOfDays,
      dairyCattleFactor
    );

    return seasonEmission;
  }

  public calculateGenericLivestockEmission(
    livestockData: LivestockTypeData,
    animalType: AnimalType,
    state: AustralianState
  ) {
    const stateFactor = genericLivestockEmissionFactors.find(
      (item) => item.state === state
    );
    const genericLivestockFactor = stateFactor?.stateFactors.find(
      (item) =>
        item.livestockType ===
        GetObjectValue(AnimalType, animalType as AnimalType)
    )?.factor;

    if (!genericLivestockFactor || !livestockData) return 0;

    const seasonEmission = this.calculateLivestockEmission(
      livestockData.numOfLivestock,
      livestockData.numOfDays,
      genericLivestockFactor
    );

    return seasonEmission;
  }
})();
