import {
  AustralianState,
  CanadaProvince,
  Country,
  UnitedStatesState,
} from "../constants";
import { Address as IAddress } from "../types";
import SelectInput from "./select-input";
import TextInput from "./text-input";

interface AddressProps {
  address: IAddress;
  setAddress: (address: IAddress) => void;
  disabled?: boolean;
  required?: boolean;
}

export default function AddressComponent({
  address,
  setAddress,
  disabled,
  required,
}: AddressProps) {
  return (
    <div className="mt-1 grid grid-cols-1 gap-x-6 gap-y-0 sm:grid-cols-12">
      <div className="sm:col-span-6">
        <SelectInput
          label="Country"
          disabled={disabled}
          value={address?.country ?? ""}
          onChange={(e) => {
            setAddress({
              ...address,
              country: e.target.value as Country,
            });
          }}
          error={required && !address?.country ? 1 : undefined}
        >
          <option></option>
          {Object.entries(Country).map(([key, value]) => (
            <option key={key} value={value}>
              {value}
            </option>
          ))}
        </SelectInput>
      </div>
      {address.country === Country.Other ? (
        <div className="sm:col-span-6">
          <TextInput
            disabled={disabled}
            label="Other Country"
            type="text"
            name="country"
            id="other-country"
            autoComplete="country"
            value={address?.otherCountry ?? ""}
            onChange={(e) =>
              setAddress({
                ...address,
                otherCountry: e.target.value,
              })
            }
            error={
              required &&
              address.country === Country.Other &&
              !address?.otherCountry
                ? 1
                : undefined
            }
          />
        </div>
      ) : null}
      <div className="col-span-full">
        <TextInput
          disabled={disabled}
          label="Street address"
          type="text"
          name="street-address"
          id="street-address"
          autoComplete="street-address"
          value={address?.streetAddress ?? ""}
          onChange={(e) =>
            setAddress({
              ...address,
              streetAddress: e.target.value,
            })
          }
          error={required && !address?.streetAddress ? 1 : undefined}
        />
      </div>

      <div className="sm:col-span-3 ">
        <TextInput
          disabled={disabled}
          label="Unit"
          type="text"
          name="city"
          id="city"
          autoComplete="address-level2"
          value={address?.unit ?? ""}
          onChange={(e) =>
            setAddress({
              ...address,
              unit: e.target.value,
            })
          }
        />
      </div>

      <div className="sm:col-span-3 ">
        <TextInput
          disabled={disabled}
          label="City"
          type="text"
          name="city"
          id="city"
          autoComplete="address-level2"
          value={address?.city ?? ""}
          onChange={(e) =>
            setAddress({
              ...address,
              city: e.target.value,
            })
          }
          error={required && !address?.city ? 1 : undefined}
        />
      </div>

      <div className="sm:col-span-3">
        {address.country === Country.Other ? (
          <TextInput
            disabled={disabled}
            label="State/Province"
            type="text"
            name="state-province"
            id="state-province"
            autoComplete="state-province"
            value={address?.stateProvince ?? ""}
            onChange={(e) =>
              setAddress({
                ...address,
                stateProvince: e.target.value,
              })
            }
            error={required && !address?.stateProvince ? 1 : undefined}
          />
        ) : (
          <SelectInput
            disabled={disabled}
            label={address?.country === Country.Canada ? "Province" : "State"}
            autoComplete="address-level1"
            name="state-province"
            id="state-province"
            value={address?.stateProvince ?? ""}
            onChange={(e) =>
              setAddress({
                ...address,
                stateProvince: e.target.value,
              })
            }
            error={required && !address?.stateProvince ? 1 : undefined}
          >
            <option value=""></option>
            {address?.country === Country.Canada ? (
              <>
                {Object.entries(CanadaProvince).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </>
            ) : address?.country === Country.UnitedStates ? (
              <>
                {Object.entries(UnitedStatesState).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </>
            ) : (
              <>
                {Object.entries(AustralianState).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </>
            )}
          </SelectInput>
        )}
      </div>

      <div className="sm:col-span-3">
        <TextInput
          disabled={disabled}
          label={
            address?.country === Country.Canada
              ? "Postal code"
              : address?.country === Country.Australia
              ? "Post code"
              : "Zip"
          }
          type="text"
          name="postal-code"
          id="postal-code"
          autoComplete="postal-code"
          value={address?.postalCode ?? ""}
          onChange={(e) =>
            setAddress({
              ...address,
              postalCode: e.target.value,
            })
          }
          error={required && !address?.postalCode ? 1 : undefined}
        />
      </div>
    </div>
  );
}
