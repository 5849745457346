import { useEffect, useState } from "react";
import { EmissionData } from "../types";

export default function useEmissionData(year?: number) {
  const [newData, setNewData] = useState<EmissionData>();

  useEffect(() => {
    const data = localStorage.getItem("EmissionData");

    if (data) {
      const parsedData = JSON.parse(data);
      setNewData(parsedData);
    }
  }, [year]);

  return newData;
}
