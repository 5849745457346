import { Link } from "react-router-dom";
import image from "../assets/image3.jpg";
import Loading from "../components/loading";
import useKybs from "../hooks/useKybs";
import Badge from "../components/badge";
import { KYBStatus } from "../constants";
import { useEffect } from "react";
import useMe from "../hooks/useMe";

export default function KYBs() {
  const me = useMe();
  const { kybs, loading, error } = useKybs(me?.id);

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="flex flex-col w-full ">
      <div className="border-b border-shark-400">
        <h1 className="text-xl font-semibold leading-6 text-shark-200 m-6">
          Know Your Business
        </h1>
        {/* <p className="mt-2 max-w-4xl text-sm text-shark-300">
              {project?.projectInfo?.description}
            </p> */}
      </div>
      {loading ? (
        <Loading />
      ) : kybs ? (
        <>
          <div className="flex-1 w-full h-full mt-2 py-4 px-8 ">
            <p className="mt-1 max-w-5xl text-sm text-shark-300">
              To remain compliant with Australian government regulations, Loam
              must adhere to the Anti-Money Laundering and Counter-Terrorism
              Financing Act (AML/CTF). The Australian Transactions Reports and
              Analysis Centre (AUSTRAC) uses AML/CTF legislation to determine
              what type of designated services are at risk of financial
              transaction crime.
            </p>

            <p className="mt-3 max-w-5xl text-sm text-shark-300">
              Australian Carbon Credit Units (ACCUs) are considered a designated
              service at risk. In order for Loam Carbon to continue to be
              AML/CTF compliant, all Loam Carbon customers need to undergo Know
              Your Business (KYB) and Know your Customer (KYC) checks at least
              once every two years. By completing this process within the
              SecondCrop Grower Portal, the information will be retained from
              your previous responses making it just that much easier the next
              time around!
            </p>
            <div className="mx-auto mt-12 grid max-w-2xl auto-rows-fr grid-cols-1 gap-4 lg:gap-8 lg:mx-0 lg:max-w-none sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {kybs.map((kyb, i) => (
                <article
                  className="relative isolate flex flex-col justify-end overflow-hidden rounded-xl  px-8 pb-8 pt-60 sm:pt-48 lg:pt-60 hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-11"
                  key={i}
                >
                  <img
                    src={image}
                    alt=""
                    className="absolute inset-0 -z-10 h-full w-full object-cover"
                  />
                  <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                  <div className="absolute inset-0 -z-10 rounded-xl ring-1 ring-inset ring-gray-900/10" />

                  <div className="flex flex-col items-start gap-y-1 text-sm leading-6 text-gray-300">
                    <Badge
                      variant={
                        kyb.kybStatus.toLowerCase() ===
                        KYBStatus.Open.toLowerCase()
                          ? "warning"
                          : kyb.kybStatus.toLowerCase() ===
                            KYBStatus.Approved.toLowerCase()
                          ? "success"
                          : kyb.kybStatus.toLowerCase() ===
                            KYBStatus.Rejected.toLowerCase()
                          ? "danger"
                          : "pending"
                      }
                    >
                      {kyb.kybStatus.toUpperCase()}
                    </Badge>

                    <div className="flex items-center">
                      <p className="flex font-semibold">Know Your Business</p>
                    </div>
                    {kyb.kybStatus.toLocaleUpperCase() !==
                      KYBStatus.Open.toLowerCase() && kyb.submissionDate ? (
                      <p className="text-sm">
                        Submitted On:
                        <span className="ml-1 font-semibold">
                          {new Date(kyb.submissionDate).toDateString()}
                        </span>
                      </p>
                    ) : null}
                  </div>
                  <h3 className="mt-1 text-lg lg:text-2xl font-semibold leading-6 text-white">
                    <Link to={`/kyb/${kyb.id}/kyb-kyc`}>
                      <span className="absolute inset-0" />
                      <span className="relative">{kyb.kybYear}</span>
                    </Link>
                  </h3>
                </article>
              ))}
            </div>
          </div>
        </>
      ) : (
        <p>{error}</p>
      )}
    </div>
  );
}
