import { useEffect, useMemo, useState } from "react";
import Checkbox from "../../../components/checkbox-input";
import SelectInput from "../../../components/select-input";
import TextInput from "../../../components/text-input";
import {
  EmissionAttachmentType,
  CropsPlanted,
  EmissionVariant,
  MINIMUM_INPUT_WARNING,
  YesNo,
} from "../../../constants";
import useEmissionData from "../../../hooks/useEmissionData";
import {
  CropInformationData,
  CropInformation as iCropInformation,
} from "../../../types";
import { api } from "../../../api";
import { convertBooleanToYesNo } from "../../../helpers/convertBooleanToYesNo";
import { convertYesNoToBoolean } from "../../../helpers/convertYesNoToBoolean";
import { formatMonthYear } from "../../../helpers/format-month-year";
import useFarmInfo from "../../../hooks/emission/useFarmInfo";
import { useParams } from "react-router-dom";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";
import EmissionAttachments from "../emission-attachments";
import useEmissionActivity from "../../../hooks/emission/useEmissionActivity";
import formatDate from "../../../helpers/format-date";
import Divider from "../../../components/divider";

interface Props {
  year: number;
  variant?: EmissionVariant;
}

export default function CropInformation({ year, variant }: Props) {
  const emissionData = useEmissionData(year);
  const farmInfo = useFarmInfo();
  const emissionActivity = useEmissionActivity();
  const { projectId, emissionActivityId } = useParams();
  const [cropInformationData, setCropInformationData] = useState<
    iCropInformation[]
  >([]);
  const [yearCropInfo, setYearCropInfo] = useState<
    iCropInformation | undefined
  >(undefined);
  const { projectAttachment, mutate } = useProjectAttachment(
    projectId,
    emissionActivityId,
    EmissionAttachmentType.CropInformation
  );

  useEffect(() => {
    if (!emissionData || !emissionData.cropInformation) {
      setCropInformationData([]);
      return;
    }
    setCropInformationData(emissionData.cropInformation);
  }, [emissionData]);

  useEffect(() => {
    if (!projectId || !emissionActivityId) return;
    if (
      !cropInformationData.length ||
      !cropInformationData.find((data) => data.activityYear === year)
    ) {
      setYearCropInfo({
        projectId: +projectId,
        emissionActivityId: +emissionActivityId,
        activityYear: year,
        cropInformationData: [] as CropInformationData[],
      });
      return;
    }
    setYearCropInfo(
      cropInformationData.find((data) => data.activityYear === year)
    );
  }, [cropInformationData, year, emissionData, projectId, emissionActivityId]);

  useEffect(() => {
    function saveYearCropInfo() {
      if (!yearCropInfo) return;
      return api.saveCropInformation(yearCropInfo);
    }
    saveYearCropInfo();
  }, [yearCropInfo]);

  const requiredAttachmentTypes = useMemo(() => {
    if (!yearCropInfo?.cropInformationData) return [];
    return yearCropInfo.cropInformationData
      .flatMap((crop) =>
        crop.burnResidue
          ? [`${crop.crop} - Harvest Size`, `${crop.crop} - Residue Burn `]
          : crop.harvestSize
          ? [`${crop.crop} - Harvest Size`]
          : []
      )
      .filter(Boolean) as [];
  }, [yearCropInfo]);

  return (
    <div className="flex flex-col items-center w-full" key={year}>
      {!yearCropInfo ||
      !projectId ||
      !emissionActivityId ||
      !emissionActivity ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p>No Data</p>
        </div>
      ) : (
        <div className="w-full">
          <div className="border-b border-gray-200 pb-4 lg:pb-8">
            <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
              {variant !== EmissionVariant.OnGoing ? (
                formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
              ) : (
                <span>
                  {formatDate(emissionActivity.activityStartDate)} -{" "}
                  {formatDate(emissionActivity.activityEndDate)}
                </span>
              )}{" "}
              Crop Information
            </h2>
          </div>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Please select all the crops you planted in the project area
                between{" "}
                <span className="font-semibold text-apple-700">
                  {variant !== EmissionVariant.OnGoing ? (
                    formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
                  ) : (
                    <span>
                      {formatDate(emissionActivity.activityStartDate)} -{" "}
                      {formatDate(emissionActivity.activityEndDate)}
                    </span>
                  )}
                </span>
                .
              </label>
              {(!yearCropInfo.cropInformationData ||
                yearCropInfo.cropInformationData.length === 0) &&
              variant === EmissionVariant.OnGoing ? (
                <p className="pt-2 text-xs text-red-600" id="error">
                  This field is required!
                </p>
              ) : null}
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-3 lg:gap-4 mt-3">
                {Object.entries(CropsPlanted).map(([key, value]) => (
                  <div
                    key={key}
                    className="flex flex-row items-center w-full col-span-1"
                  >
                    <Checkbox
                      checked={
                        yearCropInfo.cropInformationData?.find(
                          (crop) => crop.crop === key
                        )
                          ? true
                          : false
                      }
                      value={key}
                      label={value}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setYearCropInfo({
                            ...yearCropInfo,
                            cropInformationData: [
                              ...(yearCropInfo.cropInformationData ?? []),
                              {
                                crop: e.target.value as CropsPlanted,
                                harvestSize: undefined,
                                burnResidue: undefined,
                              },
                            ],
                          });
                        } else {
                          if (!yearCropInfo.cropInformationData) return;
                          setYearCropInfo({
                            ...yearCropInfo,
                            cropInformationData:
                              yearCropInfo.cropInformationData.filter(
                                (crop) => crop.crop !== key
                              ),
                          });
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {yearCropInfo.cropInformationData &&
          yearCropInfo.cropInformationData.length ? (
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
              <div className="col-span-full">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Please tell us about your crop harvest(s) between{" "}
                  <span className="font-semibold text-apple-700">
                    {formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)}
                  </span>
                  .
                </label>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full ">
                        <thead>
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                            ></th>

                            <th
                              scope="col"
                              className="px-4 py-3.5  text-sm font-semibold text-gray-900 text-center"
                            >
                              How many tonnes did you harvest?
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                            >
                              Did you burn the residue?
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-0.5 text-center px-5 text-sm font-normal text-gray-900 "
                            ></th>
                            <th
                              scope="col"
                              className="px-4 py-0.5  text-xs font-normal text-gray-900 text-center bg-apple-50"
                            >
                              Tonnes of harvest
                            </th>

                            <th
                              scope="col"
                              className="py-0.5 pl-4 pr-4  text-sm font-normal text-gray-900 sm:pr-0 "
                            ></th>
                          </tr>
                          {yearCropInfo.cropInformationData.map(
                            (cropInfo, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center">
                                  {
                                    Object.values(CropsPlanted)[
                                      Object.keys(CropsPlanted).indexOf(
                                        cropInfo.crop as CropsPlanted
                                      )
                                    ]
                                  }
                                </td>
                                <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                  <TextInput
                                    type="number"
                                    inputMode="decimal"
                                    value={cropInfo.harvestSize ?? ""}
                                    onChange={(e) => {
                                      if (+e.target.value < 0) {
                                        alert(MINIMUM_INPUT_WARNING);
                                      } else {
                                        if (!yearCropInfo.cropInformationData)
                                          return;
                                        setYearCropInfo({
                                          ...yearCropInfo,
                                          cropInformationData:
                                            yearCropInfo.cropInformationData.map(
                                              (crop) => {
                                                if (
                                                  crop.crop === cropInfo.crop
                                                ) {
                                                  return {
                                                    ...crop,
                                                    harvestSize: e.target.value
                                                      .length
                                                      ? parseFloat(
                                                          e.target.value
                                                        )
                                                      : undefined,
                                                  };
                                                }
                                                return crop;
                                              }
                                            ),
                                        });
                                      }
                                    }}
                                    error={
                                      variant === EmissionVariant.OnGoing &&
                                      (cropInfo.harvestSize === undefined ||
                                        cropInfo.harvestSize === null)
                                        ? 1
                                        : 0
                                    }
                                    dontshowerrormesage={true}
                                  />
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                                  <SelectInput
                                    value={
                                      convertBooleanToYesNo(
                                        cropInfo.burnResidue
                                      ) ?? ""
                                    }
                                    onChange={(e) => {
                                      if (!yearCropInfo.cropInformationData)
                                        return;
                                      setYearCropInfo({
                                        ...yearCropInfo,
                                        cropInformationData:
                                          yearCropInfo.cropInformationData.map(
                                            (crop) => {
                                              if (crop.crop === cropInfo.crop) {
                                                return {
                                                  ...crop,
                                                  burnResidue:
                                                    convertYesNoToBoolean(
                                                      e.target.value
                                                    ) ?? undefined,
                                                };
                                              }
                                              return crop;
                                            }
                                          ),
                                      });
                                    }}
                                    error={
                                      variant === EmissionVariant.OnGoing &&
                                      cropInfo.burnResidue === undefined
                                        ? 1
                                        : 0
                                    }
                                    dontshowerrormesage={true}
                                  >
                                    <option value={""}></option>
                                    {Object.entries(YesNo).map(
                                      ([key, value]) => (
                                        <option key={key} value={value}>
                                          {key}
                                        </option>
                                      )
                                    )}
                                  </SelectInput>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <Divider />
          {(variant === EmissionVariant.Baseline ||
            requiredAttachmentTypes.length > 0) && (
            <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
              Attachments
            </h2>
          )}
          <div className="m-1">
            <div className="mt-1 ">
              <EmissionAttachments
                variant={variant}
                emissionActivity={emissionActivity}
                emissionActivityId={+emissionActivityId}
                projectId={+projectId}
                month={farmInfo.carbonRegMonth}
                year={year}
                attachments={projectAttachment}
                title="Crop Information"
                attachmentKind={EmissionAttachmentType.CropInformation}
                mutateAttachments={mutate}
                attachmentTypes={requiredAttachmentTypes}
                uploadRequired={
                  variant === EmissionVariant.Baseline ||
                  requiredAttachmentTypes.length > 0
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
