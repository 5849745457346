import { Seasons } from "../constants";

export function hasSeasonInDateRange(
  targetSeason: Seasons,
  startDate?: string,
  futureDate?: string
): boolean {
  if (!startDate || !futureDate) {
    return false;
  }
  const currentDate = new Date(startDate);
  const endDate = new Date(futureDate);

  while (currentDate <= endDate) {
    const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index

    switch (targetSeason) {
      case Seasons.Spring:
        if (month >= 3 && month <= 5) {
          return true;
        }
        break;
      case Seasons.Summer:
        if (month >= 6 && month <= 8) {
          return true;
        }
        break;
      case Seasons.Autumn:
        if (month >= 9 && month <= 11) {
          return true;
        }
        break;
      case Seasons.Winter:
        if (month === 12 || (month >= 1 && month <= 2)) {
          return true;
        }
        break;
      default:
        throw new Error("Invalid season provided");
    }

    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return false;
}
