import { useEffect, useState, useContext } from "react";
import Loading from "../../components/loading";
import useMe from "../../hooks/useMe";
import { KYBConsent, ToBeSavedData } from "../../types";
import SignatureCanvas from "react-signature-canvas";
import { useParams } from "react-router-dom";
import Checkbox from "../../components/checkbox-input";
import useMyGrower from "../../hooks/useMyGrower";
import { DataTypes } from "../../constants";
import useConsent from "../../hooks/useConsent";
import capitalizeWords from "../../helpers/capitalize-words";
import { ComponentContext } from "../kyb-kyc";
import determineDisabled from "../../helpers/kyb-kyc/determine-disabled";

export default function ConsentForm() {
  const me = useMe();
  const { kybId } = useParams();
  const { grower, loading } = useMyGrower();
  const [kybConsent, setKYBConsent] = useState<Partial<KYBConsent>>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sigCanvas, setSigCanvas] = useState<any>(null);
  const { kybStatus } = useContext(ComponentContext);
  const { consent } = useConsent(kybId);

  useEffect(() => {
    if (!me || !kybId || !grower) return;
    if (consent) {
      setKYBConsent(consent);
      return;
    }
    setKYBConsent((kybConsent: Partial<KYBConsent>) => ({
      ...kybConsent,
      agreeConsent: kybConsent.agreeConsent ?? false,
      signedOn: new Date().toLocaleDateString(),
    }));
  }, [me, grower, kybId, consent]);

  useEffect(() => {
    if (!kybConsent) return;
    function handleSave() {
      const toBeSavedData: ToBeSavedData = {
        dataType: DataTypes.ConsentData,
        data: kybConsent,
      };
      localStorage.setItem("toBeSaved", JSON.stringify(toBeSavedData));
    }
    handleSave();
  }, [kybConsent]);

  return (
    <div className="flex flex-col flex-1">
      {!me || loading || !kybStatus ? (
        <Loading />
      ) : (
        <div className="w-full p-2 lg:p-4">
          <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-8">
            <div>
              <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
                Consent
              </h2>
              <p className="mt-1 text-xs text-gray-500">
                Please review and sign the following consent form to proceed
                with your KYB application.
              </p>
            </div>
          </div>

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <p className="text-sm font-medium leading-9 text-gray-700 inline-block">
                By signing this form, I certify that I am a duly authorized
                representative of{" "}
                <span className="inline-flex flex-col items-center">
                  <span className="rounded-md shadow-sm ">
                    <input
                      // type="text"
                      disabled={
                        consent !== undefined || determineDisabled(kybStatus)
                      }
                      value={kybConsent.businessName ?? ""}
                      onChange={(e) => {
                        const str = e.target.value;
                        setKYBConsent({
                          ...kybConsent,
                          businessName:
                            str.charAt(0).toUpperCase() + str.slice(1),
                        });
                      }}
                      className={`inline-block border-b-2 ${
                        !kybConsent.businessName
                          ? "border-red-300"
                          : "border-gray-300"
                      } py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:border-b-2 focus:border-apple-500 text-base sm:leading-4`}
                    />
                  </span>
                  {!kybConsent.businessName ? (
                    <span className="mt-0.5 text-xs text-red-600">
                      This field is required!
                    </span>
                  ) : null}
                </span>{" "}
                <strong>
                  (Individual or Entity that will sign the Grower Agreement with
                  Loam Carbon)
                </strong>
                , and that the information set out by me in this application is
                true and correct to the best of my ability. I am aware and give
                consent to the release of information to Loam Carbon and its
                partner to disseminate and transmit the results electronically
                or in hard copy to a location in/or outside of Australia. The
                information is collected and disclosed according to the
                Australian Privacy Act. It may also be subjected to applicable
                international privacy legislation ie. U.S. Patriot Act, Personal
                Information Protection and Electronic Documents Act (PIPEDA),
                and Canadian Privacy Act.
              </p>

              <p className="text-sm font-medium leading-9 text-gray-700 mt-4 inline-block">
                I consent to the collection, use and disclosure of information
                in accordance with Loam Carbon’s privacy policy, and for the
                purposes of verifying the identity of{" "}
                <input
                  value={kybConsent.businessName ?? ""}
                  disabled
                  className={`inline-block border-b-2 border-gray-400 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:border-b-2 focus:border-apple-500 text-base sm:leading-4`}
                />
                , I consent to:
                <br />
                <span>
                  <span className="px-4 py-2">
                    (a) the verification of company information with a credit
                    bureau header files (for verification only);
                  </span>
                  <br />
                  <span className="px-4 py-2">
                    (b) against records held by official document issuers or
                    official record holders via third party systems; and
                  </span>
                  <br />
                  <span className="px-4 py-2">
                    (c) Loam Carbon’s verification agent(s) acting as a
                    nominated intermediary in accordance with Australian Privacy
                    Principles.
                  </span>
                </span>
                <br />I consent to the use by third parties of the results of
                any verification checks on the company identity for the purposes
                of monitoring and improving the verification services.
              </p>

              <p className="text-sm font-medium leading-9 text-gray-700 mt-4 inline-block">
                I hereby release and forever discharge all members and employees
                of Loam Carbon and their partners from any and all actions,
                claims and demands for damages, loss or injury which may
                hereafter be sustained by myself, or{" "}
                <input
                  value={kybConsent.businessName ?? ""}
                  disabled
                  className={`inline-block border-b-2 border-gray-400 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:border-b-2 focus:border-apple-500 text-base sm:leading-4`}
                />{" "}
                as a result of the disclosure of information to Loam Carbon and
                its partners.
              </p>

              <p className="text-sm font-medium leading-9 text-gray-700 mt-4 inline-block">
                I hereby consent to the search and release of
                <input
                  value={kybConsent.businessName ?? ""}
                  disabled
                  className={`inline-block border-b-2 border-gray-400 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:border-b-2 focus:border-apple-500 text-base sm:leading-4`}
                />
                ’s information for Know Your Business (KYB) review purposes.
              </p>

              <div className="mt-4 flex flex-col lg:flex-row justify-between lg:items-center">
                <div className="mt-4 flex flex-col items-start justify-start">
                  <p className="inline-block text-sm font-medium leading-9 text-gray-700 mt-4 ">
                    Full Legal Name of Signer:
                    <span className="ml-2 inline-flex flex-col items-center">
                      <span className="rounded-md shadow-sm ">
                        <input
                          disabled={
                            consent !== undefined ||
                            determineDisabled(kybStatus)
                          }
                          value={kybConsent.signedBy?.legalName ?? ""}
                          onChange={(e) => {
                            setKYBConsent({
                              ...kybConsent,
                              signedBy: {
                                ...kybConsent?.signedBy,
                                legalName: e.target.value,
                              },
                            });
                          }}
                          className={`inline-block border-b-2 ${
                            !kybConsent.signedBy?.legalName
                              ? "border-red-300"
                              : "border-gray-300"
                          } py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:border-b-2 focus:border-apple-500 text-base sm:leading-4`}
                        />
                      </span>
                      {!kybConsent.signedBy?.legalName ? (
                        <span className="mt-0.5 text-xs text-red-600">
                          This field is required!
                        </span>
                      ) : null}
                    </span>
                  </p>
                  <p className="inline-flex text-sm font-medium leading-9 text-gray-700 mt-4 ">
                    Position at {kybConsent.businessName ?? "Company"}:
                    <span className="ml-2 inline-flex flex-col items-center">
                      <span className="rounded-md shadow-sm ">
                        <input
                          disabled={
                            consent !== undefined ||
                            determineDisabled(kybStatus)
                          }
                          value={kybConsent.signedBy?.position ?? ""}
                          onChange={(e) => {
                            setKYBConsent({
                              ...kybConsent,
                              signedBy: {
                                ...kybConsent?.signedBy,
                                position: capitalizeWords(e.target.value),
                              },
                            });
                          }}
                          className={`inline-block border-b-2 ${
                            !kybConsent.signedBy?.position
                              ? "border-red-300"
                              : "border-gray-300"
                          } py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:border-b-2 focus:border-apple-500 text-base sm:leading-4`}
                        />
                      </span>
                      {!kybConsent.signedBy?.position ? (
                        <span className="mt-0.5 text-xs text-red-600">
                          This field is required!
                        </span>
                      ) : null}
                    </span>
                  </p>
                  <p className="inline-flex text-sm font-medium leading-9 text-gray-700 mt-4 ">
                    Date:
                    <span className="ml-2">
                      <input
                        disabled
                        defaultValue={new Date().toLocaleDateString()}
                        className={`inline-block border-b-2 border-gray-400 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:border-b-2 focus:border-apple-500 text-base sm:leading-4`}
                      />
                    </span>
                  </p>
                </div>
                <div className="mt-4 flex flex-col w-full lg:w-6/12">
                  {kybConsent.agreeConsent ? (
                    <div className="flex flex-col items-center justify-center">
                      <img
                        loading="lazy"
                        alt="signature"
                        src={kybConsent.consentSig}
                        className="border-b-2 border-gray-400 w-full h-48"
                      />
                      <p className="text-sm font-medium leading-9 text-gray-700 mt-1 inline-block">
                        signature
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className="flex flex-row items-center justify-between">
                        <p className="text-sm font-medium leading-9 text-gray-700 inline-block">
                          Please sign in the box below:
                        </p>
                        <div>
                          <button
                            className="flex w-full rounded-xl hover:bg-apple-500 bg-transparent border-2 border-apple-600 py-0.5 hover:text-white hover:border-apple-500 px-4 text-base font-semibold text-apple-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-600"
                            onClick={() => {
                              sigCanvas.clear();
                              setKYBConsent({
                                ...kybConsent,
                                consentSig: undefined,
                              });
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                      <SignatureCanvas
                        penColor="black"
                        ref={(ref) => {
                          setSigCanvas(ref);
                        }}
                        canvasProps={{
                          className:
                            "sigCanvas border-2 border-gray-400 w-full mt-1 rounded-md h-48",
                        }}
                        onEnd={() => {
                          setKYBConsent({
                            ...kybConsent,
                            consentSig: sigCanvas.toDataURL(),
                          });
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <Checkbox
                disabled={consent !== undefined || determineDisabled(kybStatus)}
                checked={kybConsent.agreeConsent ?? false}
                value={kybConsent.agreeConsent ? 1 : 0}
                onChange={(e) => {
                  if (!kybConsent.consentSig) {
                    alert("Please sign in the signature box before proceeding");
                    return;
                  }
                  setKYBConsent({
                    ...kybConsent,
                    agreeConsent: e.target.checked,
                  });
                }}
                label="I acknowledge that I have read and understood the above and agree to the terms and conditions. I also acknowledge that I have the authority to provide this consent on behalf of the company."
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
