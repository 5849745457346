import Fetcher from "./lib/fetcher";
import {
  Attachment,
  BusinessInfo,
  EmissionData,
  CoverCrop,
  CropInformation,
  Grower,
  GrowerPEP,
  GrowerSanctionScreening,
  KYB,
  KYBConsent,
  KybNode,
  Livestock,
  PartnershipData,
  PastureCrop,
  Project,
  SoleTraderData,
  SyntheticFerterlizer,
  TrustData,
  UnlistedCompanyData,
  User,
  UserFeedback,
  ActivityInfo,
  IrrigationEnergy,
  SoilLandscape,
  LimingEnergy,
  CropTillage,
  EmissionAttachment,
  SummaryEmissionData,
  CalculatedBaselineEmission,
  EmissionActivityInfo,
  CarbonBuilderApplication,
  RestrictedActivity,
  ReportIssueType,
} from "./types";
import { config } from "./config";

const API_URL = config.api ?? "";

export const api = new (class {
  private fetcher = new Fetcher({
    baseUrl: API_URL,
    getAccessToken: () => localStorage.getItem("token"),
  });

  public initializeUser(username: string) {
    const loginFetcher = new Fetcher({
      baseUrl: API_URL,
    });

    return loginFetcher.fetch<{ user: User }>("/login", {
      method: "POST",
      body: {
        username,
      },
    });
  }

  public getAllProjects(userId: number) {
    return this.fetcher.fetch<{ projects: Project[] }>(
      `/api/users/${userId}/projects`,
      {
        method: "GET",
      }
    );
  }

  public getProject(projectId: number) {
    return this.fetcher.fetch<{ project: Project }>(
      `/api/projects/${projectId}`,
      {
        method: "GET",
      }
    );
  }

  public postKYBConsent(kybId: number, body: KYBConsent) {
    return this.fetcher.fetch<{ consent: KYBConsent }>(
      `/api/kyb/${kybId}/consent`,
      {
        method: "POST",
        body,
      }
    );
  }

  public getKYBConsent(kybId: number) {
    return this.fetcher.fetch<{ consent: KYBConsent }>(
      `/api/kyb/${kybId}/consent`,
      {
        method: "GET",
      }
    );
  }

  public getGrower(userId: number) {
    return this.fetcher.fetch<{ grower: Grower }>(
      `/api/users/${userId}/grower`,
      {
        method: "GET",
      }
    );
  }

  public getKYBs(userId: number) {
    return this.fetcher.fetch<{ kybs: KYB[] }>(`/api/users/${userId}/kyb`, {
      method: "GET",
    });
  }

  public getKyb(kybId: number) {
    return this.fetcher.fetch<{ kyb: KYB }>(`/api/kyb/${kybId}`, {
      method: "GET",
    });
  }

  public getKYBNodes(kybId: number) {
    return this.fetcher.fetch<{ kybNodes: KybNode[] }>(
      `/api/kyb/${kybId}/nodes`,
      {
        method: "GET",
      }
    );
  }

  public postInitialBusinessInfo(kybId: number, body: BusinessInfo) {
    return this.fetcher.fetch<{ businessInfo: BusinessInfo }>(
      `/api/kyb/${kybId}/initial-business-info`,
      {
        method: "POST",
        body,
      }
    );
  }

  public postMidBusinessInfo(
    kybId: number,
    body: BusinessInfo,
    nodeId?: number
  ) {
    return this.fetcher.fetch<{ businessInfo: BusinessInfo }>(
      `/api/kyb/${kybId}/kyb-node/${nodeId}/mid-business-info`,
      {
        method: "POST",
        body,
      }
    );
  }

  public postUnlistedCompanyData(
    kybId: number,
    nodeId: number,
    body: UnlistedCompanyData
  ) {
    return this.fetcher.fetch<{ unlistedCompanyData: UnlistedCompanyData }>(
      `/api/kyb/${kybId}/kyb-node/${nodeId}/unlisted-company`,
      {
        method: "POST",
        body,
      }
    );
  }

  public postPartnershipData(
    kybId: number,
    nodeId: number,
    body: PartnershipData
  ) {
    return this.fetcher.fetch<{ partnershipData: PartnershipData }>(
      `/api/kyb/${kybId}/kyb-node/${nodeId}/partnership`,
      {
        method: "POST",
        body,
      }
    );
  }

  public postTrustData(kybId: number, nodeId: number, body: TrustData) {
    return this.fetcher.fetch<{ trustData: TrustData }>(
      `/api/kyb/${kybId}/kyb-node/${nodeId}/trust`,
      {
        method: "POST",
        body,
      }
    );
  }

  public postAttachDocumentInitial(
    kybId: number,
    nodeId: number,
    body: Attachment
  ) {
    return this.fetcher.fetch<{ attachment: Attachment }>(
      `/api/kyb/${kybId}/kyb-node/${nodeId}/attach-document`,
      {
        method: "POST",
        body: body,
      }
    );
  }

  public postGrowersPEPDeclaration(kybId: number, body: GrowerPEP) {
    return this.fetcher.fetch<{ growerPEP: GrowerPEP }>(
      `/api/kyb/${kybId}/grower-pep`,
      {
        method: "POST",
        body,
      }
    );
  }

  public getGrowersPEPDeclaration(kybId: number) {
    return this.fetcher.fetch<{ growerPEP: GrowerPEP }>(
      `/api/kyb/${kybId}/grower-pep`,
      {
        method: "GET",
      }
    );
  }

  public postGrowersSanctionScreening(
    kybId: number,
    body: GrowerSanctionScreening
  ) {
    return this.fetcher.fetch<{
      growerSanctionScreening: GrowerSanctionScreening;
    }>(`/api/kyb/${kybId}/sanction-declaration`, {
      method: "POST",
      body,
    });
  }

  public getGrowersSanctionScreening(kybId: number) {
    return this.fetcher.fetch<{
      growerSanctionScreening: GrowerSanctionScreening;
    }>(`/api/kyb/${kybId}/sanction-declaration`, {
      method: "GET",
    });
  }

  public postSoleTraderData(
    kybId: number,
    nodeId: number,
    body: SoleTraderData
  ) {
    return this.fetcher.fetch<{ soleTrader: SoleTraderData }>(
      `/api/kyb/${kybId}/kyb-node/${nodeId}/sole-trader`,
      {
        method: "POST",
        body,
      }
    );
  }

  public postResetKYBItems(kybId: number) {
    return this.fetcher.fetch(`/api/kyb/${kybId}/reset`, {
      method: "POST",
    });
  }

  public postSubmitKYB(kybId: number) {
    return this.fetcher.fetch<{ kyb: KYB }>(`/api/kyb/${kybId}/submit`, {
      method: "POST",
    });
  }

  public postUserFeedback(userId: number, feedback: UserFeedback) {
    return this.fetcher.fetch<{ feedback: UserFeedback }>(
      `/api/users/${userId}/feedback`,
      {
        method: "POST",
        body: feedback,
      }
    );
  }

  public deleteEntityPerson(id: number) {
    return this.fetcher.fetch(`/api/entity-person/${id}`, {
      method: "DELETE",
    });
  }

  public postFirstTimeSetup(email: string, password: string) {
    return this.fetcher.fetch("/api/first-time-setup", {
      method: "POST",
      body: {
        email: email,
        password: password,
      },
    });
  }

  //
  //
  //
  //
  //
  //
  //
  // -------------- BASELINE GHG EMISSIONS API ----------------
  //
  //
  //
  //
  //
  //
  //

  public postActivityInfo(
    projectId: number,
    emissionActivityId: number,
    activityInfo: ActivityInfo
  ) {
    return this.fetcher.fetch<{ emissionActivityInfo: ActivityInfo }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/activity-info`,
      {
        method: "POST",
        body: activityInfo,
      }
    );
  }

  public getActivityInfo(projectId: number, emissionActivityId: number) {
    return this.fetcher.fetch<{ emissionActivityInfo: ActivityInfo }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/activity-info`,
      {
        method: "GET",
      }
    );
  }

  public getProjectEmissionData(projectId: number, emissionActivityId: number) {
    return this.fetcher.fetch<{ projectEmissionData: EmissionData }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/emission-data`,
      {
        method: "GET",
      }
    );
  }

  public getIrrigationEnergy(projectId: number, emissionActivityId: number) {
    return this.fetcher.fetch<{ irrigationEnergy: IrrigationEnergy[] }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/irrigation-energy`,
      {
        method: "GET",
      }
    );
  }

  public postLimingEnergy(
    projectId: number,
    emissionActivityId: number,
    limingEnergy: LimingEnergy[]
  ) {
    return this.fetcher.fetch<{ limingEnergy: LimingEnergy }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/liming-energy`,
      {
        method: "POST",
        body: limingEnergy,
      }
    );
  }

  public postSoilLandscape(
    projectId: number,
    emissionActivityId: number,
    soilLandscape: SoilLandscape[]
  ) {
    return this.fetcher.fetch<{ soilLandscape: SoilLandscape }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/soil-landscape`,
      {
        method: "POST",
        body: soilLandscape,
      }
    );
  }

  public postCropTillage(
    projectId: number,
    emissionActivityId: number,
    cropTillage: CropTillage[]
  ) {
    return this.fetcher.fetch<{ cropTillage: CropTillage }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/crop-tillage`,
      {
        method: "POST",
        body: cropTillage,
      }
    );
  }

  public postSyntheticFertilizer(
    projectId: number,
    emissionActivityId: number,
    syntheticFertilizer: SyntheticFerterlizer[]
  ) {
    return this.fetcher.fetch<{ syntheticFertilizer: SyntheticFerterlizer }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/synthetic-fertilizer`,
      {
        method: "POST",
        body: syntheticFertilizer,
      }
    );
  }

  public postCropInformation(
    projectId: number,
    emissionActivityId: number,
    cropInformation: CropInformation[]
  ) {
    return this.fetcher.fetch<{ cropInformation: CropInformation }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/crop-information`,
      {
        method: "POST",
        body: cropInformation,
      }
    );
  }

  public postCoverCrop(
    projectId: number,
    emissionActivityId: number,
    coverCrop: CoverCrop[]
  ) {
    return this.fetcher.fetch<{ coverCrop: CoverCrop }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/cover-crop`,
      {
        method: "POST",
        body: coverCrop,
      }
    );
  }

  public postPastureCrop(
    projectId: number,
    emissionActivityId: number,
    pastureCrop: PastureCrop[]
  ) {
    return this.fetcher.fetch<{ pastureCrop: PastureCrop }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/pasture-crop`,
      {
        method: "POST",
        body: pastureCrop,
      }
    );
  }

  public postLivestock(
    projectId: number,
    emissionActivityId: number,
    livestock: Livestock[]
  ) {
    return this.fetcher.fetch<{ livestock: Livestock }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/livestock`,
      {
        method: "POST",
        body: livestock,
      }
    );
  }

  public postIrrigationEnergy(
    projectId: number,
    emissionActivityId: number,
    irrigationEnergy: IrrigationEnergy[]
  ) {
    return this.fetcher.fetch<{ irrigationEnergy: IrrigationEnergy }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/irrigation-energy`,
      {
        method: "POST",
        body: irrigationEnergy,
      }
    );
  }

  public postCarbonBuilderApplication(
    projectId: number,
    emissionActivityId: number,
    carbonBuilderApplication: CarbonBuilderApplication
  ) {
    return this.fetcher.fetch<{
      carbonBuilderApplication: CarbonBuilderApplication;
    }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/carbon-builder`,
      {
        method: "POST",
        body: carbonBuilderApplication,
      }
    );
  }

  public postRestrictedActivity(
    projectId: number,
    emissionActivityId: number,
    restrictedActivity: RestrictedActivity
  ) {
    return this.fetcher.fetch<{ restrictedActivity: RestrictedActivity }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/restricted-activities`,
      {
        method: "POST",
        body: restrictedActivity,
      }
    );
  }

  public saveIrrigationEnergy(
    emissionActivityId: number,
    projectId: number,
    year: number,
    key: string,
    value?: number
  ) {
    const data = localStorage.getItem("EmissionData");
    const parsedData = JSON.parse(data ?? "{}") as EmissionData;
    const irrigationEnergyData = (parsedData.irrigationEnergy ??
      []) as IrrigationEnergy[];

    if (irrigationEnergyData.length > 0) {
      const newIrrigationEnergyData = irrigationEnergyData.find(
        (item) => item.activityYear === year
      )
        ? irrigationEnergyData.map((item) => {
            if (item.activityYear === year) {
              return {
                ...item,
                [key]: value,
              };
            }
            return item;
          })
        : [
            ...irrigationEnergyData,
            {
              projectId: projectId,
              emissionActivityId: emissionActivityId,
              activityYear: year,
              [key]: value,
            },
          ];
      const newParsedData = {
        ...parsedData,
        irrigationEnergy: newIrrigationEnergyData,
      };

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    } else {
      const irrigationEnergyData: IrrigationEnergy[] = [
        {
          projectId: projectId,
          emissionActivityId: emissionActivityId,
          activityYear: year,
          [key]: value,
        },
      ];
      const newParsedData = {
        ...parsedData,
        irrigationEnergy: irrigationEnergyData,
      } as EmissionData;

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    }
  }

  public saveLimingEnergy(
    emissionActivityId: number,
    projectId: number,
    year: number,
    key: string,
    value?: number
  ) {
    const data = localStorage.getItem("EmissionData");
    const parsedData = JSON.parse(data ?? "{}") as EmissionData;
    const limingEnergyData = (parsedData.limingEnergy ?? []) as LimingEnergy[];

    if (limingEnergyData.length > 0) {
      const newLimingEnergyData = limingEnergyData.find(
        (item) => item.activityYear === year
      )
        ? limingEnergyData.map((item) => {
            if (item.activityYear === year) {
              return {
                ...item,
                [key]: value,
              };
            }
            return item;
          })
        : [
            ...limingEnergyData,
            {
              projectId: projectId,
              emissionActivityId: emissionActivityId,
              activityYear: year,
              [key]: value,
            },
          ];
      const newParsedData: EmissionData = {
        ...parsedData,
        limingEnergy: newLimingEnergyData,
      };

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    } else {
      const limingEnergyData: IrrigationEnergy[] = [
        {
          projectId: projectId,
          emissionActivityId: emissionActivityId,
          activityYear: year,
          [key]: value,
        },
      ];
      const newParsedData: EmissionData = {
        ...parsedData,
        limingEnergy: limingEnergyData,
      } as EmissionData;

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    }
  }

  public saveSoilLandscape(
    emissionActivityId: number,
    projectId: number,
    year: number,
    key: string,
    value?: number
  ) {
    const data = localStorage.getItem("EmissionData");
    const parsedData = JSON.parse(data ?? "{}") as EmissionData;
    const soilLandscapeData = (parsedData.soilLandscape ??
      []) as SoilLandscape[];

    if (soilLandscapeData) {
      const newSoilLandscapeData = soilLandscapeData.find(
        (item) => item.activityYear === year
      )
        ? soilLandscapeData.map((item) => {
            if (item.activityYear === year) {
              return {
                ...item,
                [key]: value,
              };
            }
            return item;
          })
        : [
            ...soilLandscapeData,
            {
              projectId: projectId,
              emissionActivityId: emissionActivityId,
              activityYear: year,
              [key]: value,
            },
          ];
      const newParsedData = {
        ...parsedData,
        soilLandscape: newSoilLandscapeData,
      };

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    } else {
      const soilLandscapeData: SoilLandscape[] = [
        {
          emissionActivityId: emissionActivityId,
          projectId: projectId,
          activityYear: year,
          [key]: value,
        },
      ];
      const newParsedData = {
        ...parsedData,
        soilLandscape: soilLandscapeData,
      } as EmissionData;

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    }
  }

  public saveSyntheticFertilizer(newData: SyntheticFerterlizer) {
    const year = newData.activityYear;
    const data = localStorage.getItem("EmissionData");
    const parsedData = JSON.parse(data ?? "{}") as EmissionData;

    const syntheticFertilizerData = (parsedData.syntheticFertilizer ??
      []) as SyntheticFerterlizer[];

    if (syntheticFertilizerData.length > 0) {
      const yearData = syntheticFertilizerData.find(
        (item) => item.activityYear === year
      );
      if (yearData) {
        const index = syntheticFertilizerData.findIndex(
          (item) => item.activityYear === year
        );
        syntheticFertilizerData[index] = {
          ...newData,
        };
      } else {
        syntheticFertilizerData.push({ ...newData });
      }
      const newParsedData = {
        ...parsedData,
        syntheticFertilizer: syntheticFertilizerData,
      };

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    } else {
      syntheticFertilizerData.push({ ...newData });
      const newParsedData = {
        ...parsedData,
        syntheticFertilizer: syntheticFertilizerData,
      } as EmissionData;

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    }
  }

  public saveCarbonBuilderData(newData: CarbonBuilderApplication) {
    const data = localStorage.getItem("EmissionData");
    const parsedData = JSON.parse(data ?? "{}") as EmissionData;

    const newParsedData = {
      ...parsedData,
      carbonBuilderApplication: newData,
    } as EmissionData;

    localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
  }

  public saveRestrictedActivityData(newData: RestrictedActivity) {
    const data = localStorage.getItem("EmissionData");
    const parsedData = JSON.parse(data ?? "{}") as EmissionData;

    const newParsedData = {
      ...parsedData,
      restrictedActivity: newData,
    } as EmissionData;

    localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
  }

  public saveCropInformation(newData: CropInformation) {
    const year = newData.activityYear;
    const data = localStorage.getItem("EmissionData");
    const parsedData = JSON.parse(data ?? "{}") as EmissionData;

    const cropInformationData = (parsedData.cropInformation ??
      []) as CropInformation[];

    if (cropInformationData.length > 0) {
      const yearData = cropInformationData.find(
        (item) => item.activityYear === year
      );

      if (yearData) {
        const index = cropInformationData.findIndex(
          (item) => item.activityYear === year
        );
        cropInformationData[index] = {
          ...newData,
        };
      } else {
        cropInformationData.push({ ...newData });
      }
      const newParsedData = {
        ...parsedData,
        cropInformation: cropInformationData,
      };

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    } else {
      cropInformationData.push({ ...newData });
      const newParsedData = {
        ...parsedData,
        cropInformation: cropInformationData,
      } as EmissionData;

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    }
  }

  public saveCropTillage(
    emissionActivityId: number,
    projectId: number,
    year: number,
    value?: number
  ) {
    const data = localStorage.getItem("EmissionData");
    const parsedData = JSON.parse(data ?? "{}") as EmissionData;
    const cropTillageData = (parsedData.cropTillage ?? []) as CropTillage[];

    if (cropTillageData) {
      const newCropTillageData = cropTillageData.find(
        (item) => item.activityYear === year
      )
        ? cropTillageData.map((item) => {
            if (item.activityYear === year) {
              return {
                ...item,
                areaTilled: value,
              };
            }
            return item;
          })
        : [
            ...cropTillageData,
            {
              projectId: projectId,
              activityYear: year,
              emissionActivityId: emissionActivityId,
              areaTilled: value,
            },
          ];
      const newParsedData = {
        ...parsedData,
        cropTillage: newCropTillageData,
      };

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    } else {
      const cropTillageData: CropTillage[] = [
        {
          projectId: projectId,
          emissionActivityId: emissionActivityId,
          activityYear: year,
          areaTilled: value,
        },
      ];
      const newParsedData = {
        ...parsedData,
        cropTillage: cropTillageData,
      } as EmissionData;

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    }
  }

  public saveCoverCrop(newData: CoverCrop) {
    const year = newData.activityYear;
    const data = localStorage.getItem("EmissionData");
    const parsedData = JSON.parse(data ?? "{}") as EmissionData;

    const coverCropData = (parsedData.coverCrop ?? []) as CoverCrop[];

    if (coverCropData.length > 0) {
      const yearData = coverCropData.find((item) => item.activityYear === year);
      if (yearData) {
        const index = coverCropData.findIndex(
          (item) => item.activityYear === year
        );
        coverCropData[index] = {
          ...newData,
        };
      } else {
        coverCropData.push({ ...newData });
      }
      const newParsedData = {
        ...parsedData,
        coverCrop: coverCropData,
      };

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    } else {
      coverCropData.push({ ...newData });
      const newParsedData = {
        ...parsedData,
        coverCrop: coverCropData,
      } as EmissionData;

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    }
  }

  public savePastureCrop(newData: PastureCrop) {
    const year = newData.activityYear;
    const data = localStorage.getItem("EmissionData");
    const parsedData = JSON.parse(data ?? "{}") as EmissionData;

    const pastureCropData = (parsedData.pastureCrop ?? []) as PastureCrop[];

    if (pastureCropData.length > 0) {
      const yearData = pastureCropData.find(
        (item) => item.activityYear === year
      );
      if (yearData) {
        const index = pastureCropData.findIndex(
          (item) => item.activityYear === year
        );
        pastureCropData[index] = {
          ...newData,
        };
      } else {
        pastureCropData.push({ ...newData });
      }
      const newParsedData = {
        ...parsedData,
        pastureCrop: pastureCropData,
      };

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    } else {
      pastureCropData.push({ ...newData });
      const newParsedData = {
        ...parsedData,
        pastureCrop: pastureCropData,
      } as EmissionData;

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    }
  }

  public saveLivestock(newData: Livestock) {
    // console.log(newData);
    const year = newData.activityYear;
    const data = localStorage.getItem("EmissionData");
    const parsedData = JSON.parse(data ?? "{}") as EmissionData;

    const livestockData = (parsedData.livestock ?? []) as Livestock[];

    if (livestockData.length > 0) {
      const yearData = livestockData.find((item) => item.activityYear === year);
      if (yearData) {
        const index = livestockData.findIndex(
          (item) => item.activityYear === year
        );
        livestockData[index] = {
          ...newData,
        };
      } else {
        livestockData.push({ ...newData });
      }
      const newParsedData = {
        ...parsedData,
        livestock: livestockData,
      };

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    } else {
      livestockData.push({ ...newData });
      const newParsedData = {
        ...parsedData,
        livestock: livestockData,
      } as EmissionData;

      localStorage.setItem("EmissionData", JSON.stringify(newParsedData));
    }
  }

  public postEmissionAttachment(
    projectId: number,
    emissionActivityId: number,
    attachment: EmissionAttachment
  ) {
    return this.fetcher.fetch<{
      baselineEmissionAttachment: EmissionAttachment;
    }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/emission-attachment`,
      {
        method: "POST",
        body: attachment,
      }
    );
  }

  public getEmissionAttachment(
    projectId: number,
    emissionActivityId: number,
    attachmentKind: string
  ) {
    return this.fetcher.fetch<{ attachments: EmissionAttachment[] }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/emission-attachment/${attachmentKind}`,
      {
        method: "GET",
      }
    );
  }

  public getEmissionAttachmentUrlById(
    projectId: number,
    emissionActivityId: number,
    attachmentId: number
  ) {
    return this.fetcher.fetch<{ url: string }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/emission-attachment-url/${attachmentId}`,
      {
        method: "GET",
      }
    );
  }

  public postBaslineData(
    projectId: number,
    emissionActivityId: number,
    emissionData: EmissionData
  ) {
    return this.fetcher.fetch<{ emissionData: EmissionData }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/emission-data`,
      {
        method: "POST",
        body: emissionData,
      }
    );
  }

  public deleteBaselineEmissionAttachment(
    projectId: number,
    emissionActivityId: number,
    attachment: EmissionAttachment
  ) {
    return this.fetcher.fetch(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/emission-attachment`,
      {
        method: "DELETE",
        body: attachment,
      }
    );
  }

  public postSummaryData(
    projectId: number,
    emissionActivityId: number,
    summaryData: SummaryEmissionData
  ) {
    return this.fetcher.fetch<{ summaryEmissionData: SummaryEmissionData }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/summary-emission-data`,
      {
        method: "POST",
        body: summaryData,
      }
    );
  }

  public getSummaryData(projectId: number, emissionActivityId: number) {
    return this.fetcher.fetch<{
      summaryEmissionData: CalculatedBaselineEmission;
    }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}/summary-emission-data`,
      {
        method: "GET",
      }
    );
  }

  public getEmissionActivityInstances(projectId: number) {
    return this.fetcher.fetch<{ emissionActivity: EmissionActivityInfo[] }>(
      `/api/projects/${projectId}/emission-activity`,
      {
        method: "GET",
      }
    );
  }

  public getEmissionActivityInfo(
    projectId: number,
    emissionActivityId: number
  ) {
    return this.fetcher.fetch<{ emissionActivity: EmissionActivityInfo }>(
      `/api/projects/${projectId}/emission-activity/${emissionActivityId}`,
      {
        method: "GET",
      }
    );
  }

  public postReportIssue(issue: ReportIssueType) {
    return this.fetcher.fetch<{ reportIssue: ReportIssueType }>(
      `/api/report-issue`,
      {
        method: "POST",
        body: issue,
      }
    );
  }
})();