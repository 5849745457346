export default function roundToThree(num: number) {
  return Math.round((num + Number.EPSILON) * 1000) / 1000;
}

export function roundToTwo(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function roundToOne(num: number) {
  return Math.round((num + Number.EPSILON) * 10) / 10;
}
