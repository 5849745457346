import { useEffect, useState } from "react";
import useActivityInfo from "../../hooks/emission/useActivityInfo";
import CoverCrop from "../../views/emission-calculator/calculations/cover-crops";
import CropInformation from "../../views/emission-calculator/calculations/crop-information";
import CropTillage from "../../views/emission-calculator/calculations/crop-tillage";
import IrrigationEnergy from "../../views/emission-calculator/calculations/irrigation-energy";
import Liming from "../../views/emission-calculator/calculations/liming";
import Livestock from "../../views/emission-calculator/calculations/livestock";
import PastureCrop from "../../views/emission-calculator/calculations/pasture-crops";
import SoilLandscape from "../../views/emission-calculator/calculations/soil-landscape";
import Summary from "../../views/emission-calculator/calculations/summary";
import SyntheticFertiliserData from "../../views/emission-calculator/calculations/synthetic-fertiliser-data";
import { useParams } from "react-router-dom";
import useProjectCalculatedEmissionData from "../../hooks/emission/useProjectCalculatedEmissionData";
import { EmissionVariant } from "../../constants";

export default function CalculationSteps() {
  const activityInfo = useActivityInfo();
  const [steps, setSteps] = useState<
    {
      title: string;
      component: JSX.Element | JSX.Element[];
    }[]
  >([]);
  const { projectId, emissionActivityId } = useParams();
  const {
    projectCalculatedEmissionData,
    loading: projectCalculatedEmissionDataLoading,
  } = useProjectCalculatedEmissionData(projectId, emissionActivityId);

  useEffect(() => {
    if (
      !activityInfo ||
      !activityInfo.activityYears.length ||
      projectCalculatedEmissionDataLoading
    )
      return;

    const Steps: {
      title: string;
      component: JSX.Element | JSX.Element[];
    }[] = [];

    if (!projectCalculatedEmissionData) {
      Object.entries(activityInfo).forEach(([key, value]) => {
        switch (key) {
          case "irrigation":
            value &&
              Steps.push({
                title: "Irrigation Energy",
                component: <IrrigationEnergy />,
              });
            break;

          case "limeApplication":
            value &&
              Steps.push({
                title: "Liming",
                component: <Liming />,
              });
            break;

          case "syntheticFertilizer":
            if (value && activityInfo.activityYears.length > 0) {
              if (!Steps.find((ele) => ele.title === "Synthetic Fertilizer")) {
                Steps.push({
                  title: "Synthetic Fertilizer",
                  component: activityInfo.activityYears
                    .sort((a, b) => b - a)
                    .map((year) => <SyntheticFertiliserData year={year} />),
                });
              }
            }

            break;

          case "soilLandscape":
            value &&
              Steps.push({
                title: "Soil Landscape Modification",
                component: <SoilLandscape />,
              });
            break;

          case "growCrop":
            if (value && activityInfo.activityYears.length > 0) {
              if (!Steps.find((ele) => ele.title === "Crop Information")) {
                Steps.push({
                  title: "Crop Information",
                  component: activityInfo.activityYears
                    .sort((a, b) => b - a)
                    .map((year) => <CropInformation year={year} />),
                });
              }
            }

            break;

          case "tillCrop":
            value &&
              Steps.push({
                title: "Crop Tillage",
                component: <CropTillage />,
              });
            break;

          case "coverCrop":
            if (value && activityInfo.activityYears.length > 0) {
              if (!Steps.find((ele) => ele.title === "Cover Crop")) {
                Steps.push({
                  title: "Cover Crop",
                  component: activityInfo.activityYears
                    .sort((a, b) => b - a)
                    .map((year) => <CoverCrop year={year} />),
                });
              }
              if (!Steps.find((ele) => ele.title === "Pasture")) {
                Steps.push({
                  title: "Pasture",
                  component: activityInfo.activityYears
                    .sort((a, b) => b - a)
                    .map((year) => <PastureCrop year={year} />),
                });
              }
            }
            break;

          case "livestock":
            if (
              value &&
              activityInfo.activityYears.length > 0 &&
              activityInfo.historicalStockRate
            ) {
              if (!Steps.find((ele) => ele.title === "Livestock")) {
                Steps.push({
                  title: "Livestock",
                  component: activityInfo.activityYears
                    .sort((a, b) => b - a)
                    .map((year) => (
                      <Livestock
                        year={year}
                        variant={EmissionVariant.Baseline}
                      />
                    )),
                });
              }
            }
            break;

          default:
            break;
        }
      });
    }
    Steps.push({
      title: "Summary",
      component: <Summary />,
    });

    setSteps(Steps);
  }, [
    activityInfo,
    projectCalculatedEmissionData,
    projectCalculatedEmissionDataLoading,
  ]);

  return steps;
}
