import { useEffect, useState } from "react";
import { Grower } from "../types";
import { FetchError } from "../lib/fetcher";
import { api } from "../api";
import useMe from "./useMe";

export default function useMyGrower() {
  const me = useMe();
  const [grower, setGrower] = useState<Grower>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    async function getGrower() {
      if (!me) {
        setLoading(false);
        return;
      }
      const response = await api.getGrower(me.id);
      if (response instanceof FetchError) {
        alert(response.message);
        setLoading(false);
        setError(response.message);
        return;
      }
      setGrower(response.grower);
      setLoading(false);
    }

    getGrower(); // remove the return statement
  }, [me]);

  return { grower, loading, error };
}
