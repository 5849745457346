import { useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import Checkbox from "../../../components/checkbox-input";
import {
  CarbonBuilderProduct,
  EmissionAttachmentType,
  EmissionVariant,
  MINIMUM_INPUT_WARNING,
} from "../../../constants";
import TextInput from "../../../components/text-input";
import { CarbonBuilderApplication as CarbonBuilderApplicationType } from "../../../types";
import { useParams } from "react-router-dom";
import formatDate from "../../../helpers/format-date";
import useEmissionActivity from "../../../hooks/emission/useEmissionActivity";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import useEmissionData from "../../../hooks/useEmissionData";
import { api } from "../../../api";
import Divider from "../../../components/divider";
import EmissionAttachments from "../emission-attachments";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";

export default function CarbonBuilderApplication() {
  const [numOfPaddocks, setNumOfPaddocks] = useState<{
    barleyPaddocks?: number;
    canolaPaddocks?: number;
  }>({
    barleyPaddocks: undefined,
    canolaPaddocks: undefined,
  });
  const [carbonBuilderApplication, setCarbonBuilderApplication] =
    useState<CarbonBuilderApplicationType>();

  const { projectId, emissionActivityId } = useParams();
  const emissionActivity = useEmissionActivity();
  const emissionData = useEmissionData();
  const { projectAttachment, mutate } = useProjectAttachment(
    projectId,
    emissionActivityId,
    EmissionAttachmentType.CarbonBuilder
  );

  useEffect(() => {
    if (!emissionActivityId || !projectId) return;
    if (!emissionData || !emissionData.carbonBuilderApplication) {
      setCarbonBuilderApplication({
        carbonBuilderData: [],
        projectId: +projectId,
        emissionActivityId: +emissionActivityId,
      });
      return;
    }
    setCarbonBuilderApplication(emissionData.carbonBuilderApplication);
    const canolaPaddocks =
      emissionData.carbonBuilderApplication.carbonBuilderData?.find(
        (item) => item.carbonBuilderType === "Canola CarbonBuilder"
      )?.carbonBuilderTypeData?.length;

    const barleyPaddocks =
      emissionData.carbonBuilderApplication.carbonBuilderData?.find(
        (item) => item.carbonBuilderType === "Barley CarbonBuilder"
      )?.carbonBuilderTypeData?.length;
    setNumOfPaddocks({
      canolaPaddocks: canolaPaddocks === 0 ? undefined : canolaPaddocks,
      barleyPaddocks: barleyPaddocks === 0 ? undefined : barleyPaddocks,
    });
  }, [emissionActivityId, emissionData, projectId]);

  useEffect(() => {
    function saveCarbonBuilderApplication() {
      if (!carbonBuilderApplication) return;
      return api.saveCarbonBuilderData(carbonBuilderApplication);
    }
    saveCarbonBuilderApplication();
  }, [carbonBuilderApplication]);

  const requiredAttachmentTypes = useMemo(() => {
    if (
      !carbonBuilderApplication ||
      !carbonBuilderApplication.carbonBuilderData
    )
      return [];
    return carbonBuilderApplication.carbonBuilderData
      .map((item) => item.carbonBuilderType)
      .filter(Boolean) as string[];
  }, [carbonBuilderApplication]);

  useEffect(() => {
    if (!carbonBuilderApplication) return;

    const updatedCarbonBuilder = {
      ...carbonBuilderApplication,
      carbonBuilderData: carbonBuilderApplication.carbonBuilderData?.map(
        (item) => {
          if (item.carbonBuilderType === "Canola CarbonBuilder") {
            return {
              ...item,
              carbonBuilderTypeData:
                item.carbonBuilderTypeData?.slice(
                  0,
                  numOfPaddocks.canolaPaddocks
                ) ?? [],
            };
          } else {
            return {
              ...item,
              carbonBuilderTypeData:
                item.carbonBuilderTypeData?.slice(
                  0,
                  numOfPaddocks.barleyPaddocks
                ) ?? [],
            };
          }
        }
      ),
    };

    if (!isEqual(updatedCarbonBuilder, carbonBuilderApplication)) {
      setCarbonBuilderApplication(updatedCarbonBuilder);
    }
  }, [carbonBuilderApplication, numOfPaddocks]);

  return (
    <div className="flex flex-col items-center w-full">
      {!carbonBuilderApplication ||
      !emissionActivity ||
      !projectId ||
      !emissionActivityId ? (
        <div>No Data</div>
      ) : (
        <div className="w-full">
          <div className="border-b border-gray-200 pb-4 lg:pb-8">
            <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
              Carbon Builder Application
            </h2>
          </div>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Please select the CarbonBuilder product(s) you have applied
                between{" "}
                <span className="font-semibold text-apple-700">
                  <span>
                    {formatDate(emissionActivity?.activityStartDate)} -{" "}
                    {formatDate(emissionActivity?.activityEndDate)}
                  </span>
                  .
                </span>
              </label>
              {!carbonBuilderApplication.carbonBuilderData ||
              carbonBuilderApplication.carbonBuilderData.length === 0 ? (
                <p className="pt-2 text-xs text-red-600" id="error">
                  This field is required!
                </p>
              ) : null}
              <div className="grid grid-cols-2  lg:grid-cols-3 gap-2 sm:gap-3 lg:gap-4 mt-3">
                {Object.entries(CarbonBuilderProduct).map(([key, value]) => (
                  <div
                    key={key}
                    className="flex flex-row items-center w-full col-span-1"
                  >
                    <Checkbox
                      checked={
                        carbonBuilderApplication.carbonBuilderData?.some(
                          (item) => item.carbonBuilderType === value
                        ) ?? false
                      }
                      value={key}
                      label={value}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCarbonBuilderApplication({
                            ...carbonBuilderApplication,
                            carbonBuilderData: [
                              ...(carbonBuilderApplication.carbonBuilderData ??
                                []),
                              { carbonBuilderType: value },
                            ],
                          });
                        } else {
                          setCarbonBuilderApplication({
                            ...carbonBuilderApplication,
                            carbonBuilderData:
                              carbonBuilderApplication.carbonBuilderData?.filter(
                                (item) => item.carbonBuilderType !== value
                              ),
                          });
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Divider />
          {carbonBuilderApplication.carbonBuilderData ? (
            <>
              {carbonBuilderApplication.carbonBuilderData.map((item, i) => (
                <div
                  key={i}
                  className="grid grid-cols-1 gap-x-6 sm:grid-cols-6 px-2"
                >
                  <div className="col-span-full">
                    <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
                      {item.carbonBuilderType}
                    </h2>
                  </div>
                  <div className="col-span-full">
                    <label className="block text-sm font-medium text-gray-900">
                      How many paddocks did you apply{" "}
                      <span className="font-semibold text-apple-700">
                        {item.carbonBuilderType}
                      </span>{" "}
                      to?
                    </label>
                  </div>
                  <div className="sm:col-span-1">
                    <TextInput
                      type="number"
                      inputMode="decimal"
                      defaultValue={
                        item.carbonBuilderType === "Canola CarbonBuilder"
                          ? numOfPaddocks.canolaPaddocks ?? ""
                          : numOfPaddocks.barleyPaddocks ?? ""
                      }
                      onInput={(e) => {
                        if (item.carbonBuilderType === "Canola CarbonBuilder") {
                          if (
                            +e.currentTarget.value < 1 ||
                            !e.currentTarget.value
                          ) {
                            alert(MINIMUM_INPUT_WARNING);
                            e.currentTarget.value = "";
                            setNumOfPaddocks({
                              ...numOfPaddocks,
                              canolaPaddocks: undefined,
                            });
                          } else {
                            setNumOfPaddocks({
                              ...numOfPaddocks,
                              canolaPaddocks: e.currentTarget.value.length
                                ? +e.currentTarget.value
                                : undefined,
                            });
                          }
                        } else {
                          if (
                            +e.currentTarget.value < 1 ||
                            !e.currentTarget.value
                          ) {
                            alert(MINIMUM_INPUT_WARNING);
                            e.currentTarget.value = "";
                            setNumOfPaddocks({
                              ...numOfPaddocks,
                              barleyPaddocks: undefined,
                            });
                          } else {
                            setNumOfPaddocks({
                              ...numOfPaddocks,
                              barleyPaddocks: e.currentTarget.value.length
                                ? +e.currentTarget.value
                                : undefined,
                            });
                          }
                        }
                      }}
                      error={
                        (item.carbonBuilderType === "Canola CarbonBuilder" &&
                          !numOfPaddocks.canolaPaddocks) ||
                        (item.carbonBuilderType === "Barley CarbonBuilder" &&
                          !numOfPaddocks.barleyPaddocks)
                          ? 1
                          : 0
                      }
                    />
                  </div>
                  <div className="col-span-full sm:mt-4">
                    <div className="mt-2 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full">
                            <thead className="divide-y-reverse divide-gray-200">
                              <tr className="divide-x  divide-gray-200">
                                <th
                                  scope="col"
                                  className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                                ></th>
                                <th
                                  scope="col"
                                  className="px-4 py-3.5  text-sm font-semibold text-gray-900 text-center"
                                >
                                  Paddock Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-4 py-3.5  text-sm font-semibold text-gray-900 text-center"
                                >
                                  Date Applied
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                                >
                                  Volume Applied
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              <tr className="divide-x divide-gray-200">
                                <th
                                  scope="col"
                                  className="py-0.5 text-center px-5 text-sm font-normal text-gray-900 "
                                ></th>
                                <th
                                  scope="col"
                                  className="py-0.5 text-center px-5 text-sm font-normal text-gray-900 "
                                ></th>
                                <th
                                  scope="col"
                                  className="py-0.5 text-center px-5 text-sm font-normal text-gray-900 "
                                ></th>
                                <th
                                  scope="col"
                                  className="py-0.5 pl-4 pr-4  text-xs font-normal text-gray-900 sm:pr-0 text-center bg-apple-50"
                                >
                                  grams (g)
                                </th>
                              </tr>
                              {numOfPaddocks.canolaPaddocks ||
                              numOfPaddocks.barleyPaddocks ? (
                                Array.from(
                                  {
                                    length:
                                      item.carbonBuilderType ===
                                      CarbonBuilderProduct.CanolaCarbonBuilder
                                        ? numOfPaddocks.canolaPaddocks ?? 0
                                        : numOfPaddocks.barleyPaddocks ?? 0,
                                  },
                                  (_, i) => i + 1
                                ).map((i) => (
                                  <tr
                                    key={i}
                                    className="divide-x divide-gray-200"
                                  >
                                    <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center">
                                      Paddock {i}
                                    </td>
                                    <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                      <TextInput
                                        type="text"
                                        inputMode="text"
                                        value={
                                          item.carbonBuilderTypeData
                                            ? item.carbonBuilderTypeData[i - 1]
                                                ?.paddockName ?? ""
                                            : ""
                                        }
                                        onChange={(e) => {
                                          if (item.carbonBuilderTypeData) {
                                            item.carbonBuilderTypeData[i - 1] =
                                              {
                                                ...item.carbonBuilderTypeData[
                                                  i - 1
                                                ],
                                                paddockName: e.target.value,
                                              };
                                          } else {
                                            item.carbonBuilderTypeData = [];
                                            item.carbonBuilderTypeData[i - 1] =
                                              {
                                                paddockName: e.target.value,
                                              };
                                          }
                                          setCarbonBuilderApplication({
                                            ...carbonBuilderApplication,
                                            carbonBuilderData: [
                                              ...(carbonBuilderApplication.carbonBuilderData ??
                                                []),
                                            ],
                                          });
                                        }}
                                        error={
                                          item.carbonBuilderTypeData &&
                                          !item.carbonBuilderTypeData[i - 1]
                                            ?.paddockName
                                            ? 1
                                            : 0
                                        }
                                        dontshowerrormesage={true}
                                      />
                                    </td>
                                    <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                      <TextInput
                                        type="date"
                                        min={
                                          new Date(
                                            emissionActivity?.activityStartDate ??
                                              ""
                                          )
                                            .toISOString()
                                            .split("T")[0]
                                        }
                                        max={
                                          new Date(
                                            emissionActivity?.activityEndDate ??
                                              new Date()
                                                .toISOString()
                                                .split("T")[0]
                                          )
                                            .toISOString()
                                            .split("T")[0]
                                        }
                                        value={
                                          item.carbonBuilderTypeData
                                            ? item.carbonBuilderTypeData[i - 1]
                                                ?.dateApplied ?? ""
                                            : ""
                                        }
                                        onChange={(e) => {
                                          if (item.carbonBuilderTypeData) {
                                            item.carbonBuilderTypeData[i - 1] =
                                              {
                                                ...item.carbonBuilderTypeData[
                                                  i - 1
                                                ],
                                                dateApplied: e.target.value,
                                              };
                                          } else {
                                            item.carbonBuilderTypeData = [];
                                            item.carbonBuilderTypeData[i - 1] =
                                              {
                                                dateApplied: e.target.value,
                                              };
                                          }
                                          setCarbonBuilderApplication({
                                            ...carbonBuilderApplication,
                                            carbonBuilderData: [
                                              ...(carbonBuilderApplication.carbonBuilderData ??
                                                []),
                                            ],
                                          });
                                        }}
                                        error={
                                          item.carbonBuilderTypeData &&
                                          !item.carbonBuilderTypeData[i - 1]
                                            ?.dateApplied
                                            ? 1
                                            : 0
                                        }
                                        dontshowerrormesage={true}
                                      />
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                                      <TextInput
                                        type="number"
                                        inputMode="decimal"
                                        defaultValue={
                                          item.carbonBuilderTypeData
                                            ? item.carbonBuilderTypeData[i - 1]
                                                ?.volumeApplied ?? ""
                                            : ""
                                        }
                                        onInput={(e) => {
                                          if (
                                            +e.currentTarget.value < 1 ||
                                            !e.currentTarget.value
                                          ) {
                                            alert(MINIMUM_INPUT_WARNING);
                                            e.currentTarget.value = "";
                                          }
                                          if (item.carbonBuilderTypeData) {
                                            item.carbonBuilderTypeData[i - 1] =
                                              {
                                                ...item.carbonBuilderTypeData[
                                                  i - 1
                                                ],
                                                volumeApplied: e.currentTarget
                                                  .value
                                                  ? +e.currentTarget.value
                                                  : undefined,
                                              };
                                          } else {
                                            item.carbonBuilderTypeData = [];
                                            item.carbonBuilderTypeData[i - 1] =
                                              {
                                                volumeApplied: e.currentTarget
                                                  .value
                                                  ? +e.currentTarget.value
                                                  : undefined,
                                              };
                                          }
                                          setCarbonBuilderApplication({
                                            ...carbonBuilderApplication,
                                            carbonBuilderData: [
                                              ...(carbonBuilderApplication.carbonBuilderData ??
                                                []),
                                            ],
                                          });
                                        }}
                                        error={
                                          item.carbonBuilderTypeData &&
                                          !item.carbonBuilderTypeData[i - 1]
                                            ?.volumeApplied
                                            ? 1
                                            : 0
                                        }
                                        dontshowerrormesage={true}
                                      />
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={4}>
                                    <EmptyPlaceholder>
                                      <span className="text-sm text-gray-500">
                                        Please enter the number of paddocks you
                                        applied{" "}
                                        <span className="font-semibold text-apple-700">
                                          {item.carbonBuilderType}
                                        </span>{" "}
                                        to.
                                      </span>
                                    </EmptyPlaceholder>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <Divider />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div> No data</div>
          )}

          {carbonBuilderApplication.carbonBuilderData?.length ? (
            <>
              <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
                Attachments
              </h2>
              <EmissionAttachments
                projectId={+projectId}
                emissionActivityId={+emissionActivityId}
                emissionActivity={emissionActivity}
                variant={EmissionVariant.OnGoing}
                year={new Date(
                  emissionActivity.activityStartDate ?? new Date()
                ).getFullYear()}
                title="Carbon Builder Application"
                attachments={projectAttachment}
                mutateAttachments={mutate}
                attachmentKind={EmissionAttachmentType.CarbonBuilder}
                attachmentTypes={requiredAttachmentTypes}
              />
            </>
          ) : null}
        </div>
      )}
    </div>
  );
}
