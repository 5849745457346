import React from "react";
import Loading from "../../components/loading";
import { KybNode } from "../../types";
import { StepComponent } from "../../views/kyb-kyc";
import BusinessInformation from "../../views/kyb-kyc-components/mid-business-information";
import SoleTrader from "../../views/kyb-kyc-components/sole-trader";
import Trust from "../../views/kyb-kyc-components/trust";
import Partnership from "../../views/kyb-kyc-components/partnership";
import { EntityTypes } from "../../constants";
import PEPQuestion from "../../views/kyb-kyc-components/pep-question";
import SanctionDeclaration from "../../views/kyb-kyc-components/sanction-declaration";
import FinishPage from "../../views/kyb-kyc-components/finish";
const LazyUnlistedCompany = React.lazy(
  () => import("../../views/kyb-kyc-components/unlisted-company")
);

export default function detemineStepsfromNodes(nodes: KybNode[]) {
  const steps: StepComponent[] = [];

  nodes.map((node, i) => {
    switch (node.entityType) {
      case EntityTypes.Other:
        steps.push({
          title: `Entity ${i + 1} (Other Entity)`,
          components: [
            {
              name: "Mid Business Information",
              component: <BusinessInformation />,
            },
            {
              name: "Mid Unlisted Company",
              component: (
                <React.Suspense fallback={<Loading />}>
                  <LazyUnlistedCompany />
                </React.Suspense>
              ),
            },
          ],
        });
        break;

      case EntityTypes.UnlistedCompany:
        steps.push({
          title: `Entity ${i + 1} (Unlisted Company)`,
          components: [
            {
              name: "Mid Business Information",
              component: <BusinessInformation />,
            },
            {
              name: "Mid Unlisted Company",
              component: (
                <React.Suspense fallback={<Loading />}>
                  <LazyUnlistedCompany />
                </React.Suspense>
              ),
            },
          ],
        });

        break;
      case EntityTypes.SoleTraderIndividual:
        steps.push({
          title: `Entity ${i + 1} (Sole Trader/Individual)`,
          components: [
            {
              name: "Mid Business Information",
              component: <BusinessInformation />,
            },
            {
              name: EntityTypes.SoleTraderIndividual,
              component: <SoleTrader />,
            },
          ],
        });
        break;

      case EntityTypes.Partnership:
        steps.push({
          title: `Entity ${i + 1} (Partnership)`,
          components: [
            {
              name: "Mid Business Information",
              component: <BusinessInformation />,
            },
            {
              name: EntityTypes.Partnership,
              component: <Partnership />,
            },
          ],
        });
        break;

      case EntityTypes.Trust:
        steps.push({
          title: `Entity ${i + 1} (Trust)`,
          components: [
            {
              name: "Mid Business Information",
              component: <BusinessInformation />,
            },
            {
              name: EntityTypes.Trust,
              component: <Trust />,
            },
          ],
        });
        break;
      default:
        break;
    }
    return null;
  });
  steps.push({
    title: `Politically Exposed Person (PEP) Declaration`,
    components: [
      {
        name: "Politically Exposed Person (PEP) Declaration",
        component: <PEPQuestion />,
      },
    ],
  });
  steps.push({
    title: `Sanction Declaration`,
    components: [
      {
        name: "Sanction Declaration",
        component: <SanctionDeclaration />,
      },
    ],
  });
  steps.push({
    title: `Finish`,
    components: [
      {
        name: "Finish",
        component: <FinishPage />,
      },
    ],
  });
  return steps;
}
