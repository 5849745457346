interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string | React.ReactNode;
  error?: number;
  dontshowerrormesage?: boolean;
}
export default function SelectInput({
  label,
  dontshowerrormesage,
  ...props
}: Props) {
  return (
    <div className="my-2">
      <label className="block text-xs font-medium  text-gray-900">
        {label}
      </label>
      <div className="mt-1">
        <select
          id={label as string}
          {...props}
          className={`block w-full rounded-md border-0 py-1 px-2 ring-1 ring-inset ${
            props.error ? "ring-red-300" : "ring-gray-300"
          } focus:ring-2 focus:ring-inset focus:ring-apple-500 text-sm sm:leading-6 ${
            !props.disabled
              ? "bg-transparent text-gray-900"
              : "bg-gray-100 text-gray-500"
          }`}
        />
        {props.error && !dontshowerrormesage ? (
          <p className="mt-0.5 text-xs text-red-600" id="error">
            This field is required!
          </p>
        ) : null}
      </div>
    </div>
  );
}
