/* eslint-disable @typescript-eslint/no-explicit-any */
import { SummaryEmissionData } from "../../../../types";
import { Chart } from "react-chartjs-2";
import { Chart as ChartType } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import roundToThree, { roundToOne } from "../../../../helpers/rounding";

ChartType.register(ChartDataLabels);

interface SummaryChartProps {
  data: SummaryEmissionData;
  summaryEmissionData: SummaryEmissionData;
  period?: string;
}

export default function SummaryChart({
  data: baselineData,
  summaryEmissionData,
  period,
}: SummaryChartProps) {
  const labels = [
    "Irrigation",
    "Liming",
    "Synthetic Fertiliser",
    "Residue & Tillage",
    "Livestock",
  ];

  const options = {
    plugins: {
      title: {
        display: true,
        text: period + " Emission by Activity",
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Activity",
        },
      },
      y: {
        title: {
          display: true,
          text: "tonnes of CO2-e",
        },
      },
    },
  };
  return (
    <div className="grid grid-cols-1 gap-x-6  items-center sm:grid-cols-12 px-2 ">
      <div className="col-span-full h-[300px]">
        {summaryEmissionData && (
          <>
            <Chart
              type="bar"
              options={options}
              data={{
                labels: labels,
                datasets: [
                  {
                    label: "Activity Emission (tonnes of CO2-e)",
                    data: [
                      summaryEmissionData.irrigationEmission?.reduce(
                        (acc, data) =>
                          acc +
                          (data.fuelEmission || 0) +
                          (data.electricityEmission || 0),
                        0
                      ),
                      summaryEmissionData.limingEmission?.reduce(
                        (acc, data) =>
                          acc +
                          (data.dolomiteEmission || 0) +
                          (data.limestoneEmission || 0),
                        0
                      ),
                      summaryEmissionData.syntheticFertilizerEmission?.reduce(
                        (acc, data) => acc + (data.emission || 0),

                        0
                      ),
                      summaryEmissionData.residueTillageAndLandscapeModificationEmission?.reduce(
                        (acc, data) =>
                          acc +
                          (data.tillageAndSoilLandscapingEmission || 0) +
                          (data.cropResidueEmission || 0) +
                          (data.pastureTillageEmission || 0) +
                          (data.coverCropEmission || 0),
                        0
                      ),
                      summaryEmissionData.livestockEmission?.reduce(
                        (acc, data) =>
                          acc +
                          (data.animalEmisions?.reduce(
                            (acc, data) => acc + (data.emission ?? 0),
                            0
                          ) || 0),
                        0
                      ),
                    ],
                    backgroundColor: [
                      "#a8da9b",
                      "#93befd",
                      "#fdd28a",
                      "#fca5a5",
                      "#cdb5fd",
                    ],
                  },
                ],
              }}
            />
          </>
        )}
      </div>
      <div className="col-span-full sm:col-span-6 h-[300px]">
        <Chart
          type="pie"
          options={{
            plugins: {
              title: {
                display: true,
                text: period + " Emission by Activity",
              },
              legend: {
                display: true,
              },
              datalabels: {
                backgroundColor: [
                  "#a8da9b",
                  "#93befd",
                  "#fdd28a",
                  "#fca5a5",
                  "#cdb5fd",
                ],
                borderColor: "white",
                borderRadius: 4,
                borderWidth: 1.5,
                color: "white",
                display: true,
                font: {
                  size: 10,
                  weight: "bold",
                },
                padding: 2,
                formatter: (value: number, context: any) => {
                  const total = context.chart.data.datasets[0].data.reduce(
                    (acc: number, data: number) => (acc || 0) + (data || 0),
                    0
                  );
                  const percentage = ((value ?? 0) / total) * 100;
                  return `${roundToOne(percentage)}%`;
                },
                anchor: "end",
                // align: "end",
              },
            },
            cutout: 50,
            responsive: true,
            maintainAspectRatio: false,
          }}
          data={{
            labels: labels,
            datasets: [
              {
                label: "Activity Emission (tonnes of CO2-e)",
                data: [
                  roundToThree(
                    summaryEmissionData.irrigationEmission?.reduce(
                      (acc, data) =>
                        acc +
                        (data.fuelEmission || 0) +
                        (data.electricityEmission || 0),
                      0
                    ) ?? 0
                  ),
                  roundToThree(
                    summaryEmissionData.limingEmission?.reduce(
                      (acc, data) =>
                        acc +
                        (data.dolomiteEmission || 0) +
                        (data.limestoneEmission || 0),
                      0
                    ) ?? 0
                  ),
                  roundToThree(
                    summaryEmissionData.syntheticFertilizerEmission?.reduce(
                      (acc, data) => acc + (data.emission || 0),

                      0
                    ) ?? 0
                  ),
                  roundToThree(
                    summaryEmissionData.residueTillageAndLandscapeModificationEmission?.reduce(
                      (acc, data) =>
                        acc +
                        (data.tillageAndSoilLandscapingEmission || 0) +
                        (data.cropResidueEmission || 0) +
                        (data.pastureTillageEmission || 0) +
                        (data.coverCropEmission || 0),
                      0
                    ) ?? 0
                  ),
                  roundToThree(
                    summaryEmissionData.livestockEmission?.reduce(
                      (acc, data) =>
                        acc +
                        (data.animalEmisions?.reduce(
                          (acc, data) => acc + (data.emission ?? 0),
                          0
                        ) || 0),
                      0
                    ) ?? 0
                  ),
                ],
                backgroundColor: [
                  "#a8da9b",
                  "#93befd",
                  "#fdd28a",
                  "#fca5a5",
                  "#cdb5fd",
                ],
              },
            ],
          }}
        />
      </div>
      <div className="col-span-full sm:col-span-6 h-[300px]">
        <Chart
          type="pie"
          options={{
            plugins: {
              title: {
                display: true,
                text: "Baseline Emission by Activity",
              },
              legend: {
                display: true,
              },
              datalabels: {
                backgroundColor: [
                  "#a8da9b",
                  "#93befd",
                  "#fdd28a",
                  "#fca5a5",
                  "#cdb5fd",
                ],
                borderColor: "white",
                borderRadius: 4,
                borderWidth: 1.5,
                color: "white",
                display: true,
                font: {
                  size: 10,
                  weight: "bold",
                },
                padding: 2,
                formatter: (value: number, context: any) => {
                  const total = context.chart.data.datasets[0].data.reduce(
                    (acc: number, data: number) => acc + data,
                    0
                  );
                  const percentage = (value / total) * 100;
                  return `${roundToOne(percentage)}%`;
                },
                anchor: "end",
                // align: "end",
              },
            },
            cutout: 50,
            responsive: true,
            maintainAspectRatio: false,
            // tooltip: true,
          }}
          data={{
            labels: labels,
            datasets: [
              {
                label: "Activity Emission (tonnes of CO2-e)",
                data: [
                  roundToThree(
                    (baselineData.irrigationEmission?.reduce(
                      (acc, data) =>
                        acc +
                        (data.fuelEmission || 0) +
                        (data.electricityEmission || 0),
                      0
                    ) ?? 0) / 5
                  ),
                  roundToThree(
                    (baselineData.limingEmission?.reduce(
                      (acc, data) =>
                        acc +
                        (data.dolomiteEmission || 0) +
                        (data.limestoneEmission || 0),
                      0
                    ) ?? 0) / 5
                  ),
                  roundToThree(
                    (baselineData.syntheticFertilizerEmission?.reduce(
                      (acc, data) => acc + (data.emission || 0),

                      0
                    ) ?? 0) / 5
                  ),
                  roundToThree(
                    (baselineData.residueTillageAndLandscapeModificationEmission?.reduce(
                      (acc, data) =>
                        acc +
                        (data.tillageAndSoilLandscapingEmission || 0) +
                        (data.cropResidueEmission || 0) +
                        (data.pastureTillageEmission || 0) +
                        (data.coverCropEmission || 0),
                      0
                    ) ?? 0) / 5
                  ),
                  roundToThree(
                    (baselineData.livestockEmission?.reduce(
                      (acc, data) =>
                        acc +
                        (data.animalEmisions?.reduce(
                          (acc, data) => acc + (data.emission ?? 0),
                          0
                        ) || 0),
                      0
                    ) ?? 0) / 5
                  ),
                ],
                backgroundColor: [
                  "#a8da9b",
                  "#93befd",
                  "#fdd28a",
                  "#fca5a5",
                  "#cdb5fd",
                ],
              },
            ],
          }}
        />
      </div>
    </div>
  );
}
