import { AnimalType } from "../../../constants";
import { GetObjectValue } from "../../../helpers/getObjectValue";
import useEmissionData from "../../../hooks/useEmissionData";
import { EmissionData, SummaryEmissionData } from "../../../types";
import { useEffect, useMemo, useState } from "react";
import useFarmInfo from "../../../hooks/emission/useFarmInfo";
import useActivityInfo from "../../../hooks/emission/useActivityInfo";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import useProjectCalculatedEmissionData from "../../../hooks/emission/useProjectCalculatedEmissionData";
import { useParams } from "react-router-dom";
import OverlayLoading from "../../../components/overlay-loading";
import roundToThree from "../../../helpers/rounding";
import useEmissionActivity from "../../../hooks/emission/useEmissionActivity";
import formatDate from "../../../helpers/format-date";
import SummaryChart from "./summary-charts/summary-chart";
import IrrigationChart from "./summary-charts/irrigation-chart";
import LimingChart from "./summary-charts/liming-chart";
import SynthFertiliserChart from "./summary-charts/synthetic-ferterlizer-chart";
import ResidueChart from "./summary-charts/residue-chart";
import LivestockChart from "./summary-charts/livestock-chart";
import calculateEmissionData from "../../../helpers/ongoing-emission/calculate-emission-data";

export default function Summary() {
  const emissionData = useEmissionData();
  const emissionActivity = useEmissionActivity();
  const baselineEmissionId = localStorage.getItem("baselineEmissionId") ?? "";
  const [projectBaselineData, setProjectBaselineData] =
    useState<EmissionData>();
  const farmInfo = useFarmInfo();
  const activityInfo = useActivityInfo();
  const [summaryEmissionData, setSummaryEmissionData] =
    useState<SummaryEmissionData>();
  const { projectId, emissionActivityId } = useParams();
  const {
    projectCalculatedEmissionData: calculatedEmissionData,
    loading: projectCalculatedEmissionDataLoading,
  } = useProjectCalculatedEmissionData(projectId, emissionActivityId);

  const { projectCalculatedEmissionData: baselineCalculatedEmissionData } =
    useProjectCalculatedEmissionData(projectId, baselineEmissionId);

  useEffect(() => {
    if (!emissionData) return;

    setProjectBaselineData(emissionData);
  }, [emissionData]);

  const period = useMemo(() => {
    if (!emissionActivity) return undefined;
    return (
      formatDate(emissionActivity.activityStartDate) +
      " - " +
      formatDate(emissionActivity.activityEndDate)
    );
  }, [emissionActivity]);

  const emissionUnit = (
    <span className="font-bold">
      {" "}
      tonnes of{" "}
      <span>
        CO<sub>2</sub>-e
      </span>
    </span>
  );

  function RenderAverageEmission(emissionNumber: number) {
    return (
      <tfoot className="divide-y divide-gray-200 bg-apple-50">
        <tr className="divide-x divide-gray-200">
          <td className="whitespace-wrap py-2 pl-2 pr-2 text-base font-bold text-gray-900 text-center px-8 ">
            Average Emission
          </td>
          <td
            className="whitespace-nowrap py-4 pl-4 pr-4 sm:pr-0 text-center"
            colSpan={100}
          >
            <span className="text-base text-apple-800 font-extrabold">
              {roundToThree(emissionNumber)}
            </span>
            <span className="text-sm text-gray-700">{emissionUnit}</span>
          </td>
        </tr>
      </tfoot>
    );
  }

  useEffect(() => {
    if (!projectBaselineData || !emissionActivity) return;

    const parsedData = calculateEmissionData();

    if (calculatedEmissionData) {
      setSummaryEmissionData(calculatedEmissionData);
      return;
    } else {
      setSummaryEmissionData(parsedData);
    }
  }, [
    projectBaselineData,
    farmInfo,
    activityInfo,
    calculatedEmissionData,
    emissionActivity,
  ]);

  return (
    <div
      className="flex flex-col items-center w-full overflow-hidden"
      id="summary"
    >
      <OverlayLoading open={projectCalculatedEmissionDataLoading} />
      {!summaryEmissionData || !baselineCalculatedEmissionData ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p>No Data</p>
        </div>
      ) : (
        <div className="w-full h-full">
          <div className="border-b border-gray-200 pb-4 lg:pb-8">
            <div className="flex-col">
              <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
                Emission Summary
              </h2>
            </div>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Please find below our calculations based on the data you entered.
            </p>
          </div>
          <SummaryChart
            data={baselineCalculatedEmissionData}
            summaryEmissionData={summaryEmissionData}
            period={period}
          />
          <div className="mt-4 grid grid-cols-1 gap-x-6 sm:grid-cols-6 items-center px-2">
            <div className="col-span-full">
              <label className="block text-base font-medium leading-6 text-apple-700">
                Irrigation Emissions
              </label>
            </div>

            <IrrigationChart
              data={summaryEmissionData}
              baselineCalculatedEmissionData={baselineCalculatedEmissionData}
              emissionActivity={emissionActivity}
            />

            <div className="col-span-full sm:col-span-3">
              {summaryEmissionData.irrigationEmission ? (
                <div className="mt-2 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr className="divide-x divide-gray-200">
                            <th
                              rowSpan={2}
                              className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                            >
                              Period
                            </th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                            >
                              Irrrigation Emissions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {summaryEmissionData.irrigationEmission
                            .sort((a, b) => b.year - a.year)
                            .map((data, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center px-8 ">
                                  {period}
                                </td>

                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0 text-center">
                                  {roundToThree(
                                    (data.electricityEmission || 0) +
                                      (data.fuelEmission || 0)
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        {RenderAverageEmission(
                          summaryEmissionData.irrigationEmission.reduce(
                            (acc, data) =>
                              acc +
                              (data.fuelEmission || 0) +
                              (data.electricityEmission || 0),
                            0
                          )
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Irrigation Emissions not available because you have not
                  entered Irrigation data.
                </EmptyPlaceholder>
              )}
            </div>
          </div>
          <div className="col-span-full my-6 border-t border-t-gray-200" />

          <div className="mt-4 grid grid-cols-1 gap-x-6  items-center sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-base font-medium leading-6 text-apple-700">
                Liming Emissions
              </label>
            </div>

            <LimingChart
              data={summaryEmissionData}
              baselineCalculatedEmissionData={baselineCalculatedEmissionData}
              emissionActivity={emissionActivity}
            />

            <div className="col-span-full sm:col-span-3">
              {summaryEmissionData.limingEmission ? (
                <div className="mt-2 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                            >
                              Period
                            </th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                            >
                              Liming Emissions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {summaryEmissionData.limingEmission
                            .sort((a, b) => b.year - a.year)
                            .map((data, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center px-8 ">
                                  {period}
                                </td>

                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0 text-center">
                                  {roundToThree(
                                    (data.dolomiteEmission ?? 0) +
                                      (data.limestoneEmission ?? 0)
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        {RenderAverageEmission(
                          summaryEmissionData.limingEmission.reduce(
                            (acc, data) =>
                              acc +
                              (data.dolomiteEmission || 0) +
                              (data.limestoneEmission || 0),
                            0
                          )
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Liming Emissions not available because you have not entered
                  Liming data.
                </EmptyPlaceholder>
              )}
            </div>
          </div>
          <div className="col-span-full my-6 border-t border-t-gray-200" />

          <div className="mt-4 grid grid-cols-1 gap-x-6  items-center sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-base font-medium leading-6 text-apple-700">
                Synthetic Fertiliser Emissions
              </label>
            </div>

            <SynthFertiliserChart
              data={summaryEmissionData}
              baselineCalculatedEmissionData={baselineCalculatedEmissionData}
              emissionActivity={emissionActivity}
            />

            <div className="col-span-full sm:col-span-3">
              {summaryEmissionData.syntheticFertilizerEmission ? (
                <div className="mt-2 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                            >
                              Period
                            </th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                            >
                              Synthetic Fertiliser Emissions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {summaryEmissionData.syntheticFertilizerEmission
                            .sort((a, b) => b.year - a.year)
                            .map((data, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center px-8 ">
                                  {period}
                                </td>

                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0 text-center">
                                  {roundToThree(data.emission || 0)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        {RenderAverageEmission(
                          summaryEmissionData.syntheticFertilizerEmission.reduce(
                            (acc, data) => acc + (data.emission || 0),

                            0
                          )
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Synthetic Fertiliser Emissions not available because you have
                  not entered Synthetic Fertiliser data.
                </EmptyPlaceholder>
              )}
            </div>
          </div>

          <div className="col-span-full my-6 border-t border-t-gray-200" />

          <div className="mt-4 grid grid-cols-1 gap-x-6 items-center sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-base font-medium leading-6 text-apple-700">
                Residue, Tillage, and Soil Landscape Modification Emissions
              </label>
            </div>

            <ResidueChart
              data={summaryEmissionData}
              baselineCalculatedEmissionData={baselineCalculatedEmissionData}
              emissionActivity={emissionActivity}
            />
            <div className="col-span-full sm:col-span-3">
              {summaryEmissionData.residueTillageAndLandscapeModificationEmission ? (
                <div className="mt-2 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                            >
                              Period
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-3.5 text-sm font-semibold text-gray-900 text-center"
                            >
                              Tillage and Soil landscape modification Emmissions
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-3.5 text-sm font-semibold text-gray-900 text-center"
                            >
                              Crop Residue
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-3.5 text-sm font-semibold text-gray-900 text-center"
                            >
                              Pasture
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                            >
                              Cover Crop Residue
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {summaryEmissionData.residueTillageAndLandscapeModificationEmission.map(
                            (data, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center px-8 ">
                                  {period}
                                </td>

                                <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-center">
                                  {roundToThree(
                                    data.tillageAndSoilLandscapingEmission || 0
                                  )}
                                </td>
                                <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-center">
                                  {roundToThree(data.cropResidueEmission || 0)}
                                </td>
                                <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-center">
                                  {roundToThree(
                                    data.pastureTillageEmission || 0
                                  )}
                                </td>

                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0 text-center">
                                  {roundToThree(data.coverCropEmission || 0)}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                        {RenderAverageEmission(
                          summaryEmissionData.residueTillageAndLandscapeModificationEmission.reduce(
                            (acc, data) =>
                              acc +
                              (data.tillageAndSoilLandscapingEmission || 0) +
                              (data.cropResidueEmission || 0) +
                              (data.pastureTillageEmission || 0) +
                              (data.coverCropEmission || 0),
                            0
                          )
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Residue, Tillage, and Soil Landscape Modification Emissions
                  not available because you have not entered Tillage, Soil
                  Landscape Modification, or Residue data.
                </EmptyPlaceholder>
              )}
            </div>
          </div>

          <div className="col-span-full my-6 border-t border-t-gray-200" />

          <div className="mt-4 grid grid-cols-1 gap-x-6 items-center sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-base font-medium leading-6 text-apple-700">
                Livestock Emissions
              </label>
            </div>
            <div className="col-span-full sm:col-span-3">
              <LivestockChart
                data={summaryEmissionData}
                baselineCalculatedEmissionData={baselineCalculatedEmissionData}
                emissionActivity={emissionActivity}
              />
            </div>
            <div className="col-span-full">
              {summaryEmissionData.livestockEmission &&
              summaryEmissionData.livestockEmission.length ? (
                <div className="mt-2 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr className="divide-x  divide-gray-200">
                            <th
                              scope="col"
                              rowSpan={2}
                              className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                            >
                              Period
                            </th>

                            <th
                              colSpan={
                                summaryEmissionData.livestockEmission[0]
                                  .animalEmisions?.length
                              }
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                            >
                              Livestock Emissions
                            </th>
                          </tr>
                          <tr className="divide-x divide-gray-200 bg-neutral-50">
                            {summaryEmissionData.livestockEmission[0].animalEmisions?.map(
                              (data, i) => (
                                <th
                                  key={i}
                                  scope="col"
                                  className={
                                    i <
                                    (summaryEmissionData.livestockEmission?.[0]
                                      ?.animalEmisions?.length ?? 0) -
                                      2
                                      ? `px-4 py-3.5  text-sm font-semibold text-gray-900 text-center`
                                      : `py-3.5 pl-6 pr-8  text-sm font-semibold text-gray-900  text-center`
                                  }
                                >
                                  {GetObjectValue(
                                    AnimalType,
                                    data.animal as AnimalType
                                  )}
                                </th>
                              )
                            )}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {summaryEmissionData.livestockEmission
                            .sort((a, b) => b.year - a.year)
                            .map((data, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center px-8 ">
                                  {period}
                                </td>

                                {data.animalEmisions?.map((data, i) => (
                                  <td
                                    key={i}
                                    className="whitespace-nowrap py-4 px-4 text-sm text-gray-500 text-center"
                                  >
                                    {roundToThree(data.emission || 0)}
                                  </td>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                        {RenderAverageEmission(
                          summaryEmissionData.livestockEmission.reduce(
                            (acc, data) =>
                              acc +
                              (data.animalEmisions?.reduce(
                                (acc, data) => acc + (data.emission ?? 0),
                                0
                              ) || 0),
                            0
                          )
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Livestock Emissions not available because you have not entered
                  Livestock data.
                </EmptyPlaceholder>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
