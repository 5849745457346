import { useEffect, useState } from "react";
import { Project } from "../types";
import { api } from "../api";
import { FetchError } from "../lib/fetcher";

export default function useAllProjects(userId?: number) {
  const [allProjects, setAllProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getGrowers() {
      if (!userId) return;
      const response = await api.getAllProjects(userId);
      if (response instanceof FetchError) {
        setLoading(false);
        alert(response.message);
        return;
      }
      setAllProjects(response.projects);
      setLoading(false);
    }

    getGrowers(); // remove the return statement
  }, [userId]);

  return { allProjects, loading };
}
