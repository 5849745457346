import { Link } from "react-router-dom";
import Loading from "../components/loading";
import useAllProjects from "../hooks/useAllProjects";
import { Month } from "../constants";
import { BriefcaseIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import useMe from "../hooks/useMe";
import EmptyPage from "../components/empty-page";

export default function Projects() {
  const me = useMe();
  const { allProjects, loading } = useAllProjects(me?.id);

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="flex flex-col flex-1 ">
      <div className="border-b border-shark-400">
        <h1 className="text-xl font-semibold leading-6 text-shark-200 m-6">
          All Projects
        </h1>
      </div>
      <div className="mx-auto max-w-7xl p-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          {loading ? (
            <Loading />
          ) : (
            <div className="flex flex-col">
              {allProjects.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                  {allProjects
                    .filter(
                      (project) =>
                        project.projectInfo.carbonRegMonth !== null &&
                        project.projectInfo.carbonRegYear !== null
                    )
                    .map((project, i) => (
                      <Link
                        to={`/projects/${project?.id}`}
                        key={i}
                        className="border overflow-hidden  border-gray-300 rounded-xl relative isolate hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-11"
                      >
                        <div
                          className="absolute inset-0 -z-10 overflow-hidden items-center justify-center"
                          aria-hidden="true"
                        >
                          <div className="absolute left-16 -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
                            <div
                              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-apple-300 to-apple-50"
                              style={{
                                clipPath:
                                  "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
                              }}
                            />
                          </div>

                          <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                        </div>
                        <div className="flex items-center gap-x-4 h-40 border-b border-gray-900/5 p-6">
                          <span className="inline-flex flex-shrink-0 h-12 w-12 items-center justify-center rounded-full bg-apple-500/10">
                            <BriefcaseIcon className="h-8 w-8 text-apple-700" />
                          </span>

                          <div className="text-3xl font-medium leading-6 text-gray-900 break-words text-ellipsis">
                            {project.projectInfo.name}
                          </div>
                        </div>
                        <dl className=" divide-y divide-gray-200 px-6 py-2 text-xs leading-6 bg-white/80">
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">
                              Project Registration Date
                            </dt>
                            <dd className="text-gray-900">
                              {
                                Month[
                                  (project.projectInfo.carbonRegMonth ?? 0) - 1
                                ]
                              }{" "}
                              {project.projectInfo.carbonRegYear}
                            </dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">Project Location</dt>
                            <dd className="text-gray-900">
                              {project.projectInfo.address.stateProvince},{" "}
                              {project.projectInfo.address.country}
                            </dd>
                          </div>
                        </dl>
                      </Link>
                    ))}
                </div>
              ) : (
                <EmptyPage
                  text={
                    <>
                      <p className="text-sm sm:text-lg font-medium text-gray-900 text-center">
                        No projects at the moment.
                      </p>
                      <p className="text-xs sm:text-sm text-gray-500 text-center">
                        Please check back later.
                      </p>
                    </>
                  }
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
