import { useEffect, useState } from "react";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";
import { SummaryEmissionData } from "../../types";

export default function useProjectCalculatedEmissionData(
  projectId?: string,
  emissionActivityId?: string
) {
  const [projectCalculatedEmissionData, setProjectCalculatedEmissionData] =
    useState<SummaryEmissionData>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    async function getProjectCalculatedEmissionData() {
      if (!projectId || !emissionActivityId) {
        setLoading(false);
        return;
      }
      const response = await api.getSummaryData(
        +projectId,
        +emissionActivityId
      );
      if (response instanceof FetchError) {
        setLoading(false);
        setError(response.message);
        return;
      }
      setProjectCalculatedEmissionData(
        response.summaryEmissionData.calculatedEmissionData
      );
      setLoading(false);
    }

    getProjectCalculatedEmissionData();
  }, [emissionActivityId, projectId]);

  return { projectCalculatedEmissionData, loading, error };
}
