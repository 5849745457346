interface BadgeProps {
  children: React.ReactNode | string;
  variant?: "success" | "warning" | "danger" | "pending" | "disabled";
}

export default function Badge({ children, variant }: BadgeProps) {
  return variant === "success" ? (
    <p className="text-green-700 bg-green-50 ring-green-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-[11px] leading-4 font-semibold ring-1 ring-inset">
      {children}
    </p>
  ) : variant === "danger" ? (
    <p className="text-red-700 bg-red-50 ring-red-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-[11px] leading-4 font-semibold ring-1 ring-inset">
      {children}
    </p>
  ) : variant === "pending" ? (
    <p className="text-blue-700 bg-blue-50 ring-blue-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-[11px] leading-4 font-semibold ring-1 ring-inset">
      {children}
    </p>
  ) : variant === "disabled" ? (
    <p className="text-gray-400 bg-gray-50 ring-gray-400/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-[11px] leading-4 font-semibold ring-1 ring-inset">
      {children}
    </p>
  ) : (
    <p className="text-yellow-700 bg-yellow-50 ring-yellow-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-[11px] leading-4 font-semibold ring-1 ring-inset">
      {children}
    </p>
  );
}
