import { Link } from "react-router-dom";
import Background from "../assets/background2.jpg";
import Button from "../components/button";

export default function Home() {
  return (
    <div className="h-full relative isolate overflow-hidden">
      <img
        loading="lazy"
        src={Background}
        // src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2830&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover"
      />
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4f9a3d] to-[#e6f6e2] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl px-4 py-40  lg:py-60">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-apple-100 sm:text-6xl">
            A better way to manage your Carbon Project
          </h1>
          <p className="mt-6 text-base leading-8 text-apple-50">
            Welcome to your SecondCrop Grower Portal.
          </p>
        </div>
        <div className="flex flex-col items-center mt-8">
          <Link to="/kyb">
            <Button variant="outline">Go to KYB (Know Your Business)</Button>
          </Link>
        </div>
      </div>
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <p className="absolute inset-x-0 bottom-0 text-center text-[0.65rem] text-apple-50 pb-4 px-4">
        Loam Bio will handle any information you provide according to Loam Bio’s{" "}
        <a
          className="underline text-apple-200 "
          href="https://www.loambio.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          <strong>privacy policy</strong>
        </a>{" "}
        and applicable law. Loam Bio commits to protecting the privacy and
        secure storage of your personal information in line with applicable
        privacy laws, including the Australian Privacy Principles (APPs) and the
        Privacy Act 1988 (Cth) (Privacy Act), and relevant State and Territory
        privacy legislation.
      </p>
    </div>
  );
}
