import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

interface EmptyPlaceholderProps {
  children: React.ReactNode | string;
}

export default function EmptyPlaceholder({ children }: EmptyPlaceholderProps) {
  return (
    <div className="mt-4 flex flex-row w-full justify-center items-center bg-red-50 ring-red-600/20 rounded-md whitespace-wrap text-center p-2 ring-1 ring-inset  lg:p-4 ">
      <div className=" flex  items-center justify-center rounded-xl  sm:mx-0 sm:h-10 sm:w-10">
        <ExclamationTriangleIcon
          className="h-5 w-5 text-red-600"
          aria-hidden="true"
        />
      </div>
      <p className=" text-red-700 text-xs lg:text-sm font-medium pl-2">
        {children}
      </p>
    </div>
  );
}
