// import Button from "../../components/button";
import SelectInput from "../../components/select-input";
import TextArea from "../../components/text-area";
import TextInput from "../../components/text-input";
import { Country, DataTypes } from "../../constants";
import { Address, BusinessInfo, ToBeSavedData } from "../../types";
import { useContext, useEffect, useMemo, useState } from "react";
import { ComponentContext } from "../kyb-kyc";
import Loading from "../../components/loading";
import AddressComponent from "../../components/address";
import Checkbox from "../../components/checkbox-input";
import capitalizeWords from "../../helpers/capitalize-words";
import determineDisabled from "../../helpers/kyb-kyc/determine-disabled";

export default function BusinessInformation() {
  const { nodeData, kybStatus } = useContext(ComponentContext);

  const businessInfoData = useMemo(
    () =>
      nodeData
        ? nodeData.businessInfo ?? ({} as BusinessInfo)
        : ({} as BusinessInfo),
    [nodeData]
  );

  const [businessInfo, setBusinessInfo] = useState<Partial<BusinessInfo>>();
  const [registeredSameAsPrincipal, setRegisteredSameAsPrincipal] = useState(
    !businessInfoData || !businessInfoData.registeredOfficeAddress
      ? false
      : JSON.stringify(businessInfoData.registeredOfficeAddress) ===
          JSON.stringify(businessInfoData.principalOfficeAddress)
  );

  useEffect(() => {
    if (!businessInfoData) return;
    setBusinessInfo({
      ...businessInfoData,
      legalName:
        businessInfoData.legalName ?? nodeData?.entityPerson?.legalName ?? "",
    });
  }, [businessInfoData, nodeData?.entityPerson?.legalName]);

  useEffect(() => {
    if (!businessInfo) return;
    function handleChildSave() {
      const toBeSaveData: ToBeSavedData = {
        dataType: DataTypes.MidBusinessInfoData,
        data: businessInfo as BusinessInfo,
      };
      localStorage.setItem("toBeSaved", JSON.stringify(toBeSaveData));
    }
    handleChildSave();
  }, [businessInfo]);

  return (
    <div className="flex flex-col w-full flex-1 py-4 lg:py-8">
      {!businessInfo || !kybStatus ? (
        <Loading />
      ) : (
        <div className="w-full p-1 lg:px-8">
          <>
            <p className="block text-md font-medium leading-6 text-apple-600 ">
              Business Information
            </p>
            <div className="mt-1 grid  gap-x-6 gap-y-0 grid-cols-12 px-2 sm:px-4">
              <div className="col-span-full sm:col-span-6">
                <TextInput
                  disabled={determineDisabled(kybStatus)}
                  label="Full Legal Name"
                  type="text"
                  value={
                    businessInfo.legalName ??
                    nodeData?.entityPerson?.legalName ??
                    ""
                  }
                  onChange={(e) => {
                    setBusinessInfo({
                      ...businessInfo,
                      legalName: e.target.value,
                    });
                  }}
                  error={
                    !businessInfo.legalName || businessInfo.legalName === ""
                      ? 1
                      : undefined
                  }
                />
              </div>
              <div className="col-span-full sm:col-span-6">
                <TextInput
                  disabled={determineDisabled(kybStatus)}
                  label="Other name/s (if any)"
                  type="text"
                  value={businessInfo.otherName ?? ""}
                  onChange={(e) => {
                    setBusinessInfo({
                      ...businessInfo,
                      otherName: capitalizeWords(e.target.value),
                    });
                  }}
                />
              </div>
              <div className="col-span-4 sm:col-span-2">
                <SelectInput
                  disabled={determineDisabled(kybStatus)}
                  label="ABN/ACN Type"
                  value={businessInfo.abnAcnType ?? ""}
                  onChange={(e) => {
                    setBusinessInfo({
                      ...businessInfo,
                      abnAcnType: e.target.value,
                    });
                  }}
                  error={
                    !businessInfo.abnAcnType || businessInfo.abnAcnType === ""
                      ? 1
                      : undefined
                  }
                >
                  <option></option>
                  <option value="ABN">ABN</option>
                  <option value="ACN">ACN</option>
                </SelectInput>
              </div>
              <div className="col-span-8 sm:col-span-3">
                <TextInput
                  label="ABN/ACN"
                  type="number"
                  disabled={
                    businessInfo.abnAcnType && !determineDisabled(kybStatus)
                      ? false
                      : true
                  }
                  value={businessInfo.abnAcnNumber ?? ""}
                  onChange={(e) => {
                    const value =
                      businessInfo.abnAcnType === "ABN"
                        ? e.target.value.slice(0, 11)
                        : e.target.value.slice(0, 9);
                    setBusinessInfo({
                      ...businessInfo,
                      abnAcnNumber: value,
                    });
                  }}
                  error={
                    !businessInfo.abnAcnNumber ||
                    (businessInfo.abnAcnType === "ABN" &&
                      businessInfo.abnAcnNumber.length !== 11) ||
                    (businessInfo.abnAcnType === "ACN" &&
                      businessInfo.abnAcnNumber.length !== 9) ||
                    businessInfo.abnAcnNumber === ""
                      ? 1
                      : undefined
                  }
                  errormesage={
                    !businessInfo.abnAcnNumber
                      ? "This field is required!"
                      : businessInfo.abnAcnType === "ABN" &&
                        businessInfo.abnAcnNumber.length !== 11
                      ? "ABN must be 11 digits"
                      : businessInfo.abnAcnType === "ACN" &&
                        businessInfo.abnAcnNumber.length !== 9
                      ? "ACN must be 9 digits"
                      : "Please enter a valid ABN/ACN!"
                  }
                />
              </div>

              <div className="col-span-full sm:col-span-4">
                <SelectInput
                  disabled={determineDisabled(kybStatus)}
                  label="Place of Incorporation/Establishment"
                  value={businessInfo.placeOfIncorporation ?? ""}
                  onChange={(e) => {
                    setBusinessInfo({
                      ...businessInfo,
                      placeOfIncorporation: e.target.value as Country,
                    });
                  }}
                  error={
                    !businessInfo.placeOfIncorporation ||
                    businessInfo.placeOfIncorporation === ""
                      ? 1
                      : undefined
                  }
                >
                  <option></option>
                  {Object.entries(Country).map(([key, value]) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
                </SelectInput>
              </div>

              {businessInfo.placeOfIncorporation === Country.Other && (
                <div className="col-span-full sm:col-span-3">
                  <TextInput
                    disabled={determineDisabled(kybStatus)}
                    label="Other Place of Incorporation/Establishment"
                    type="text"
                    value={businessInfo.placeOfIncorporationOther ?? ""}
                    onChange={(e) => {
                      setBusinessInfo({
                        ...businessInfo,
                        placeOfIncorporationOther: e.target.value,
                      });
                    }}
                    error={
                      (businessInfo.placeOfIncorporation === Country.Other &&
                        !businessInfo.placeOfIncorporationOther) ||
                      businessInfo.placeOfIncorporationOther === ""
                        ? 1
                        : undefined
                    }
                  />
                </div>
              )}

              <div className="col-span-full sm:col-span-3">
                <TextInput
                  disabled={determineDisabled(kybStatus)}
                  label="Date of Incorporation/Establishment"
                  type="date"
                  value={businessInfo.dateOfIncorporation ?? ""}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    if (new Date(e.target.value) > new Date()) return;
                    setBusinessInfo({
                      ...businessInfo,
                      dateOfIncorporation: e.target.value,
                    });
                  }}
                  // error={
                  //   !businessInfo.dateOfIncorporation ||
                  //   businessInfo.dateOfIncorporation === ""
                  //     ? 1
                  //     : undefined
                  // }
                />
              </div>
              <div className="col-span-full">
                <TextArea
                  disabled={determineDisabled(kybStatus)}
                  label={
                    <>
                      <p>
                        Nature of Business{" "}
                        <span className="text-xs text-gray-500">
                          (Type of business, service, and/or products you offer,
                          your customer sector, and countries where you
                          operate.)
                        </span>
                      </p>
                    </>
                  }
                  value={businessInfo.natureOfBusiness ?? ""}
                  onChange={(e) => {
                    setBusinessInfo({
                      ...businessInfo,
                      natureOfBusiness: e.target.value,
                    });
                  }}
                  // error={
                  //   !businessInfo.natureOfBusiness ||
                  //   businessInfo.natureOfBusiness === ""
                  //     ? 1
                  //     : undefined
                  // }
                />
              </div>
            </div>
            <div className="col-span-full border-b border-gray-200 py-4 px-6" />
            <p className="block text-md font-medium leading-6 text-apple-600 pt-4">
              Principal Office Address
            </p>
            <div className="px-2 sm:px-4">
              <AddressComponent
                // required
                disabled={determineDisabled(kybStatus)}
                address={
                  businessInfo?.principalOfficeAddress ?? ({} as Address)
                }
                setAddress={(address) =>
                  setBusinessInfo({
                    ...businessInfo,
                    principalOfficeAddress: address,
                    registeredOfficeAddress: registeredSameAsPrincipal
                      ? address
                      : businessInfo?.registeredOfficeAddress,
                  })
                }
              />
            </div>
            <div className="col-span-full border-b border-gray-200 py-4 px-6" />
            <div className="flex flex-row justify-between items-center pt-4">
              <p className="block text-md font-medium leading-6 text-apple-600 ">
                Registered Office Address
              </p>
              <Checkbox
                label="Same as Principal Office Address"
                checked={registeredSameAsPrincipal}
                disabled={determineDisabled(kybStatus)}
                // value={registeredSameAsPrincipal ? "true" : "false"}
                onChange={(e) => {
                  setRegisteredSameAsPrincipal(e.target.checked ? true : false);
                  setBusinessInfo({
                    ...businessInfo,
                    registeredOfficeAddress: e.target.checked
                      ? businessInfo.principalOfficeAddress
                      : undefined,
                  });
                }}
              />
            </div>
            <div className="px-2 sm:px-4">
              <AddressComponent
                // required
                address={
                  (registeredSameAsPrincipal
                    ? businessInfo?.principalOfficeAddress ?? {}
                    : businessInfo?.registeredOfficeAddress ?? {}) as Address
                }
                setAddress={(address) =>
                  setBusinessInfo({
                    ...businessInfo,
                    registeredOfficeAddress: address,
                  })
                }
                disabled={
                  registeredSameAsPrincipal || determineDisabled(kybStatus)
                }
              />
            </div>
            <div className="col-span-full border-b border-gray-200 py-4 px-6" />
            <div className="flex flex-col">
              <p className="block text-md font-medium leading-6 text-apple-600 pt-4">
                Other Address
              </p>
              <p className="mt-1 text-xs text-gray-500">
                Additional addresses where business is conducted for this
                entity.
              </p>
            </div>
            <div className="px-2 sm:px-4">
              <AddressComponent
                disabled={determineDisabled(kybStatus)}
                address={businessInfo?.otherAddresses ?? ({} as Address)}
                setAddress={(address) =>
                  setBusinessInfo({
                    ...businessInfo,
                    otherAddresses: address,
                  })
                }
              />
            </div>
            <div className="col-span-full border-b border-gray-200 py-4 px-6" />
          </>
        </div>
      )}
    </div>
  );
}
