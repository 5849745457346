import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import Button from "../components/button";
import Illustration from "../assets/image4.jpg";
import { useEffect, useState } from "react";

interface FirstTimeDataProps {
  user: string;
  firstTime: boolean;
}

export default function Welcome() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 pt-24 pb-8 sm:pt-32 lg:overflow-visible lg:px-0 w-full">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-apple-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
        </svg>
      </div>
      <div className="w-full flex flex-col lg:flex-row lg:justify-center items-center">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <div className="flex h-40 shrink-0 items-center">
                  <img loading="lazy" src={logo} alt="Logo" className="h-24" />
                </div>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Welcome to the SecondCrop Grower Portal
                </h1>
                <p className="mt-6 text-xl leading-8 text-gray-700">
                  At SecondCrop we are committed to making the compliance
                  requirements of a carbon project easier to manage. As you
                  progress through the project stages and need more information
                  and tools, the options available to you on your portal will
                  grow.
                </p>
                <div className="mt-6 flex">
                  <Link to={"/login"}>
                    <Button>Get Started</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 lg:-ml-12 lg:-mt-12 p-0 lg:p-12 lg:pl-40 place-items-center lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            loading="lazy"
            className="w-full xl:w-[400px] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10"
            src={Illustration}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
