import { EntityTypes } from "../../constants";
import OtherEntity from "../../views/kyb-kyc-components/other-entity";
import Partnership from "../../views/kyb-kyc-components/partnership";
import SoleTrader from "../../views/kyb-kyc-components/sole-trader";
import Trust from "../../views/kyb-kyc-components/trust";
import UnlistedCompany from "../../views/kyb-kyc-components/unlisted-company";

export default function determineInitialStep(entity: EntityTypes) {
  if (!entity) return;
  switch (entity) {
    case EntityTypes.Other:
      return {
        name: "Other Entity",
        component: <OtherEntity />,
      };
    case EntityTypes.UnlistedCompany:
      return {
        name: "Unlisted Company",
        component: <UnlistedCompany />,
      };
    case EntityTypes.SoleTraderIndividual:
      return {
        name: "Sole Trader/Individual",
        component: <SoleTrader />,
      };
    case EntityTypes.Partnership:
      return {
        name: "Partnership",
        component: <Partnership />,
      };
    case EntityTypes.Trust:
      return {
        name: "Trust",
        component: <Trust />,
      };
    default:
      return null;
  }
}
