import calculateAverageAnnualBaselineEmission from "../../../../helpers/calculateAverageAnnualBaselineEmission";
import calculateExpectedEmissionUsingDateProportion from "../../../../helpers/calculateExpectedEmission";
import formatDate from "../../../../helpers/format-date";
import { EmissionActivityInfo, SummaryEmissionData } from "../../../../types";
import { Chart } from "react-chartjs-2";

interface LivestockChartProps {
  data: SummaryEmissionData;
  baselineCalculatedEmissionData?: SummaryEmissionData;
  emissionActivity?: EmissionActivityInfo;
}

export default function LivestockChart({
  data: summaryEmissionData,
  baselineCalculatedEmissionData,
  emissionActivity,
}: LivestockChartProps) {
  return (
    <>
      {summaryEmissionData.livestockEmission && (
        <div className="h-[250px]">
          <Chart
            type="bar"
            options={{
              plugins: {
                legend: {
                  display: true,
                },
                datalabels: {
                  display: false,
                },
                annotation: {
                  annotations: {
                    line1: {
                      yMin: calculateExpectedEmissionUsingDateProportion(
                        calculateAverageAnnualBaselineEmission(
                          baselineCalculatedEmissionData
                        ).livestockEmission,
                        emissionActivity?.activityStartDate,
                        emissionActivity?.activityEndDate
                      ),
                      yMax: calculateExpectedEmissionUsingDateProportion(
                        calculateAverageAnnualBaselineEmission(
                          baselineCalculatedEmissionData
                        ).livestockEmission,
                        emissionActivity?.activityStartDate,
                        emissionActivity?.activityEndDate
                      ),
                      borderColor: "#376b2a",
                      borderWidth: 2,
                      borderDash: [8, 8],
                      label: {
                        backgroundColor: "#4f9a3d",
                        content: `Expected Emission = ${calculateExpectedEmissionUsingDateProportion(
                          calculateAverageAnnualBaselineEmission(
                            baselineCalculatedEmissionData
                          ).livestockEmission,
                          emissionActivity?.activityStartDate,
                          emissionActivity?.activityEndDate
                        )} tonnes of CO2-e.`,
                        position: "end",
                        display: true,
                      },
                    },
                  },
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  title: {
                    display: true,
                    text: "tonnes of CO2-e",
                  },
                },
              },
            }}
            data={{
              labels: ["Livestock Emission"],
              datasets: [
                {
                  label: `${
                    formatDate(emissionActivity?.activityStartDate) +
                    " - " +
                    formatDate(emissionActivity?.activityEndDate)
                  } Emission (tonnes of CO2-e)`,
                  data: [
                    summaryEmissionData.livestockEmission?.reduce(
                      (acc, data) =>
                        acc +
                        (data.animalEmisions?.reduce(
                          (acc, data) => acc + (data.emission ?? 0),
                          0
                        ) || 0),
                      0
                    ),
                  ],
                  backgroundColor: ["#cdb5fd"],
                },
                {
                  label: "Average Annual Baseline Emission (tonnes of CO2-e)",
                  data: [
                    calculateAverageAnnualBaselineEmission(
                      baselineCalculatedEmissionData
                    ).livestockEmission,
                  ],
                  backgroundColor: ["#391065"],
                },
              ],
            }}
          />
        </div>
      )}
    </>
  );
}
