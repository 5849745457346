import { useEffect, useState } from "react";
import { FarmInfo } from "../../types";

export default function useFarmInfo() {
  const [newData, setNewData] = useState<FarmInfo>({
    state: undefined,
    region: undefined,
    carbonRegYear: undefined,
  });

  useEffect(() => {
    const data = localStorage.getItem("FarmInfo");

    if (data) {
      const parsedData = JSON.parse(data);
      const farmInfo = parsedData as FarmInfo;
      setNewData(farmInfo);
    }
  }, []);

  return newData;
}
