import { useEffect, useMemo, useState } from "react";
import TextInput from "../../../components/text-input";
import {
  AnimalType,
  EmissionAttachmentType,
  BeefCattleType,
  DairyCattleType,
  EmissionVariant,
  MINIMUM_INPUT_WARNING,
  REGULAR_INPUT_WARNING,
  REGULAR_MAXIMUM_NUMBER_OF_DAYS,
  SEASONAL_MAXIMUM_NUMBER_OF_DAYS,
  SEASON_INPUT_WARNING,
  Seasons,
  SheepType,
} from "../../../constants";
import useEmissionData from "../../../hooks/useEmissionData";
import {
  BeefCattleData,
  DairyCattleData,
  LivestockData,
  LivestockTypeData,
  SheepData,
  Livestock as iLivestock,
} from "../../../types";
import { api } from "../../../api";
import { formatMonthYear } from "../../../helpers/format-month-year";
import useFarmInfo from "../../../hooks/emission/useFarmInfo";
import { useParams } from "react-router-dom";
import useActivityInfo from "../../../hooks/emission/useActivityInfo";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";
import EmissionAttachments from "../emission-attachments";
import { GetObjectValue } from "../../../helpers/getObjectValue";
import useEmissionActivity from "../../../hooks/emission/useEmissionActivity";
import formatDate from "../../../helpers/format-date";
import { hasSeasonInDateRange } from "../../../helpers/has-season-in-date-range";

interface Props {
  year: number;
  variant?: EmissionVariant;
}

export default function Livestock({ year, variant }: Props) {
  const emissionData = useEmissionData(year);
  const farmInfo = useFarmInfo();
  const activityInfo = useActivityInfo();
  const { projectId, emissionActivityId } = useParams();
  const emissionActivity = useEmissionActivity();
  const [allLivestockData, setAllLivestockData] = useState<iLivestock[]>([]);
  const [yearLivestockData, setYearLivestockData] = useState<
    iLivestock | undefined
  >(undefined);
  const { projectAttachment, mutate } = useProjectAttachment(
    projectId,
    emissionActivityId,
    EmissionAttachmentType.Livestock
  );

  useEffect(() => {
    if (!emissionData || !emissionData.livestock) {
      setAllLivestockData([]);
      return;
    }
    setAllLivestockData(emissionData.livestock);
  }, [emissionData]);

  useEffect(() => {
    if (!projectId || !emissionActivityId) return;
    if (
      !allLivestockData.length ||
      !allLivestockData.find((livestock) => livestock.activityYear === year)
    ) {
      setYearLivestockData({
        projectId: +projectId,
        emissionActivityId: +emissionActivityId,
        activityYear: year,
        livestockData: activityInfo.animalType.map((animal) => {
          return {
            animalType: animal,
            animalData: [],
          };
        }) as LivestockData[],
      });
      return;
    }
    setYearLivestockData(
      allLivestockData.find((livestock) => livestock.activityYear === year)
    );
  }, [
    allLivestockData,
    year,
    emissionData,
    projectId,
    activityInfo,
    emissionActivityId,
  ]);

  useEffect(() => {
    function saveYearLivestockData() {
      if (
        !yearLivestockData ||
        !yearLivestockData.livestockData ||
        !yearLivestockData.livestockData.length
      )
        return;
      return api.saveLivestock(yearLivestockData);
    }
    saveYearLivestockData();
  }, [yearLivestockData]);

  const selectedGenericLivesStock = useMemo(() => {
    if (
      !activityInfo ||
      !activityInfo.animalType ||
      !activityInfo.animalType.length
    ) {
      return [];
    }
    return activityInfo.animalType.filter(
      (animal) =>
        animal !== ("BeefCattle" as AnimalType) &&
        animal !== ("Sheep" as AnimalType) &&
        animal !== ("DairyCattle" as AnimalType)
    );
  }, [activityInfo]);

  function ViewCreator(animal: string, key: number) {
    if (
      !yearLivestockData ||
      !yearLivestockData.livestockData ||
      !yearLivestockData.livestockData.length ||
      !emissionActivity
    )
      return;
    switch (animal) {
      case "BeefCattle": {
        const beefCattleDataIndex = yearLivestockData.livestockData.findIndex(
          (data) => data.animalType === AnimalType.BeefCattle
        );
        const beefCattleData = ((
          (yearLivestockData.livestockData.find(
            (data) => data.animalType === (animal as AnimalType)
          ) ?? {}) as LivestockData
        ).animalData ?? []) as BeefCattleData[];

        return (
          <div
            className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2"
            key={key}
          >
            <div className="col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Please tell us about the number of{" "}
                <span className="font-semibold text-apple-700">
                  {AnimalType.BeefCattle}
                </span>{" "}
                stocked and the length of time they were stocked (in days) in
                the project area for each{" "}
                <span className="font-semibold text-apple-700">
                  {AnimalType.BeefCattle}
                </span>{" "}
                type in each season between{" "}
                <span className="font-semibold text-apple-700">
                  {" "}
                  {variant !== EmissionVariant.OnGoing ? (
                    formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
                  ) : (
                    <span>
                      {formatDate(emissionActivity.activityStartDate)} -{" "}
                      {formatDate(emissionActivity.activityEndDate)}
                    </span>
                  )}
                </span>
                .
              </label>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-4">
                    <table className=" min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr className="divide-x divide-gray-200">
                          <th
                            colSpan={2}
                            className="py-3.5 text-center pr-5 text-sm font-semibold text-gray-900"
                          ></th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 whitespace-nowrap text-sm font-semibold text-gray-900 text-center"
                          >
                            Bulls younger <br />
                            than 1 year
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 whitespace-nowrap text-sm font-semibold text-gray-900 text-center"
                          >
                            Bulls older <br />
                            than 1 year
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 whitespace-nowrap text-sm font-semibold text-gray-900 text-center"
                          >
                            Steers <br /> younger <br />
                            than 1 year
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 whitespace-nowrap text-sm font-semibold text-gray-900 text-center"
                          >
                            Steers older <br />
                            than 1 year
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 whitespace-nowrap text-sm font-semibold text-gray-900 text-center"
                          >
                            Cows younger <br />
                            than 1 year
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 whitespace-nowrap text-sm font-semibold text-gray-900 text-center"
                          >
                            Cows <br />1 to 2 year
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-4 whitespace-nowrap  text-sm font-semibold text-gray-900 text-center"
                          >
                            Cows older <br />
                            than 2 year
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {variant === EmissionVariant.Baseline ||
                        hasSeasonInDateRange(
                          Seasons.Spring,
                          emissionActivity.activityStartDate,
                          emissionActivity.activityEndDate
                        ) ? (
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap py-4  pr-4 text-xs font-medium text-gray-900 text-center  ">
                              Spring
                            </td>
                            <td className="whitespace-nowrap text-sm text-gray-500 bg-apple-50">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 text-xs py-2.5 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full text-center" />
                                <div className="py-2 px-2 w-full text-xs text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(BeefCattleType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap px-4 text-sm text-gray-500"
                              >
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    beefCattleData.find(
                                      (data) => data.beefType === key
                                    )?.seasonsData?.spring?.numOfLivestock ?? ""
                                  }
                                  onChange={(e) => {
                                    if (+e.target.value < 0) {
                                      alert(MINIMUM_INPUT_WARNING);
                                    } else {
                                      if (
                                        beefCattleData &&
                                        beefCattleData.length > 0
                                      ) {
                                        const typeIndex =
                                          beefCattleData?.findIndex(
                                            (data) => data.beefType === key
                                          );
                                        if (typeIndex === -1) {
                                          beefCattleData.push({
                                            beefType: key as BeefCattleType,
                                            seasonsData: {
                                              spring: {
                                                numOfLivestock: e.target.value
                                                  .length
                                                  ? parseInt(e.target.value)
                                                  : undefined,
                                              },
                                            },
                                          });
                                        } else {
                                          beefCattleData[typeIndex] = {
                                            ...beefCattleData[typeIndex],
                                            seasonsData: {
                                              ...beefCattleData[typeIndex]
                                                ?.seasonsData,
                                              spring: {
                                                ...beefCattleData[typeIndex]
                                                  ?.seasonsData?.spring,
                                                numOfLivestock: e.target.value
                                                  .length
                                                  ? parseInt(e.target.value)
                                                  : undefined,
                                              },
                                            },
                                          };
                                        }
                                        const newData = [
                                          ...(yearLivestockData.livestockData ??
                                            []),
                                        ];
                                        setYearLivestockData({
                                          ...yearLivestockData,
                                          livestockData: newData,
                                        });
                                      } else {
                                        beefCattleData.push({
                                          beefType: key as BeefCattleType,
                                          seasonsData: {
                                            spring: {
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                        const newData = [
                                          ...(yearLivestockData.livestockData ??
                                            []),
                                        ];
                                        newData[beefCattleDataIndex] = {
                                          ...newData[beefCattleDataIndex],
                                          animalData: beefCattleData,
                                        };
                                        setYearLivestockData({
                                          ...yearLivestockData,
                                          livestockData: newData,
                                        });
                                      }
                                    }
                                  }}
                                />
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    beefCattleData?.find(
                                      (data) => data.beefType === key
                                    )?.seasonsData?.spring?.numOfDays ?? ""
                                  }
                                  onChange={(e) => {
                                    if (
                                      +e.target.value < 0 ||
                                      +e.target.value >
                                        SEASONAL_MAXIMUM_NUMBER_OF_DAYS
                                    ) {
                                      alert(SEASON_INPUT_WARNING);
                                    } else {
                                      const typeIndex =
                                        beefCattleData?.findIndex(
                                          (data) => data.beefType === key
                                        );
                                      if (typeIndex === -1) {
                                        beefCattleData?.push({
                                          beefType: key as BeefCattleType,
                                          seasonsData: {
                                            spring: {
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        beefCattleData[typeIndex] = {
                                          ...beefCattleData[typeIndex],
                                          seasonsData: {
                                            ...beefCattleData[typeIndex]
                                              ?.seasonsData,
                                            spring: {
                                              ...beefCattleData[typeIndex]
                                                ?.seasonsData?.spring,
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                        ) : null}
                        {variant === EmissionVariant.Baseline ||
                        hasSeasonInDateRange(
                          Seasons.Summer,
                          emissionActivity.activityStartDate,
                          emissionActivity.activityEndDate
                        ) ? (
                          <tr className="divide-x divide-gray-200 bg-neutral-50">
                            <td className="whitespace-nowrap py-4  pr-4 text-xs font-medium text-gray-900 text-center  ">
                              Summer
                            </td>
                            <td className="whitespace-nowrap text-sm text-gray-500 bg-apple-50">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full text-xs px-2 py-2.5 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full text-center" />
                                <div className="py-2 px-2 text-xs w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(BeefCattleType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap px-4 text-sm text-gray-500"
                              >
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    beefCattleData?.find(
                                      (data) => data.beefType === key
                                    )?.seasonsData?.summer?.numOfLivestock ?? ""
                                  }
                                  onChange={(e) => {
                                    if (+e.target.value < 0) {
                                      alert(MINIMUM_INPUT_WARNING);
                                    } else {
                                      const typeIndex =
                                        beefCattleData?.findIndex(
                                          (data) => data.beefType === key
                                        );
                                      if (typeIndex === -1) {
                                        beefCattleData?.push({
                                          beefType: key as BeefCattleType,
                                          seasonsData: {
                                            summer: {
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        beefCattleData[typeIndex] = {
                                          ...beefCattleData[typeIndex],
                                          seasonsData: {
                                            ...beefCattleData[typeIndex]
                                              ?.seasonsData,
                                            summer: {
                                              ...beefCattleData[typeIndex]
                                                ?.seasonsData?.summer,
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    beefCattleData?.find(
                                      (data) => data.beefType === key
                                    )?.seasonsData?.summer?.numOfDays ?? ""
                                  }
                                  onChange={(e) => {
                                    if (
                                      +e.target.value < 0 ||
                                      +e.target.value >
                                        SEASONAL_MAXIMUM_NUMBER_OF_DAYS
                                    ) {
                                      alert(SEASON_INPUT_WARNING);
                                    } else {
                                      const typeIndex =
                                        beefCattleData?.findIndex(
                                          (data) => data.beefType === key
                                        );
                                      if (typeIndex === -1) {
                                        beefCattleData?.push({
                                          beefType: key as BeefCattleType,
                                          seasonsData: {
                                            summer: {
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        beefCattleData[typeIndex] = {
                                          ...beefCattleData[typeIndex],
                                          seasonsData: {
                                            ...beefCattleData[typeIndex]
                                              ?.seasonsData,
                                            summer: {
                                              ...beefCattleData[typeIndex]
                                                ?.seasonsData?.summer,
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                        ) : null}
                        {variant === EmissionVariant.Baseline ||
                        hasSeasonInDateRange(
                          Seasons.Autumn,
                          emissionActivity.activityStartDate,
                          emissionActivity.activityEndDate
                        ) ? (
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap py-4  pr-4 text-xs font-medium text-gray-900 text-center  ">
                              Autumn
                            </td>
                            <td className="whitespace-nowrap text-sm text-gray-500 bg-apple-50">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 text-xs py-2.5 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full text-center" />
                                <div className="py-2 px-2 text-xs w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(BeefCattleType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap px-4 text-sm text-gray-500"
                              >
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    beefCattleData?.find(
                                      (data) => data.beefType === key
                                    )?.seasonsData?.autumn?.numOfLivestock ?? ""
                                  }
                                  onChange={(e) => {
                                    if (+e.target.value < 0) {
                                      alert(MINIMUM_INPUT_WARNING);
                                    } else {
                                      const typeIndex =
                                        beefCattleData?.findIndex(
                                          (data) => data.beefType === key
                                        );
                                      if (typeIndex === -1) {
                                        beefCattleData?.push({
                                          beefType: key as BeefCattleType,
                                          seasonsData: {
                                            autumn: {
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        beefCattleData[typeIndex] = {
                                          ...beefCattleData[typeIndex],
                                          seasonsData: {
                                            ...beefCattleData[typeIndex]
                                              ?.seasonsData,
                                            autumn: {
                                              ...beefCattleData[typeIndex]
                                                ?.seasonsData?.autumn,
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    beefCattleData?.find(
                                      (data) => data.beefType === key
                                    )?.seasonsData?.autumn?.numOfDays ?? ""
                                  }
                                  onChange={(e) => {
                                    if (
                                      +e.target.value < 0 ||
                                      +e.target.value >
                                        SEASONAL_MAXIMUM_NUMBER_OF_DAYS
                                    ) {
                                      alert(SEASON_INPUT_WARNING);
                                    } else {
                                      const typeIndex =
                                        beefCattleData?.findIndex(
                                          (data) => data.beefType === key
                                        );
                                      if (typeIndex === -1) {
                                        beefCattleData?.push({
                                          beefType: key as BeefCattleType,
                                          seasonsData: {
                                            autumn: {
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        beefCattleData[typeIndex] = {
                                          ...beefCattleData[typeIndex],
                                          seasonsData: {
                                            ...beefCattleData[typeIndex]
                                              ?.seasonsData,
                                            autumn: {
                                              ...beefCattleData[typeIndex]
                                                ?.seasonsData?.autumn,
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                        ) : null}
                        {variant === EmissionVariant.Baseline ||
                        hasSeasonInDateRange(
                          Seasons.Winter,
                          emissionActivity.activityStartDate,
                          emissionActivity.activityEndDate
                        ) ? (
                          <tr className="divide-x divide-gray-200 bg-neutral-50">
                            <td className="whitespace-nowrap py-4  pr-4 text-xs font-medium text-gray-900 text-center  ">
                              Winter
                            </td>
                            <td className="whitespace-nowrap text-xs text-gray-500 bg-apple-50">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 py-2.5 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full text-center" />
                                <div className="py-2 px-2 w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(BeefCattleType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap px-4 text-sm text-gray-500"
                              >
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    beefCattleData?.find(
                                      (data) => data.beefType === key
                                    )?.seasonsData?.winter?.numOfLivestock ?? ""
                                  }
                                  onChange={(e) => {
                                    if (+e.target.value < 0) {
                                      alert(MINIMUM_INPUT_WARNING);
                                    } else {
                                      const typeIndex =
                                        beefCattleData?.findIndex(
                                          (data) => data.beefType === key
                                        );
                                      if (typeIndex === -1) {
                                        beefCattleData?.push({
                                          beefType: key as BeefCattleType,
                                          seasonsData: {
                                            winter: {
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        beefCattleData[typeIndex] = {
                                          ...beefCattleData[typeIndex],
                                          seasonsData: {
                                            ...beefCattleData[typeIndex]
                                              ?.seasonsData,
                                            winter: {
                                              ...beefCattleData[typeIndex]
                                                ?.seasonsData?.winter,
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    beefCattleData?.find(
                                      (data) => data.beefType === key
                                    )?.seasonsData?.winter?.numOfDays ?? ""
                                  }
                                  onChange={(e) => {
                                    if (
                                      +e.target.value < 0 ||
                                      +e.target.value >
                                        SEASONAL_MAXIMUM_NUMBER_OF_DAYS
                                    ) {
                                      alert(SEASON_INPUT_WARNING);
                                    } else {
                                      const typeIndex =
                                        beefCattleData?.findIndex(
                                          (data) => data.beefType === key
                                        );
                                      if (typeIndex === -1) {
                                        beefCattleData?.push({
                                          beefType: key as BeefCattleType,
                                          seasonsData: {
                                            winter: {
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        beefCattleData[typeIndex] = {
                                          ...beefCattleData[typeIndex],
                                          seasonsData: {
                                            ...beefCattleData[typeIndex]
                                              ?.seasonsData,
                                            winter: {
                                              ...beefCattleData[typeIndex]
                                                ?.seasonsData?.winter,
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      case AnimalType.Sheep: {
        if (
          !yearLivestockData ||
          !yearLivestockData.livestockData ||
          !yearLivestockData.livestockData.length
        )
          return;
        const sheepIndex = yearLivestockData.livestockData.findIndex(
          (data) => data.animalType === animal
        );
        const sheepData = yearLivestockData.livestockData[sheepIndex]
          ?.animalData as SheepData[];
        return (
          <div
            className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2"
            key={key}
          >
            <div className="col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Please tell us about the number of{" "}
                <span className="font-semibold text-apple-700">
                  {AnimalType.Sheep}
                </span>{" "}
                stocked and the length of time they were stocked (in days) in
                the project area for each{" "}
                <span className="font-semibold text-apple-700">
                  {AnimalType.Sheep}
                </span>{" "}
                type in each season between{" "}
                <span className="font-semibold text-apple-700">
                  {variant !== EmissionVariant.OnGoing ? (
                    formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
                  ) : (
                    <span>
                      {formatDate(emissionActivity.activityStartDate)} -{" "}
                      {formatDate(emissionActivity.activityEndDate)}
                    </span>
                  )}
                </span>
                .
              </label>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-3 lg:px-4">
                    <table className=" min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr className="divide-x divide-gray-200">
                          <th
                            colSpan={2}
                            className="py-3.5 text-center pr-5 text-sm font-semibold text-gray-900"
                          ></th>

                          <th
                            scope="col"
                            className="px-8 py-3.5  text-sm font-semibold text-gray-900 text-center"
                          >
                            Rams
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-semibold text-gray-900 text-center"
                          >
                            Wethers
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5  text-sm font-semibold text-gray-900 text-center"
                          >
                            Maiden Ewes
                            <br />
                            (for breeding)
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5  text-sm font-semibold text-gray-900 text-center"
                          >
                            Breeding Ewes
                          </th>
                          <th
                            scope="col"
                            className="px-8 py-3.5  text-sm font-semibold text-gray-900 text-center"
                          >
                            Other Ewes
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center"
                          >
                            Lambs & Hoggets
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {variant === EmissionVariant.Baseline ||
                        hasSeasonInDateRange(
                          Seasons.Spring,
                          emissionActivity.activityStartDate,
                          emissionActivity.activityEndDate
                        ) ? (
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap py-4  pr-4 text-xs font-medium text-gray-900 text-center  ">
                              Spring
                            </td>
                            <td className="whitespace-nowrap text-xs text-gray-500 bg-apple-50">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 py-2.5 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full text-center" />
                                <div className="py-2 px-2 w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(SheepType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap px-4 text-sm text-gray-500"
                              >
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    sheepData?.find(
                                      (data) => data.sheepType === key
                                    )?.seasonsData?.spring?.numOfLivestock ?? ""
                                  }
                                  onChange={(e) => {
                                    if (+e.target.value < 0) {
                                      alert(MINIMUM_INPUT_WARNING);
                                    } else {
                                      const typeIndex = sheepData?.findIndex(
                                        (data) => data.sheepType === key
                                      );
                                      if (typeIndex === -1) {
                                        sheepData?.push({
                                          sheepType: key as SheepType,
                                          seasonsData: {
                                            spring: {
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        sheepData[typeIndex] = {
                                          ...sheepData[typeIndex],
                                          seasonsData: {
                                            ...sheepData[typeIndex]
                                              ?.seasonsData,
                                            spring: {
                                              ...sheepData[typeIndex]
                                                ?.seasonsData?.spring,
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    sheepData?.find(
                                      (data) => data.sheepType === key
                                    )?.seasonsData?.spring?.numOfDays ?? ""
                                  }
                                  onChange={(e) => {
                                    if (
                                      +e.target.value < 0 ||
                                      +e.target.value >
                                        SEASONAL_MAXIMUM_NUMBER_OF_DAYS
                                    ) {
                                      alert(SEASON_INPUT_WARNING);
                                    } else {
                                      const typeIndex = sheepData?.findIndex(
                                        (data) => data.sheepType === key
                                      );
                                      if (typeIndex === -1) {
                                        sheepData?.push({
                                          sheepType: key as SheepType,
                                          seasonsData: {
                                            spring: {
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        sheepData[typeIndex] = {
                                          ...sheepData[typeIndex],
                                          seasonsData: {
                                            ...sheepData[typeIndex]
                                              ?.seasonsData,
                                            spring: {
                                              ...sheepData[typeIndex]
                                                ?.seasonsData?.spring,
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                        ) : null}
                        {variant === EmissionVariant.Baseline ||
                        hasSeasonInDateRange(
                          Seasons.Summer,
                          emissionActivity.activityStartDate,
                          emissionActivity.activityEndDate
                        ) ? (
                          <tr className="divide-x divide-gray-200 bg-neutral-50">
                            <td className="whitespace-nowrap py-4  pr-4 text-xs font-medium text-gray-900 text-center  ">
                              Summer
                            </td>
                            <td className="whitespace-nowrap text-xs text-gray-500 bg-apple-50">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 py-2.5 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full text-center" />
                                <div className="py-2 px-2 w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(SheepType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap px-4 text-sm text-gray-500"
                              >
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    sheepData?.find(
                                      (data) => data.sheepType === key
                                    )?.seasonsData?.summer?.numOfLivestock ?? ""
                                  }
                                  onChange={(e) => {
                                    if (+e.target.value < 0) {
                                      alert(MINIMUM_INPUT_WARNING);
                                    } else {
                                      const typeIndex = sheepData?.findIndex(
                                        (data) => data.sheepType === key
                                      );
                                      if (typeIndex === -1) {
                                        sheepData?.push({
                                          sheepType: key as SheepType,
                                          seasonsData: {
                                            summer: {
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        sheepData[typeIndex] = {
                                          ...sheepData[typeIndex],
                                          seasonsData: {
                                            ...sheepData[typeIndex]
                                              ?.seasonsData,
                                            summer: {
                                              ...sheepData[typeIndex]
                                                ?.seasonsData?.summer,
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    sheepData?.find(
                                      (data) => data.sheepType === key
                                    )?.seasonsData?.summer?.numOfDays ?? ""
                                  }
                                  onChange={(e) => {
                                    if (
                                      +e.target.value < 0 ||
                                      +e.target.value >
                                        SEASONAL_MAXIMUM_NUMBER_OF_DAYS
                                    ) {
                                      alert(SEASON_INPUT_WARNING);
                                    } else {
                                      const typeIndex = sheepData?.findIndex(
                                        (data) => data.sheepType === key
                                      );
                                      if (typeIndex === -1) {
                                        sheepData?.push({
                                          sheepType: key as SheepType,
                                          seasonsData: {
                                            summer: {
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        sheepData[typeIndex] = {
                                          ...sheepData[typeIndex],
                                          seasonsData: {
                                            ...sheepData[typeIndex]
                                              ?.seasonsData,
                                            summer: {
                                              ...sheepData[typeIndex]
                                                ?.seasonsData?.summer,
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                        ) : null}
                        {variant === EmissionVariant.Baseline ||
                        hasSeasonInDateRange(
                          Seasons.Autumn,
                          emissionActivity.activityStartDate,
                          emissionActivity.activityEndDate
                        ) ? (
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap py-4  pr-4 text-xs font-medium text-gray-900 text-center  ">
                              Autumn
                            </td>
                            <td className="whitespace-nowrap text-xs text-gray-500 bg-apple-50">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 py-2.5 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full text-center" />
                                <div className="py-2 px-2 w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(SheepType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap px-4 text-sm text-gray-500"
                              >
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    sheepData?.find(
                                      (data) => data.sheepType === key
                                    )?.seasonsData?.autumn?.numOfLivestock ?? ""
                                  }
                                  onChange={(e) => {
                                    if (+e.target.value < 0) {
                                      alert(MINIMUM_INPUT_WARNING);
                                    } else {
                                      const typeIndex = sheepData?.findIndex(
                                        (data) => data.sheepType === key
                                      );
                                      if (typeIndex === -1) {
                                        sheepData?.push({
                                          sheepType: key as SheepType,
                                          seasonsData: {
                                            autumn: {
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        sheepData[typeIndex] = {
                                          ...sheepData[typeIndex],
                                          seasonsData: {
                                            ...sheepData[typeIndex]
                                              ?.seasonsData,
                                            autumn: {
                                              ...sheepData[typeIndex]
                                                ?.seasonsData?.autumn,
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    sheepData?.find(
                                      (data) => data.sheepType === key
                                    )?.seasonsData?.autumn?.numOfDays ?? ""
                                  }
                                  onChange={(e) => {
                                    if (
                                      +e.target.value < 0 ||
                                      +e.target.value >
                                        SEASONAL_MAXIMUM_NUMBER_OF_DAYS
                                    ) {
                                      alert(SEASON_INPUT_WARNING);
                                    } else {
                                      const typeIndex = sheepData?.findIndex(
                                        (data) => data.sheepType === key
                                      );
                                      if (typeIndex === -1) {
                                        sheepData?.push({
                                          sheepType: key as SheepType,
                                          seasonsData: {
                                            autumn: {
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        sheepData[typeIndex] = {
                                          ...sheepData[typeIndex],
                                          seasonsData: {
                                            ...sheepData[typeIndex]
                                              ?.seasonsData,
                                            autumn: {
                                              ...sheepData[typeIndex]
                                                ?.seasonsData?.autumn,
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                        ) : null}
                        {variant === EmissionVariant.Baseline ||
                        hasSeasonInDateRange(
                          Seasons.Winter,
                          emissionActivity.activityStartDate,
                          emissionActivity.activityEndDate
                        ) ? (
                          <tr className="divide-x divide-gray-200 bg-neutral-50">
                            <td className="whitespace-nowrap py-4  pr-4 text-xs font-medium text-gray-900 text-center  ">
                              Winter
                            </td>
                            <td className="whitespace-nowrap text-xs text-gray-500 bg-apple-50">
                              <div className="flex flex-col justify-center items-center">
                                <div className="w-full px-2 py-2.5 text-center">
                                  # of livestock
                                </div>
                                <div className="border-b w-full text-center" />
                                <div className="py-2 px-2 w-full text-center">
                                  # of days
                                </div>
                              </div>
                            </td>
                            {Object.entries(SheepType).map(([key], i) => (
                              <td
                                key={i}
                                className="whitespace-nowrap px-4 text-sm text-gray-500"
                              >
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    sheepData?.find(
                                      (data) => data.sheepType === key
                                    )?.seasonsData?.winter?.numOfLivestock ?? ""
                                  }
                                  onChange={(e) => {
                                    if (+e.target.value < 0) {
                                      alert(MINIMUM_INPUT_WARNING);
                                    } else {
                                      const typeIndex = sheepData?.findIndex(
                                        (data) => data.sheepType === key
                                      );
                                      if (typeIndex === -1) {
                                        sheepData?.push({
                                          sheepType: key as SheepType,
                                          seasonsData: {
                                            winter: {
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        sheepData[typeIndex] = {
                                          ...sheepData[typeIndex],
                                          seasonsData: {
                                            ...sheepData[typeIndex]
                                              ?.seasonsData,
                                            winter: {
                                              ...sheepData[typeIndex]
                                                ?.seasonsData?.winter,
                                              numOfLivestock: e.target.value
                                                .length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    sheepData?.find(
                                      (data) => data.sheepType === key
                                    )?.seasonsData?.winter?.numOfDays ?? ""
                                  }
                                  onChange={(e) => {
                                    if (
                                      +e.target.value < 0 ||
                                      +e.target.value >
                                        SEASONAL_MAXIMUM_NUMBER_OF_DAYS
                                    ) {
                                      alert(SEASON_INPUT_WARNING);
                                    } else {
                                      const typeIndex = sheepData?.findIndex(
                                        (data) => data.sheepType === key
                                      );
                                      if (typeIndex === -1) {
                                        sheepData?.push({
                                          sheepType: key as SheepType,
                                          seasonsData: {
                                            winter: {
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        });
                                      } else {
                                        sheepData[typeIndex] = {
                                          ...sheepData[typeIndex],
                                          seasonsData: {
                                            ...sheepData[typeIndex]
                                              ?.seasonsData,
                                            winter: {
                                              ...sheepData[typeIndex]
                                                ?.seasonsData?.winter,
                                              numOfDays: e.target.value.length
                                                ? parseInt(e.target.value)
                                                : undefined,
                                            },
                                          },
                                        };
                                      }
                                      const newData = [
                                        ...(yearLivestockData?.livestockData ??
                                          []),
                                      ];
                                      setYearLivestockData({
                                        ...yearLivestockData,
                                        livestockData: newData,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      case "DairyCattle": {
        if (
          !yearLivestockData ||
          !yearLivestockData.livestockData ||
          !yearLivestockData.livestockData.length
        )
          return;
        const dairyCattleIndex = yearLivestockData.livestockData.findIndex(
          (data) => data.animalType === (animal as AnimalType)
        );
        const dairyCattleData = yearLivestockData.livestockData[
          dairyCattleIndex
        ]?.animalData as DairyCattleData[];
        return (
          <div
            className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2"
            key={key}
          >
            <div className="col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Please tell us about the number of{" "}
                <span className="font-semibold text-apple-700">
                  {AnimalType.DairyCattle}
                </span>{" "}
                stocked and the length of time they were stocked (in days) in
                the project area for each{" "}
                <span className="font-semibold text-apple-700">
                  {AnimalType.DairyCattle}
                </span>{" "}
                type between{" "}
                <span className="font-semibold text-apple-700">
                  {variant !== EmissionVariant.OnGoing ? (
                    formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
                  ) : (
                    <span>
                      {formatDate(emissionActivity.activityStartDate)} -{" "}
                      {formatDate(emissionActivity.activityEndDate)}
                    </span>
                  )}
                </span>
                .
              </label>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-3 lg:px-4">
                    <table className=" min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr className="divide-x divide-gray-200">
                          <th
                            colSpan={2}
                            className="py-3.5 text-center pr-5 text-sm font-semibold text-gray-900"
                          ></th>

                          <th
                            scope="col"
                            className="px-8 py-3.5  text-sm font-semibold text-gray-900 text-center"
                          >
                            Milking Cows
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5  text-sm font-semibold text-gray-900 text-center"
                          >
                            Heifers younger
                            <br />
                            than 1 Year
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-semibold text-gray-900 text-center"
                          >
                            Heifers older
                            <br />
                            than 1 Year
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5  text-sm font-semibold text-gray-900 text-center"
                          >
                            Dairy Bulls younger
                            <br />
                            than 1 Year
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center"
                          >
                            Dairy Bulls older
                            <br />
                            than 1 Year
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr className="divide-x divide-gray-200">
                          <td className="whitespace-wrap py-4  pr-4 text-xs font-medium text-gray-900 text-center  ">
                            {variant !== EmissionVariant.OnGoing ? (
                              formatMonthYear(
                                farmInfo?.carbonRegMonth ?? 1,
                                year
                              )
                            ) : (
                              <span>
                                {formatDate(emissionActivity.activityStartDate)}{" "}
                                - {formatDate(emissionActivity.activityEndDate)}
                              </span>
                            )}
                          </td>
                          <td className="whitespace-nowrap text-xs text-gray-500 bg-apple-50">
                            <div className="flex flex-col justify-center items-center">
                              <div className="w-full px-2 py-2.5 text-center">
                                # of livestock
                              </div>
                              <div className="border-b w-full text-center" />
                              <div className="py-2 px-2 w-full text-center">
                                # of days
                              </div>
                            </div>
                          </td>
                          {Object.entries(DairyCattleType).map(([key], i) => (
                            <td
                              key={i}
                              className="whitespace-nowrap px-4 text-sm text-gray-500"
                            >
                              <TextInput
                                type="number"
                                inputMode="decimal"
                                value={
                                  dairyCattleData?.find(
                                    (data) => data.dairyCattleType === key
                                  )?.dairyCattleData?.numOfLivestock ?? ""
                                }
                                onChange={(e) => {
                                  if (+e.target.value < 0) {
                                    alert(MINIMUM_INPUT_WARNING);
                                  } else {
                                    const typeIndex =
                                      dairyCattleData?.findIndex(
                                        (data) => data.dairyCattleType === key
                                      );
                                    if (typeIndex === -1) {
                                      dairyCattleData?.push({
                                        dairyCattleType: key as DairyCattleType,
                                        dairyCattleData: {
                                          numOfLivestock: e.target.value.length
                                            ? parseInt(e.target.value)
                                            : undefined,
                                        },
                                      });
                                    } else {
                                      dairyCattleData[typeIndex] = {
                                        ...dairyCattleData[typeIndex],
                                        dairyCattleData: {
                                          ...dairyCattleData[typeIndex]
                                            ?.dairyCattleData,
                                          numOfLivestock: e.target.value.length
                                            ? parseInt(e.target.value)
                                            : undefined,
                                        },
                                      };
                                    }
                                    const newData = [
                                      ...(yearLivestockData?.livestockData ??
                                        []),
                                    ];
                                    setYearLivestockData({
                                      ...yearLivestockData,
                                      livestockData: newData,
                                    });
                                  }
                                }}
                              />
                              <TextInput
                                type="number"
                                inputMode="decimal"
                                value={
                                  dairyCattleData?.find(
                                    (data) => data.dairyCattleType === key
                                  )?.dairyCattleData?.numOfDays ?? ""
                                }
                                onChange={(e) => {
                                  if (
                                    +e.target.value < 0 ||
                                    +e.target.value >
                                      REGULAR_MAXIMUM_NUMBER_OF_DAYS
                                  ) {
                                    alert(REGULAR_INPUT_WARNING);
                                  } else {
                                    const typeIndex =
                                      dairyCattleData?.findIndex(
                                        (data) => data.dairyCattleType === key
                                      );
                                    if (typeIndex === -1) {
                                      dairyCattleData?.push({
                                        dairyCattleType: key as DairyCattleType,
                                        dairyCattleData: {
                                          numOfDays: e.target.value.length
                                            ? parseInt(e.target.value)
                                            : undefined,
                                        },
                                      });
                                    } else {
                                      dairyCattleData[typeIndex] = {
                                        ...dairyCattleData[typeIndex],
                                        dairyCattleData: {
                                          ...dairyCattleData[typeIndex]
                                            ?.dairyCattleData,
                                          numOfDays: e.target.value.length
                                            ? parseInt(e.target.value)
                                            : undefined,
                                        },
                                      };
                                    }
                                    const newData = [
                                      ...(yearLivestockData?.livestockData ??
                                        []),
                                    ];
                                    setYearLivestockData({
                                      ...yearLivestockData,
                                      livestockData: newData,
                                    });
                                  }
                                }}
                              />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      case "GenericLivestock":
        return selectedGenericLivesStock.length ? (
          <div
            className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2"
            key={key}
          >
            <div className="col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Please tell us about the number of{" "}
                <span className="font-semibold text-apple-700">
                  {selectedGenericLivesStock
                    .map(
                      (data) =>
                        Object.values(AnimalType)[
                          Object.keys(AnimalType).indexOf(data ?? "")
                        ]
                    )
                    .join(", ")}
                </span>{" "}
                stocked and the length of time they were stocked (in days) in
                the project area for each livestock type between{" "}
                <span className="font-semibold text-apple-700">
                  {variant !== EmissionVariant.OnGoing ? (
                    formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
                  ) : (
                    <span>
                      {formatDate(emissionActivity.activityStartDate)} -{" "}
                      {formatDate(emissionActivity.activityEndDate)}
                    </span>
                  )}
                </span>
                .
              </label>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-3 lg:px-4">
                    <table className=" min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr className="divide-x divide-gray-200">
                          <th
                            colSpan={2}
                            className="py-3.5 text-center pr-5 text-sm font-semibold text-gray-900"
                          ></th>
                          {selectedGenericLivesStock.map((data, i) => (
                            <th
                              key={i}
                              scope="col"
                              className={
                                i < selectedGenericLivesStock.length - 1
                                  ? `px-8 py-3.5  text-sm font-semibold text-gray-900 text-center`
                                  : `py-3.5 pl-6 pr-8  text-sm font-semibold text-gray-900  text-center`
                              }
                            >
                              {
                                Object.values(AnimalType)[
                                  Object.keys(AnimalType).indexOf(data ?? "")
                                ]
                              }
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr className="divide-x divide-gray-200">
                          <td className="whitespace-wrap py-4  pr-4 text-xs font-medium text-gray-900 text-center  ">
                            {variant !== EmissionVariant.OnGoing ? (
                              formatMonthYear(
                                farmInfo?.carbonRegMonth ?? 1,
                                year
                              )
                            ) : (
                              <span>
                                {formatDate(emissionActivity.activityStartDate)}{" "}
                                - {formatDate(emissionActivity.activityEndDate)}
                              </span>
                            )}
                          </td>
                          <td className="whitespace-nowrap text-xs text-gray-500 bg-apple-50">
                            <div className="flex flex-col justify-center items-center">
                              <div className="w-full px-2 py-2.5 text-center">
                                # of livestock
                              </div>
                              <div className="border-b w-full text-center" />
                              <div className="py-2 px-2 w-full text-center">
                                # of days
                              </div>
                            </div>
                          </td>
                          {yearLivestockData.livestockData &&
                          selectedGenericLivesStock
                            ? selectedGenericLivesStock.map(
                                (genericAnimalType, i) => (
                                  <td
                                    key={i}
                                    className="whitespace-nowrap px-4 text-sm text-gray-500"
                                  >
                                    <TextInput
                                      type="number"
                                      inputMode="decimal"
                                      value={
                                        (
                                          (yearLivestockData.livestockData?.find(
                                            (data) =>
                                              data.animalType ===
                                              genericAnimalType
                                          )?.animalData ??
                                            {}) as LivestockTypeData
                                        ).numOfLivestock ?? ""
                                      }
                                      onChange={(e) => {
                                        if (+e.target.value < 0) {
                                          alert(MINIMUM_INPUT_WARNING);
                                        } else {
                                          if (
                                            yearLivestockData &&
                                            yearLivestockData.livestockData
                                          ) {
                                            const typeIndex =
                                              yearLivestockData.livestockData.findIndex(
                                                (data) =>
                                                  data.animalType ===
                                                  (genericAnimalType as AnimalType)
                                              );
                                            if (typeIndex === -1) {
                                              yearLivestockData.livestockData?.push(
                                                {
                                                  animalType:
                                                    genericAnimalType as AnimalType,
                                                  animalData: {
                                                    numOfLivestock: e.target
                                                      .value.length
                                                      ? parseInt(e.target.value)
                                                      : undefined,
                                                  },
                                                }
                                              );
                                            } else {
                                              yearLivestockData.livestockData[
                                                typeIndex
                                              ] = {
                                                ...yearLivestockData
                                                  .livestockData[typeIndex],
                                                animalData: {
                                                  ...yearLivestockData
                                                    .livestockData[typeIndex]
                                                    ?.animalData,
                                                  numOfLivestock: e.target.value
                                                    .length
                                                    ? parseInt(e.target.value)
                                                    : undefined,
                                                },
                                              };
                                            }

                                            const newData = [
                                              ...(yearLivestockData?.livestockData ??
                                                []),
                                            ];
                                            setYearLivestockData({
                                              ...yearLivestockData,
                                              livestockData: newData,
                                            });
                                          }
                                        }
                                      }}
                                    />
                                    <TextInput
                                      type="number"
                                      inputMode="decimal"
                                      value={
                                        (
                                          (yearLivestockData.livestockData?.find(
                                            (data) =>
                                              data.animalType ===
                                              genericAnimalType
                                          )?.animalData ??
                                            {}) as LivestockTypeData
                                        ).numOfDays ?? ""
                                      }
                                      onChange={(e) => {
                                        if (
                                          +e.target.value < 0 ||
                                          +e.target.value >
                                            REGULAR_MAXIMUM_NUMBER_OF_DAYS
                                        ) {
                                          alert(REGULAR_INPUT_WARNING);
                                        } else {
                                          if (
                                            yearLivestockData &&
                                            yearLivestockData.livestockData
                                          ) {
                                            const typeIndex =
                                              yearLivestockData.livestockData.findIndex(
                                                (data) =>
                                                  data.animalType ===
                                                  (genericAnimalType as AnimalType)
                                              );
                                            if (typeIndex === -1) {
                                              yearLivestockData.livestockData?.push(
                                                {
                                                  animalType:
                                                    genericAnimalType as AnimalType,
                                                  animalData: {
                                                    numOfDays: e.target.value
                                                      .length
                                                      ? parseInt(e.target.value)
                                                      : undefined,
                                                  },
                                                }
                                              );
                                            } else {
                                              yearLivestockData.livestockData[
                                                typeIndex
                                              ] = {
                                                ...yearLivestockData
                                                  .livestockData[typeIndex],
                                                animalData: {
                                                  ...yearLivestockData
                                                    .livestockData[typeIndex]
                                                    ?.animalData,
                                                  numOfDays: e.target.value
                                                    .length
                                                    ? parseInt(e.target.value)
                                                    : undefined,
                                                },
                                              };
                                            }
                                            const newData = [
                                              ...(yearLivestockData?.livestockData ??
                                                []),
                                            ];
                                            setYearLivestockData({
                                              ...yearLivestockData,
                                              livestockData: newData,
                                            });
                                          }
                                        }
                                      }}
                                    />
                                  </td>
                                )
                              )
                            : null}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null;
      default:
        break;
    }
  }
  return (
    <div className="flex flex-col items-center w-full">
      {!emissionData || !projectId || !emissionActivityId ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p>No Data</p>
        </div>
      ) : (
        <div className="w-full">
          <div className="border-b border-gray-200 pb-4 lg:pb-8">
            <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
              {formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)} Livestock
            </h2>
          </div>
          {activityInfo?.animalType.map((animal, i) => {
            if (
              animal === ("BeefCattle" as AnimalType) ||
              animal === ("Sheep" as AnimalType) ||
              animal === ("DairyCattle" as AnimalType)
            ) {
              return ViewCreator(animal, i);
            }
            return null;
          })}
          {ViewCreator("GenericLivestock", 3)}

          <div className="border-t border-gray-200 pb-4 lg:pb-8" />
          <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
            Attachments
          </h2>
          <div className="m-1">
            <div className="mt-1 ">
              <EmissionAttachments
                variant={variant}
                emissionActivity={emissionActivity}
                emissionActivityId={+emissionActivityId}
                projectId={+projectId}
                month={farmInfo.carbonRegMonth}
                year={year}
                attachments={projectAttachment}
                title="Livestock"
                attachmentKind={EmissionAttachmentType.Livestock}
                mutateAttachments={mutate}
                attachmentTypes={activityInfo.animalType.map((animal) =>
                  GetObjectValue(AnimalType, animal)
                )}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
