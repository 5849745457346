interface PageContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export default function PageContainer({ children }: PageContainerProps) {
  return (
    <div
      id="scrollMe"
      className="flex flex-col items-center px-4 md:px-20 w-full h-full overflow-y-auto"
    >
      {children}
    </div>
  );
}
