import { useEffect, useState } from "react";
import TextInput from "../../../components/text-input";
import useEmissionData from "../../../hooks/useEmissionData";
import { LimingEnergy } from "../../../types";
import { api } from "../../../api";
import { formatMonthYear } from "../../../helpers/format-month-year";
import {
  EmissionAttachmentType,
  EmissionVariant,
  LimingEnergyAttachmentType,
  MINIMUM_INPUT_WARNING,
} from "../../../constants";
import useActivityInfo from "../../../hooks/emission/useActivityInfo";
import useFarmInfo from "../../../hooks/emission/useFarmInfo";
import { useParams } from "react-router-dom";
import EmissionAttachments from "../emission-attachments";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";
import formatDate from "../../../helpers/format-date";
import useEmissionActivity from "../../../hooks/emission/useEmissionActivity";

interface LimingProps {
  variant?: EmissionVariant;
}

export default function Liming({ variant }: LimingProps) {
  const emissionData = useEmissionData();
  const activityInfo = useActivityInfo();
  const farmInfo = useFarmInfo();
  const emissionActivity = useEmissionActivity();

  const { projectId, emissionActivityId } = useParams();
  const [limingEnergyData, setLimingEnergyData] = useState<LimingEnergy[]>([]);
  const { projectAttachment, mutate } = useProjectAttachment(
    projectId,
    emissionActivityId,
    EmissionAttachmentType.LimingEnergy
  );
  const [inputCheck, setInputCheck] = useState<{
    showLimeError: boolean;
    showDolomiteError: boolean;
    limeAttachment: boolean;
    dolomiteAttachment: boolean;
  }>({
    showLimeError: true,
    showDolomiteError: true,
    limeAttachment: false,
    dolomiteAttachment: false,
  });

  useEffect(() => {
    if (!emissionData) {
      setLimingEnergyData([]);
    }

    setLimingEnergyData(emissionData?.limingEnergy ?? []);
  }, [emissionData]);

  useEffect(() => {
    if (!emissionActivity) {
      setInputCheck({
        showLimeError: false,
        showDolomiteError: false,
        limeAttachment: false,
        dolomiteAttachment: false,
      });
    }

    const limeData = limingEnergyData.find(
      (data) =>
        data.activityYear ===
        new Date(
          emissionActivity?.activityStartDate ?? new Date()
        ).getFullYear()
    )?.limestoneQuantity;

    const dolomiteData = limingEnergyData.find(
      (data) =>
        data.activityYear ===
        new Date(
          emissionActivity?.activityStartDate ?? new Date()
        ).getFullYear()
    )?.dolomiteQuantity;

    setInputCheck({
      showLimeError: limeData === undefined,
      showDolomiteError: dolomiteData === undefined,
      limeAttachment: limeData !== undefined && +limeData !== 0,
      dolomiteAttachment: dolomiteData !== undefined && +dolomiteData !== 0,
    });
  }, [emissionActivity, limingEnergyData]);

  function fetchLiming(year: number) {
    const data = limingEnergyData.find((data) => data.activityYear === year);

    if (data) {
      return data as LimingEnergy;
    }

    return {} as LimingEnergy;
  }

  return (
    <div className="flex flex-col items-center w-full">
      {!emissionData ||
      !activityInfo ||
      !farmInfo ||
      !projectId ||
      !emissionActivityId ||
      !emissionActivity ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p>No Data</p>
        </div>
      ) : (
        <div className="w-full">
          <div className="border-b border-gray-200 pb-4 lg:pb-8">
            <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
              Liming
            </h2>
          </div>

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Please list the amount of lime you applied to your project area
                in the specified{" "}
                {variant !== EmissionVariant.OnGoing ? "years" : "period"}.{" "}
              </label>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full ">
                      <thead>
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                          ></th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-semibold text-gray-900 text-center"
                          >
                            Quantity of Limestone applied
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                          >
                            Quantity of Dolomite applied
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-0.5 text-center px-5 text-sm font-normal text-gray-900 "
                          ></th>
                          <th
                            scope="col"
                            className="px-4 py-0.5  text-xs font-normal text-gray-900 text-center bg-apple-50"
                          >
                            Tonnes (T)
                          </th>
                          <th
                            scope="col"
                            className="py-0.5 pl-4 pr-4  text-xs font-normal text-gray-900 sm:pr-0 text-center bg-apple-50"
                          >
                            Tonnes (T)
                          </th>
                        </tr>
                        {variant !== EmissionVariant.OnGoing ? (
                          activityInfo?.activityYears
                            .sort((a, b) => b - a)
                            .map((year, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center">
                                  {formatMonthYear(
                                    farmInfo?.carbonRegMonth ?? 1,
                                    year
                                  )}
                                </td>

                                <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                  <TextInput
                                    type="number"
                                    inputMode="decimal"
                                    defaultValue={
                                      fetchLiming(year).limestoneQuantity
                                    }
                                    onInput={(e) => {
                                      if (
                                        +e.currentTarget.value < 0 ||
                                        !e.currentTarget.value
                                      ) {
                                        alert(MINIMUM_INPUT_WARNING);
                                        e.currentTarget.value = "";
                                        api.saveLimingEnergy(
                                          +emissionActivityId,
                                          +projectId,
                                          year,
                                          "limestoneQuantity",
                                          undefined
                                        );
                                      } else {
                                        api.saveLimingEnergy(
                                          +emissionActivityId,
                                          +projectId,
                                          year,
                                          "limestoneQuantity",
                                          +e.currentTarget.value
                                        );
                                      }
                                    }}
                                  />
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                                  <TextInput
                                    type="number"
                                    inputMode="decimal"
                                    defaultValue={
                                      fetchLiming(year).dolomiteQuantity
                                    }
                                    onInput={(e) => {
                                      if (
                                        +e.currentTarget.value < 0 ||
                                        !e.currentTarget.value
                                      ) {
                                        alert(MINIMUM_INPUT_WARNING);
                                        e.currentTarget.value = "";
                                        api.saveLimingEnergy(
                                          +emissionActivityId,
                                          +projectId,
                                          year,
                                          "dolomiteQuantity",
                                          undefined
                                        );
                                      } else {
                                        api.saveLimingEnergy(
                                          +emissionActivityId,
                                          +projectId,
                                          year,
                                          "dolomiteQuantity",
                                          +e.currentTarget.value
                                        );
                                      }
                                    }}
                                  />
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center">
                              {formatDate(emissionActivity.activityStartDate)} -{" "}
                              {formatDate(emissionActivity.activityEndDate)}
                            </td>

                            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                              <TextInput
                                type="number"
                                inputMode="decimal"
                                defaultValue={
                                  fetchLiming(
                                    new Date(
                                      emissionActivity.activityStartDate ??
                                        new Date()
                                    ).getFullYear()
                                  ).limestoneQuantity
                                }
                                onInput={(e) => {
                                  if (
                                    +e.currentTarget.value < 0 ||
                                    !e.currentTarget.value
                                  ) {
                                    alert(MINIMUM_INPUT_WARNING);
                                    e.currentTarget.value = "";
                                    api.saveLimingEnergy(
                                      +emissionActivityId,
                                      +projectId,
                                      new Date(
                                        emissionActivity.activityStartDate ??
                                          new Date()
                                      ).getFullYear(),
                                      "limestoneQuantity",
                                      undefined
                                    );
                                    setInputCheck({
                                      ...inputCheck,
                                      showLimeError: true,
                                      limeAttachment: false,
                                    });
                                  } else {
                                    api.saveLimingEnergy(
                                      +emissionActivityId,
                                      +projectId,
                                      new Date(
                                        emissionActivity.activityStartDate ??
                                          new Date()
                                      ).getFullYear(),
                                      "limestoneQuantity",
                                      +e.currentTarget.value
                                    );
                                    setInputCheck({
                                      ...inputCheck,
                                      showLimeError: false,
                                      limeAttachment:
                                        +e.currentTarget.value !== 0,
                                    });
                                  }
                                }}
                                error={inputCheck.showLimeError ? 1 : 0}
                              />
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                              <TextInput
                                type="number"
                                inputMode="decimal"
                                defaultValue={
                                  fetchLiming(
                                    new Date(
                                      emissionActivity.activityStartDate ??
                                        new Date()
                                    ).getFullYear()
                                  ).dolomiteQuantity
                                }
                                onInput={(e) => {
                                  if (
                                    +e.currentTarget.value < 0 ||
                                    !e.currentTarget.value
                                  ) {
                                    alert(MINIMUM_INPUT_WARNING);
                                    e.currentTarget.value = "";
                                    api.saveLimingEnergy(
                                      +emissionActivityId,
                                      +projectId,
                                      new Date(
                                        emissionActivity.activityStartDate ??
                                          new Date()
                                      ).getFullYear(),
                                      "dolomiteQuantity",
                                      undefined
                                    );
                                    setInputCheck({
                                      ...inputCheck,
                                      showDolomiteError: true,
                                      dolomiteAttachment: false,
                                    });
                                  } else {
                                    api.saveLimingEnergy(
                                      +emissionActivityId,
                                      +projectId,
                                      new Date(
                                        emissionActivity.activityStartDate ??
                                          new Date()
                                      ).getFullYear(),
                                      "dolomiteQuantity",
                                      +e.currentTarget.value
                                    );
                                    setInputCheck({
                                      ...inputCheck,
                                      showDolomiteError: false,
                                      dolomiteAttachment:
                                        +e.currentTarget.value !== 0,
                                    });
                                  }
                                }}
                                error={inputCheck.showDolomiteError ? 1 : 0}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="border-t border-gray-200 pb-4 lg:pb-8" />
              {variant === EmissionVariant.OnGoing &&
              !(
                inputCheck.limeAttachment || inputCheck.dolomiteAttachment
              ) ? null : (
                <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
                  Attachments
                </h2>
              )}
              <div className="m-1">
                {variant !== EmissionVariant.OnGoing ? (
                  activityInfo.activityYears
                    .sort((a, b) => b - a)
                    .map((year, i) => (
                      <div className="mt-1 " key={i}>
                        <EmissionAttachments
                          emissionActivityId={+emissionActivityId}
                          month={farmInfo.carbonRegMonth}
                          year={year}
                          projectId={+projectId}
                          title="Liming"
                          attachmentKind={EmissionAttachmentType.LimingEnergy}
                          attachments={projectAttachment}
                          mutateAttachments={mutate}
                        />
                        {i < activityInfo.activityYears.length - 1 && (
                          <div className="border-b border-gray-200 pb-4 lg:pb-8" />
                        )}
                      </div>
                    ))
                ) : (
                  <EmissionAttachments
                    variant={variant}
                    emissionActivity={emissionActivity}
                    emissionActivityId={+emissionActivityId}
                    month={farmInfo.carbonRegMonth}
                    year={new Date(
                      emissionActivity.activityStartDate ?? new Date()
                    ).getFullYear()}
                    projectId={+projectId}
                    title="Liming"
                    attachmentKind={EmissionAttachmentType.LimingEnergy}
                    attachments={projectAttachment}
                    mutateAttachments={mutate}
                    attachmentTypes={
                      [
                        inputCheck.limeAttachment
                          ? LimingEnergyAttachmentType.QuantityOfLimestone
                          : undefined,
                        inputCheck.dolomiteAttachment
                          ? LimingEnergyAttachmentType.QuantityOfDolomite
                          : undefined,
                      ].filter(Boolean) as LimingEnergyAttachmentType[]
                    }
                    uploadRequired={
                      inputCheck.limeAttachment || inputCheck.dolomiteAttachment
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
