import { useEffect, useState } from "react";
import Checkbox from "../../../components/checkbox-input";
import TextInput from "../../../components/text-input";
import {
  MINIMUM_INPUT_WARNING,
  RestrictedActivities,
  RestrictedActivitiesLongShort,
} from "../../../constants";
import { useParams } from "react-router-dom";
import useEmissionActivity from "../../../hooks/emission/useEmissionActivity";
import useEmissionData from "../../../hooks/useEmissionData";
import { RestrictedActivity } from "../../../types";
import { api } from "../../../api";
import Divider from "../../../components/divider";
import formatDate from "../../../helpers/format-date";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import TextArea from "../../../components/text-area";

interface RestrictedActivitiesNumOfTimes {
  clearedWoodyVegetation?: number;
  thinningOfTheLand?: number;
  additionOfSoil?: number;
  addingSoilWithBiochar?: number;
  addingSoilWithCoal?: number;
  addingRestrictedNonSyntheticFertilizer?: number;
  applyingIrrigationMoreThanTheBaseline?: number;
}

export default function RestrictedActivitiesView() {
  const [numOfTimes, setNumOfTimes] = useState<RestrictedActivitiesNumOfTimes>(
    {}
  );
  const [restrictedActivity, setRestrictedActivity] =
    useState<RestrictedActivity>();
  const { projectId, emissionActivityId } = useParams();
  const emissionActivity = useEmissionActivity();
  const emissionData = useEmissionData();

  useEffect(() => {
    if (!emissionActivityId || !projectId) return;
    if (!emissionData || !emissionData.restrictedActivity) {
      setRestrictedActivity({
        projectId: +projectId,
        emissionActivityId: +emissionActivityId,
        restrictedActivityData: [],
      });
      return;
    }
    setRestrictedActivity(emissionData.restrictedActivity);

    setNumOfTimes({
      clearedWoodyVegetation:
        emissionData.restrictedActivity?.restrictedActivityData?.find(
          (item) =>
            item.restrictedActivityType ===
            RestrictedActivities.ClearedWoodyVegetation
        )?.restrictedActivityData?.length,
      thinningOfTheLand:
        emissionData.restrictedActivity?.restrictedActivityData?.find(
          (item) =>
            item.restrictedActivityType ===
            RestrictedActivities.ThinningOfTheLand
        )?.restrictedActivityData?.length,
      additionOfSoil:
        emissionData.restrictedActivity?.restrictedActivityData?.find(
          (item) =>
            item.restrictedActivityType === RestrictedActivities.AdditionOfSoil
        )?.restrictedActivityData?.length,
      addingSoilWithBiochar:
        emissionData.restrictedActivity?.restrictedActivityData?.find(
          (item) =>
            item.restrictedActivityType ===
            RestrictedActivities.AddingSoilWithBiochar
        )?.restrictedActivityData?.length,
      addingSoilWithCoal:
        emissionData.restrictedActivity?.restrictedActivityData?.find(
          (item) =>
            item.restrictedActivityType ===
            RestrictedActivities.AddingSoilWithCoal
        )?.restrictedActivityData?.length,
      addingRestrictedNonSyntheticFertilizer:
        emissionData.restrictedActivity?.restrictedActivityData?.find(
          (item) =>
            item.restrictedActivityType ===
            RestrictedActivities.AddingRestrictedNonSyntheticFertilizer
        )?.restrictedActivityData?.length,
      applyingIrrigationMoreThanTheBaseline:
        emissionData.restrictedActivity?.restrictedActivityData?.find(
          (item) =>
            item.restrictedActivityType ===
            RestrictedActivities.ApplyingIrrigationMoreThanTheBaseline
        )?.restrictedActivityData?.length,
    });
  }, [emissionData, emissionActivityId, projectId]);

  useEffect(() => {
    function saveRestrictedActivity() {
      if (!restrictedActivity) return;
      return api.saveRestrictedActivityData(restrictedActivity);
    }
    saveRestrictedActivity();
  }, [restrictedActivity]);

  function handleNumberOfTimesChange(
    e: React.FormEvent<HTMLInputElement>,
    itemType?: RestrictedActivities
  ): void {
    if (!itemType) return;

    if (+e.currentTarget.value < 1 || !e.currentTarget.value) {
      alert(MINIMUM_INPUT_WARNING);
      e.currentTarget.value = "";
    }

    switch (itemType) {
      case RestrictedActivities.ClearedWoodyVegetation:
        setNumOfTimes({
          ...numOfTimes,
          clearedWoodyVegetation: e.currentTarget.value.length
            ? +e.currentTarget.value
            : undefined,
        });

        break;
      case RestrictedActivities.ThinningOfTheLand:
        setNumOfTimes({
          ...numOfTimes,
          thinningOfTheLand: e.currentTarget.value.length
            ? +e.currentTarget.value
            : undefined,
        });
        break;
      case RestrictedActivities.AdditionOfSoil:
        setNumOfTimes({
          ...numOfTimes,
          additionOfSoil: e.currentTarget.value.length
            ? +e.currentTarget.value
            : undefined,
        });
        break;
      case RestrictedActivities.AddingSoilWithBiochar:
        setNumOfTimes({
          ...numOfTimes,
          addingSoilWithBiochar: e.currentTarget.value.length
            ? +e.currentTarget.value
            : undefined,
        });
        break;
      case RestrictedActivities.AddingSoilWithCoal:
        setNumOfTimes({
          ...numOfTimes,
          addingSoilWithCoal: e.currentTarget.value.length
            ? +e.currentTarget.value
            : undefined,
        });
        break;
      case RestrictedActivities.AddingRestrictedNonSyntheticFertilizer:
        setNumOfTimes({
          ...numOfTimes,
          addingRestrictedNonSyntheticFertilizer: e.currentTarget.value.length
            ? +e.currentTarget.value
            : undefined,
        });
        break;
      case RestrictedActivities.ApplyingIrrigationMoreThanTheBaseline:
        setNumOfTimes({
          ...numOfTimes,
          applyingIrrigationMoreThanTheBaseline: e.currentTarget.value.length
            ? +e.currentTarget.value
            : undefined,
        });
        break;
      default:
        break;
    }
  }

  function getNumOfTimesValue(itemType?: RestrictedActivities): number {
    if (!itemType) return 0;

    switch (itemType) {
      case RestrictedActivities.ClearedWoodyVegetation:
        return numOfTimes.clearedWoodyVegetation ?? 0;
      case RestrictedActivities.ThinningOfTheLand:
        return numOfTimes.thinningOfTheLand ?? 0;

      case RestrictedActivities.AdditionOfSoil:
        return numOfTimes.additionOfSoil ?? 0;
      case RestrictedActivities.AddingSoilWithBiochar:
        return numOfTimes.addingSoilWithBiochar ?? 0;
      case RestrictedActivities.AddingSoilWithCoal:
        return numOfTimes.addingSoilWithCoal ?? 0;
      case RestrictedActivities.AddingRestrictedNonSyntheticFertilizer:
        return numOfTimes.addingRestrictedNonSyntheticFertilizer ?? 0;
      case RestrictedActivities.ApplyingIrrigationMoreThanTheBaseline:
        return numOfTimes.applyingIrrigationMoreThanTheBaseline ?? 0;
      default:
        return 0;
    }
  }

  function getShortVersion(value?: RestrictedActivities): string {
    if (!value) return "";
    const key = Object.keys(RestrictedActivities).find(
      (key) =>
        RestrictedActivities[key as keyof typeof RestrictedActivities] === value
    );
    if (!key) return "";
    return (
      RestrictedActivitiesLongShort[
        key as keyof typeof RestrictedActivitiesLongShort
      ].short ?? ""
    );
  }
  return (
    <div className="flex flex-col items-center w-full">
      {!restrictedActivity ||
      !emissionActivity ||
      !projectId ||
      !emissionActivityId ? (
        <div>No Data</div>
      ) : (
        <div className="w-full">
          <div className="border-b border-gray-200 pb-4 lg:pb-8">
            <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
              Restricted Activities
            </h2>
          </div>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Please select the following activities that were conducted in
                the project area between{" "}
                <span className="font-semibold text-apple-700">
                  <span>
                    {formatDate(emissionActivity?.activityStartDate)} -{" "}
                    {formatDate(emissionActivity?.activityEndDate)}
                  </span>
                  .
                </span>
              </label>
              {!restrictedActivity.restrictedActivityData ||
              restrictedActivity.restrictedActivityData.length === 0 ? (
                <p className="pt-2 text-xs text-red-600" id="error">
                  This field is required!
                </p>
              ) : null}
              <div className="grid grid-cols-1 gap-2 sm:gap-3 lg:gap-4 mt-3">
                {Object.entries(RestrictedActivities).map(([key, value]) => (
                  <div
                    key={key}
                    className="flex flex-row items-center w-full col-span-1"
                  >
                    <Checkbox
                      disabled={
                        value !==
                          RestrictedActivities.IDidNotConductAnyRestrictedActivities &&
                        restrictedActivity.restrictedActivityData?.some(
                          (item) =>
                            item.restrictedActivityType ===
                            RestrictedActivities.IDidNotConductAnyRestrictedActivities
                        )
                      }
                      value={key}
                      label={value}
                      checked={
                        restrictedActivity.restrictedActivityData?.some(
                          (item) => item.restrictedActivityType === value
                        ) ?? false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (
                            value ===
                            RestrictedActivities.IDidNotConductAnyRestrictedActivities
                          ) {
                            setRestrictedActivity({
                              ...restrictedActivity,
                              restrictedActivityData: [
                                {
                                  restrictedActivityType: value,
                                },
                              ],
                            });
                            return;
                          } else {
                            setRestrictedActivity({
                              ...restrictedActivity,
                              restrictedActivityData: [
                                ...(restrictedActivity.restrictedActivityData ??
                                  []),
                                { restrictedActivityType: value },
                              ],
                            });
                          }
                        } else {
                          setRestrictedActivity({
                            ...restrictedActivity,
                            restrictedActivityData: [
                              ...(
                                restrictedActivity.restrictedActivityData ?? []
                              ).filter(
                                (item) => item.restrictedActivityType !== value
                              ),
                            ],
                          });
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Divider />
          {restrictedActivity.restrictedActivityData &&
          !restrictedActivity.restrictedActivityData.find(
            (activity) =>
              activity.restrictedActivityType ===
              RestrictedActivities.IDidNotConductAnyRestrictedActivities
          ) ? (
            <>
              {restrictedActivity.restrictedActivityData.map((item, i) => (
                <div
                  key={i}
                  className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2"
                >
                  <div className="col-span-full">
                    <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
                      {getShortVersion(item.restrictedActivityType)}
                    </h2>
                    <TextInput
                      type="number"
                      inputMode="decimal"
                      name={item.restrictedActivityType}
                      label={
                        <span className="text-sm">
                          How many times did you{" "}
                          <span className="font-semibold text-apple-700">
                            {item.restrictedActivityType}
                          </span>{" "}
                          in the project area between{" "}
                          <span className="font-semibold text-apple-700">
                            <span>
                              {formatDate(emissionActivity?.activityStartDate)}{" "}
                              - {formatDate(emissionActivity?.activityEndDate)}
                            </span>
                            .
                          </span>
                        </span>
                      }
                      defaultValue={
                        getNumOfTimesValue(item.restrictedActivityType) === 0
                          ? ""
                          : getNumOfTimesValue(item.restrictedActivityType)
                      }
                      onInput={(e) => {
                        handleNumberOfTimesChange(
                          e,
                          item.restrictedActivityType
                        );
                        if (!item.restrictedActivityData) return;
                        if (
                          item.restrictedActivityData.length > 0 &&
                          +e.currentTarget.value <
                            item.restrictedActivityData.length
                        ) {
                          item.restrictedActivityData =
                            item.restrictedActivityData.slice(
                              0,
                              +e.currentTarget.value
                            );
                          setRestrictedActivity({
                            ...restrictedActivity,
                            restrictedActivityData: [
                              ...(restrictedActivity.restrictedActivityData ??
                                []),
                            ],
                          });
                        }
                      }}
                      error={
                        !getNumOfTimesValue(item.restrictedActivityType) ||
                        getNumOfTimesValue(item.restrictedActivityType) === 0
                          ? 1
                          : 0
                      }
                    />
                    <div className="mt-8 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full">
                            <thead className="divide-y-reverse divide-gray-200">
                              <tr className="divide-x  divide-gray-200">
                                <th
                                  scope="col"
                                  className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                                ></th>

                                <th
                                  scope="col"
                                  className="px-4 py-3.5  text-sm font-semibold text-gray-900 text-center"
                                >
                                  Date of Activity
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                                >
                                  Please describe the reason(s) for conducting
                                  the activity.
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              <tr className="divide-x divide-gray-200"></tr>
                              {getNumOfTimesValue(
                                item.restrictedActivityType
                              ) ? (
                                Array.from(
                                  {
                                    length: getNumOfTimesValue(
                                      item.restrictedActivityType
                                    ),
                                  },
                                  (_, i) => i + 1
                                ).map((i) => (
                                  <tr
                                    key={i}
                                    className="divide-x divide-gray-200"
                                  >
                                    <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center">
                                      {i}
                                    </td>

                                    <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                      <TextInput
                                        type="date"
                                        min={
                                          new Date(
                                            emissionActivity?.activityStartDate ??
                                              ""
                                          )
                                            .toISOString()
                                            .split("T")[0]
                                        }
                                        max={
                                          new Date(
                                            emissionActivity?.activityEndDate ??
                                              ""
                                          )
                                            .toISOString()
                                            .split("T")[0]
                                        }
                                        value={
                                          item.restrictedActivityData
                                            ? item.restrictedActivityData[i - 1]
                                                ?.activityDate ?? ""
                                            : ""
                                        }
                                        onChange={(e) => {
                                          if (item.restrictedActivityData) {
                                            item.restrictedActivityData[i - 1] =
                                              {
                                                ...item.restrictedActivityData[
                                                  i - 1
                                                ],
                                                activityDate:
                                                  e.currentTarget.value,
                                              };
                                          } else {
                                            item.restrictedActivityData = [];
                                            item.restrictedActivityData[i - 1] =
                                              {
                                                ...item.restrictedActivityData[
                                                  i - 1
                                                ],
                                                activityDate:
                                                  e.currentTarget.value,
                                              };
                                          }
                                          setRestrictedActivity({
                                            ...restrictedActivity,
                                            restrictedActivityData: [
                                              ...(restrictedActivity.restrictedActivityData ??
                                                []),
                                            ],
                                          });
                                        }}
                                        error={
                                          !item.restrictedActivityData ||
                                          !item.restrictedActivityData[i - 1]
                                            ?.activityDate
                                            ? 1
                                            : 0
                                        }
                                      />
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                                      <TextArea
                                        value={
                                          item.restrictedActivityData
                                            ? item.restrictedActivityData[i - 1]
                                                ?.activityReason ?? ""
                                            : ""
                                        }
                                        onChange={(e) => {
                                          if (item.restrictedActivityData) {
                                            item.restrictedActivityData[i - 1] =
                                              {
                                                ...item.restrictedActivityData[
                                                  i - 1
                                                ],
                                                activityReason:
                                                  e.currentTarget.value,
                                              };
                                          } else {
                                            item.restrictedActivityData = [];
                                            item.restrictedActivityData[i - 1] =
                                              {
                                                ...item.restrictedActivityData[
                                                  i - 1
                                                ],
                                                activityReason:
                                                  e.currentTarget.value,
                                              };
                                          }
                                          setRestrictedActivity({
                                            ...restrictedActivity,
                                            restrictedActivityData: [
                                              ...(restrictedActivity.restrictedActivityData ??
                                                []),
                                            ],
                                          });
                                        }}
                                        error={
                                          !item.restrictedActivityData ||
                                          !item.restrictedActivityData[i - 1]
                                            ?.activityReason
                                            ? 1
                                            : 0
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={4}>
                                    <EmptyPlaceholder>
                                      <span className="text-sm text-gray-500">
                                        Please enter the number of times you{" "}
                                        <span className="font-semibold text-apple-700">
                                          {getShortVersion(
                                            item.restrictedActivityType
                                          )}
                                        </span>{" "}
                                        in the project area.
                                      </span>
                                    </EmptyPlaceholder>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <Divider />
                  </div>
                </div>
              ))}
            </>
          ) : null}
        </div>
      )}
    </div>
  );
}
