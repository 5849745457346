import Infograph from "../../../assets/infographic-ongoing.png";
import Button from "../../../components/button";
import { Link, useLocation, useParams } from "react-router-dom";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import useProjectEmissionActivityInfo from "../../../hooks/emission/useProjectEmissionActivityInfo";
import useProjectEmissionData from "../../../hooks/emission/useProjectEmissionData";
import useProjectCalculatedEmissionData from "../../../hooks/emission/useProjectCalculatedEmissionData";
import OverlayLoading from "../../../components/overlay-loading";
import useProjectEmissionActivity from "../../../hooks/emission/useProjectEmissionActivity";

export default function Welcome() {
  const location = useLocation();
  const { projectId, emissionActivityId } = useParams();
  const { emissionActivityInfo, loading: baselineActivityInfoLoading } =
    useProjectEmissionActivityInfo(projectId, emissionActivityId);
  const { projectEmissionData, loading: projectBaselineDataLoading } =
    useProjectEmissionData(projectId, emissionActivityId);
  const {
    projectCalculatedEmissionData,
    loading: projectCalculatedEmissionDataLoading,
  } = useProjectCalculatedEmissionData(projectId);
  const { emissionActivity } = useProjectEmissionActivity(
    projectId,
    emissionActivityId
  );

  useEffect(() => {
    localStorage.setItem("ActivityInfo", JSON.stringify(emissionActivityInfo));
    localStorage.setItem("EmissionData", JSON.stringify(projectEmissionData));
    localStorage.setItem(
      "CalculatedEmissionData",
      JSON.stringify(projectCalculatedEmissionData)
    );
    localStorage.setItem("EmissionActivity", JSON.stringify(emissionActivity));
  }, [
    emissionActivityInfo,
    projectEmissionData,
    projectCalculatedEmissionData,
    emissionActivity,
  ]);
  return (
    <>
      {baselineActivityInfoLoading ||
      projectBaselineDataLoading ||
      projectCalculatedEmissionDataLoading ? (
        <OverlayLoading
          open={
            baselineActivityInfoLoading ||
            projectBaselineDataLoading ||
            projectCalculatedEmissionDataLoading
          }
        />
      ) : (
        <div className="relative isolate overflow-hidden bg-white px-6 pt-24 pb-8 sm:pt-32 lg:overflow-visible lg:px-0">
          <div className="absolute inset-0 -z-10 overflow-hidden">
            <svg
              className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                  width={200}
                  height={200}
                  x="50%"
                  y={-1}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={-1} className="overflow-visible fill-apple-50">
                <path
                  d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
              />
            </svg>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:px-20 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <h1 className="mt-2 text-4xl font-bold tracking-tight text-apple-500 sm:text-5xl">
                    Hello!
                  </h1>
                  <p className="mt-6 text-xl leading-8 text-gray-700">
                    We are so glad to partner with you throughout this entire
                    SecondCrop carbon farming journey.
                  </p>
                  <p className="mt-6 text-xl leading-8 text-gray-700">
                    The goal of the SecondCrop program is to help you build as
                    many ACCUs as possible by showing a positive net carbon
                    sequestration from the carbon project (an increase in soil
                    carbon which also takes into account any increases in
                    project emissions).
                  </p>
                  <p className="mt-6 text-xl leading-8 text-gray-700">
                    To claim ACCUs, Loam Carbon submits an offset report on your
                    behalf to the CER at the end of each reporting period. An
                    offset report is a document that details your project’s
                    progress and an understanding of on-farm activity for the
                    duration of a project.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:-ml-12 lg:-mt-12 p-0 lg:p-12 place-items-center lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
              <img
                loading="lazy"
                className="w-full max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10"
                src={Infograph}
                alt=""
              />
            </div>
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <p className="text-xl font-bold leading-7 text-apple-700">
                    What you'll need
                  </p>
                  <p className="mt-6 text-xl leading-8 text-gray-700">
                    In order to prepare the offset reports, we will use this
                    form to periodically collect key information regarding your
                    activities on your project area. These activities includes
                    (if applicable):
                  </p>
                  <ul role="list" className="mt-8 space-y-8 text-gray-600">
                    <li className="flex gap-x-3">
                      <ChevronDoubleRightIcon
                        className="mt-1 h-5 w-5 flex-none text-apple-500"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-900">
                          CarbonBuilder application rates and dates
                        </strong>
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <ChevronDoubleRightIcon
                        className="mt-1 h-5 w-5 flex-none text-apple-500"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-900">
                          Fuel usage for irrigation and soil landscape
                          modification
                        </strong>
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <ChevronDoubleRightIcon
                        className="mt-1 h-5 w-5 flex-none text-apple-500"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-900">
                          Fertiliser and liming application rates
                        </strong>
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <ChevronDoubleRightIcon
                        className="mt-1 h-5 w-5 flex-none text-apple-500"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-900">
                          Livestock types and quantities
                        </strong>
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <ChevronDoubleRightIcon
                        className="mt-1 h-5 w-5 flex-none text-apple-500"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-900">
                          Crop harvest(s) quantities
                        </strong>
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <ChevronDoubleRightIcon
                        className="mt-1 h-5 w-5 flex-none text-apple-500"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-900">
                          Crop tillage area(s)
                        </strong>
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <ChevronDoubleRightIcon
                        className="mt-1 h-5 w-5 flex-none text-apple-500"
                        aria-hidden="true"
                      />
                      <span>
                        <strong className="font-semibold text-gray-900">
                          Cover crops sown
                        </strong>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="mt-[2.5rem] lg:max-w-lg">
                  <p className="text-xl font-bold leading-7 text-apple-700">
                    How the ongoing greenhouse gas emission collection form
                    works
                  </p>
                  <p className="mt-6 text-xl leading-8 text-gray-700">
                    The questions will take approximately{" "}
                    <strong className="font-semibold text-gray-900">
                      15-25 minutes to complete
                    </strong>{" "}
                    if you have the data listed above. To facilitate completion
                    of the survey,{" "}
                    <strong>
                      please have on hand records of these project activities
                      (e.g. digitally or manually recorded farm logs, receipts
                      of inputs or services)
                    </strong>
                  </p>
                  {/* <p className="mt-6 text-xl leading-8 text-gray-700">
                    By answering the questions, we can get a better
                    understanding of your ongoing activities in your carbon
                    farming project area. If you are not sure about anything,
                    that's OK! Drop us a note at the end of the survey and we
                    can walk through it together.
                  </p> */}
                  <p className="mt-6 text-xl leading-8 text-gray-700">
                    The answers you provide in this form are for use by you and
                    Loam to determine your ongoing greenhouse gas emissions
                    associated with your carbon project.{" "}
                    <strong className="font-semibold text-gray-900">
                      We do not share your information with other parties
                      without your prior consent at any time.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center my-8 mx-0 lg:my-16 lg:mx-40">
            <div className="flex flex-col items-center mt-8">
              <Link to={`${location.pathname}/get-started`}>
                <Button>Get Started</Button>
              </Link>
            </div>
          </div>

          <div className="mt-16 border-t border-grey/10 pt-8 lg:px-16 sm:mt-20 md:flex md:flex-col md:items-center lg:mt-24">
            <p className="mt-8 text-xs leading-5 text-center text-gray-400 md:order-1 md:mt-0">
              These documents constitute, and contain information which
              constitutes, our intellectual property and confidential
              information. Any use, disclosure, copying, distribution or
              exploitation of our intellectual property or confidential
              information is strictly prohibited without our prior written
              consent. Nothing in these documents, including any information,
              advice, material or work constitutes legal, tax, financial,
              investment, merger, due diligence or risk management advice, and
              all content is for informational purposes only.
            </p>

            <p className="mt-8 text-sm  leading-5 text-gray-500 md:order-1 md:mt-4 text-center">
              &copy; {new Date().getFullYear()} Loam Bio Pty Ltd.
            </p>
          </div>
        </div>
      )}
    </>
  );
}
