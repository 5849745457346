import {
  Country,
  DataTypes,
  PartnershipPersonType,
  TrustPersonType,
  UnlistedCompanyPersonType,
} from "../constants";
import {
  Address,
  BusinessInfo,
  GrowerPEP,
  GrowerSanctionScreening,
  KYBConsent,
  PartnershipData,
  ToBeSavedData,
  TrustData,
  UnlistedCompanyData,
} from "../types";

interface StepDataValidation {
  stepname?: string;
}

function addressValid(address: Address) {
  if (!address) return false;
  if (
    !address.country ||
    (address.country === Country.Other && !address.otherCountry) ||
    !address.streetAddress ||
    !address.city ||
    !address.stateProvince ||
    !address.postalCode
  )
    return false;
  return true;
}

export default function QCChecker({ stepname }: StepDataValidation) {
  const data = localStorage.getItem("toBeSaved");
  const toBeSavedData = data ? (JSON.parse(data) as ToBeSavedData) : undefined;
  const numOfAttachments = parseInt(
    localStorage.getItem("NumOfAttachments") ?? "0"
  );

  if (!toBeSavedData) return;
  if (!toBeSavedData.data || !Object.keys(toBeSavedData.data).length) return;
  switch (stepname) {
    case "Consent": {
      const consentData =
        toBeSavedData.dataType === DataTypes.ConsentData
          ? (toBeSavedData.data as KYBConsent)
          : undefined;
      if (consentData) {
        if (
          !consentData.agreeConsent ||
          !consentData.consentSig ||
          !consentData.signedBy ||
          !consentData.signedBy
        ) {
          return false;
        }
        if (!consentData.signedBy.legalName || !consentData.signedBy.position)
          return false;
        return true;
      }
      return false;
    }
    case "Business Information": {
      const businessInfoData =
        toBeSavedData.dataType === DataTypes.BusinessInfoData
          ? (toBeSavedData.data as BusinessInfo)
          : undefined;
      if (businessInfoData) {
        if (
          !businessInfoData.legalName ||
          !businessInfoData.abnAcnType ||
          !businessInfoData.abnAcnNumber ||
          !businessInfoData.placeOfIncorporation ||
          (businessInfoData.placeOfIncorporation === Country.Other &&
            !businessInfoData.placeOfIncorporationOther) ||
          !businessInfoData.dateOfIncorporation ||
          !businessInfoData.natureOfBusiness ||
          !businessInfoData.principalOfficeAddress ||
          !businessInfoData.registeredOfficeAddress ||
          !businessInfoData.entityType ||
          (businessInfoData.entityType === "Other" &&
            !businessInfoData.otherEntityType) ||
          !addressValid(businessInfoData.principalOfficeAddress) ||
          !addressValid(businessInfoData.registeredOfficeAddress)
        ) {
          return false;
        }
        return true;
      }
      return false;
    }

    case "Mid Business Information": {
      const midBusinessInfoData =
        toBeSavedData.dataType === DataTypes.MidBusinessInfoData
          ? (toBeSavedData.data as BusinessInfo)
          : undefined;
      if (midBusinessInfoData) {
        if (
          !midBusinessInfoData.legalName ||
          !midBusinessInfoData.abnAcnType ||
          !midBusinessInfoData.abnAcnNumber ||
          !midBusinessInfoData.placeOfIncorporation ||
          (midBusinessInfoData.placeOfIncorporation === Country.Other &&
            !midBusinessInfoData.placeOfIncorporationOther)
        ) {
          return false;
        }
        return true;
      }
      return false;
    }
    case "Unlisted Company": {
      const unlistedCompanyData =
        toBeSavedData.dataType === DataTypes.UnlistedCompanyData
          ? (toBeSavedData.data as UnlistedCompanyData)
          : undefined;
      if (numOfAttachments < 1) return false;
      if (unlistedCompanyData) {
        if (
          !unlistedCompanyData.entityPeople ||
          unlistedCompanyData.entityPeople.length === 0
        ) {
          return false;
        }
        if (unlistedCompanyData.hasBeneficialOwners === undefined) {
          return false;
        }

        const directors = unlistedCompanyData.entityPeople.filter(
          (person) => person.personType === UnlistedCompanyPersonType.Director
        );

        if (directors.length < 1) {
          return false;
        }

        if (unlistedCompanyData.hasBeneficialOwners === false) {
          const controllers = unlistedCompanyData.entityPeople.filter(
            (person) =>
              person.personType === UnlistedCompanyPersonType.Controller
          );

          if (controllers.length < 1) {
            return false;
          }
        } else {
          const beneficiaries = unlistedCompanyData.entityPeople.filter(
            (person) =>
              person.personType === UnlistedCompanyPersonType.BeneficialOwner
          );

          if (beneficiaries.length < 1) {
            return false;
          }
        }

        return true;
      }
      return false;
    }

    case "Mid Unlisted Company": {
      const midUnlistedCompanyData =
        toBeSavedData.dataType === DataTypes.UnlistedCompanyData
          ? (toBeSavedData.data as UnlistedCompanyData)
          : undefined;

      if (numOfAttachments < 1) return false;
      if (!midUnlistedCompanyData) {
        return false;
      }

      if (midUnlistedCompanyData.hasBeneficialOwners === undefined) {
        return false;
      }
      if (midUnlistedCompanyData.hasBeneficialOwners) {
        if (
          !midUnlistedCompanyData.entityPeople ||
          midUnlistedCompanyData.entityPeople.length === 0
        ) {
          return false;
        }
        const unlistedCompanyBeneficiaries =
          midUnlistedCompanyData.entityPeople.filter(
            (person) =>
              person.personType === UnlistedCompanyPersonType.BeneficialOwner
          );
        if (unlistedCompanyBeneficiaries.length < 1) {
          return false;
        }
      }

      return true;
    }

    case "Trust": {
      const trustData =
        toBeSavedData.dataType === DataTypes.TrustData
          ? (toBeSavedData.data as TrustData)
          : undefined;

      if (numOfAttachments < 1) return false;
      if (!trustData) return false;
      if (!trustData.trustType || trustData.trustSettleSumOver10k === undefined)
        return false;
      if (trustData.trustType === "Other" && !trustData.trustTypeOther)
        return false;
      if (
        trustData.trustSettleSumOver10k === false &&
        trustData.trustSettlorDeceased === undefined
      )
        return false;
      if (!trustData.entityPeople || trustData.entityPeople.length < 1)
        return false;

      if (trustData.trustSettlorDeceased === false) {
        const settlor = trustData.entityPeople.filter(
          (person) => person.personType === TrustPersonType.Settlor
        );
        if (!settlor || settlor.length === 0) return false;
        const settlorPerson = settlor[0];
        if (!settlorPerson.legalName || !settlorPerson.email) return false;
      }

      const beneficiaries = trustData.entityPeople.filter(
        (person) => person.personType === TrustPersonType.Beneficiary
      );

      const trustees = trustData.entityPeople.filter(
        (person) => person.personType === TrustPersonType.Trustee
      );
      if (!beneficiaries || beneficiaries.length === 0) return false;
      if (!trustees || trustees.length === 0) return false;
      return true;
    }
    case "Partnership": {
      const partnershipData =
        toBeSavedData.dataType === DataTypes.PartnershipData
          ? (toBeSavedData.data as PartnershipData)
          : undefined;

      if (numOfAttachments < 1) return false;
      if (!partnershipData) return false;
      if (
        !partnershipData.entityPeople ||
        partnershipData.entityPeople.length === 0
      )
        return false;
      const partners = partnershipData.entityPeople.filter(
        (person) => person.personType === PartnershipPersonType.Partner
      );
      const beneficiaries = partnershipData.entityPeople.filter(
        (person) => person.personType === PartnershipPersonType.Beneficiary
      );
      if (!partners || partners.length === 0) return false;
      if (!beneficiaries || beneficiaries.length === 0) return false;
      return true;
    }

    case "PEP": {
      const pepData =
        toBeSavedData.dataType === DataTypes.GrowerPEP
          ? (toBeSavedData.data as GrowerPEP)
          : undefined;

      if (!pepData) return false;
      if (pepData.isPep === undefined) return false;
      if (
        pepData.isPep &&
        (!pepData.entityPeople || !pepData.entityPeople.length)
      )
        return false;
      return true;
    }

    case "Sanction Screening": {
      const sanctionScreeningData =
        toBeSavedData.dataType === DataTypes.GrowerSanctionScreening
          ? (toBeSavedData.data as GrowerSanctionScreening)
          : undefined;

      if (!sanctionScreeningData) return false;
      if (
        sanctionScreeningData.plannedBusinessActivities === undefined ||
        sanctionScreeningData.indirectRevenue === undefined
      )
        return false;
      if (
        (sanctionScreeningData.plannedBusinessActivities ||
          sanctionScreeningData.indirectRevenue) &&
        !sanctionScreeningData.sanctionDetails
      )
        return false;
      if (!sanctionScreeningData.agreedBy) return false;
      if (
        !sanctionScreeningData.agreedBy.legalName ||
        !sanctionScreeningData.agreedBy.position
      )
        return false;
      return true;
    }

    default:
      return false;
  }
}
