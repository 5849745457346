import { useContext, useEffect, useState } from "react";
import { DataTypes } from "../../constants";
import { GrowerSanctionScreening, ToBeSavedData } from "../../types";
import SelectInput from "../../components/select-input";
import TextArea from "../../components/text-area";
import Checkbox from "../../components/checkbox-input";
import useMe from "../../hooks/useMe";
import Loading from "../../components/loading";
import useSanctionDeclaration from "../../hooks/useSanction";
import { useParams } from "react-router-dom";
import { ComponentContext } from "../kyb-kyc";
import determineDisabled from "../../helpers/kyb-kyc/determine-disabled";
import capitalizeWords from "../../helpers/capitalize-words";

export default function SanctionDeclaration() {
  const [growerSanctionDeclaration, setGrowerSanctionDeclaration] = useState<
    Partial<GrowerSanctionScreening>
  >({});
  const me = useMe();
  const { kybId } = useParams();
  const { sanctionDeclaration } = useSanctionDeclaration(kybId);
  const { kybStatus } = useContext(ComponentContext);

  useEffect(() => {
    function handleChildSave() {
      const toBeSaveData: ToBeSavedData = {
        dataType: DataTypes.GrowerSanctionScreening,
        data: growerSanctionDeclaration,
      };
      localStorage.setItem("toBeSaved", JSON.stringify(toBeSaveData));
    }
    handleChildSave();
  }, [growerSanctionDeclaration]);

  useEffect(() => {
    if (!me || !sanctionDeclaration) return;
    if (sanctionDeclaration) {
      setGrowerSanctionDeclaration(sanctionDeclaration);
      return;
    } else {
      setGrowerSanctionDeclaration(
        (growerSanctionDeclaration: Partial<GrowerSanctionScreening>) => ({
          ...growerSanctionDeclaration,
          agreeSanction: growerSanctionDeclaration?.agreeSanction ?? false,
          agreedOn: new Date().toLocaleDateString(),
        })
      );
    }
  }, [me, sanctionDeclaration]);

  return !me || !kybStatus ? (
    <Loading />
  ) : (
    <div className="flex flex-col w-full flex-1 lg:p-4">
      <p className="mt-2 py-1 text-xs text-gray-600">
        Part of Loam’s requirement as a reporting entity is to screen new and
        existing customers against a sanctions list to identify any potential
        money launderer, criminal, and terrorist. This questionnaire will help
        us understand the level of due diligence we need to perform if our
        customers have any activities with any of the countries listed below.
      </p>
      <div className="col-span-full border-b border-gray-200 my-2 px-6" />
      <div className="mt-2">
        <SelectInput
          disabled={determineDisabled(kybStatus)}
          label="To the best of your knowledge, does the company or are any of the company’s Connected Parties, or Other Related Parties have current or planned business activities in any of the following jurisdictions?"
          value={
            growerSanctionDeclaration.plannedBusinessActivities === false
              ? 0
              : growerSanctionDeclaration.plannedBusinessActivities === true
              ? 1
              : ""
          }
          onChange={(e) => {
            setGrowerSanctionDeclaration({
              ...growerSanctionDeclaration,
              plannedBusinessActivities: !e.target.value
                ? undefined
                : e.target.value === "1"
                ? true
                : false,
            });
          }}
          error={
            growerSanctionDeclaration.plannedBusinessActivities === undefined
              ? 1
              : undefined
          }
        >
          <option value=""></option>
          <option value={1}>Yes</option>
          <option value={0}>No</option>
        </SelectInput>
      </div>
      <div className="mt-2 bg-gray-100 p-3 border border-gray-400 rounded-xl">
        <strong className="py-1 text-xs text-gray-500">
          Sanctioned Jurisdictions
        </strong>{" "}
        <ul className="list-disc list-inside py-1 text-xs text-gray-500">
          <li>Central African Republic and Democratic Republic of the Congo</li>
          <li>Democratic People's Republic of Korea (North Korea)</li>
          <li>Guinea-Bissau</li>
          <li>Iran</li>
          <li>Iraq</li>
          <li>Lebanon</li>
          <li>Libya</li>
          <li>Mali</li>
          <li>Myanmar</li>
          <li>Russia</li>
          <li>Somalia</li>
          <li>Sudan and South Sudan</li>
          <li>Syria</li>
          <li>Ukraine</li>
          <li>Yemen</li>
          <li>Zimbabwe</li>
        </ul>
      </div>
      <div className="col-span-full border-b border-gray-200 my-4 px-6" />
      <div className="mt-2">
        <SelectInput
          disabled={determineDisabled(kybStatus)}
          label="Do you receive any indirect revenue from/facilitate movement of funds/goods/services to any of the above sanctioned jurisdictions?"
          value={
            growerSanctionDeclaration?.indirectRevenue === false
              ? 0
              : growerSanctionDeclaration?.indirectRevenue === true
              ? 1
              : ""
          }
          onChange={(e) => {
            setGrowerSanctionDeclaration({
              ...growerSanctionDeclaration,
              indirectRevenue: !e.target.value
                ? undefined
                : e.target.value === "1"
                ? true
                : false,
            });
          }}
          error={
            growerSanctionDeclaration.indirectRevenue === undefined
              ? 1
              : undefined
          }
        >
          <option value=""></option>
          <option value={1}>Yes</option>
          <option value={0}>No</option>
        </SelectInput>
      </div>

      {growerSanctionDeclaration.plannedBusinessActivities ||
      growerSanctionDeclaration.indirectRevenue ? (
        <>
          <div className="col-span-full border-b border-gray-200 my-4 px-6" />

          <TextArea
            disabled={determineDisabled(kybStatus)}
            label="Please provide an overview below, including the sanctioned party’s name, location, sanctions regime that targets them, connection to the company,
      whether any sanctions license or legal advice is relied upon for continuing the connection, details of how transactions are made between the company and sanctioned party."
            value={growerSanctionDeclaration.sanctionDetails ?? ""}
            onChange={(e) => {
              setGrowerSanctionDeclaration({
                ...growerSanctionDeclaration,
                sanctionDetails: e.target.value,
              });
            }}
            error={
              (growerSanctionDeclaration.plannedBusinessActivities ||
                growerSanctionDeclaration.indirectRevenue) &&
              !growerSanctionDeclaration.sanctionDetails
                ? 1
                : undefined
            }
          />
        </>
      ) : null}

      <div className="col-span-full border-b border-gray-200 my-4 px-6" />

      <Checkbox
        disabled={determineDisabled(kybStatus)}
        checked={growerSanctionDeclaration.agreeSanction ?? false}
        value={growerSanctionDeclaration.agreeSanction ? 1 : 0}
        onChange={(e) => {
          setGrowerSanctionDeclaration({
            ...growerSanctionDeclaration,
            agreeSanction: e.target.checked,
          });
        }}
        label="I acknowledge that I have read and understood the above information. I also acknowledge that I have the authority to provide this declaration on behalf of the company."
      />

      <div className="mt-4 flex flex-col lg:flex-row justify-between lg:items-center">
        <div className="mt-4 flex flex-col items-start justify-start">
          <p className="inline-block text-sm font-medium leading-9 text-gray-700 mt-4 ">
            Full Legal Name:
            <span className="ml-2 inline-flex flex-col items-center">
              <span className="rounded-md shadow-sm ">
                <input
                  value={growerSanctionDeclaration.agreedBy?.legalName ?? ""}
                  onChange={(e) => {
                    setGrowerSanctionDeclaration({
                      ...growerSanctionDeclaration,
                      agreedBy: {
                        ...growerSanctionDeclaration.agreedBy,
                        legalName: e.target.value,
                      },
                    });
                  }}
                  className={`inline-block border-b-2 ${
                    !growerSanctionDeclaration.agreedBy?.legalName
                      ? "border-red-300"
                      : "border-gray-300"
                  } py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:border-b-2 focus:border-apple-500 text-base sm:leading-4`}
                />
              </span>
              {!growerSanctionDeclaration.agreedBy?.legalName ? (
                <span className="mt-0.5 text-xs text-red-600">
                  This field is required!
                </span>
              ) : null}
            </span>
          </p>
          <p className="inline-flex text-sm font-medium leading-9 text-gray-700 mt-4 ">
            Position at Company:
            <span className="ml-2 inline-flex flex-col items-center">
              <span className="rounded-md shadow-sm ">
                <input
                  value={growerSanctionDeclaration.agreedBy?.position ?? ""}
                  onChange={(e) => {
                    setGrowerSanctionDeclaration({
                      ...growerSanctionDeclaration,
                      agreedBy: {
                        ...growerSanctionDeclaration?.agreedBy,
                        position: capitalizeWords(e.target.value),
                      },
                    });
                  }}
                  className={`inline-block border-b-2 ${
                    !growerSanctionDeclaration.agreedBy?.position
                      ? "border-red-300"
                      : "border-gray-300"
                  } py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:border-b-2 focus:border-apple-500 text-base sm:leading-4`}
                />
              </span>
              {!growerSanctionDeclaration.agreedBy?.position ? (
                <span className="mt-0.5 text-xs text-red-600">
                  This field is required!
                </span>
              ) : null}
            </span>
          </p>
          <p className="inline-flex text-xs font-medium leading-9 text-gray-700 mt-2 ">
            Date:
            <span className="ml-2">
              <input
                disabled
                defaultValue={
                  growerSanctionDeclaration.agreedOn ??
                  new Date().toLocaleDateString()
                }
                className={`inline-block border-b-2 border-gray-400 py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:border-b-2 focus:border-apple-500 text-sm sm:leading-4`}
              />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
