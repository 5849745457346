export default function calculateExpectedEmissionUsingDateProportion(
  baselineEmission?: number,
  startDate?: string,
  endDate?: string
) {
  if (!baselineEmission || !startDate || !endDate) {
    return 0;
  }
  const totalDaysInYear = 365;
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Adjust for time zone differences
  start.setUTCHours(0, 0, 0, 0);
  end.setUTCHours(23, 59, 59, 999);

  const daysDifference = Math.round((+end - +start) / (24 * 60 * 60 * 1000));

  const percentageOfDateRange = daysDifference / totalDaysInYear;

  return (baselineEmission * percentageOfDateRange).toFixed(2);

  // Rest of the code...
}
