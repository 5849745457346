import { KYBStatus } from "../../constants";

export default function determineDisabled(status?: KYBStatus) {
  if (!status) return false;
  switch (status.toLowerCase()) {
    case KYBStatus.Incomplete.toLowerCase():
      return false;

    case KYBStatus.Pending.toLowerCase():
      return true;

    case KYBStatus.Open.toLowerCase():
      return false;

    default:
      return true;
  }
}
