import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Month, AnimalType, EmissionVariant } from "../constants";
import { convertBooleanToYesNo } from "../helpers/convertBooleanToYesNo";
import { formatMonthYear } from "../helpers/format-month-year";
import { GetObjectValue } from "../helpers/getObjectValue";
import Button from "./button";
import DescriptionItem from "./description-item";
import { ActivityInfo as ActivityInformation, FarmInfo } from "../types";

interface FarmInfoTabProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  editUrl: string;
  variant?: EmissionVariant;
}
export default function FarmInfoTab({
  open,
  setOpen,
  editUrl,
  variant,
}: FarmInfoTabProps) {
  const getFarmInfo = localStorage.getItem("FarmInfo");
  const getActivityInfo = localStorage.getItem("ActivityInfo");

  const farmInfo =
    getFarmInfo === "undefined" || getFarmInfo === null
      ? undefined
      : (JSON.parse(getFarmInfo) as FarmInfo);
  const activityInfo =
    getActivityInfo === "undefined" || getActivityInfo === null
      ? undefined
      : (JSON.parse(getActivityInfo) as ActivityInformation);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"
            onClick={() => setOpen(!open)}
          />
        </Transition.Child>
        <div className="fixed inset-0">
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-4xl">
                    <div className="flex h-full flex-col  bg-white shadow-xl pb-16">
                      <div className="p-6 sticky z-50">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Farm Information
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-apple-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="border-b border-gray-200" />
                      {!farmInfo || !activityInfo ? null : (
                        <div className="flex flex-col items-center p-6 w-full overflow-y-scroll">
                          {farmInfo ? (
                            <div className="w-full sm:px-6">
                              <div className="px-0 sm:px-0">
                                <h3 className="text-base sm:text-xl font-semibold leading-7 text-apple-700">
                                  Farm Details
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                                  A summary of all the information you have
                                  given us about your farm.
                                </p>
                              </div>
                              <div className="mt-6  border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">
                                  <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">
                                      Carbon Project Registration Date
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                      {
                                        Month[
                                          farmInfo.carbonRegMonth
                                            ? farmInfo.carbonRegMonth - 1
                                            : 0
                                        ]
                                      }{" "}
                                      {farmInfo.carbonRegYear}
                                    </dd>
                                  </div>
                                  <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">
                                      State
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                      {farmInfo.state}
                                    </dd>
                                  </div>
                                  {farmInfo.region ? (
                                    <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                      <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Salary expectation
                                      </dt>
                                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        $120,000
                                      </dd>
                                    </div>
                                  ) : null}
                                </dl>
                              </div>
                            </div>
                          ) : null}

                          <div className="w-full border-t mt-8 border-gray-100" />
                          {activityInfo ? (
                            <div className="w-full sm:px-6">
                              <div className="px-0 mt-6">
                                <h3 className="text-base sm:text-xl font-semibold leading-7 text-apple-700">
                                  Activity Information
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                                  A summary of all the activity information you
                                  have given us about your farm.
                                </p>
                              </div>
                              <div className="mt-6  border-t border-gray-100">
                                {variant === EmissionVariant.OnGoing && (
                                  <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <DescriptionItem
                                      title="CarbonBuilder Application"
                                      value={convertBooleanToYesNo(
                                        activityInfo.carbonBuilderProduct
                                      )}
                                    />
                                  </div>
                                )}
                                <dl className="divide-y divide-gray-100">
                                  {variant ===
                                  EmissionVariant.OnGoing ? null : (
                                    <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                      <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Activity Data Years
                                      </dt>
                                      {activityInfo.activityYears && (
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                          {activityInfo.activityYears
                                            .sort((a, b) => b - a)
                                            .map((year, i) => (
                                              <span
                                                key={i}
                                                className="inline-flex items-center rounded-md bg-apple-50 mr-4 px-2 py-1 text-xs font-medium text-apple-700 ring-1 ring-inset ring-apple-600/20"
                                              >
                                                {formatMonthYear(
                                                  farmInfo?.carbonRegMonth ?? 1,
                                                  year
                                                )}
                                              </span>
                                            ))}
                                        </dd>
                                      )}
                                    </div>
                                  )}
                                  <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <DescriptionItem
                                      title="Irrigation"
                                      value={convertBooleanToYesNo(
                                        activityInfo.irrigation
                                      )}
                                    />
                                  </div>
                                  <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">
                                      Grid Electricty
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                      {convertBooleanToYesNo(
                                        activityInfo.gridElectricity
                                      )}
                                    </dd>
                                  </div>
                                  <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <DescriptionItem
                                      title="Apply Dolomite or Lime"
                                      value={convertBooleanToYesNo(
                                        activityInfo.limeApplication
                                      )}
                                    />
                                  </div>
                                  <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <DescriptionItem
                                      title=" Synthetic Fertiliser"
                                      value={convertBooleanToYesNo(
                                        activityInfo.syntheticFertilizer
                                      )}
                                    />
                                  </div>
                                  <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <DescriptionItem
                                      title="Landscape Modification"
                                      value={convertBooleanToYesNo(
                                        activityInfo.soilLandscape
                                      )}
                                    />
                                  </div>
                                  <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <DescriptionItem
                                      title="Grow Crops"
                                      value={convertBooleanToYesNo(
                                        activityInfo.soilLandscape
                                      )}
                                    />
                                  </div>
                                  {activityInfo.growCrop ? (
                                    <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                      <DescriptionItem
                                        title="Crop tillage"
                                        value={convertBooleanToYesNo(
                                          activityInfo.tillCrop
                                        )}
                                      />
                                    </div>
                                  ) : null}
                                  <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <DescriptionItem
                                      title="Grow Cover Crops"
                                      value={convertBooleanToYesNo(
                                        activityInfo.coverCrop
                                      )}
                                    />
                                  </div>
                                  <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <DescriptionItem
                                      title="Pasture tillage"
                                      value={convertBooleanToYesNo(
                                        activityInfo.tillPasture
                                      )}
                                    />
                                  </div>
                                  <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <DescriptionItem
                                      title="Keep livestock"
                                      value={convertBooleanToYesNo(
                                        activityInfo.livestock
                                      )}
                                    />
                                  </div>
                                  {activityInfo.livestock ? (
                                    <>
                                      <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                        <DescriptionItem
                                          title="Livesock Type"
                                          value={activityInfo.animalType.map(
                                            (animal, i) => (
                                              <span
                                                key={i}
                                                className="inline-flex items-center rounded-md bg-apple-50 m-2 px-2 py-1 text-xs font-medium text-apple-700 ring-1 ring-inset ring-apple-600/20"
                                              >
                                                {GetObjectValue(
                                                  AnimalType,
                                                  animal as AnimalType
                                                )}
                                              </span>
                                            )
                                          )}
                                        />
                                      </div>
                                      {variant !== EmissionVariant.OnGoing && (
                                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                          <DescriptionItem
                                            title="Historical Livestock Stock "
                                            value={convertBooleanToYesNo(
                                              activityInfo.historicalStockRate
                                            )}
                                          />
                                        </div>
                                      )}
                                    </>
                                  ) : null}
                                </dl>
                              </div>
                            </div>
                          ) : null}
                          <Link
                            className="mt-6"
                            to={editUrl}
                            onClick={() => setOpen(!open)}
                          >
                            <Button>Edit Data</Button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
