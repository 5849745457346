import { AnimalType, AustralianState } from "../../../constants";
import calculationFunctions from "../../../helpers/baseline-emission/calculation-functions";
import { GetObjectValue } from "../../../helpers/getObjectValue";
import useEmissionData from "../../../hooks/useEmissionData";
import {
  BeefCattleData,
  EmissionData,
  DairyCattleData,
  LivestockTypeData,
  SheepData,
  SummaryEmissionData,
} from "../../../types";
import { formatMonthYear } from "../../../helpers/format-month-year";
import { useEffect, useMemo, useState } from "react";
import useFarmInfo from "../../../hooks/emission/useFarmInfo";
import useActivityInfo from "../../../hooks/emission/useActivityInfo";
import EmptyPlaceholder from "../../../components/empty-placeholder";
import useProjectCalculatedEmissionData from "../../../hooks/emission/useProjectCalculatedEmissionData";
import { useParams } from "react-router-dom";
import OverlayLoading from "../../../components/overlay-loading";
import { Chart } from "react-chartjs-2";
import Annotation from "chartjs-plugin-annotation";
import "chart.js/auto";
import { Chart as ChartType } from "chart.js";
import roundToThree from "../../../helpers/rounding";

ChartType.register(Annotation);

export default function Summary() {
  const emissionData = useEmissionData();
  const [projectBaselineData, setProjectBaselineData] =
    useState<EmissionData>();
  const farmInfo = useFarmInfo();
  const activityInfo = useActivityInfo();
  const [summaryEmissionData, setSummaryEmissionData] =
    useState<SummaryEmissionData>();
  const { projectId } = useParams();
  const {
    projectCalculatedEmissionData,
    loading: projectCalculatedEmissionDataLoading,
  } = useProjectCalculatedEmissionData(projectId);

  useEffect(() => {
    if (!emissionData) return;

    setProjectBaselineData(emissionData);
  }, [emissionData]);

  const emissionUnit = (
    <span className="font-bold">
      {" "}
      tonnes of{" "}
      <span>
        CO<sub>2</sub>-e
      </span>{" "}
      per year.
    </span>
  );

  function RenderAverageEmission(emissionNumber: number) {
    return (
      <tfoot className="divide-y divide-gray-200 bg-apple-50">
        <tr className="divide-x divide-gray-200">
          <td className="whitespace-wrap py-2 pl-2 pr-2 text-base font-bold text-gray-900 text-center px-8 ">
            Average Annual Emission
          </td>
          <td
            className="whitespace-nowrap py-4 pl-4 pr-4 sm:pr-0 text-center"
            colSpan={100}
          >
            <span className="text-base text-apple-800 font-extrabold">
              {roundToThree(emissionNumber)}
            </span>
            <span className="text-sm text-gray-700">{emissionUnit}</span>
          </td>
        </tr>
      </tfoot>
    );
  }

  const labels = useMemo(() => {
    return activityInfo.activityYears.sort((a, b) => b - a);
  }, [activityInfo.activityYears]);

  const averageAnnualEmission = useMemo(() => {
    if (!summaryEmissionData) return 0;
    return (
      (summaryEmissionData.irrigationEmission
        ? summaryEmissionData.irrigationEmission.reduce(
            (acc, data) =>
              acc + (data.electricityEmission || 0) + (data.fuelEmission || 0),
            0
          ) / 5
        : 0) +
      (summaryEmissionData.limingEmission
        ? summaryEmissionData.limingEmission.reduce(
            (acc, data) =>
              acc +
              (data.dolomiteEmission || 0) +
              (data.limestoneEmission || 0),
            0
          ) / 5
        : 0) +
      (summaryEmissionData.syntheticFertilizerEmission
        ? summaryEmissionData.syntheticFertilizerEmission.reduce(
            (acc, data) => acc + (data.emission || 0),
            0
          ) / 5
        : 0) +
      (summaryEmissionData.residueTillageAndLandscapeModificationEmission
        ? summaryEmissionData.residueTillageAndLandscapeModificationEmission.reduce(
            (acc, data) =>
              acc +
              (data.tillageAndSoilLandscapingEmission || 0) +
              (data.cropResidueEmission || 0) +
              (data.pastureTillageEmission || 0) +
              (data.coverCropEmission || 0),
            0
          ) / 5
        : 0) +
      (summaryEmissionData.livestockEmission
        ? summaryEmissionData.livestockEmission.reduce(
            (acc, data) =>
              acc +
              (data.animalEmisions?.reduce(
                (acc, data) => acc + (data.emission || 0),
                0
              ) || 0),
            0
          ) / 5
        : 0)
    );
  }, [summaryEmissionData]);

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Annual Emissions by Activity",
      },
      datalabels: {
        display: false,
      },
      annotation: {
        annotations: {
          line1: {
            yMin: averageAnnualEmission,
            yMax: averageAnnualEmission,
            borderColor: "#376b2a",
            borderWidth: 2,
            borderDash: [8, 8],
            label: {
              backgroundColor: "#4f9a3d",
              content: `Average of ${averageAnnualEmission.toFixed(
                3
              )} tonnes of CO2-e per year.`,
              position: "end",
              display: true,
            },
          },
        },
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      // xAxis: {

      // },
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Activity Year",
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "tonnes of CO2-e",
        },
      },
    },
  };

  useEffect(() => {
    if (!projectBaselineData) return;

    if (projectCalculatedEmissionData) {
      setSummaryEmissionData(projectCalculatedEmissionData);
      return;
    } else {
      const limingEmissionData =
        projectBaselineData.limingEnergy !== undefined &&
        projectBaselineData.limingEnergy.length > 0 &&
        activityInfo.activityYears.length > 0 &&
        activityInfo.limeApplication
          ? activityInfo.activityYears.map((data) => {
              const yearData = projectBaselineData.limingEnergy?.find(
                (ele) => ele.activityYear === data
              );
              if (!yearData) {
                return {
                  year: data,
                  dolomiteEmission: 0,
                  limestoneEmission: 0,
                };
              }
              return {
                year: data,
                dolomiteEmission:
                  calculationFunctions.calculateDolomiteEmission(
                    yearData.dolomiteQuantity || 0
                  ),
                limestoneEmission: calculationFunctions.calculateLimeEmission(
                  yearData.limestoneQuantity || 0
                ),
              };
            })
          : undefined;

      const irrigationEmissionData =
        projectBaselineData.irrigationEnergy !== undefined &&
        projectBaselineData.irrigationEnergy.length > 0 &&
        activityInfo.activityYears.length > 0 &&
        activityInfo.irrigation
          ? activityInfo.activityYears.map((data) => {
              const yearData = projectBaselineData.irrigationEnergy?.find(
                (ele) => ele.activityYear === data
              );
              if (!yearData) {
                return {
                  year: data,
                  fuelEmission: 0,
                  electricityEmission: 0,
                };
              }
              return {
                year: data,
                fuelEmission: calculationFunctions.calculateFuelEmission(
                  yearData.fuelQuantity || 0
                ),
                electricityEmission:
                  calculationFunctions.calculateElectricityEmission(
                    yearData.electrictyQuantity || 0,
                    activityInfo.gridElectricity ?? false
                  ),
              };
            })
          : undefined;

      const syntheticFertilizerEmissionData =
        projectBaselineData.syntheticFertilizer !== undefined &&
        projectBaselineData.syntheticFertilizer.length > 0 &&
        activityInfo.activityYears.length > 0 &&
        activityInfo.syntheticFertilizer
          ? activityInfo.activityYears.map((data) => {
              const yearData = projectBaselineData.syntheticFertilizer?.find(
                (ele) => ele.activityYear === data
              );
              if (!yearData) {
                return {
                  year: data,
                  emission: 0,
                };
              }
              return {
                year: data,
                emission: calculationFunctions.ureaEmission(
                  yearData.ferterlizerLocationData.map((location) => {
                    return calculationFunctions.calculateNitriousOxideEmission(
                      farmInfo.state as AustralianState,
                      location
                    );
                  }),
                  yearData.ureaQuantity || 0
                ),
              };
            })
          : undefined;

      const residueTillageAndLandscapeModificationEmissionData =
        projectBaselineData.cropInformation !== undefined &&
        projectBaselineData.cropInformation.length > 0 &&
        activityInfo.activityYears.length > 0 &&
        (activityInfo.soilLandscape ||
          activityInfo.tillCrop ||
          activityInfo.tillPasture ||
          activityInfo.coverCrop)
          ? activityInfo.activityYears.map((data) => {
              const yearCropInfoData =
                projectBaselineData.cropInformation?.find(
                  (ele) => ele.activityYear === data
                );
              const yearSoilLandscapeData =
                projectBaselineData.soilLandscape?.find(
                  (ele) => ele.activityYear === data
                );
              const yearCropTillageData = projectBaselineData.cropTillage?.find(
                (ele) => ele.activityYear === data
              );
              const yearPastureCropData = projectBaselineData.pastureCrop?.find(
                (ele) => ele.activityYear === data
              );
              const yearCoverCropData = projectBaselineData.coverCrop?.find(
                (ele) => ele.activityYear === data
              );
              if (
                !yearCoverCropData ||
                !yearCropInfoData ||
                !yearCropTillageData ||
                !yearPastureCropData ||
                !yearSoilLandscapeData
              ) {
                return {
                  year: data,
                  tillageAndSoilLandscapingEmission: 0,
                  cropResidueEmission: 0,
                  pastureTillageEmission: 0,
                  coverCropEmission: 0,
                };
              }
              return {
                year: data,
                tillageAndSoilLandscapingEmission:
                  calculationFunctions.calculateTillageAndSoilLandscapeEmission(
                    yearSoilLandscapeData,
                    yearCropTillageData
                  ),
                cropResidueEmission:
                  calculationFunctions.calculateAllCropResidueEmission(
                    yearCropInfoData
                  ),
                pastureTillageEmission:
                  calculationFunctions.calculateAllPastureCropEmission(
                    yearPastureCropData
                  ),
                coverCropEmission:
                  calculationFunctions.calculateAllCoverCropEmission(
                    yearCoverCropData
                  ),
              };
            })
          : undefined;

      const livestockEmissionData =
        projectBaselineData.livestock !== undefined &&
        projectBaselineData.livestock.length > 0 &&
        activityInfo.animalType.length > 0 &&
        activityInfo.activityYears.length > 0 &&
        activityInfo.historicalStockRate
          ? activityInfo.activityYears.map((data) => {
              const yearData = projectBaselineData.livestock?.find(
                (ele) => ele.activityYear === data
              );
              if (
                !yearData ||
                !yearData.livestockData ||
                !yearData.livestockData.length
              ) {
                return {
                  year: data,
                  animalEmisions: undefined,
                };
              }
              return {
                year: data,
                animalEmisions: activityInfo.animalType.map((animal) => {
                  const animalData = yearData?.livestockData?.find(
                    (ele) => ele.animalType === animal
                  );

                  if (!animalData)
                    return {
                      animal: animal as AnimalType,
                      emission: 0,
                    };

                  switch (animalData.animalType) {
                    case AnimalType.Sheep:
                      return {
                        animal: animalData.animalType as AnimalType,
                        emission: (animalData.animalData as SheepData[])
                          .map((data) =>
                            calculationFunctions.calculateSheepEmission(
                              data as SheepData,
                              farmInfo.state as AustralianState
                            )
                          )
                          .reduce((acc, data) => acc + data, 0),
                      };

                    case "BeefCattle" as AnimalType:
                      return {
                        animal: animalData.animalType as AnimalType,
                        emission: (animalData.animalData as BeefCattleData[])
                          .map((data) =>
                            calculationFunctions.calculateBeefCattleEmission(
                              data as BeefCattleData,
                              farmInfo.state as AustralianState,
                              farmInfo.region
                            )
                          )
                          .reduce((acc, data) => acc + data, 0),
                      };

                    case "DairyCattle" as AnimalType:
                      return {
                        animal: animalData.animalType as AnimalType,
                        emission: (animalData.animalData as DairyCattleData[])
                          .map((data) =>
                            calculationFunctions.calculateDairyCattleEmission(
                              data as DairyCattleData,
                              farmInfo.state as AustralianState
                            )
                          )
                          .reduce((acc, data) => acc + data, 0),
                      };

                    default:
                      return {
                        animal: animalData.animalType as AnimalType,
                        emission:
                          calculationFunctions.calculateGenericLivestockEmission(
                            animalData.animalData as LivestockTypeData,
                            animalData.animalType as AnimalType,
                            farmInfo.state as AustralianState
                          ),
                      };
                  }
                }),
              };
            })
          : undefined;

      setSummaryEmissionData({
        limingEmission: limingEmissionData,
        irrigationEmission: irrigationEmissionData,
        syntheticFertilizerEmission: syntheticFertilizerEmissionData,
        residueTillageAndLandscapeModificationEmission:
          residueTillageAndLandscapeModificationEmissionData,
        livestockEmission: livestockEmissionData,
      });
    }
  }, [
    projectBaselineData,
    farmInfo,
    activityInfo,
    projectCalculatedEmissionData,
  ]);

  useMemo(() => {
    function saveCalculatedEmissionData() {
      if (!summaryEmissionData) return;
      localStorage.setItem(
        "CalculatedEmissionData",
        JSON.stringify(summaryEmissionData)
      );
    }
    saveCalculatedEmissionData();
  }, [summaryEmissionData]);

  return (
    <div
      className="flex flex-col items-center w-full overflow-hidden"
      id="summary"
    >
      <OverlayLoading open={projectCalculatedEmissionDataLoading} />
      {!summaryEmissionData ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p>No Data</p>
        </div>
      ) : (
        <div className="w-full h-full">
          <div className="border-b border-gray-200 pb-4 lg:pb-8">
            <div className="flex-col">
              <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
                Emission Summary
              </h2>
            </div>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Please find below our calculations based on the data you entered.
            </p>
          </div>
          <div className="h-[500px]">
            {summaryEmissionData && (
              <Chart
                type="bar"
                options={options}
                data={{
                  labels: labels,
                  datasets: [
                    {
                      label: "Irrigation Emissions",
                      data: summaryEmissionData.irrigationEmission
                        ?.sort((a, b) => b.year - a.year)
                        .map(
                          (data) =>
                            (data.electricityEmission || 0) +
                            (data.fuelEmission || 0)
                        ),

                      backgroundColor: "#a8da9b",
                      order: 1,
                    },
                    {
                      label: "Liming Emissions",
                      data: summaryEmissionData.limingEmission
                        ?.sort((a, b) => b.year - a.year)
                        .map(
                          (data) =>
                            (data.dolomiteEmission || 0) +
                            (data.limestoneEmission || 0)
                        ),
                      backgroundColor: "#93befd",
                      order: 1,
                    },
                    {
                      label: "Synthetic Fertiliser Emissions",
                      data: summaryEmissionData.syntheticFertilizerEmission?.map(
                        (data) => data.emission || 0
                      ),
                      backgroundColor: "#fdd28a",
                      order: 1,
                    },
                    {
                      label:
                        "Residue, Tillage, and Soil Landscape Modification Emissions",
                      data: summaryEmissionData.residueTillageAndLandscapeModificationEmission
                        ?.sort((a, b) => b.year - a.year)
                        .map(
                          (data) =>
                            (data.tillageAndSoilLandscapingEmission || 0) +
                            (data.cropResidueEmission || 0) +
                            (data.pastureTillageEmission || 0) +
                            (data.coverCropEmission || 0)
                        ),
                      backgroundColor: "#fca5a5",
                      order: 1,
                    },
                    {
                      label: "Livestock Emissions",
                      data: summaryEmissionData.livestockEmission
                        ?.sort((a, b) => b.year - a.year)
                        .map(
                          (data) =>
                            data.animalEmisions?.reduce(
                              (acc, data) => acc + (data.emission || 0),
                              0
                            ) || 0
                        ),
                      backgroundColor: "#cdb5fd",
                      order: 1,
                    },
                  ],
                }}
              />
            )}
          </div>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-base font-medium leading-6 text-apple-700">
                Irrigation Emissions
              </label>
              {summaryEmissionData.irrigationEmission ? (
                <div className="mt-2 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr className="divide-x divide-gray-200">
                            <th
                              rowSpan={2}
                              className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                            >
                              Years
                            </th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                            >
                              Irrrigation Emissions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {summaryEmissionData.irrigationEmission
                            .sort((a, b) => b.year - a.year)
                            .map((data, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center px-8 ">
                                  {formatMonthYear(
                                    farmInfo.carbonRegMonth ?? 1,
                                    data.year
                                  )}
                                </td>

                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0 text-center">
                                  {roundToThree(
                                    (data.electricityEmission || 0) +
                                      (data.fuelEmission || 0)
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        {RenderAverageEmission(
                          summaryEmissionData.irrigationEmission.reduce(
                            (acc, data) =>
                              acc +
                              (data.fuelEmission || 0) +
                              (data.electricityEmission || 0),
                            0
                          ) / 5
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Irrigation Emissions not available because you have not
                  entered Irrigation data.
                </EmptyPlaceholder>
              )}
            </div>
          </div>
          <div className="col-span-full my-6 border-t border-t-gray-200" />

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-base font-medium leading-6 text-apple-700">
                Liming Emissions
              </label>
              {summaryEmissionData.limingEmission ? (
                <div className="mt-2 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                            >
                              Years
                            </th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                            >
                              Liming Emissions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {summaryEmissionData.limingEmission
                            .sort((a, b) => b.year - a.year)
                            .map((data, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center px-8 ">
                                  {formatMonthYear(
                                    farmInfo.carbonRegMonth ?? 1,
                                    data.year
                                  )}
                                </td>

                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0 text-center">
                                  {roundToThree(
                                    (data.dolomiteEmission ?? 0) +
                                      (data.limestoneEmission ?? 0)
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        {RenderAverageEmission(
                          summaryEmissionData.limingEmission.reduce(
                            (acc, data) =>
                              acc +
                              (data.dolomiteEmission || 0) +
                              (data.limestoneEmission || 0),
                            0
                          ) / 5
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Liming Emissions not available because you have not entered
                  Liming data.
                </EmptyPlaceholder>
              )}
            </div>
          </div>
          <div className="col-span-full my-6 border-t border-t-gray-200" />

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-base font-medium leading-6 text-apple-700">
                Synthetic Fertiliser Emissions
              </label>
              {summaryEmissionData.syntheticFertilizerEmission ? (
                <div className="mt-2 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                            >
                              Years
                            </th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                            >
                              Synthetic Fertiliser Emissions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {summaryEmissionData.syntheticFertilizerEmission
                            .sort((a, b) => b.year - a.year)
                            .map((data, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center px-8 ">
                                  {formatMonthYear(
                                    farmInfo.carbonRegMonth ?? 1,
                                    data.year
                                  )}
                                </td>

                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0 text-center">
                                  {roundToThree(data.emission || 0)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        {RenderAverageEmission(
                          summaryEmissionData.syntheticFertilizerEmission.reduce(
                            (acc, data) => acc + (data.emission || 0),

                            0
                          ) / 5
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Synthetic Fertiliser Emissions not available because you have
                  not entered Synthetic Fertiliser data.
                </EmptyPlaceholder>
              )}
            </div>
          </div>

          <div className="col-span-full my-6 border-t border-t-gray-200" />

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-base font-medium leading-6 text-apple-700">
                Residue, Tillage, and Soil Landscape Modification Emissions
              </label>

              {summaryEmissionData.residueTillageAndLandscapeModificationEmission ? (
                <div className="mt-2 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                            >
                              Years
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-3.5 text-sm font-semibold text-gray-900 text-center"
                            >
                              Tillage and Soil landscape modification Emmissions
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-3.5 text-sm font-semibold text-gray-900 text-center"
                            >
                              Crop Residue
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-3.5 text-sm font-semibold text-gray-900 text-center"
                            >
                              Pasture
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                            >
                              Cover Crop Residue
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {summaryEmissionData.residueTillageAndLandscapeModificationEmission.map(
                            (data, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center px-8 ">
                                  {formatMonthYear(
                                    farmInfo.carbonRegMonth ?? 1,
                                    data.year
                                  )}
                                </td>

                                <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-center">
                                  {roundToThree(
                                    data.tillageAndSoilLandscapingEmission || 0
                                  )}
                                </td>
                                <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-center">
                                  {roundToThree(data.cropResidueEmission || 0)}
                                </td>
                                <td className="whitespace-nowrap p-4 text-sm text-gray-500 text-center">
                                  {roundToThree(
                                    data.pastureTillageEmission || 0
                                  )}
                                </td>

                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0 text-center">
                                  {roundToThree(data.coverCropEmission || 0)}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                        {RenderAverageEmission(
                          summaryEmissionData.residueTillageAndLandscapeModificationEmission.reduce(
                            (acc, data) =>
                              acc +
                              (data.tillageAndSoilLandscapingEmission || 0) +
                              (data.cropResidueEmission || 0) +
                              (data.pastureTillageEmission || 0) +
                              (data.coverCropEmission || 0),
                            0
                          ) / 5
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Residue, Tillage, and Soil Landscape Modification Emissions
                  not available because you have not entered Tillage, Soil
                  Landscape Modification, or Residue data.
                </EmptyPlaceholder>
              )}
            </div>
          </div>

          <div className="col-span-full my-6 border-t border-t-gray-200" />

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-base font-medium leading-6 text-apple-700">
                Livestock Emissions
              </label>
              {summaryEmissionData.livestockEmission &&
              summaryEmissionData.livestockEmission.length ? (
                <div className="mt-2 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr className="divide-x  divide-gray-200">
                            <th
                              scope="col"
                              rowSpan={2}
                              className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                            >
                              Years
                            </th>

                            <th
                              colSpan={
                                summaryEmissionData.livestockEmission[0]
                                  .animalEmisions?.length
                              }
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                            >
                              Livestock Emissions
                            </th>
                          </tr>
                          <tr className="divide-x divide-gray-200 bg-neutral-50">
                            {summaryEmissionData.livestockEmission[0].animalEmisions?.map(
                              (data, i) => (
                                <th
                                  key={i}
                                  scope="col"
                                  className={
                                    i <
                                    (summaryEmissionData.livestockEmission?.[0]
                                      ?.animalEmisions?.length ?? 0) -
                                      2
                                      ? `px-4 py-3.5  text-sm font-semibold text-gray-900 text-center`
                                      : `py-3.5 pl-6 pr-8  text-sm font-semibold text-gray-900  text-center`
                                  }
                                >
                                  {GetObjectValue(
                                    AnimalType,
                                    data.animal as AnimalType
                                  )}
                                </th>
                              )
                            )}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {summaryEmissionData.livestockEmission
                            .sort((a, b) => b.year - a.year)
                            .map((data, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center px-8 ">
                                  {formatMonthYear(
                                    farmInfo.carbonRegMonth ?? 1,
                                    data.year
                                  )}
                                </td>

                                {data.animalEmisions?.map((data, i) => (
                                  <td
                                    key={i}
                                    className="whitespace-nowrap py-4 px-4 text-sm text-gray-500 text-center"
                                  >
                                    {roundToThree(data.emission || 0)}
                                  </td>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                        {RenderAverageEmission(
                          summaryEmissionData.livestockEmission.reduce(
                            (acc, data) =>
                              acc +
                              (data.animalEmisions?.reduce(
                                (acc, data) => acc + (data.emission ?? 0),
                                0
                              ) || 0),
                            0
                          ) / 5
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyPlaceholder>
                  Livestock Emissions not available because you have not entered
                  Livestock data.
                </EmptyPlaceholder>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
