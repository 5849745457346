import { RestrictedActivities } from "../../constants";
import {
  CarbonBuilderApplication,
  CoverCrop,
  CropInformation,
  CropTillage,
  IrrigationEnergy,
  LimingEnergy,
  PastureCrop,
  RestrictedActivity,
  SoilLandscape,
  StepValidationInterface,
  SyntheticFerterlizer,
} from "../../types";

interface CalculationStepValidation {
  stepname: string;
  data?:
    | CoverCrop
    | CropInformation
    | CropTillage
    | IrrigationEnergy
    | LimingEnergy
    | PastureCrop
    | SoilLandscape
    | SyntheticFerterlizer
    | RestrictedActivity
    | CarbonBuilderApplication;
}

export function stepValidation({
  stepname,
  data,
}: CalculationStepValidation): StepValidationInterface {
  switch (stepname) {
    case "Irrigation Energy": {
      const irrigationEnergy = data as IrrigationEnergy;
      const needsAttachments =
        (irrigationEnergy.electrictyQuantity !== undefined &&
          +irrigationEnergy.electrictyQuantity !== 0) ||
        (irrigationEnergy.fuelQuantity !== undefined &&
          +irrigationEnergy.fuelQuantity !== 0);
      const inputValid =
        !irrigationEnergy ||
        irrigationEnergy.electrictyQuantity === undefined ||
        irrigationEnergy.fuelQuantity === undefined
          ? false
          : true;

      return {
        needsAttachments: needsAttachments,
        inputValid: inputValid,
      };
    }

    case "Liming": {
      const limeApplication = data as LimingEnergy;
      const needsAttachments =
        (limeApplication.dolomiteQuantity !== undefined &&
          +limeApplication.dolomiteQuantity !== 0) ||
        (limeApplication.limestoneQuantity !== undefined &&
          +limeApplication.limestoneQuantity !== 0);

      const inputValid =
        !limeApplication ||
        limeApplication.dolomiteQuantity === undefined ||
        limeApplication.limestoneQuantity === undefined
          ? false
          : true;

      return {
        needsAttachments: needsAttachments,
        inputValid: inputValid,
      };
    }

    case "Synthetic Fertilizer": {
      const syntheticFertilizer = data as SyntheticFerterlizer;
      const needsAttachments =
        syntheticFertilizer.ferterlizerLocationData
          .flatMap((location) =>
            location.locationData
              ? Object.values(location.locationData).map(
                  (item) => item.quantityApplied ?? 0 > 0
                )
              : []
          )
          .filter(Boolean).length > 0;

      if (
        !syntheticFertilizer ||
        !syntheticFertilizer.ureaQuantity ||
        !syntheticFertilizer.ferterlizerLocationData.length
      )
        return {
          needsAttachments: needsAttachments,
          inputValid: false,
        };

      const validSyntheticFertilizer =
        syntheticFertilizer.ferterlizerLocationData
          .map((item) => {
            if (!item) return false;
            if (!item.location) return false;
            if (!item.locationData) return false;
            const t = Object.values(item.locationData).map((location) => {
              if (Object.values(location).length === 1) return false;
              return true;
            });

            if (t.filter((item) => item === false).length > 0) return false;

            return true;
          })
          .filter((item) => item === false).length === 0;

      return {
        needsAttachments: needsAttachments,
        inputValid: validSyntheticFertilizer,
      };
    }

    case "Crop Information": {
      const cropInformation = data as CropInformation;

      if (
        !cropInformation ||
        !cropInformation.cropInformationData ||
        !cropInformation.cropInformationData.length
      )
        return {
          needsAttachments: false,
          inputValid: false,
        };

      const needsAttachments =
        cropInformation.cropInformationData
          .flatMap((crop) =>
            crop.crop
              ? (crop.harvestSize ?? 0) > 0 || crop.burnResidue !== undefined
              : []
          )
          .filter(Boolean).length > 0;

      const validCropInformation =
        cropInformation.cropInformationData
          .map((item) => {
            if (!item) return false;
            if (!item.crop) return false;
            if (!item.harvestSize) return false;
            if (item.burnResidue === undefined) return false;
            return true;
          })
          .filter((item) => item === false).length === 0;
      return {
        needsAttachments: needsAttachments,
        inputValid: validCropInformation,
      };
    }

    case "Cover Crop": {
      const coverCrop = data as CoverCrop;

      if (
        !coverCrop ||
        !coverCrop.coverCropData ||
        !coverCrop.coverCropData.length
      )
        return {
          needsAttachments: false,
          inputValid: false,
        };

      const needsAttachments =
        coverCrop.coverCropData.map((item) => item.areaSown).filter(Boolean)
          .length > 0;

      const validCoverCrop =
        coverCrop.coverCropData
          .map((item) => {
            if (!item) return false;
            if (!item.areaSown) return false;
            return true;
          })
          .filter((item) => item === false).length === 0;
      return {
        needsAttachments: needsAttachments,
        inputValid: validCoverCrop,
      };
    }

    case "Pasture": {
      const pastureCrop = data as PastureCrop;

      if (
        !pastureCrop ||
        !pastureCrop.pastureCropData ||
        !pastureCrop.pastureCropData.length
      )
        return {
          needsAttachments: false,
          inputValid: false,
        };
      const needsAttachments =
        pastureCrop.pastureCropData
          .map((item) => item.areaTilled)
          .filter(Boolean).length > 0;

      const validPastureCrop =
        pastureCrop.pastureCropData
          .map((item) => {
            if (!item) return false;
            if (!item.areaTilled) return false;
            return true;
          })
          .filter((item) => item === false).length === 0;
      return {
        needsAttachments: needsAttachments,
        inputValid: validPastureCrop,
      };
    }

    case "Soil Landscape Modification": {
      const soilLandscapeData = data as SoilLandscape;
      const needsAttachments =
        soilLandscapeData.dieselQuantity !== undefined &&
        soilLandscapeData.dieselQuantity > 0;
      if (!soilLandscapeData)
        return {
          needsAttachments: needsAttachments,
          inputValid: false,
        };

      return {
        needsAttachments: needsAttachments,
        inputValid: soilLandscapeData.dieselQuantity !== undefined,
      };
    }

    case "Crop Tillage": {
      const cropTillage = data as CropTillage;
      const needsAttachments =
        cropTillage.areaTilled !== undefined && cropTillage.areaTilled > 0;
      if (!cropTillage)
        return {
          needsAttachments: needsAttachments,
          inputValid: false,
        };
      return {
        needsAttachments: needsAttachments,
        inputValid: cropTillage.areaTilled !== undefined,
      };
    }

    case "Restricted Activities": {
      const restrictedActivity = data as RestrictedActivity;
      if (!restrictedActivity)
        return {
          needsAttachments: false,
          inputValid: false,
        };

      if (
        !restrictedActivity.restrictedActivityData ||
        !restrictedActivity.restrictedActivityData.length
      )
        return {
          needsAttachments: false,
          inputValid: false,
        };

      const restrictedCheck =
        restrictedActivity.restrictedActivityData
          .map((item) => {
            if (!item) return false;
            if (!item.restrictedActivityType) return false;
            if (
              item.restrictedActivityType ===
              RestrictedActivities.IDidNotConductAnyRestrictedActivities
            )
              return true;
            else if (
              !item.restrictedActivityData ||
              !item.restrictedActivityData.length
            )
              return false;
            else {
              const interiorCheck = item.restrictedActivityData.map((item) => {
                if (!item) return false;
                if (!item.activityDate) return false;
                if (!item.activityReason) return false;
              });

              if (interiorCheck.filter((item) => item === false).length > 0)
                return false;
            }
          })
          .filter((item) => item === false).length === 0;

      return {
        needsAttachments: false,
        inputValid: restrictedCheck,
      };
    }

    case "Carbon Builder": {
      const carbonBuilderApplication = data as CarbonBuilderApplication;
      if (
        !carbonBuilderApplication ||
        !carbonBuilderApplication.carbonBuilderData ||
        !carbonBuilderApplication.carbonBuilderData.length
      )
        return {
          needsAttachments: false,
          inputValid: false,
        };

      const needsAttachments = true;

      const check =
        carbonBuilderApplication.carbonBuilderData
          .map((item) => {
            if (!item) return false;
            if (!item.carbonBuilderType) return false;
            if (!item.carbonBuilderTypeData) return false;
            const interiorCheck = item.carbonBuilderTypeData.map((item) => {
              if (!item) return false;
              if (!item.dateApplied) return false;
              if (!item.paddockName) return false;
              if (!item.volumeApplied) return false;
            });

            if (interiorCheck.filter((item) => item === false).length > 0)
              return false;
          })
          .filter((item) => item === false).length === 0;
      return {
        needsAttachments: needsAttachments,
        inputValid: check,
      };
    }
    default:
      return {
        needsAttachments: false,
        inputValid: true,
      };
  }
}
