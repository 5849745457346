// import Button from "../../components/button";

import { useContext, useEffect, useMemo, useState } from "react";
import OverlayLoading from "../../components/overlay-loading";
import { ComponentContext } from "../kyb-kyc";
import AttachmentsModals from "../../components/attachments";
import Loading from "../../components/loading";
import { Attachment, KybNode } from "../../types";
import { OtherAttachmentType } from "../../constants";

export default function OtherEntity() {
  const { nodeData, kybStatus } = useContext(ComponentContext);

  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [areyousure, setAreyousure] = useState(false);
  const [attachment, setAttachment] = useState<Attachment | undefined>(
    undefined
  );

  const [data, setData] = useState<KybNode>();
  useMemo(() => {
    if (!nodeData) return;
    setData(nodeData);
  }, [nodeData]);

  function mutateNodeAttachments(attachment: Attachment) {
    if (!data) return;
    setData({
      ...data,
      attachments: [...(data.attachments ?? []), attachment],
    });
  }

  function removeAttachment(id?: number) {
    if (!data) return;
    const attachments = (data.attachments ?? []).filter(a => a.id != id);
    setData({
      ...data,
      attachments,
    });
  }

  useEffect(() => {
    if (!data) return;
    localStorage.setItem(
      "NumOfAttachments",
      data.attachments?.length.toString() ?? "0"
    );
  }, [data]);

  return (
    <div className="w-full p-2 lg:p-4">
      {!data ? (
        <Loading />
      ) : (
        <div className="w-full">
          {<OverlayLoading open={attachmentLoading} />}
          <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-8">
            <div>
              <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
                Other Entity
              </h2>
              <p className="mt-1 text-xs text-gray-500">
                Sole trader explanation.
              </p>
            </div>
            {/*
        <div>
          <Button variant="outline"> Save Progress</Button>
        </div> */}
          </div>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              {data ? (
                <div className="w-full">
                  {AttachmentsModals({
                    nodeData: data,
                    openAttachmentModal,
                    setOpenAttachmentModal,
                    setAttachmentLoading,
                    areyousure,
                    setAreyousure,
                    attachment,
                    setAttachment,
                    mutateNodeAttachments,
                    removeAttachment,
                    AttachmentType: OtherAttachmentType,
                    kybStatus,
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
