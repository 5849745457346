import { Fragment, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CalculationSteps from "../../../helpers/ongoing-emission/calculation-steps";
import { Transition, Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import OverlayLoading from "../../../components/overlay-loading";
import useProjectCalculatedEmissionData from "../../../hooks/emission/useProjectCalculatedEmissionData";
import Button from "../../../components/button";
import { EmissionData, StepValidationInterface } from "../../../types";
import { api } from "../../../api";
import { FetchError } from "../../../lib/fetcher";
import { stepValidation } from "../../../helpers/ongoing-emission/input-checker";
import calculateEmissionData from "../../../helpers/ongoing-emission/calculate-emission-data";
import ErrorDialog from "../../../components/error-dialog";
import Divider from "../../../components/divider";
import ReportIssueSidebar from "../../../components/report-issue-sidebar";
import { ReportIssueVariant } from "../../../constants";

export default function Calculations() {
  const [currentStep, setCurrentStep] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const [showNeedHelp, setShowNeedHelp] = useState(false);
  const [errorType, setErrorType] = useState<{
    input: boolean;
    attachment: boolean;
  }>({
    input: true,
    attachment: false,
  });

  const { projectId, emissionActivityId } = useParams();
  const navigate = useNavigate();

  const {
    projectCalculatedEmissionData,
    loading: projectCalculatedEmissionDataLoading,
  } = useProjectCalculatedEmissionData(projectId, emissionActivityId);

  const Steps = CalculationSteps();

  const RenderComponent = useMemo(() => {
    if (Steps.length === 0) return <div>No Data</div>;
    return Steps[currentStep].component;
  }, [currentStep, Steps]);

  function AreYouSure() {
    return (
      <Transition.Root show={areYouSure} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setAreYouSure}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-950 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-5 w-5 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-700"
                      >
                        Attention!
                      </Dialog.Title>
                      <div className="mt-2 text-red-500">
                        <p className="text-sm">
                          Once you submit your Ongoing emission data, you will
                          not be able to edit it.
                        </p>
                        <p className="text-sm mt-3 font-semibold">
                          Are you sure you want to submit?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-apple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-apple-500 sm:ml-3 sm:w-auto"
                      onClick={onClickSubmit}
                    >
                      Yes, I want to submit
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-apple-600 shadow-sm ring-2 ring-inset ring-apple-600 hover:bg-apple-100 sm:mt-0 sm:w-auto"
                    >
                      Go Back
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  async function onClickSubmit() {
    setAreYouSure(false);
    const calculatedData = localStorage.getItem("CalculatedEmissionData");
    if (!calculatedData || !projectId || !emissionActivityId) return;

    const parsedData = calculateEmissionData();

    if (!parsedData) {
      setLoading(false);
      return;
    }

    const response = await api.postSummaryData(
      +projectId,
      +emissionActivityId,
      parsedData
    );

    if (response instanceof FetchError) {
      alert(response.error);
      setLoading(false);
      return;
    }

    setCurrentStep(currentStep + 1);
    setLoading(false);
  }

  function changePage(data: StepValidationInterface) {
    const numOfAttachments = +(localStorage.getItem("NumOfAttachments") || 0);

    if (!data.inputValid || (data.needsAttachments && numOfAttachments < 1)) {
      setErrorType({
        input: !data.inputValid,
        attachment: data.needsAttachments && numOfAttachments < 1,
      });
      setShowWarning(true);
      return;
    }

    onClickNext();
  }

  function onClickNext() {
    setLoading(true);
    if (currentStep === Steps.length - 1) {
      setLoading(false);
      setAreYouSure(true);
    } else {
      const element = document.getElementById("scrollToMe");
      element?.scrollIntoView();
      setCurrentStep(currentStep + 1);
      setLoading(false);
    }
  }

  async function onClickContinue() {
    setLoading(true);
    if (!projectId || !emissionActivityId) {
      return;
    }
    const data = localStorage.getItem("EmissionData");
    const parsedData: EmissionData | undefined = data
      ? JSON.parse(data)
      : undefined;

    switch (Steps[currentStep].title) {
      case "Irrigation Energy": {
        const irrigationEnergyData = parsedData?.irrigationEnergy;
        if (
          !irrigationEnergyData ||
          !irrigationEnergyData.length ||
          irrigationEnergyData.length < 1
        ) {
          setShowWarning(true);
          setLoading(false);
          return;
        }
        const response = await api.postIrrigationEnergy(
          +projectId,
          +emissionActivityId,
          irrigationEnergyData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          setLoading(false);
          return;
        }

        const isValid = stepValidation({
          stepname: Steps[currentStep].title,
          data: irrigationEnergyData[0],
        });

        changePage(isValid as StepValidationInterface);
        break;
      }

      case "Liming": {
        const limingData = parsedData?.limingEnergy;
        if (!limingData || !limingData.length || limingData.length < 1) {
          setLoading(false);
          setShowWarning(true);
          return;
        }
        const response = await api.postLimingEnergy(
          +projectId,
          +emissionActivityId,
          limingData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          setLoading(false);
          setLoading(false);
          return;
        }

        const isValid = stepValidation({
          stepname: Steps[currentStep].title,
          data: limingData[0],
        });

        changePage(isValid);
        break;
      }

      case "Soil Landscape Modification": {
        const soilLandscapeData = parsedData?.soilLandscape;
        if (
          !soilLandscapeData ||
          !soilLandscapeData.length ||
          soilLandscapeData.length < 1
        ) {
          setLoading(false);
          setShowWarning(true);
          return;
        }
        const response = await api.postSoilLandscape(
          +projectId,
          +emissionActivityId,
          soilLandscapeData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          setLoading(false);
          return;
        }

        const isValid = stepValidation({
          stepname: Steps[currentStep].title,
          data: soilLandscapeData[0],
        });

        changePage(isValid);
        break;
      }

      case "Crop Tillage": {
        const cropTillageData = parsedData?.cropTillage;
        if (
          !cropTillageData ||
          !cropTillageData.length ||
          cropTillageData.length < 1
        ) {
          setShowWarning(true);
          setLoading(false);
          return;
        }
        const response = await api.postCropTillage(
          +projectId,
          +emissionActivityId,
          cropTillageData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          setLoading(false);
          return;
        }

        const isValid = stepValidation({
          stepname: Steps[currentStep].title,
          data: cropTillageData[0],
        });

        changePage(isValid);
        break;
      }

      case "Crop Information": {
        const cropInfoData = parsedData?.cropInformation;
        if (!cropInfoData) {
          setShowWarning(true);
          setLoading(false);
          return;
        }

        // Save Data
        const response = await api.postCropInformation(
          +projectId,
          +emissionActivityId,
          cropInfoData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          setLoading(false);
          return;
        }
        const cropInfoValid = stepValidation({
          stepname: "Crop Information",
          data: cropInfoData[0],
        });

        changePage(cropInfoValid);

        break;
      }

      case "Cover Crop": {
        const coverCropData = parsedData?.coverCrop;
        if (!coverCropData) {
          setShowWarning(true);
          setLoading(false);
          return;
        }

        // Save Data
        const response = await api.postCoverCrop(
          +projectId,
          +emissionActivityId,
          coverCropData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          setLoading(false);

          return;
        }

        const coverCropValid = stepValidation({
          stepname: "Cover Crop",
          data: coverCropData[0],
        });

        changePage(coverCropValid);
        break;
      }

      case "Pasture": {
        const pastureCropData = parsedData?.pastureCrop;
        if (!pastureCropData) {
          setShowWarning(true);
          setLoading(false);
          return;
        }

        // Save Data
        const response = await api.postPastureCrop(
          +projectId,
          +emissionActivityId,
          pastureCropData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          setLoading(false);
          return;
        }

        const pastureCropValid = stepValidation({
          stepname: "Pasture",
          data: pastureCropData[0],
        });

        changePage(pastureCropValid);
        break;
      }

      case "Synthetic Fertiliser": {
        const syntheticFertilizerData = parsedData?.syntheticFertilizer;
        if (!syntheticFertilizerData) {
          setShowWarning(true);
          setLoading(false);
          return;
        }

        // Save Data
        const response = await api.postSyntheticFertilizer(
          +projectId,
          +emissionActivityId,
          syntheticFertilizerData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          setLoading(false);
          return;
        }

        const syntheticValid = stepValidation({
          stepname: "Synthetic Fertilizer",
          data: syntheticFertilizerData[0],
        });

        changePage(syntheticValid);
        break;
      }

      case "Livestock": {
        const livestockData = parsedData?.livestock;
        if (!livestockData) return;

        // Save Data
        const response = await api.postLivestock(
          +projectId,
          +emissionActivityId,
          livestockData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          setLoading(false);
          return;
        }

        onClickNext();
        break;
      }

      case "Carbon Builder": {
        const carbonBuilderData = parsedData?.carbonBuilderApplication;
        if (!carbonBuilderData) {
          setShowWarning(true);
          setLoading(false);
          return;
        }

        // Save Data
        const response = await api.postCarbonBuilderApplication(
          +projectId,
          +emissionActivityId,
          carbonBuilderData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          setLoading(false);
          return;
        }

        const carbonBuilderValid = stepValidation({
          stepname: "Carbon Builder",
          data: carbonBuilderData,
        });

        changePage(carbonBuilderValid);

        break;
      }

      case "Restricted Activities": {
        const restrictedActivitiesData = parsedData?.restrictedActivity;
        if (!restrictedActivitiesData) {
          setShowWarning(true);
          setLoading(false);
          return;
        }

        // Save Data
        const response = await api.postRestrictedActivity(
          +projectId,
          +emissionActivityId,
          restrictedActivitiesData
        );
        if (response instanceof FetchError) {
          alert(response.error);
          setLoading(false);
          return;
        }

        const restrictedActivitiesValid = stepValidation({
          stepname: "Restricted Activities",
          data: restrictedActivitiesData,
        });

        restrictedActivitiesValid.inputValid
          ? setAreYouSure(true)
          : setShowWarning(true);
        break;
      }

      default: {
        onClickNext();
        break;
      }
    }
  }

  function onClickPrevious() {
    setLoading(true);
    setCurrentStep(currentStep - 1);
    setLoading(false);
  }
  return (
    <div className="flex flex-1 flex-col">
      {
        <ReportIssueSidebar
          variant={ReportIssueVariant.EMISSION_CALCULATOR}
          showReportIssue={showNeedHelp}
          setShowReportIssue={setShowNeedHelp}
        />
      }
      <ErrorDialog
        showError={showWarning}
        setshowError={setShowWarning}
        errorMessage={
          errorType.input && errorType.attachment
            ? " Please fill all the fields and upload all the required attachments to continue. "
            : errorType.input
            ? "Please fill all the fields to continue."
            : "Please upload all the required attachments to continue."
        }
        onPressOk={() => {
          setShowWarning(false);
          setLoading(false);
        }}
      />
      <AreYouSure />
      <OverlayLoading open={loading || projectCalculatedEmissionDataLoading} />
      <div className="flex flex-col w-full ">
        {!projectCalculatedEmissionData ? (
          <div className="px-2 sm:px-4 lg:px-8 pt-4 sm:pt-4 lg:pt-8 flex flex-row justify-between w-full">
            {Steps.filter((step) => step.title !== "Summary").map(
              (step, index) => (
                <div
                  key={index}
                  // onClick={() => {
                  //   // if (index < Steps.length - 1) {
                  //   setCurrentStep(index);
                  //   // }
                  // }}
                  className={`w-full pb-2  ${
                    index < Steps.length - 1 ? "pr-1" : ""
                  }`}
                >
                  <div
                    className={`flex flex-col w-full rounded-full h-1.5 ${
                      currentStep === index ? "bg-apple-500" : "bg-gray-200"
                    }`}
                  >
                    <div className="hidden sm:flex text-xxs font-medium text-gray-600 sm:pt-3">
                      {step.title}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ) : null}
        <div className="flex flex-col flex-1 items-center mx-2 my-4 lg:my-8 lg:mx-8">
          {RenderComponent}
        </div>
        <div className="flex flex-col items-center mx-0 lg:mx-40 my-14">
          <div className="flex flex-col items-center ">
            <div className="flex flex-row w-full gap-x-4 justify-center items-center">
              {currentStep === 0 || currentStep === Steps.length - 1 ? null : (
                <Button
                  variant="outline"
                  onClick={() => {
                    onClickPrevious();
                  }}
                >
                  Previous
                </Button>
              )}

              {projectCalculatedEmissionData ? null : (
                <Button
                  onClick={() => {
                    currentStep === Steps.length - 1
                      ? navigate(`/projects/${projectId}/emission-calculator`)
                      : onClickContinue();
                  }}
                >
                  {Steps.length === currentStep + 2
                    ? "Submit"
                    : currentStep === Steps.length - 1
                    ? "Exit"
                    : "Continue"}
                </Button>
              )}
            </div>
          </div>
        </div>

        <Divider />
        <div className="mt-4 py-8 lg:px-16 sm:mt-4 md:flex md:flex-col md:items-center lg:mt-4">
          <div className="flex flex-row w-full items-center justify-center">
            <a
              href="https://www.loambio.com/privacy"
              target="_blank"
              className="text-xs font-medium text-apple-500 hover:text-apple-900"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <p className="text-xs font-medium text-gray-500 mx-2">|</p>
            <p
              className="text-xs font-medium text-apple-500 hover:text-apple-900 cursor-pointer"
              onClick={() => setShowNeedHelp(true)}
            >
              Need Help? Contact Us
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
