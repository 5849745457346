// import Button from "../../components/button";

import { useContext, useEffect, useMemo, useState } from "react";
import { SoleTraderData, ToBeSavedData } from "../../types";
import { ComponentContext } from "../kyb-kyc";
import { DataTypes } from "../../constants";
import TextInput from "../../components/text-input";
import emailChecker from "../../helpers/email-checker";
import Loading from "../../components/loading";
import determineDisabled from "../../helpers/kyb-kyc/determine-disabled";

export default function SoleTrader() {
  const { nodeData, kybStatus } = useContext(ComponentContext);

  const soleTrader = useMemo(() => {
    if (!nodeData || !nodeData.entityData) return undefined;
    return nodeData.entityData as SoleTraderData;
  }, [nodeData]);

  const [soleTraderData, setSoleTraderData] = useState<SoleTraderData>({});

  useEffect(() => {
    if (!soleTrader) return;
    setSoleTraderData(soleTrader);
  }, [soleTrader]);

  useEffect(() => {
    function handleSave() {
      if (!soleTraderData) return;
      const toBeSavedData: ToBeSavedData = {
        dataType: DataTypes.SoleTraderData,
        data: soleTraderData,
      };
      localStorage.setItem("toBeSaved", JSON.stringify(toBeSavedData));
    }
    handleSave();
  }, [soleTraderData]);

  return !kybStatus ? (
    <Loading />
  ) : (
    <div className="w-full p-2 lg:p-4">
      <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-8">
        <div>
          <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
            Sole Trader/Individual
          </h2>
          <p className="mt-1 text-xs text-gray-500">
            Please fill all the required information to proceed with your KYB
            application.
          </p>
        </div>
        {/*
        <div>
          <Button variant="outline"> Save Progress</Button>
        </div> */}
      </div>
      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 px-4">
        <div className="sm:col-span-6">
          <TextInput
            disabled={determineDisabled(kybStatus)}
            label="Full Legal Name"
            type="text"
            value={soleTraderData.entityPerson?.legalName ?? ""}
            onChange={(e) => {
              setSoleTraderData({
                ...soleTraderData,
                entityPerson: {
                  ...soleTraderData.entityPerson,
                  legalName: e.target.value,
                },
              });
            }}
            error={
              soleTraderData.entityPerson?.legalName === "" ||
              !soleTraderData.entityPerson?.legalName
                ? 1
                : undefined
            }
          />
        </div>

        <div className="sm:col-span-6">
          <TextInput
            disabled={determineDisabled(kybStatus)}
            label="Email"
            type="email"
            value={soleTraderData.entityPerson?.email ?? ""}
            onChange={(e) => {
              setSoleTraderData({
                ...soleTraderData,
                entityPerson: {
                  ...soleTraderData.entityPerson,
                  email: e.target.value,
                },
              });
            }}
            error={
              soleTraderData.entityPerson?.email === "" ||
              !soleTraderData.entityPerson?.email ||
              !emailChecker(soleTraderData.entityPerson?.email)
                ? 1
                : undefined
            }
            errormesage="Please enter a valid email!"
          />
        </div>
      </div>
    </div>
  );
}
