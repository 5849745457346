import {
  Routes,
  Route,
  useLocation,
  useParams,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Welcome from "./welcome";
import ActivityInfoPage from "../activity-info";
import Calaculations from "./calculations";
import Button from "../../../components/button";
import { useState } from "react";
import FarmInfoTab from "../../../components/farm-info-tab";
import OverlayLoading from "../../../components/overlay-loading";
import { api } from "../../../api";
import { FetchError } from "../../../lib/fetcher";
import useProjectCalculatedEmissionData from "../../../hooks/emission/useProjectCalculatedEmissionData";
import Finish from "../finish";
import { EmissionVariant } from "../../../constants";

export default function BaselineEmissionRouter() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { projectId, emissionActivityId } = useParams();
  const navigate = useNavigate();

  const currentLocation =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  const {
    projectCalculatedEmissionData,
    loading: projectCalculatedEmissionDataLoading,
  } = useProjectCalculatedEmissionData(projectId, emissionActivityId);

  function handleExit() {
    localStorage.clear();

    navigate(`/projects/${projectId}`);
    return;
  }

  async function handleSaveAndExit() {
    setLoading(true);

    const activityInfo = JSON.parse(
      localStorage.getItem("ActivityInfo") || "{}"
    );

    const emissionData = JSON.parse(
      localStorage.getItem("EmissionData") ?? "{}"
    );
    if (!projectId || !emissionActivityId) {
      setLoading(false);
      navigate("/projects");
    }

    if (emissionData && projectId && emissionActivityId && activityInfo) {
      const activityInfoResponse = await api.postActivityInfo(
        +projectId,
        +emissionActivityId,
        activityInfo
      );
      if (activityInfoResponse instanceof FetchError) {
        setLoading(false);
        alert(activityInfoResponse.message);
        return;
      }

      const response = await api.postBaslineData(
        +projectId,
        +emissionActivityId,
        emissionData
      );
      if (response instanceof FetchError) {
        setLoading(false);
        alert(response.message);
        return;
      }
    }
    setLoading(false);
    localStorage.clear();

    navigate(`/projects/${projectId}`);
    return;
  }

  return (
    <div className="flex flex-col w-full">
      <OverlayLoading open={loading || projectCalculatedEmissionDataLoading} />
      {projectCalculatedEmissionData ? null : (
        <FarmInfoTab
          open={open}
          setOpen={setOpen}
          editUrl={`/projects/${projectId}/emission-calculator/${emissionActivityId}/get-started`}
        />
      )}

      <main className="flex-1 h-4/5 ">
        <>
          {currentLocation === "calculations" ||
          currentLocation === "get-started" ? (
            <header className="bg-mineral-green-100 sticky h-16 top-0 z-50 flex flex-wrap w-full items-center justify-between px-2 py-3 sm:py-4 lg:py-5 border-b border-mineral-green-200 transition duration-500 sm:px-3 lg:px-4">
              <div className="flex flex-row justify-between items-center w-full">
                <div className="flex w-1/2">
                  <h1 className="text-lg sm:text-2xl font-semibold text-black">
                    Baseline Emission
                  </h1>
                </div>
                <div>
                  <div className="flex flex-row w-auto items-center justify-end">
                    <div className="flex flex-row gap-x-2 w-auto items-center justify-end">
                      {projectCalculatedEmissionData ? (
                        <Button
                          size="sm"
                          variant="black-outline"
                          onClick={handleExit}
                        >
                          Exit
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          variant="black-outline"
                          onClick={handleSaveAndExit}
                        >
                          Save & Exit
                        </Button>
                      )}

                      {currentLocation !== "calculations" ||
                      projectCalculatedEmissionData ? null : (
                        <Button
                          size="sm"
                          variant="black-outline"
                          onClick={() => setOpen(!open)}
                        >
                          Farm Info
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </header>
          ) : null}
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route
              path="/get-started"
              element={
                projectCalculatedEmissionData ? (
                  <Navigate
                    replace
                    to={`/projects/${projectId}/emission-calculator/${emissionActivityId}/calculations
                      `}
                  />
                ) : (
                  <ActivityInfoPage variant={EmissionVariant.Baseline} />
                )
              }
            />
            <Route path="/calculations" element={<Calaculations />} />
            <Route path="/finish" element={<Finish />} />
          </Routes>
        </>
      </main>
    </div>
  );
}
