import React from "react";
import EmptyImage from "../assets/empty.png";

export default function EmptyPage({ text }: { text: React.ReactNode }) {
  return (
    <div className="flex w-full flex-col py-12 sm:py-32 items-center">
      <img src={EmptyImage} alt="Empty" className="w-auto h-20 sm:h-40" />

      {text}
    </div>
  );
}
