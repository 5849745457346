import {
  AnimalType,
  BeefCattleType,
  CropsPlanted,
  DairyCattleType,
  PastureCrops,
  Region,
  SheepType,
  AustralianState,
} from "./constants";

export const limingFactors = {
  dolomite: {
    emissionFactor: 0.48,
    carbonateContent: 0.95,
  },
  limestone: {
    emissionFactor: 0.44,
    carbonateContent: 0.9,
  },
};

// from 2023 NGA Factors - Page 9
export const electrictyFactors = {
  off_grid: 0.61,
  on_grid: 0.69,
};

export const dieselFactors = {
  energyContentFactor: 38.6,
  emissionFactor: 70.2,
  emissionFactorMoving: 70.4,
};

export const ureaFactor = 0.7333;

export const tillageFuelQuantity = 0.012;

export const residueDecompositionFactor = 4.16;

export const nitrousOxideFactors = [
  {
    state: AustralianState.NewSouthWales,
    factors: {
      IrrigatedPasture: 2.902,
      IrrigatedCrop: 5.028,
      NonIrrigatedPasture: 1.338,
      NonIrrigatedCrop: 1.135,
      SugarCane: 10.286,
      Cotton: 3.567,
      HorticultureVegetables: 4.587,
    },
  },
  {
    state: AustralianState.NorthernTerritory,
    factors: {
      IrrigatedPasture: 2.902,
      IrrigatedCrop: 5.028,
      NonIrrigatedPasture: 1.862,
      NonIrrigatedCrop: 1.779,
      SugarCane: 0,
      Cotton: 0,
      HorticultureVegetables: 4.871,
    },
  },
  {
    state: AustralianState.Queensland,
    factors: {
      IrrigatedPasture: 2.902,
      IrrigatedCrop: 5.028,
      NonIrrigatedPasture: 1.111,
      NonIrrigatedCrop: 0.972,
      SugarCane: 9.92,
      Cotton: 3.326,
      HorticultureVegetables: 4.251,
    },
  },
  {
    state: AustralianState.SouthAustralia,
    factors: {
      IrrigatedPasture: 2.902,
      IrrigatedCrop: 5.028,
      NonIrrigatedPasture: 1.749,
      NonIrrigatedCrop: 1.232,
      SugarCane: 0,
      Cotton: 0,
      HorticultureVegetables: 4.662,
    },
  },
  {
    state: AustralianState.Tasmania,
    factors: {
      IrrigatedPasture: 2.902,
      IrrigatedCrop: 5.028,
      NonIrrigatedPasture: 2.06,
      NonIrrigatedCrop: 2.007,
      SugarCane: 0,
      Cotton: 0,
      HorticultureVegetables: 5.024,
    },
  },
  {
    state: AustralianState.Victoria,
    factors: {
      IrrigatedPasture: 2.902,
      IrrigatedCrop: 5.028,
      NonIrrigatedPasture: 1.911,
      NonIrrigatedCrop: 1.406,
      SugarCane: 0,
      Cotton: 0,
      HorticultureVegetables: 4.701,
    },
  },
  {
    state: AustralianState.WesternAustralia,
    factors: {
      IrrigatedPasture: 2.902,
      IrrigatedCrop: 5.028,
      NonIrrigatedPasture: 1.529,
      NonIrrigatedCrop: 1.169,
      SugarCane: 0,
      Cotton: 3.642,
      HorticultureVegetables: 4.931,
    },
  },
];

export const cropResidueFactors = [
  {
    cropType: CropsPlanted.Wheat,
    factors: {
      ncvab: 0.006,
      ncvbg: 0.01,
      dmv: 0.88,
      rcv: 1.5,
      bgabrrv: 0.29,
      frrbv: 0.5,
      rfv: 0,
      efv: 0.071,
    },
  },
  {
    cropType: CropsPlanted.Barley,
    factors: {
      ncvab: 0.007,
      ncvbg: 0.01,
      dmv: 0.88,
      rcv: 1.24,
      bgabrrv: 0.32,
      frrbv: 0.5,
      rfv: 0,
      efv: 0.074,
    },
  },
  {
    cropType: CropsPlanted.Maize,
    factors: {
      ncvab: 0.005,
      ncvbg: 0.007,
      dmv: 0.85,
      rcv: 0.81,
      bgabrrv: 0.39,
      frrbv: 1.0,
      rfv: 0,
      efv: 0.071,
    },
  },
  {
    cropType: CropsPlanted.Oats,
    factors: {
      ncvab: 0.006,
      ncvbg: 0.01,
      dmv: 0.88,
      rcv: 1.42,
      bgabrrv: 0.43,
      frrbv: 0.5,
      rfv: 0,
      efv: 0.071,
    },
  },
  {
    cropType: CropsPlanted.Rice,
    factors: {
      ncvab: 0.007,
      ncvbg: 0.01,
      dmv: 0.8,
      rcv: 1.31,
      bgabrrv: 0.16,
      frrbv: 1.0,
      rfv: 0,
      efv: 0.077,
    },
  },
  {
    cropType: CropsPlanted.Sorghum,
    factors: {
      ncvab: 0.008,
      ncvbg: 0.007,
      dmv: 0.8,
      rcv: 1.5,
      bgabrrv: 0.22,
      frrbv: 0.5,
      rfv: 0,
      efv: 0.078,
    },
  },
  {
    cropType: CropsPlanted.Triticale,
    factors: {
      ncvab: 0.006,
      ncvbg: 0.01,
      dmv: 0.88,
      rcv: 1.5,
      bgabrrv: 0.42,
      frrbv: 0.5,
      rfv: 0,
      efv: 0.071,
    },
  },
  {
    cropType: CropsPlanted.OtherCereals,
    factors: {
      ncvab: 0.006,
      ncvbg: 0.01,
      dmv: 0.88,
      rcv: 1.46,
      bgabrrv: 0.36,
      frrbv: 0.5,
      rfv: 0,
      efv: 0.071,
    },
  },
  {
    cropType: CropsPlanted.Pulses,
    factors: {
      ncvab: 0.009,
      ncvbg: 0.01,
      dmv: 0.87,
      rcv: 1.37,
      bgabrrv: 0.51,
      frrbv: 0.5,
      rfv: 0,
      efv: 0.081,
    },
  },
  {
    cropType: CropsPlanted.TuberAndRoots,
    factors: {
      ncvab: 0.002,
      ncvbg: 0.01,
      dmv: 0.25,
      rcv: 0.34,
      bgabrrv: 0.43,
      frrbv: 0,
      rfv: 0,
      efv: 0,
    },
  },
  {
    cropType: CropsPlanted.SugarCane,
    factors: {
      ncvab: 0.005,
      ncvbg: 0.007,
      dmv: 0.2,
      rcv: 0.25,
      bgabrrv: 0.45,
      frrbv: 1.0,
      rfv: 0,
      efv: 0.068,
    },
  },
  {
    cropType: CropsPlanted.Peanuts,
    factors: {
      ncvab: 0.016,
      ncvbg: 0.014,
      dmv: 0.8,
      rcv: 1.07,
      bgabrrv: 0.2,
      frrbv: 0.5,
      rfv: 0,
      efv: 0.106,
    },
  },
  {
    cropType: CropsPlanted.Cotton,
    factors: {
      ncvab: 0.01,
      ncvbg: 0.01,
      dmv: 0.9,
      rcv: 1.9,
      bgabrrv: 0.3,
      frrbv: 0,
      rfv: 0,
      efv: 0,
    },
  },
  {
    cropType: CropsPlanted.Hops,
    factors: {
      ncvab: 0.006,
      ncvbg: 0.01,
      dmv: 0.88,
      rcv: 1.5,
      bgabrrv: 0.29,
      frrbv: 0,
      rfv: 0,
      efv: 0,
    },
  },
  {
    cropType: CropsPlanted.Oilseeds,
    factors: {
      ncvab: 0.009,
      ncvbg: 0.01,
      dmv: 0.96,
      rcv: 2.08,
      bgabrrv: 0.33,
      frrbv: 0.5,
      rfv: 0,
      efv: 0.081,
    },
  },
  {
    cropType: CropsPlanted.ForageCrops,
    factors: {
      ncvab: 0.006,
      ncvbg: 0.01,
      dmv: 0.88,
      rcv: 1.34,
      bgabrrv: 0.37,
      frrbv: 0,
      rfv: 0,
      efv: 0,
    },
  },
];

export const coverCropFactors = [
  {
    coverCrop: PastureCrops.AnnualGrass,
    factors: {
      dm: 4.41,
      bgabrrv: 0.4,
      rfv: 0.8,
      ncab: 0.015,
      ncbg: 0.012,
    },
  },
  {
    coverCrop: PastureCrops.GrassCloverMixture,
    factors: {
      dm: 8.34,
      bgabrrv: 0.8,
      rfv: 0.8,
      ncab: 0.025,
      ncbg: 0.016,
    },
  },
  {
    coverCrop: PastureCrops.Lucerne,
    factors: {
      dm: 8.62,
      bgabrrv: 0.4,
      rfv: 0.8,
      ncab: 0.027,
      ncbg: 0.019,
    },
  },
  {
    coverCrop: PastureCrops.OtherLegumes,
    factors: {
      dm: 5.62,
      bgabrrv: 0.4,
      rfv: 0.8,
      ncab: 0.027,
      ncbg: 0.022,
    },
  },
  {
    coverCrop: PastureCrops.PerennialPasture,
    factors: {
      dm: 8.35,
      bgabrrv: 0.8,
      rfv: 0.8,
      ncab: 0.015,
      ncbg: 0.012,
    },
  },
  {
    coverCrop: PastureCrops.ForagedCrops,
    factors: {
      dm: 7.1,
      bgabrrv: 0.56,
      rfv: 0.8,
      ncab: 0.0218,
      ncbg: 0.00872,
    },
  },
  {
    coverCrop: PastureCrops.CoverCrops,
    factors: {
      dm: 7.1,
      bgabrrv: 0.56,
      rfv: 0,
      ncab: 0.0218,
      ncbg: 0.00872,
    },
  },
];

export const sheepEmissionFactors = [
  {
    state: AustralianState.NewSouthWales,
    stateFactors: [
      {
        sheepType: SheepType.Rams,
        factors: {
          spring: 1.03,
          summer: 0.88,
          autumn: 0.83,
          winter: 0.91,
        },
      },
      {
        sheepType: SheepType.Wethers,
        factors: {
          spring: 0.85,
          summer: 0.64,
          autumn: 0.66,
          winter: 0.73,
        },
      },
      {
        sheepType: SheepType.MaidenEwes,
        factors: {
          spring: 0.61,
          summer: 0.49,
          autumn: 0.52,
          winter: 0.6,
        },
      },
      {
        sheepType: SheepType.BreedingEwes,
        factors: {
          spring: 0.81,
          summer: 0.58,
          autumn: 0.63,
          winter: 0.71,
        },
      },
      {
        sheepType: SheepType.OtherEwes,
        factors: {
          spring: 0.77,
          summer: 0.59,
          autumn: 0.6,
          winter: 0.68,
        },
      },
      {
        sheepType: SheepType.LambsHoggets,
        factors: {
          spring: 0.32,
          summer: 0.34,
          autumn: 0.4,
          winter: 0.47,
        },
      },
    ],
  },
  {
    state: AustralianState.Queensland,
    stateFactors: [
      {
        sheepType: SheepType.Rams,
        factors: {
          spring: 0.58,
          summer: 0.65,
          autumn: 0.71,
          winter: 0.67,
        },
      },
      {
        sheepType: SheepType.Wethers,
        factors: {
          spring: 0.5,
          summer: 0.59,
          autumn: 0.62,
          winter: 0.56,
        },
      },
      {
        sheepType: SheepType.MaidenEwes,
        factors: {
          spring: 0.35,
          summer: 0.43,
          autumn: 0.46,
          winter: 0.4,
        },
      },
      {
        sheepType: SheepType.BreedingEwes,
        factors: {
          spring: 0.43,
          summer: 0.48,
          autumn: 0.55,
          winter: 0.47,
        },
      },
      {
        sheepType: SheepType.OtherEwes,
        factors: {
          spring: 0.45,
          summer: 0.53,
          autumn: 0.57,
          winter: 0.53,
        },
      },
      {
        sheepType: SheepType.LambsHoggets,
        factors: {
          spring: 0.22,
          summer: 0.29,
          autumn: 0.26,
          winter: 0.3,
        },
      },
    ],
  },
  {
    state: AustralianState.SouthAustralia,
    stateFactors: [
      {
        sheepType: SheepType.Rams,
        factors: {
          spring: 1.04,
          summer: 0.76,
          autumn: 0.49,
          winter: 0.78,
        },
      },
      {
        sheepType: SheepType.Wethers,
        factors: {
          spring: 0.91,
          summer: 0.7,
          autumn: 0.42,
          winter: 0.67,
        },
      },
      {
        sheepType: SheepType.MaidenEwes,
        factors: {
          spring: 0.68,
          summer: 0.55,
          autumn: 0.36,
          winter: 0.58,
        },
      },
      {
        sheepType: SheepType.BreedingEwes,
        factors: {
          spring: 0.74,
          summer: 0.59,
          autumn: 0.41,
          winter: 0.69,
        },
      },
      {
        sheepType: SheepType.OtherEwes,
        factors: {
          spring: 0.71,
          summer: 0.59,
          autumn: 0.38,
          winter: 0.61,
        },
      },
      {
        sheepType: SheepType.LambsHoggets,
        factors: {
          spring: 0.53,
          summer: 0.48,
          autumn: 0.17,
          winter: 0.36,
        },
      },
    ],
  },
  {
    state: AustralianState.Tasmania,
    stateFactors: [
      {
        sheepType: SheepType.Rams,
        factors: {
          spring: 1.24,
          summer: 1.01,
          autumn: 0.91,
          winter: 0.72,
        },
      },
      {
        sheepType: SheepType.Wethers,
        factors: {
          spring: 0.75,
          summer: 0.59,
          autumn: 0.61,
          winter: 0.44,
        },
      },
      {
        sheepType: SheepType.MaidenEwes,
        factors: {
          spring: 0.63,
          summer: 0.48,
          autumn: 0.55,
          winter: 0.48,
        },
      },
      {
        sheepType: SheepType.BreedingEwes,
        factors: {
          spring: 0.81,
          summer: 0.53,
          autumn: 0.63,
          winter: 0.57,
        },
      },
      {
        sheepType: SheepType.OtherEwes,
        factors: {
          spring: 0.69,
          summer: 0.53,
          autumn: 0.61,
          winter: 0.48,
        },
      },
      {
        sheepType: SheepType.LambsHoggets,
        factors: {
          spring: 0.25,
          summer: 0.28,
          autumn: 0.45,
          winter: 0.41,
        },
      },
    ],
  },
  {
    state: AustralianState.Victoria,
    stateFactors: [
      {
        sheepType: SheepType.Rams,
        factors: {
          spring: 0.91,
          summer: 0.7,
          autumn: 0.79,
          winter: 0.6,
        },
      },
      {
        sheepType: SheepType.Wethers,
        factors: {
          spring: 0.78,
          summer: 0.59,
          autumn: 0.64,
          winter: 0.5,
        },
      },
      {
        sheepType: SheepType.MaidenEwes,
        factors: {
          spring: 0.65,
          summer: 0.48,
          autumn: 0.53,
          winter: 0.41,
        },
      },
      {
        sheepType: SheepType.BreedingEwes,
        factors: {
          spring: 0.77,
          summer: 0.55,
          autumn: 0.63,
          winter: 0.49,
        },
      },
      {
        sheepType: SheepType.OtherEwes,
        factors: {
          spring: 0.65,
          summer: 0.53,
          autumn: 0.61,
          winter: 0.5,
        },
      },
      {
        sheepType: SheepType.LambsHoggets,
        factors: {
          spring: 0.32,
          summer: 0.31,
          autumn: 0.42,
          winter: 0.36,
        },
      },
    ],
  },
  {
    state: AustralianState.WesternAustralia,
    stateFactors: [
      {
        sheepType: SheepType.Rams,
        factors: {
          spring: 1.01,
          summer: 0.69,
          autumn: 0.42,
          winter: 0.85,
        },
      },
      {
        sheepType: SheepType.Wethers,
        factors: {
          spring: 0.8,
          summer: 0.58,
          autumn: 0.31,
          winter: 0.64,
        },
      },
      {
        sheepType: SheepType.MaidenEwes,
        factors: {
          spring: 0.67,
          summer: 0.48,
          autumn: 0.35,
          winter: 0.6,
        },
      },
      {
        sheepType: SheepType.BreedingEwes,
        factors: {
          spring: 0.76,
          summer: 0.54,
          autumn: 0.4,
          winter: 0.75,
        },
      },
      {
        sheepType: SheepType.OtherEwes,
        factors: {
          spring: 0.74,
          summer: 0.53,
          autumn: 0.29,
          winter: 0.66,
        },
      },
      {
        sheepType: SheepType.LambsHoggets,
        factors: {
          spring: 0.43,
          summer: 0.33,
          autumn: 0.13,
          winter: 0.31,
        },
      },
    ],
  },
];

export const beefCattleEmissionFactors = [
  {
    state: AustralianState.NorthernTerritory,
    stateFactors: [
      {
        beefCattleType: BeefCattleType.BullsYoungerThan1,
        factors: {
          spring: 3.152,
          summer: 2.358,
          autumn: 2.866,
          winter: 3.008,
        },
      },
      {
        beefCattleType: BeefCattleType.BullsOlderThan1,
        factors: {
          spring: 5.762,
          summer: 6.587,
          autumn: 6.563,
          winter: 6.095,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersYoungerThan1,
        factors: {
          spring: 3.136,
          summer: 2.402,
          autumn: 3.014,
          winter: 3.111,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersOlderThan1,
        factors: {
          spring: 3.885,
          summer: 3.572,
          autumn: 3.982,
          winter: 3.994,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsYoungerThan1,
        factors: {
          spring: 2.793,
          summer: 2.39,
          autumn: 2.986,
          winter: 2.993,
        },
      },
      {
        beefCattleType: BeefCattleType.Cows1To2,
        factors: {
          spring: 3.83,
          summer: 3.302,
          autumn: 3.668,
          winter: 3.832,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsOlderThan2,
        factors: {
          spring: 4.645,
          summer: 4.852,
          autumn: 4.785,
          winter: 4.722,
        },
      },
    ],
  },
  {
    state: AustralianState.NewSouthWales,
    stateFactors: [
      {
        beefCattleType: BeefCattleType.BullsYoungerThan1,
        factors: {
          spring: 1.766,
          summer: 2.945,
          autumn: 3.473,
          winter: 3.527,
        },
      },
      {
        beefCattleType: BeefCattleType.BullsOlderThan1,
        factors: {
          spring: 4.952,
          summer: 5.379,
          autumn: 5.496,
          winter: 5.402,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersYoungerThan1,
        factors: {
          spring: 1.723,
          summer: 2.765,
          autumn: 3.191,
          winter: 3.343,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersOlderThan1,
        factors: {
          spring: 4.383,
          summer: 4.646,
          autumn: 4.806,
          winter: 4.731,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsYoungerThan1,
        factors: {
          spring: 1.723,
          summer: 2.765,
          autumn: 3.191,
          winter: 3.343,
        },
      },
      {
        beefCattleType: BeefCattleType.Cows1To2,
        factors: {
          spring: 3.683,
          summer: 4.459,
          autumn: 4.332,
          winter: 4.453,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsOlderThan2,
        factors: {
          spring: 5.962,
          summer: 5.332,
          autumn: 4.987,
          winter: 5.016,
        },
      },
    ],
  },
  {
    state: AustralianState.Queensland,
    stateFactors: [
      {
        beefCattleType: BeefCattleType.BullsYoungerThan1,
        factors: {
          spring: 2.946,
          summer: 2.118,
          autumn: 2.515,
          winter: 2.967,
        },
      },
      {
        beefCattleType: BeefCattleType.BullsOlderThan1,
        factors: {
          spring: 5.811,
          summer: 6.12,
          autumn: 6.135,
          winter: 6.006,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersYoungerThan1,
        factors: {
          spring: 2.94,
          summer: 2.36,
          autumn: 2.847,
          winter: 2.774,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersOlderThan1,
        factors: {
          spring: 5.02,
          summer: 4.461,
          autumn: 4.466,
          winter: 4.655,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsYoungerThan1,
        factors: {
          spring: 2.825,
          summer: 2.189,
          autumn: 2.562,
          winter: 2.619,
        },
      },
      {
        beefCattleType: BeefCattleType.Cows1To2,
        factors: {
          spring: 3.719,
          summer: 3.494,
          autumn: 3.655,
          winter: 3.734,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsOlderThan2,
        factors: {
          spring: 4.4,
          summer: 5.467,
          autumn: 4.8,
          winter: 4.332,
        },
      },
    ],
  },
  {
    state: AustralianState.SouthAustralia,
    stateFactors: [
      {
        beefCattleType: BeefCattleType.BullsYoungerThan1,
        factors: {
          spring: 3.695,
          summer: 4.231,
          autumn: 2.04,
          winter: 2.709,
        },
      },
      {
        beefCattleType: BeefCattleType.BullsOlderThan1,
        factors: {
          spring: 7.421,
          summer: 6.155,
          autumn: 4.656,
          winter: 5.74,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersYoungerThan1,
        factors: {
          spring: 3.4,
          summer: 3.841,
          autumn: 1.924,
          winter: 2.567,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersOlderThan1,
        factors: {
          spring: 4.425,
          summer: 4.293,
          autumn: 3.918,
          winter: 4.02,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsYoungerThan1,
        factors: {
          spring: 3.304,
          summer: 3.747,
          autumn: 1.811,
          winter: 2.436,
        },
      },
      {
        beefCattleType: BeefCattleType.Cows1To2,
        factors: {
          spring: 4.61,
          summer: 4.521,
          autumn: 3.514,
          winter: 4.157,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsOlderThan2,
        factors: {
          spring: 5.375,
          summer: 4.871,
          autumn: 4.975,
          winter: 4.766,
        },
      },
    ],
  },
  {
    state: AustralianState.Tasmania,
    stateFactors: [
      {
        beefCattleType: BeefCattleType.BullsYoungerThan1,
        factors: {
          spring: 2.283,
          summer: 5.619,
          autumn: 3.49,
          winter: 2.976,
        },
      },
      {
        beefCattleType: BeefCattleType.BullsOlderThan1,
        factors: {
          spring: 6.34,
          summer: 6.697,
          autumn: 6.469,
          winter: 5.428,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersYoungerThan1,
        factors: {
          spring: 2.143,
          summer: 2.678,
          autumn: 3.013,
          winter: 2.76,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersOlderThan1,
        factors: {
          spring: 5.138,
          summer: 5.101,
          autumn: 5.058,
          winter: 4.305,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsYoungerThan1,
        factors: {
          spring: 2.097,
          summer: 2.536,
          autumn: 2.833,
          winter: 2.549,
        },
      },
      {
        beefCattleType: BeefCattleType.Cows1To2,
        factors: {
          spring: 4.154,
          summer: 4.248,
          autumn: 3.839,
          winter: 4.079,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsOlderThan2,
        factors: {
          spring: 6.665,
          summer: 6.045,
          autumn: 4.421,
          winter: 4.019,
        },
      },
    ],
  },
  {
    state: AustralianState.Victoria,
    stateFactors: [
      {
        beefCattleType: BeefCattleType.BullsYoungerThan1,
        factors: {
          spring: 3.744,
          summer: 3.437,
          autumn: 1.927,
          winter: 2.357,
        },
      },
      {
        beefCattleType: BeefCattleType.BullsOlderThan1,
        factors: {
          spring: 7.34,
          summer: 6.618,
          autumn: 6.107,
          winter: 6.056,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersYoungerThan1,
        factors: {
          spring: 3.647,
          summer: 3.347,
          autumn: 1.917,
          winter: 2.223,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersOlderThan1,
        factors: {
          spring: 5.616,
          summer: 5.126,
          autumn: 4.39,
          winter: 4.653,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsYoungerThan1,
        factors: {
          spring: 3.647,
          summer: 3.159,
          autumn: 1.917,
          winter: 2.223,
        },
      },
      {
        beefCattleType: BeefCattleType.Cows1To2,
        factors: {
          spring: 5.096,
          summer: 4.796,
          autumn: 3.695,
          winter: 3.579,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsOlderThan2,
        factors: {
          spring: 6.206,
          summer: 5.078,
          autumn: 5.989,
          winter: 5.191,
        },
      },
    ],
  },
  {
    state: AustralianState.WesternAustralia,
    region: Region.SouthWest,
    stateFactors: [
      {
        beefCattleType: BeefCattleType.BullsYoungerThan1,
        factors: {
          spring: 5.178,
          summer: 4.406,
          autumn: 2.025,
          winter: 3.088,
        },
      },
      {
        beefCattleType: BeefCattleType.BullsOlderThan1,
        factors: {
          spring: 7.313,
          summer: 5.829,
          autumn: 5.876,
          winter: 6.0,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersYoungerThan1,
        factors: {
          spring: 4.55,
          summer: 4.061,
          autumn: 2.025,
          winter: 2.714,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersOlderThan1,
        factors: {
          spring: 5.782,
          summer: 4.527,
          autumn: 3.676,
          winter: 4.132,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsYoungerThan1,
        factors: {
          spring: 3.947,
          summer: 3.705,
          autumn: 1.846,
          winter: 2.528,
        },
      },
      {
        beefCattleType: BeefCattleType.Cows1To2,
        factors: {
          spring: 5.179,
          summer: 4.852,
          autumn: 3.715,
          winter: 3.566,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsOlderThan2,
        factors: {
          spring: 5.751,
          summer: 4.804,
          autumn: 5.137,
          winter: 5.145,
        },
      },
    ],
  },
  {
    state: AustralianState.WesternAustralia,
    region: Region.Pilbara,
    stateFactors: [
      {
        beefCattleType: BeefCattleType.BullsYoungerThan1,
        factors: {
          spring: 2.403,
          summer: 2.934,
          autumn: 4.107,
          winter: 3.797,
        },
      },
      {
        beefCattleType: BeefCattleType.BullsOlderThan1,
        factors: {
          spring: 5.003,
          summer: 6.192,
          autumn: 6.893,
          winter: 5.304,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersYoungerThan1,
        factors: {
          spring: 2.403,
          summer: 2.934,
          autumn: 4.107,
          winter: 3.797,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersOlderThan1,
        factors: {
          spring: 4.658,
          summer: 5.047,
          autumn: 5.346,
          winter: 4.62,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsYoungerThan1,
        factors: {
          spring: 2.29,
          summer: 2.826,
          autumn: 3.991,
          winter: 3.687,
        },
      },
      {
        beefCattleType: BeefCattleType.Cows1To2,
        factors: {
          spring: 3.994,
          summer: 4.541,
          autumn: 4.463,
          winter: 4.644,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsOlderThan2,
        factors: {
          spring: 5.341,
          summer: 5.295,
          autumn: 4.963,
          winter: 4.459,
        },
      },
    ],
  },
  {
    state: AustralianState.WesternAustralia,
    region: Region.Kimberley,
    stateFactors: [
      {
        beefCattleType: BeefCattleType.BullsYoungerThan1,
        factors: {
          spring: 3.542,
          summer: 2.533,
          autumn: 3.196,
          winter: 3.354,
        },
      },
      {
        beefCattleType: BeefCattleType.BullsOlderThan1,
        factors: {
          spring: 5.421,
          summer: 6.574,
          autumn: 7.245,
          winter: 5.675,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersYoungerThan1,
        factors: {
          spring: 3.429,
          summer: 2.428,
          autumn: 3.084,
          winter: 3.242,
        },
      },
      {
        beefCattleType: BeefCattleType.SteersOlderThan1,
        factors: {
          spring: 3.858,
          summer: 5.216,
          autumn: 5.718,
          winter: 4.714,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsYoungerThan1,
        factors: {
          spring: 3.2,
          summer: 2.244,
          autumn: 2.763,
          winter: 2.599,
        },
      },
      {
        beefCattleType: BeefCattleType.Cows1To2,
        factors: {
          spring: 4.442,
          summer: 3.368,
          autumn: 4.197,
          winter: 4.005,
        },
      },
      {
        beefCattleType: BeefCattleType.CowsOlderThan2,
        factors: {
          spring: 4.025,
          summer: 6.44,
          autumn: 5.3,
          winter: 4.111,
        },
      },
    ],
  },
];

export const dairyCattleEmissionFactors = [
  {
    state: AustralianState.NewSouthWales,
    stateFactors: [
      {
        dairyCattleType: DairyCattleType.MilkingCows,
        factor: 12.724,
      },
      {
        dairyCattleType: DairyCattleType.HeifersOlderThan1,
        factor: 4.307,
      },
      {
        dairyCattleType: DairyCattleType.HeifersYoungerThan1,
        factor: 2.113,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsOlderThan1,
        factor: 5.342,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsYoungerThan1,
        factor: 2.601,
      },
    ],
  },
  {
    state: AustralianState.NorthernTerritory,
    stateFactors: [
      {
        dairyCattleType: DairyCattleType.MilkingCows,
        factor: 10.709,
      },
      {
        dairyCattleType: DairyCattleType.HeifersOlderThan1,
        factor: 4.396,
      },
      {
        dairyCattleType: DairyCattleType.HeifersYoungerThan1,
        factor: 2.157,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsOlderThan1,
        factor: 5.452,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsYoungerThan1,
        factor: 2.655,
      },
    ],
  },

  {
    state: AustralianState.Queensland,
    stateFactors: [
      {
        dairyCattleType: DairyCattleType.MilkingCows,
        factor: 10.354,
      },
      {
        dairyCattleType: DairyCattleType.HeifersOlderThan1,
        factor: 4.307,
      },
      {
        dairyCattleType: DairyCattleType.HeifersYoungerThan1,
        factor: 2.113,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsOlderThan1,
        factor: 5.342,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsYoungerThan1,
        factor: 2.601,
      },
    ],
  },
  {
    state: AustralianState.SouthAustralia,
    stateFactors: [
      {
        dairyCattleType: DairyCattleType.MilkingCows,
        factor: 12.69,
      },
      {
        dairyCattleType: DairyCattleType.HeifersOlderThan1,
        factor: 4.307,
      },
      {
        dairyCattleType: DairyCattleType.HeifersYoungerThan1,
        factor: 2.113,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsOlderThan1,
        factor: 5.342,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsYoungerThan1,
        factor: 2.601,
      },
    ],
  },
  {
    state: AustralianState.Tasmania,
    stateFactors: [
      {
        dairyCattleType: DairyCattleType.MilkingCows,
        factor: 10.644,
      },
      {
        dairyCattleType: DairyCattleType.HeifersOlderThan1,
        factor: 4.307,
      },
      {
        dairyCattleType: DairyCattleType.HeifersYoungerThan1,
        factor: 2.113,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsOlderThan1,
        factor: 5.342,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsYoungerThan1,
        factor: 2.601,
      },
    ],
  },
  {
    state: AustralianState.Victoria,
    stateFactors: [
      {
        dairyCattleType: DairyCattleType.MilkingCows,
        factor: 11.598,
      },
      {
        dairyCattleType: DairyCattleType.HeifersOlderThan1,
        factor: 4.307,
      },
      {
        dairyCattleType: DairyCattleType.HeifersYoungerThan1,
        factor: 2.113,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsOlderThan1,
        factor: 5.342,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsYoungerThan1,
        factor: 2.601,
      },
    ],
  },
  {
    state: AustralianState.WesternAustralia,
    stateFactors: [
      {
        dairyCattleType: DairyCattleType.MilkingCows,
        factor: 12.437,
      },
      {
        dairyCattleType: DairyCattleType.HeifersOlderThan1,
        factor: 4.307,
      },
      {
        dairyCattleType: DairyCattleType.HeifersYoungerThan1,
        factor: 2.113,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsOlderThan1,
        factor: 5.342,
      },
      {
        dairyCattleType: DairyCattleType.DairyBullsYoungerThan1,
        factor: 2.601,
      },
    ],
  },
];

export const genericLivestockEmissionFactors = [
  {
    state: AustralianState.NewSouthWales,
    stateFactors: [
      {
        livestockType: AnimalType.Goats,
        factor: 0.411,
      },
      {
        livestockType: AnimalType.Horses,
        factor: 1.611,
      },
      {
        livestockType: AnimalType.Deer,
        factor: 1.611,
      },
      {
        livestockType: AnimalType.Buffalo,
        factor: 6.06,
      },
      {
        livestockType: AnimalType.DonkeysMules,
        factor: 0.844,
      },
      {
        livestockType: AnimalType.EmusOsctriches,
        factor: 0.411,
      },
      {
        livestockType: AnimalType.Alpacas,
        factor: 0.641,
      },
      {
        livestockType: AnimalType.Camels,
        factor: 3.759,
      },
    ],
  },
  {
    state: AustralianState.NorthernTerritory,
    stateFactors: [
      {
        livestockType: AnimalType.Goats,
        factor: 0.489,
      },
      {
        livestockType: AnimalType.Horses,
        factor: 2.268,
      },
      {
        livestockType: AnimalType.Deer,
        factor: 1.83,
      },
      {
        livestockType: AnimalType.Buffalo,
        factor: 6.718,
      },
      {
        livestockType: AnimalType.DonkeysMules,
        factor: 1.063,
      },
      {
        livestockType: AnimalType.EmusOsctriches,
        factor: 0.489,
      },
      {
        livestockType: AnimalType.Alpacas,
        factor: 0.719,
      },
      {
        livestockType: AnimalType.Camels,
        factor: 4.416,
      },
    ],
  },
  {
    state: AustralianState.Queensland,
    stateFactors: [
      {
        livestockType: AnimalType.Goats,
        factor: 0.411,
      },
      {
        livestockType: AnimalType.Horses,
        factor: 1.611,
      },
      {
        livestockType: AnimalType.Deer,
        factor: 1.611,
      },
      {
        livestockType: AnimalType.Buffalo,
        factor: 6.06,
      },
      {
        livestockType: AnimalType.DonkeysMules,
        factor: 0.844,
      },
      {
        livestockType: AnimalType.EmusOsctriches,
        factor: 0.411,
      },
      {
        livestockType: AnimalType.Alpacas,
        factor: 0.641,
      },
      {
        livestockType: AnimalType.Camels,
        factor: 3.759,
      },
    ],
  },
  {
    state: AustralianState.SouthAustralia,
    stateFactors: [
      {
        livestockType: AnimalType.Goats,
        factor: 0.411,
      },
      {
        livestockType: AnimalType.Horses,
        factor: 1.611,
      },
      {
        livestockType: AnimalType.Deer,
        factor: 1.611,
      },
      {
        livestockType: AnimalType.Buffalo,
        factor: 6.06,
      },
      {
        livestockType: AnimalType.DonkeysMules,
        factor: 0.844,
      },
      {
        livestockType: AnimalType.EmusOsctriches,
        factor: 0.411,
      },
      {
        livestockType: AnimalType.Alpacas,
        factor: 0.641,
      },
      {
        livestockType: AnimalType.Camels,
        factor: 3.759,
      },
    ],
  },
  {
    state: AustralianState.Tasmania,
    stateFactors: [
      {
        livestockType: AnimalType.Goats,
        factor: 0.411,
      },
      {
        livestockType: AnimalType.Horses,
        factor: 1.611,
      },
      {
        livestockType: AnimalType.Deer,
        factor: 1.611,
      },
      {
        livestockType: AnimalType.Buffalo,
        factor: 6.06,
      },
      {
        livestockType: AnimalType.DonkeysMules,
        factor: 0.844,
      },
      {
        livestockType: AnimalType.EmusOsctriches,
        factor: 0.411,
      },
      {
        livestockType: AnimalType.Alpacas,
        factor: 0.641,
      },
      {
        livestockType: AnimalType.Camels,
        factor: 3.759,
      },
    ],
  },
  {
    state: AustralianState.Victoria,
    stateFactors: [
      {
        livestockType: AnimalType.Goats,
        factor: 0.411,
      },
      {
        livestockType: AnimalType.Horses,
        factor: 1.611,
      },
      {
        livestockType: AnimalType.Deer,
        factor: 1.611,
      },
      {
        livestockType: AnimalType.Buffalo,
        factor: 6.06,
      },
      {
        livestockType: AnimalType.DonkeysMules,
        factor: 0.844,
      },
      {
        livestockType: AnimalType.EmusOsctriches,
        factor: 0.411,
      },
      {
        livestockType: AnimalType.Alpacas,
        factor: 0.641,
      },
      {
        livestockType: AnimalType.Camels,
        factor: 3.759,
      },
    ],
  },
  {
    state: AustralianState.WesternAustralia,
    stateFactors: [
      {
        livestockType: AnimalType.Goats,
        factor: 0.411,
      },
      {
        livestockType: AnimalType.Horses,
        factor: 1.611,
      },
      {
        livestockType: AnimalType.Deer,
        factor: 1.611,
      },
      {
        livestockType: AnimalType.Buffalo,
        factor: 6.06,
      },
      {
        livestockType: AnimalType.DonkeysMules,
        factor: 0.844,
      },
      {
        livestockType: AnimalType.EmusOsctriches,
        factor: 0.411,
      },
      {
        livestockType: AnimalType.Alpacas,
        factor: 0.641,
      },
      {
        livestockType: AnimalType.Camels,
        factor: 3.759,
      },
    ],
  },
];
