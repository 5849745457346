interface Props {
  title: string;
  value: React.ReactNode;
}
export default function DescriptionItem({ title, value }: Props) {
  return (
    <>
      <dt className="text-sm font-medium leading-6 text-gray-900">{title}</dt>
      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        {value}
      </dd>
    </>
  );
}
