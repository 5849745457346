import { useEffect, useState } from "react";
import TextInput from "../../../components/text-input";
import useEmissionData from "../../../hooks/useEmissionData";
import { IrrigationEnergy as iIrrigationEnergy } from "../../../types";
import { api } from "../../../api";
import { formatMonthYear } from "../../../helpers/format-month-year";
import {
  EmissionAttachmentType,
  EmissionVariant,
  IrrigationEnergyAttachmentType,
  MINIMUM_INPUT_WARNING,
} from "../../../constants";
import useActivityInfo from "../../../hooks/emission/useActivityInfo";
import useFarmInfo from "../../../hooks/emission/useFarmInfo";
import { useParams } from "react-router-dom";
import EmissionAttachments from "../emission-attachments";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";
import formatDate from "../../../helpers/format-date";
import useEmissionActivity from "../../../hooks/emission/useEmissionActivity";
import Divider from "../../../components/divider";

interface IrrigationEnergyProps {
  variant?: EmissionVariant;
}

export default function IrrigationEnergy({ variant }: IrrigationEnergyProps) {
  const emissionData = useEmissionData();
  const activityInfo = useActivityInfo();
  const farmInfo = useFarmInfo();
  const emissionActivity = useEmissionActivity();

  const { projectId, emissionActivityId } = useParams();
  const { projectAttachment, mutate } = useProjectAttachment(
    projectId,
    emissionActivityId,
    EmissionAttachmentType.IrrigationEnergy
  );
  const [irrigationEnergyData, setIrrigationEnergyData] = useState<
    iIrrigationEnergy[]
  >([]);
  const [inputCheck, setInputCheck] = useState<{
    showFuelError: boolean;
    fuelAttachment: boolean;
    showElectricityError: boolean;
    electricityAttachment: boolean;
  }>({
    showFuelError: true,
    fuelAttachment: false,
    showElectricityError: true,
    electricityAttachment: false,
  });

  useEffect(() => {
    if (!emissionData) {
      setIrrigationEnergyData([]);
    }

    setIrrigationEnergyData(emissionData?.irrigationEnergy ?? []);
  }, [emissionData]);

  useEffect(() => {
    if (!emissionActivity) {
      setInputCheck({
        showFuelError: false,
        showElectricityError: false,
        fuelAttachment: false,
        electricityAttachment: false,
      });
      return;
    }
    const fuelData = irrigationEnergyData.find(
      (data) =>
        data.activityYear ===
        new Date(
          emissionActivity?.activityStartDate ?? new Date()
        ).getFullYear()
    )?.fuelQuantity;

    const electricityData = irrigationEnergyData.find(
      (data) =>
        data.activityYear ===
        new Date(
          emissionActivity?.activityStartDate ?? new Date()
        ).getFullYear()
    )?.electrictyQuantity;

    setInputCheck({
      showFuelError: fuelData === undefined,
      showElectricityError: electricityData === undefined,
      fuelAttachment: fuelData !== undefined && +fuelData !== 0,
      electricityAttachment:
        electricityData !== undefined && +electricityData !== 0,
    });
  }, [emissionActivity, irrigationEnergyData]);

  function fetchIrrigationEnergy(year: number) {
    const data = irrigationEnergyData.find(
      (data) => data.activityYear === year
    );

    if (data) {
      return data as iIrrigationEnergy;
    }

    return {} as iIrrigationEnergy;
  }

  return (
    <div className="flex flex-col items-center w-full">
      {!irrigationEnergyData ||
      !activityInfo ||
      !farmInfo ||
      !projectId ||
      !emissionActivityId ||
      !emissionActivity ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p>No Data</p>
        </div>
      ) : (
        <div className="w-full">
          <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
            Irrigation Energy
          </h2>
          <Divider />

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Please list the amount of energy you used to irrigate your
                project area in the specified{" "}
                {variant !== EmissionVariant.OnGoing ? "years" : "period"}.{" "}
              </label>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full ">
                      <thead>
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 text-center px-5 text-xs font-semibold text-gray-900 "
                          ></th>

                          <th
                            scope="col"
                            className="px-4 py-3.5  text-sm font-semibold text-gray-900 text-center"
                          >
                            Quantity of fuel used to irrigate project area
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                          >
                            Quantity of electricity used to irrigate the project
                            area
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-0.5 text-center px-5 text-xs font-normal text-gray-900 "
                          ></th>
                          <th
                            scope="col"
                            className="px-4 py-0.5  text-xs font-normal text-gray-900 text-center bg-apple-50"
                          >
                            Kilolitres (kL)
                          </th>
                          <th
                            scope="col"
                            className="py-0.5 pl-4 pr-4  text-xs font-normal text-gray-900 sm:pr-0 text-center bg-apple-50"
                          >
                            Kilowatt Hour (kWh)
                          </th>
                        </tr>
                        {variant !== EmissionVariant.OnGoing ? (
                          activityInfo.activityYears
                            .sort((a, b) => b - a)
                            .map((year, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center">
                                  {formatMonthYear(
                                    farmInfo.carbonRegMonth ?? 1,
                                    year
                                  )}
                                </td>
                                <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                  <TextInput
                                    inputMode="decimal"
                                    key={i}
                                    type="number"
                                    defaultValue={
                                      fetchIrrigationEnergy(year)
                                        .fuelQuantity ?? ""
                                    }
                                    onInput={(e) => {
                                      if (
                                        +e.currentTarget.value < 0 ||
                                        !e.currentTarget.value
                                      ) {
                                        alert(MINIMUM_INPUT_WARNING);
                                        e.currentTarget.value = "";
                                        api.saveIrrigationEnergy(
                                          +emissionActivityId,
                                          +projectId,
                                          year,
                                          "fuelQuantity",
                                          undefined
                                        );
                                      } else {
                                        api.saveIrrigationEnergy(
                                          +emissionActivityId,
                                          +projectId,
                                          year,
                                          "fuelQuantity",
                                          e.currentTarget.value.length
                                            ? +e.currentTarget.value
                                            : undefined
                                        );
                                      }
                                    }}
                                  />
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                                  <TextInput
                                    type="number"
                                    inputMode="decimal"
                                    defaultValue={
                                      fetchIrrigationEnergy(year)
                                        .electrictyQuantity ?? ""
                                    }
                                    onInput={(e) => {
                                      if (
                                        +e.currentTarget.value < 0 ||
                                        !e.currentTarget.value
                                      ) {
                                        alert(MINIMUM_INPUT_WARNING);
                                        e.currentTarget.value = "";
                                        api.saveIrrigationEnergy(
                                          +emissionActivityId,
                                          +projectId,
                                          year,
                                          "electrictyQuantity",
                                          undefined
                                        );
                                      } else {
                                        api.saveIrrigationEnergy(
                                          +emissionActivityId,
                                          +projectId,
                                          year,
                                          "electrictyQuantity",
                                          e.currentTarget.value.length
                                            ? +e.currentTarget.value
                                            : undefined
                                        );
                                      }
                                    }}
                                  />
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center">
                              {formatDate(emissionActivity.activityStartDate)} -{" "}
                              {formatDate(emissionActivity.activityEndDate)}
                            </td>
                            <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                              <TextInput
                                inputMode="decimal"
                                type="number"
                                defaultValue={
                                  fetchIrrigationEnergy(
                                    new Date(
                                      emissionActivity.activityStartDate ??
                                        new Date()
                                    ).getFullYear()
                                  ).fuelQuantity ?? ""
                                }
                                onInput={(e) => {
                                  if (
                                    +e.currentTarget.value < 0 ||
                                    !e.currentTarget.value
                                  ) {
                                    alert(MINIMUM_INPUT_WARNING);
                                    e.currentTarget.value = "";
                                    api.saveIrrigationEnergy(
                                      +emissionActivityId,
                                      +projectId,
                                      new Date(
                                        emissionActivity.activityStartDate ??
                                          new Date()
                                      ).getFullYear(),
                                      "fuelQuantity",
                                      undefined
                                    );
                                    setInputCheck({
                                      ...inputCheck,
                                      showFuelError: true,
                                      fuelAttachment: false,
                                    });
                                  } else {
                                    api.saveIrrigationEnergy(
                                      +emissionActivityId,
                                      +projectId,
                                      new Date(
                                        emissionActivity.activityStartDate ??
                                          new Date()
                                      ).getFullYear(),
                                      "fuelQuantity",
                                      e.currentTarget.value.length
                                        ? +e.currentTarget.value
                                        : undefined
                                    );
                                    setInputCheck({
                                      ...inputCheck,
                                      showFuelError: false,
                                      fuelAttachment:
                                        +e.currentTarget.value === 0
                                          ? false
                                          : true,
                                    });
                                  }
                                }}
                                error={inputCheck.showFuelError ? 1 : undefined}
                              />
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                              <TextInput
                                type="number"
                                inputMode="decimal"
                                defaultValue={
                                  fetchIrrigationEnergy(
                                    new Date(
                                      emissionActivity.activityStartDate ??
                                        new Date()
                                    ).getFullYear()
                                  ).electrictyQuantity ?? ""
                                }
                                onInput={(e) => {
                                  if (
                                    +e.currentTarget.value < 0 ||
                                    !e.currentTarget.value
                                  ) {
                                    alert(MINIMUM_INPUT_WARNING);
                                    e.currentTarget.value = "";
                                    api.saveIrrigationEnergy(
                                      +emissionActivityId,
                                      +projectId,
                                      new Date(
                                        emissionActivity.activityStartDate ??
                                          new Date()
                                      ).getFullYear(),
                                      "electrictyQuantity",
                                      undefined
                                    );
                                    setInputCheck({
                                      ...inputCheck,
                                      showElectricityError: true,
                                      electricityAttachment: false,
                                    });
                                  } else {
                                    api.saveIrrigationEnergy(
                                      +emissionActivityId,
                                      +projectId,
                                      new Date(
                                        emissionActivity.activityStartDate ??
                                          new Date()
                                      ).getFullYear(),
                                      "electrictyQuantity",
                                      e.currentTarget.value.length
                                        ? +e.currentTarget.value
                                        : undefined
                                    );
                                    setInputCheck({
                                      ...inputCheck,
                                      showElectricityError: false,
                                      electricityAttachment:
                                        +e.currentTarget.value === 0
                                          ? false
                                          : true,
                                    });
                                  }
                                }}
                                error={
                                  inputCheck.showElectricityError
                                    ? 1
                                    : undefined
                                }
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Divider />

          {variant === EmissionVariant.OnGoing &&
          !(
            inputCheck.fuelAttachment || inputCheck.electricityAttachment
          ) ? null : (
            <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
              Attachments
            </h2>
          )}
          <div className="m-1">
            {variant !== EmissionVariant.OnGoing ? (
              activityInfo.activityYears
                .sort((a, b) => b - a)
                .map((year, i) => (
                  <div className="mt-1 " key={i}>
                    <EmissionAttachments
                      emissionActivityId={+emissionActivityId}
                      projectId={+projectId}
                      month={farmInfo.carbonRegMonth}
                      year={year}
                      attachments={projectAttachment}
                      title="Irrigation Energy"
                      attachmentKind={EmissionAttachmentType.IrrigationEnergy}
                      mutateAttachments={mutate}
                    />
                    {i < activityInfo.activityYears.length - 1 && <Divider />}
                  </div>
                ))
            ) : (
              <div className="mt-1 ">
                <EmissionAttachments
                  variant={variant}
                  emissionActivity={emissionActivity}
                  emissionActivityId={+emissionActivityId}
                  projectId={+projectId}
                  month={farmInfo.carbonRegMonth}
                  year={new Date(
                    emissionActivity.activityStartDate ?? new Date()
                  ).getFullYear()}
                  attachments={projectAttachment}
                  title="Irrigation Energy"
                  attachmentKind={EmissionAttachmentType.IrrigationEnergy}
                  attachmentTypes={
                    [
                      inputCheck.fuelAttachment
                        ? IrrigationEnergyAttachmentType.QuantityOfFuel
                        : undefined,
                      inputCheck.electricityAttachment
                        ? IrrigationEnergyAttachmentType.QuantityOfElectricity
                        : undefined,
                    ].filter(Boolean) as IrrigationEnergyAttachmentType[]
                  }
                  mutateAttachments={mutate}
                  uploadRequired={
                    inputCheck.fuelAttachment ||
                    inputCheck.electricityAttachment
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
