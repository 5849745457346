import { useEffect, useState } from "react";
import useActivityInfo from "../../hooks/emission/useActivityInfo";
import CoverCrop from "../../views/emission-calculator/calculations/cover-crops";
import CropInformation from "../../views/emission-calculator/calculations/crop-information";
import CropTillage from "../../views/emission-calculator/calculations/crop-tillage";
import IrrigationEnergy from "../../views/emission-calculator/calculations/irrigation-energy";
import Liming from "../../views/emission-calculator/calculations/liming";
import Livestock from "../../views/emission-calculator/calculations/livestock";
import PastureCrop from "../../views/emission-calculator/calculations/pasture-crops";
import SoilLandscape from "../../views/emission-calculator/calculations/soil-landscape";
import SyntheticFertiliserData from "../../views/emission-calculator/calculations/synthetic-fertiliser-data";
import { useParams } from "react-router-dom";
import useProjectCalculatedEmissionData from "../../hooks/emission/useProjectCalculatedEmissionData";
import { EmissionVariant } from "../../constants";
import useProjectEmissionActivity from "../../hooks/emission/useProjectEmissionActivity";
import CarbonBuilderApplication from "../../views/emission-calculator/ongoing/carbon-builder";
import RestrictedActivitiesView from "../../views/emission-calculator/ongoing/restricted-activities";
import Summary from "../../views/emission-calculator/ongoing/summary";

interface Step {
  title: string;
  component: JSX.Element;
}

export default function CalculationSteps() {
  const activityInfo = useActivityInfo();
  const { projectId, emissionActivityId } = useParams();
  const [steps, setSteps] = useState<Step[]>([]);
  const { emissionActivity } = useProjectEmissionActivity(
    projectId,
    emissionActivityId
  );
  const {
    projectCalculatedEmissionData,
    loading: projectCalculatedEmissionDataLoading,
  } = useProjectCalculatedEmissionData(projectId, emissionActivityId);

  useEffect(() => {
    if (
      !activityInfo ||
      !emissionActivity ||
      projectCalculatedEmissionDataLoading
    )
      return;

    const Steps: Step[] = [];

    const year = new Date(
      emissionActivity.activityStartDate ?? new Date()
    ).getFullYear();

    if (!projectCalculatedEmissionData) {
      Object.entries(activityInfo).forEach(([key, value]) => {
        switch (key) {
          case "irrigation":
            value &&
              Steps.push({
                title: "Irrigation Energy",
                component: (
                  <IrrigationEnergy variant={EmissionVariant.OnGoing} />
                ),
              });
            break;

          case "limeApplication":
            value &&
              Steps.push({
                title: "Liming",
                component: <Liming variant={EmissionVariant.OnGoing} />,
              });
            break;

          case "syntheticFertilizer":
            value &&
              Steps.push({
                title: "Synthetic Fertiliser",
                component: (
                  <SyntheticFertiliserData
                    year={year}
                    variant={EmissionVariant.OnGoing}
                  />
                ),
              });

            break;

          case "soilLandscape":
            value &&
              Steps.push({
                title: "Soil Landscape Modification",
                component: <SoilLandscape variant={EmissionVariant.OnGoing} />,
              });
            break;

          case "growCrop":
            value &&
              Steps.push({
                title: "Crop Information",
                component: (
                  <CropInformation
                    year={year}
                    variant={EmissionVariant.OnGoing}
                  />
                ),
              });

            break;

          case "tillCrop":
            value &&
              Steps.push({
                title: "Crop Tillage",
                component: <CropTillage variant={EmissionVariant.OnGoing} />,
              });
            break;

          case "coverCrop":
            value &&
              Steps.push({
                title: "Cover Crop",
                component: (
                  <CoverCrop year={year} variant={EmissionVariant.OnGoing} />
                ),
              });
            value &&
              Steps.push({
                title: "Pasture",
                component: (
                  <PastureCrop year={year} variant={EmissionVariant.OnGoing} />
                ),
              });
            break;

          case "livestock":
            value &&
              Steps.push({
                title: "Livestock",
                component: (
                  <Livestock year={year} variant={EmissionVariant.OnGoing} />
                ),
              });
            break;

          case "carbonBuilderProduct":
            value &&
              Steps.push({
                title: "Carbon Builder",
                component: <CarbonBuilderApplication />,
              });
            break;

          default:
            break;
        }
      });
      Steps.push({
        title: "Restricted Activities",
        component: <RestrictedActivitiesView />,
      });
    }

    Steps.push({
      title: "Summary",
      component: <Summary />,
    });

    setSteps(Steps);
  }, [
    activityInfo,
    emissionActivity,
    projectCalculatedEmissionData,
    projectCalculatedEmissionDataLoading,
  ]);

  return steps;
}
