import { ExclamationCircleIcon, EyeIcon } from "@heroicons/react/24/outline";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode;
  error?: number;
  errormesage?: string;
  inputWidth?: string;
  inputtype?: string;
  showpassword?: () => void;
  dontshowerrormesage?: boolean;
}

export default function TextInput({
  label,
  dontshowerrormesage,
  ...props
}: Props) {
  return (
    <div className="my-2">
      <label className="block text-xs font-medium text-gray-900">{label}</label>
      <div className="mt-1">
        <div className="relative rounded-md shadow-sm ">
          <input
            {...props}
            className={`block w-${
              props.inputWidth ? `[${props.inputWidth}]` : "full"
            } rounded-md border-0 py-1 px-2  ring-1 ring-inset ${
              props.error === 1 ? "ring-red-300" : "ring-gray-300"
            } focus:ring-2 focus:ring-inset focus:ring-apple-500 text-sm sm:leading-6 ${
              !props.disabled
                ? "bg-transparent text-gray-900"
                : "bg-gray-100 text-gray-500"
            }`}
          />
          {props.inputtype === "password" ? (
            <div
              className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
              onClick={props.showpassword}
            >
              <EyeIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </div>
          ) : props.error === 1 ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          ) : null}
        </div>
        {props.error && !dontshowerrormesage ? (
          <p className="mt-0.5 text-xs text-red-600" id="error">
            {props.errormesage ? props.errormesage : "This field is required!"}
          </p>
        ) : null}
      </div>
    </div>
  );
}
