import { Player } from "@lottiefiles/react-lottie-player";
import SuccessAnimation from "../assets/lottie/success2.json";

interface SuccessProps {
  message?: string;
}

export default function Success({ message }: SuccessProps) {
  return (
    <div className="flex min-h-full flex-1">
      <div className="flex flex-1 flex-col justify-center bg-white">
        <Player
          autoplay
          loop
          controls
          speed={2}
          src={SuccessAnimation}
          style={{
            height: "300px",
            width: "300px",
          }}
        />
        <p className="text-center text-lg font-bold text-gray-700">{message}</p>
      </div>
    </div>
  );
}
