import { AverageAnnualBaselineEmission, SummaryEmissionData } from "../types";

export default function calculateAverageAnnualBaselineEmission(
  data?: SummaryEmissionData
): AverageAnnualBaselineEmission {
  if (!data) {
    return {
      irrigationEmission: 0,
      limingEmission: 0,
    };
  }
  return {
    irrigationEmission: data.irrigationEmission
      ? data.irrigationEmission.reduce(
          (acc, data) =>
            acc + (data.electricityEmission || 0) + (data.fuelEmission || 0),
          0
        ) / 5
      : 0,
    limingEmission: data.limingEmission
      ? data.limingEmission.reduce(
          (acc, data) =>
            acc + (data.dolomiteEmission || 0) + (data.limestoneEmission || 0),
          0
        ) / 5
      : 0,
    syntheticFertilizerEmission: data.syntheticFertilizerEmission
      ? data.syntheticFertilizerEmission.reduce(
          (acc, data) => acc + (data.emission || 0),
          0
        ) / 5
      : 0,
    residueTillageAndLandscapeModificationEmission:
      data.residueTillageAndLandscapeModificationEmission
        ? data.residueTillageAndLandscapeModificationEmission.reduce(
            (acc, data) =>
              acc +
              (data.tillageAndSoilLandscapingEmission || 0) +
              (data.cropResidueEmission || 0) +
              (data.pastureTillageEmission || 0) +
              (data.coverCropEmission || 0),
            0
          ) / 5
        : 0,
    livestockEmission: data.livestockEmission
      ? data.livestockEmission.reduce(
          (acc, data) =>
            acc +
            (data.animalEmisions?.reduce(
              (acc, data) => acc + (data.emission || 0),
              0
            ) || 0),
          0
        ) / 5
      : 0,
  };
}
