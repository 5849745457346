import {
  Routes,
  Route,
  useLocation,
  useParams,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Welcome from "./welcome";
import ActivityInfoPage from "../activity-info";
import Button from "../../../components/button";
import { useState } from "react";
import FarmInfoTab from "../../../components/farm-info-tab";
import OverlayLoading from "../../../components/overlay-loading";
import { api } from "../../../api";
import { FetchError } from "../../../lib/fetcher";
import useProjectCalculatedEmissionData from "../../../hooks/emission/useProjectCalculatedEmissionData";
import { EmissionVariant } from "../../../constants";
import Finish from "../finish";
import Calaculations from "../ongoing/calculations";
import formatDate from "../../../helpers/format-date";
import useProjectEmissionActivity from "../../../hooks/emission/useProjectEmissionActivity";
import PageNotFound from "../../../components/page-not-found";

export default function OngoingEmissionRouter() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { projectId, emissionActivityId } = useParams();
  const navigate = useNavigate();
  const { emissionActivity } = useProjectEmissionActivity(
    projectId,
    emissionActivityId
  );
  const currentLocation =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  const {
    projectCalculatedEmissionData,
    loading: projectCalculatedEmissionDataLoading,
  } = useProjectCalculatedEmissionData(projectId, emissionActivityId);

  function handleExit() {
    localStorage.clear();
    navigate(`/projects/${projectId}`);
    return;
  }

  async function handleSaveAndExit() {
    setLoading(true);

    const activityInfo = JSON.parse(
      localStorage.getItem("ActivityInfo") || "{}"
    );

    const ongoingEmissionData = JSON.parse(
      localStorage.getItem("EmissionData") || "{}"
    );
    if (!projectId) {
      setLoading(false);
      navigate("/projects");
    }

    if (
      ongoingEmissionData &&
      projectId &&
      activityInfo &&
      emissionActivityId
    ) {
      const activityInfoResponse = await api.postActivityInfo(
        +projectId,
        +emissionActivityId,
        activityInfo
      );
      if (activityInfoResponse instanceof FetchError) {
        setLoading(false);
        alert(activityInfoResponse.message);
        return;
      }

      const response = await api.postBaslineData(
        +projectId,
        +emissionActivityId,
        ongoingEmissionData
      );
      if (response instanceof FetchError) {
        setLoading(false);
        alert(response.message);
        return;
      }
    }
    setLoading(false);
    localStorage.clear();

    navigate(`/projects/${projectId}`);
    return;
  }

  return (
    <div className="flex flex-col w-full">
      <OverlayLoading open={loading || projectCalculatedEmissionDataLoading} />
      {
        <FarmInfoTab
          open={open}
          setOpen={setOpen}
          editUrl={`/projects/${projectId}/emission-calculator/${emissionActivityId}/get-started`}
          variant={EmissionVariant.OnGoing}
        />
      }

      <main className="flex-1 h-4/5 ">
        {emissionActivity ? (
          <>
            {currentLocation === "calculations" ||
            currentLocation === "get-started" ? (
              <header className="bg-mineral-green-100 sticky top-0 z-30 flex flex-wrap w-full items-center justify-between px-2 py-3 sm:py-4  border-b border-mineral-green-200 transition duration-500 sm:px-3 lg:px-4">
                <div className="flex flex-row justify-between items-center w-full">
                  <div className="flex flex-col w-1/2">
                    <h1 className="text-lg sm:text-2xl font-semibold text-black">
                      Ongoing Emission
                    </h1>
                    <p className="text-xxs sm:text-xs font-normal text-gray-600">
                      {formatDate(emissionActivity.activityStartDate)} -{" "}
                      {formatDate(emissionActivity.activityEndDate)}
                    </p>
                  </div>
                  <div>
                    <div className="flex flex-row w-auto items-center justify-end">
                      <div className="flex flex-row gap-x-2 w-auto items-center justify-end">
                        {projectCalculatedEmissionData ? (
                          <Button
                            size="sm"
                            variant="black-outline"
                            onClick={handleExit}
                          >
                            Exit
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            variant="black-outline"
                            onClick={handleSaveAndExit}
                          >
                            Save & Exit
                          </Button>
                        )}

                        {currentLocation !== "calculations" ? null : (
                          <Button
                            size="sm"
                            variant="black-outline"
                            onClick={() => setOpen(!open)}
                          >
                            Farm Info
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            ) : null}
            <Routes>
              <Route path="/" element={<Welcome />} />
              <Route
                path="/get-started"
                element={
                  projectCalculatedEmissionData ? (
                    <Navigate
                      replace
                      to={`/projects/${projectId}/emission-calculator/${emissionActivityId}/calculations`}
                    />
                  ) : (
                    <ActivityInfoPage variant={EmissionVariant.OnGoing} />
                  )
                }
              />
              <Route path="/calculations" element={<Calaculations />} />
              <Route path="/finish" element={<Finish />} />
            </Routes>
          </>
        ) : (
          <>
            <PageNotFound />
          </>
        )}
      </main>
    </div>
  );
}
