import { useParams } from "react-router-dom";
import Loading from "../../components/loading";
import { EmissionVariant } from "../../constants";
import BaselineEmissionRouter from "./baseline/baseline-emission";
import OngoingEmissionRouter from "./ongoing/ongoing-emission";
import useProjectEmissionActivity from "../../hooks/emission/useProjectEmissionActivity";

export default function EmissionCalculatorRouter() {
  const { projectId, emissionActivityId } = useParams();
  const { emissionActivity, loading } = useProjectEmissionActivity(
    projectId,
    emissionActivityId
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : emissionActivity ? (
        <>
          {emissionActivity.activityType === EmissionVariant.Baseline ? (
            <BaselineEmissionRouter />
          ) : emissionActivity.activityType === EmissionVariant.OnGoing ? (
            <OngoingEmissionRouter />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
