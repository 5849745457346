import { AnimalType, AustralianState } from "../../constants";
import {
  ActivityInfo,
  BeefCattleData,
  DairyCattleData,
  EmissionActivityInfo,
  EmissionData,
  FarmInfo,
  LivestockTypeData,
  SheepData,
  SummaryEmissionData,
} from "../../types";
import calculationFunctions from "../baseline-emission/calculation-functions";

export default function calculateEmissionData() {
  const projectBaselineData = JSON.parse(
    localStorage.getItem("EmissionData") ?? ""
  ) as EmissionData;
  const emissionActivity = JSON.parse(
    localStorage.getItem("EmissionActivity") ?? ""
  ) as EmissionActivityInfo;
  const farmInfo = JSON.parse(
    localStorage.getItem("FarmInfo") ?? ""
  ) as FarmInfo;
  const activityInfo = JSON.parse(
    localStorage.getItem("ActivityInfo") ?? ""
  ) as ActivityInfo;

  if (!projectBaselineData || !emissionActivity) return;

  const emissionYear = new Date(
    emissionActivity.activityStartDate ?? new Date()
  ).getFullYear();

  const limingEmissionData =
    projectBaselineData.limingEnergy !== undefined &&
    projectBaselineData.limingEnergy.length > 0 &&
    activityInfo.limeApplication
      ? [
          {
            year: emissionYear,
            dolomiteEmission: calculationFunctions.calculateDolomiteEmission(
              projectBaselineData.limingEnergy[0].dolomiteQuantity || 0
            ),
            limestoneEmission: calculationFunctions.calculateLimeEmission(
              projectBaselineData.limingEnergy[0].limestoneQuantity || 0
            ),
          },
        ]
      : undefined;

  const irrigationEmissionData =
    projectBaselineData.irrigationEnergy !== undefined &&
    projectBaselineData.irrigationEnergy.length > 0 &&
    activityInfo.irrigation
      ? [
          {
            year: emissionYear,
            fuelEmission: calculationFunctions.calculateFuelEmission(
              projectBaselineData.irrigationEnergy[0].fuelQuantity || 0
            ),
            electricityEmission:
              calculationFunctions.calculateElectricityEmission(
                projectBaselineData.irrigationEnergy[0].electrictyQuantity || 0,
                activityInfo.gridElectricity ?? false
              ),
          },
        ]
      : undefined;

  const syntheticFertilizerEmissionData =
    projectBaselineData.syntheticFertilizer !== undefined &&
    projectBaselineData.syntheticFertilizer.length > 0 &&
    activityInfo.syntheticFertilizer
      ? [
          {
            year: emissionYear,
            emission: calculationFunctions.ureaEmission(
              projectBaselineData.syntheticFertilizer[0].ferterlizerLocationData.map(
                (location) => {
                  return calculationFunctions.calculateNitriousOxideEmission(
                    farmInfo.state as AustralianState,
                    location
                  );
                }
              ),
              projectBaselineData.syntheticFertilizer[0].ureaQuantity || 0
            ),
          },
        ]
      : undefined;

  const residueTillageAndLandscapeModificationEmissionData =
    projectBaselineData.cropInformation !== undefined &&
    projectBaselineData.cropInformation.length > 0 &&
    projectBaselineData.soilLandscape !== undefined &&
    projectBaselineData.soilLandscape.length > 0 &&
    projectBaselineData.cropTillage !== undefined &&
    projectBaselineData.cropTillage.length > 0 &&
    projectBaselineData.pastureCrop !== undefined &&
    projectBaselineData.pastureCrop.length > 0 &&
    projectBaselineData.coverCrop !== undefined &&
    projectBaselineData.coverCrop.length > 0 &&
    (activityInfo.growCrop || 
      activityInfo.soilLandscape ||
      activityInfo.tillCrop ||
      activityInfo.tillPasture ||
      activityInfo.coverCrop)
      ? [
          {
            year: emissionYear,
            tillageAndSoilLandscapingEmission:
              calculationFunctions.calculateTillageAndSoilLandscapeEmission(
                projectBaselineData.soilLandscape[0],
                projectBaselineData.cropTillage[0]
              ),
            cropResidueEmission:
              calculationFunctions.calculateAllCropResidueEmission(
                projectBaselineData.cropInformation[0]
              ),
            pastureTillageEmission:
              calculationFunctions.calculateAllPastureCropEmission(
                projectBaselineData.pastureCrop[0]
              ),
            coverCropEmission:
              calculationFunctions.calculateAllCoverCropEmission(
                projectBaselineData.coverCrop[0]
              ),
          },
        ]
      : undefined;

  const livestockEmissionData =
    projectBaselineData.livestock !== undefined &&
    projectBaselineData.livestock.length > 0 &&
    activityInfo.animalType.length > 0
      ? [
          {
            year: emissionYear,
            animalEmisions: activityInfo.animalType.map((animal) => {
              const animalData =
                projectBaselineData.livestock?.[0]?.livestockData?.find(
                  (ele) => ele.animalType === animal
                );

              if (!animalData)
                return {
                  animal: animal as AnimalType,
                  emission: 0,
                };

              switch (animalData.animalType) {
                case AnimalType.Sheep:
                  return {
                    animal: animalData.animalType as AnimalType,
                    emission: (animalData.animalData as SheepData[])
                      .map((data) =>
                        calculationFunctions.calculateSheepEmission(
                          data as SheepData,
                          farmInfo.state as AustralianState
                        )
                      )
                      .reduce((acc, data) => acc + data, 0),
                  };

                case "BeefCattle" as AnimalType:
                  return {
                    animal: animalData.animalType as AnimalType,
                    emission: (animalData.animalData as BeefCattleData[])
                      .map((data) =>
                        calculationFunctions.calculateBeefCattleEmission(
                          data as BeefCattleData,
                          farmInfo.state as AustralianState,
                          farmInfo.region
                        )
                      )
                      .reduce((acc, data) => acc + data, 0),
                  };

                case "DairyCattle" as AnimalType:
                  return {
                    animal: animalData.animalType as AnimalType,
                    emission: (animalData.animalData as DairyCattleData[])
                      .map((data) =>
                        calculationFunctions.calculateDairyCattleEmission(
                          data as DairyCattleData,
                          farmInfo.state as AustralianState
                        )
                      )
                      .reduce((acc, data) => acc + data, 0),
                  };

                default:
                  return {
                    animal: animalData.animalType as AnimalType,
                    emission:
                      calculationFunctions.calculateGenericLivestockEmission(
                        animalData.animalData as LivestockTypeData,
                        animalData.animalType as AnimalType,
                        farmInfo.state as AustralianState
                      ),
                  };
              }
            }),
          },
        ]
      : undefined;

  const summaryEmissionData: SummaryEmissionData = {
    limingEmission: limingEmissionData,
    irrigationEmission: irrigationEmissionData,
    syntheticFertilizerEmission: syntheticFertilizerEmissionData,
    residueTillageAndLandscapeModificationEmission:
      residueTillageAndLandscapeModificationEmissionData,
    livestockEmission: livestockEmissionData,
  };

  localStorage.setItem(
    "CalculatedEmissionData",
    JSON.stringify(summaryEmissionData)
  );

  return summaryEmissionData;
}
