import { useMemo, useState, useEffect, createContext, Fragment } from "react";
import BusinessInformationAndEntity from "./kyb-kyc-components/business-information-and-entity";
import ConsentForm from "./kyb-kyc-components/consent-form";
import Intro from "./kyb-kyc-components/intro";
import Loading from "../components/loading";
import Button from "../components/button";
import {
  DataTypes,
  EntityTypes,
  KYBStatus,
  ReportIssueVariant,
} from "../constants";
import QCChecker from "../helpers/qc-checker";
import {
  BusinessInfo,
  GrowerPEP,
  GrowerSanctionScreening,
  KYBConsent,
  KybNode,
  PartnershipData,
  SoleTraderData,
  ToBeSavedData,
  TrustData,
  UnlistedCompanyData,
} from "../types";
import { api } from "../api";
import useMyGrower from "../hooks/useMyGrower";
import { FetchError } from "../lib/fetcher";
import detemineStepsfromNodes from "../helpers/kyb-kyc/determine-steps-from-nodes";
import OverlayLoading from "../components/overlay-loading";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate, useParams } from "react-router-dom";
import useKyb from "../hooks/useKyb";
import useConsent from "../hooks/useConsent";
import ErrorDialog from "../components/error-dialog";
import SuccessDialog from "../components/success-dialog";
import determineDisabled from "../helpers/kyb-kyc/determine-disabled";
import determineInitialStep from "../helpers/kyb-kyc/determine-initial-step";
import Warning from "../components/warning";
import PageNotFound from "../components/page-not-found";
import DisclaimerSideBar from "../components/disclaimer-sidebar";
import ReportIssueSidebar from "../components/report-issue-sidebar";
import * as Sentry from "@sentry/browser";

type SaveVariant = "saveAndContinue" | "saveAndExit";

export const ComponentContext = createContext<{
  componentId?: number | undefined;
  nodeData?: KybNode | undefined;
  updateComponent?: (entity: EntityTypes) => void;
  kybStatus?: KYBStatus | undefined;
  growersBusinessLegalName?: string | undefined;
}>({
  componentId: undefined,
  nodeData: undefined,
  updateComponent: undefined,
  growersBusinessLegalName: undefined,
});

export interface StepComponent {
  title: string;
  description?: string;
  components: {
    name: string;
    component: JSX.Element;
  }[];
}

export default function KYBKYC() {
  const { kybId } = useParams();
  const { kyb, loading: kybLoading } = useKyb(kybId);
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [activeChildComponentIndex, setActiveChildComponentIndex] = useState(0);
  const { grower } = useMyGrower();
  const [nodes, setNodes] = useState<KybNode[]>([]);
  const [refreshNodes, setRefreshNodes] = useState(false);
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const { consent } = useConsent(kybId);
  const [showWarning, setShowWarning] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [exit, setExit] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showNeedHelp, setShowNeedHelp] = useState(false);
  const [stepComponents, setStepComponent] = useState<StepComponent[]>([
    {
      title: "Intro",
      components: [
        { name: "Intro", component: <Intro /> },
        { name: "Consent", component: <ConsentForm /> },
      ],
    },
    {
      title: "Grower's Entity",
      components: [
        {
          name: "Business Information",
          component: <BusinessInformationAndEntity />,
        },
      ],
    },
  ]);

  const getCurrentStepId = useMemo(() => {
    if (currentStep >= nodes.length) {
      return nodes.length - 1;
    } else {
      return (currentStep < 1 ? 1 : currentStep - 1);
    }
  },[currentStep, nodes]);
  const getStepComponents = useMemo(() => stepComponents, [stepComponents]);
  const getActiveChildComponentIndex = useMemo(
    () => activeChildComponentIndex,
    [activeChildComponentIndex]
  );

  function emptyStorage(
    response: FetchError<unknown> | unknown,
    variant?: SaveVariant
  ) {
    if (!(response instanceof FetchError)) {
      setRefreshNodes(true);
      localStorage.removeItem("toBeSaved");
      localStorage.removeItem("NumOfAttachments");
      if (variant === "saveAndExit") {
        setExit(true);
      } else {
        moveForward();
      }
      setSaving(false);
    } else {
      Sentry.captureException(response.error);
      setMessage("Error saving your data. Please try again.");
      setShowError(true);
      setSaving(false);
    }
  }

  useEffect(() => {
    if (kybLoading) return;
    async function fetchNodes() {
      if (!kyb) return;
      const response = await api.getKYBNodes(kyb.id);
      if (response instanceof FetchError) {
        Sentry.captureException(response.error);
        setMessage(
          "Error fetching Steps for KYB application. Please try again."
        );
        setShowError(true);
        return;
      }
      if (response.kybNodes) {
        setNodes(response.kybNodes);
        const rootEntityType = response.kybNodes.find((n) => !n.parentNodeId)
          ?.entityType as EntityTypes;
        setInitialStep(rootEntityType);
      }
      setRefreshNodes(false);
    }
    fetchNodes();
    if (refreshNodes) {
      fetchNodes();
    }
  }, [
    getActiveChildComponentIndex,
    getCurrentStepId,
    kyb,
    refreshNodes,
    kybLoading,
  ]);

  const stepsFromNodes = useMemo(() => {
    if (!nodes || !nodes.length) return;
    const filteredNodes = nodes
      .filter(
        (node) => node.parentNodeId !== null && node.parentNodeId !== undefined
      )
      .filter(
        (node) =>
          node.parentNodeId !== null ||
          node.parentNodeId !== undefined ||
          (node.entityType !== null && node.entityType !== undefined) ||
          (node.businessInfo?.entityType !== null &&
            node.businessInfo?.entityType !== undefined)
      );
    const steps = detemineStepsfromNodes(filteredNodes);
    if (!steps) return;
    return steps;
  }, [nodes]);

  useEffect(() => {
    setStepComponent((prev) => [
      ...prev.slice(0, 2),
      ...(stepsFromNodes ?? []),
    ]);
  }, [stepsFromNodes]);

  useEffect(() => {
    if (!consent) return;
    stepComponents[0].components = [{ name: "Intro", component: <Intro /> }];
  }, [consent, stepComponents, currentStep, activeChildComponentIndex]);

  useEffect(() => {
    if (
      nodes.length > 0 &&
      nodes[0].businessInfo &&
      nodes[0].businessInfo.legalName
    ) {
      localStorage.removeItem("growerBusinessName");
    }
  }, [nodes]);

  async function SaveData(variant?: SaveVariant) {
    if (!kyb) return;
    if (!determineDisabled(kyb.kybStatus)) {
      const data = localStorage.getItem("toBeSaved");
      const toBeSavedData = data
        ? (JSON.parse(data) as ToBeSavedData)
        : undefined;

      if (!toBeSavedData || !grower || !kyb) {
        if (variant === "saveAndExit") {
          navigate("/kyb");
        }
        setSaving(false);
        setShowError(true);
        setMessage("Please fill out all required fields to continue.");
        return;
      }
      if (!toBeSavedData.data || !Object.keys(toBeSavedData.data).length) {
        if (variant === "saveAndExit") {
          navigate("/kyb");
        }
        setSaving(false);
        setShowError(true);
        setMessage("Please fill out all required fields to continue.");
        return;
      }

      switch (toBeSavedData.dataType) {
        case DataTypes.ConsentData: {
          const consentResponse = await api.getKYBConsent(kyb.id);
          if (!consentResponse || consentResponse instanceof FetchError) {
            localStorage.setItem(
              "growerBusinessName",
              (toBeSavedData.data as KYBConsent).businessName
            );
            const consentResponse = await api.postKYBConsent(
              kyb.id,
              toBeSavedData.data as KYBConsent
            );
            emptyStorage(consentResponse, variant);
            break;
          }
          moveForward();
          setSaving(false);
          break;
        }

        case DataTypes.BusinessInfoData: {
          const businessInfoResponse = await api.postInitialBusinessInfo(
            kyb.id,
            toBeSavedData.data as BusinessInfo
          );

          emptyStorage(businessInfoResponse, variant);
          break;
        }
        case DataTypes.MidBusinessInfoData: {
          const midBusinessInfoResponse = await api.postMidBusinessInfo(
            kyb.id,
            toBeSavedData.data as BusinessInfo,
            nodes[getCurrentStepId].id
          );
          emptyStorage(midBusinessInfoResponse, variant);
          break;
        }

        case DataTypes.SoleTraderData: {
          const soleTraderResponse = await api.postSoleTraderData(
            kyb.id,
            nodes[getCurrentStepId].id,
            toBeSavedData.data as SoleTraderData
          );
          emptyStorage(soleTraderResponse, variant);
          break;
        }

        case DataTypes.UnlistedCompanyData: {
          const unlistedCompanyResponse = await api.postUnlistedCompanyData(
            kyb.id,
            nodes[getCurrentStepId].id,
            toBeSavedData.data as UnlistedCompanyData
          );
          emptyStorage(unlistedCompanyResponse, variant);
          break;
        }
        case DataTypes.TrustData: {
          const trustResponse = await api.postTrustData(
            kyb.id,
            nodes[getCurrentStepId].id,
            toBeSavedData.data as TrustData
          );
          emptyStorage(trustResponse, variant);
          break;
        }
        case DataTypes.PartnershipData: {
          const partnershipResponse = await api.postPartnershipData(
            kyb.id,
            nodes[getCurrentStepId].id,
            toBeSavedData.data as PartnershipData
          );
          emptyStorage(partnershipResponse, variant);
          break;
        }

        case DataTypes.GrowerPEP: {
          const pepResponse = await api.postGrowersPEPDeclaration(
            kyb.id,
            toBeSavedData.data as GrowerPEP
          );
          emptyStorage(pepResponse, variant);
          break;
        }
        case DataTypes.GrowerSanctionScreening: {
          const sanctionResponse = await api.postGrowersSanctionScreening(
            kyb.id,
            toBeSavedData.data as GrowerSanctionScreening
          );
          emptyStorage(sanctionResponse, variant);
          break;
        }
        default:
          emptyStorage({}, variant);
          break;
      }
    } else {
      moveForward();
      setSaving(false);
    }
  }

  const setInitialStep = (entity: EntityTypes | undefined) => {
    if (!entity) return;
    const newComponent = determineInitialStep(entity);
    if (!newComponent) return;
    setStepComponent((prev) => [
      prev[0],
      {
        title: "Grower's Entity",
        components: [prev[1].components[0], newComponent],
      },
      ...prev.slice(2),
    ]);
  };

  const updateComponent = (entity: EntityTypes) => {
    if (!stepComponents || !kyb) return;
    // When we change the entity type of the grower's entity,
    // we will remove any existing entity data created and reset the steps.
    api.postResetKYBItems(kyb.id);
    setInitialStep(entity);
  };

  function moveForward() {
    if (
      activeChildComponentIndex <
      getStepComponents[currentStep].components.length - 1
    ) {
      setActiveChildComponentIndex(activeChildComponentIndex + 1);
      return;
    } else if (currentStep < getStepComponents.length - 1) {
      setCurrentStep(currentStep + 1);
      setActiveChildComponentIndex(0);
    }
  }

  async function qcBeforeContinue(stepname: string) {
    const moveReady = QCChecker({ stepname: stepname });
    if (moveReady) {
      await SaveData();
    } else {
      setSaving(false);
      setShowError(true);
      setMessage("Please fill out all required fields to continue.");
    }
    setSaving(false);
  }

  async function handleOnPressContinue() {
    const element = document.getElementById("scrollToMe");
    element?.scrollIntoView(true);

    setSaving(true);

    if (!kyb) return;

    if (determineDisabled(kyb.kybStatus)) {
      setSaving(false);
      moveForward();
    } else {
      switch (
        stepComponents[currentStep].components[activeChildComponentIndex].name
      ) {
        case "Intro":
          moveForward();
          setSaving(false);
          break;
        case "Consent":
          if (consent) {
            moveForward();
            setSaving(false);
            break;
          } else {
            await qcBeforeContinue("Consent");
            break;
          }
        case "Other Entity": {
          moveForward();
          setSaving(false);
          break;
        }

        case "Business Information":
          await qcBeforeContinue("Business Information");
          break;

        case "Mid Business Information":
          await qcBeforeContinue("Mid Business Information");
          break;

        case "Sole Trader":
          await qcBeforeContinue("Sole Trader");
          break;

        case "Unlisted Company":
          await qcBeforeContinue("Unlisted Company");
          break;

        case "Mid Unlisted Company":
          await qcBeforeContinue("Mid Unlisted Company");
          break;

        case "Trust":
          await qcBeforeContinue("Trust");
          break;

        case "Partnership":
          await qcBeforeContinue("Partnership");
          break;

        case "Politically Exposed Person (PEP) Declaration":
          await qcBeforeContinue("PEP");
          break;

        case "Sanction Declaration":
          await qcBeforeContinue("Sanction Screening");
          break;

        default:
          await SaveData();
          break;
      }
    }
  }

  async function handleSubmit() {
    setSaving(true);
    if (!kyb || kyb.kybStatus.toLowerCase() !== KYBStatus.Open.toLowerCase()) {
      setMessage("KYB application is not open for submission.");
      setShowError(true);
      setSaving(false);
      return;
    }

    //In theory, we do QC before allowing the user to submit the KYB application.
    const response = await api.postSubmitKYB(kyb.id);

    if (response instanceof FetchError) {
      Sentry.captureException(response.error);
      setSaving(false);
      setMessage("Error submitting KYB application. Please try again.");
      setShowError(true);
      return;
    }
    setMessage("KYB application submitted successfully.");
    setSaving(false);
    setShowSuccess(true);
  }

  function handlePrevious() {
    const element = document.getElementById("scrollToMe");
    element?.scrollIntoView(true);

    if (activeChildComponentIndex > 0) {
      setActiveChildComponentIndex(activeChildComponentIndex - 1);
    } else if (currentStep > 0 && currentStep < stepComponents.length) {
      setCurrentStep(currentStep - 1);
      setActiveChildComponentIndex(
        stepComponents[currentStep - 1].components.length - 1
      );
    }
  }

  const growersEntityTitle = () => {
    const growerBusinessName = localStorage.getItem("growerBusinessName");
    if (
      nodes.length > 0 &&
      nodes[0].businessInfo &&
      nodes[0].businessInfo.legalName
    ) {
      return `${nodes[0].businessInfo.legalName}'s Entity`;
    } else if (growerBusinessName) {
      return `${growerBusinessName}'s Entity`;
    } else {
      return stepComponents[currentStep].title ?? "Grower's Entity";
    }
  };

  function StepsSideBar() {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full mt-16 lg:mt-0 pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-96">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="h-full overflow-y-auto bg-white ">
                      <div className="flex flex-col">
                        {stepComponents.slice(1).map((step, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              if (index < stepComponents.length) {
                                setCurrentStep(index + 1);
                                setActiveChildComponentIndex(0);
                                setOpen(false);
                              }
                            }}
                            className={`w-full cursor-pointer border border-gray-200 hover:bg-apple-50`}
                          >
                            {currentStep === index + 1 ? (
                              <div className="step bg-apple-100">
                                <span className="flex items-center px-6 py-3 text-sm font-medium">
                                  <span className="flex-shrink-0">
                                    <span className="flex h-8 w-8 items-center justify-center rounded-xl border-2 border-apple-600">
                                      <span className="text-apple-600">
                                        {index + 1}
                                      </span>
                                    </span>
                                  </span>
                                  <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                                    <span className="text-sm font-medium text-apple-600">
                                      {step.title}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            ) : (
                              <div className="group">
                                <span className="flex items-center px-6 py-3 text-sm font-medium">
                                  <span className="flex-shrink-0">
                                    <span className="flex h-8 w-8 items-center justify-center rounded-xl border-2 border-gray-300">
                                      <span className="text-gray-500">
                                        {index + 1}
                                      </span>
                                    </span>
                                  </span>
                                  <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                                    <span className="text-sm font-medium text-gray-500">
                                      {step.title}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  function WarningDialog() {
    return (
      <Transition.Root show={showWarning} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setShowWarning}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-950 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-xl bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-5 w-5 text-yellow-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-700"
                      >
                        Attention!
                      </Dialog.Title>
                      <div className="mt-2 text-gray-500">
                        <p className="text-sm">
                          It is crucial to provide all requested information to
                          avoid delays in the processing of your Know Your
                          Business (KYB) application.
                        </p>
                        <p className="text-sm mt-3 font-semibold">
                          Are you sure you want to continue?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-apple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-apple-500 sm:ml-3 sm:w-auto"
                      onClick={handleOnPressContinue}
                    >
                      Continue
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-apple-600 ring-2 ring-inset ring-apple-600 hover:bg-apple-100 sm:mt-0 sm:w-auto"
                      onClick={() => setShowWarning(false)}
                    >
                      Go Back
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  const shouldContinue =
    currentStep < stepComponents.length - 1 || !nodes.length;

  function determineContinue() {
    if (!kyb) return;
    if (shouldContinue) {
      handleOnPressContinue();
    } else {
      handleSubmit();
    }
  }
  const pageTitleDescription = useMemo(() => {
    const currentNode = nodes[getCurrentStepId];

    if (!nodes || !nodes.length) {
      return null;
    } else if (currentStep < 1) {
      return null;
    } else if (currentStep === 1) {
      return <span>Information about the entity you are representing.</span>;
    } else if (currentStep > stepComponents.length - 4) {
      return stepComponents[currentStep].description;
    } else if (
      currentNode.entityPerson?.entityType &&
      currentNode.entityPerson?.legalName
    ) {
      const parentNode = currentNode.parentNodeId
        ? nodes.find((node) => node.id === currentNode.parentNodeId)
        : undefined;
      return (
        <span>
          You entered{" "}
          <strong className="text-apple-700">{`${currentNode.entityPerson?.legalName}`}</strong>{" "}
          as a{" "}
          <strong className="text-apple-700">{`${currentNode.entityPerson?.entityType}`}</strong>{" "}
          in
          <strong className="text-apple-700">{` ${parentNode?.businessInfo?.legalName}'s`}</strong>{" "}
          <strong className="text-apple-700">{`${parentNode?.entityType}`}</strong>{" "}
          entity. Please provide more information about{" "}
          <strong className="text-apple-700">{`${currentNode.entityPerson?.legalName}`}</strong>
          .
        </span>
      );
    } else {
      return (
        <span>{`${
          stepComponents[getCurrentStepId + 1].description ?? ""
        }`}</span>
      );
    }
  }, [nodes, currentStep, stepComponents, getCurrentStepId]);

  const RenderComponent = useMemo(() => {
    if (!stepComponents[currentStep].components) return <Loading />;
    return stepComponents[currentStep].components[activeChildComponentIndex]
      .component as JSX.Element;
  }, [stepComponents, currentStep, activeChildComponentIndex]);

  return (
    <ComponentContext.Provider
      value={{
        componentId: getCurrentStepId,
        nodeData: nodes[getCurrentStepId],
        updateComponent: updateComponent,
        kybStatus: kyb?.kybStatus,
        growersBusinessLegalName: consent?.businessName,
      }}
    >
      <div id="scrollMe" />
      <div className="flex flex-1 flex-col">
        {StepsSideBar()}
        {
          <DisclaimerSideBar
            showDisclaimer={showDisclaimer}
            setShowDisclaimer={setShowDisclaimer}
          />
        }
        {
          <ReportIssueSidebar
            variant={ReportIssueVariant.KYB}
            showReportIssue={showNeedHelp}
            setShowReportIssue={setShowNeedHelp}
          />
        }
        <div
          className={`w-full ${
            currentStep === 0
              ? "hidden"
              : "flex flex-col sticky px-2 sm:px-4 lg:px-8 pt-4 sm:pt-4 lg:pt-8 top-[4rem] lg:top-0 z-50 bg-apple-100 border-b w-full border-gray-200"
          }`}
        >
          <div className="flex flex-col w-full ">
            <div
              className={`pb-3 flex-row justify-between w-full ${
                currentStep === 0 ? "hidden" : "flex"
              }`}
            >
              {stepComponents.slice(1).map((step, index) => (
                <div
                  key={index}
                  // onClick={() => {
                  //   if (index < stepComponents.length) {
                  //     setCurrentStep(index + 1);
                  //     setActiveChildComponentIndex(0);
                  //   }
                  // }}
                  className={`w-full  pr-1
              }`}
                >
                  <div
                    className={`flex flex-col w-full rounded-xl h-1.5 ${
                      currentStep === index + 1 ? "bg-apple-500" : "bg-gray-200"
                    }`}
                  />
                </div>
              ))}
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <div className="flex flex-row justify-between items-center w-full pb-4">
                <div className="w-2/3">
                  <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold leading-7 text-gray-900">
                    {currentStep < 1
                      ? ""
                      : currentStep === 1
                      ? growersEntityTitle()
                      : currentStep > stepComponents.length - 4
                      ? stepComponents[currentStep].title
                      : nodes[getCurrentStepId].entityPerson?.legalName
                      ? `${
                          nodes[getCurrentStepId].entityPerson?.legalName +
                            "'s Entity" ?? ""
                        }`
                      : `${stepComponents[getCurrentStepId + 1].title ?? ""}`}
                  </h2>
                  <p className="text-xs sm:text-base text-gray-500">
                    {pageTitleDescription}
                  </p>
                </div>
                {determineDisabled(kyb?.kybStatus) ? (
                  <div className="w-1/3">
                    <div className="flex flex-row justify-end items-center mr-2">
                      <Link to="/kyb">
                        <Button size="sm" variant="black-outline">
                          Exit
                        </Button>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="w-1/3">
                    <div className="flex flex-row justify-end items-center mr-2">
                      <Button
                        size="sm"
                        variant="black-outline"
                        onClick={() => {
                          setSaving(true);
                          SaveData("saveAndExit");
                        }}
                      >
                        Save & Exit
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-1 items-center mx-2 lg:mx-8 my-2">
          <WarningDialog />
          {
            <Warning show={exit} setShow={setExit}>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-apple-100 sm:mx-0 sm:h-10 sm:w-10">
                  <CheckIcon
                    className="h-5 w-5 text-apple-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-semibold leading-6 text-gray-700"
                  >
                    Saved!
                  </Dialog.Title>
                  <div className="mt-2 text-gray-500">
                    <p className="text-sm">
                      Your KYB progress has been saved. You can continue your
                      KYB application at a later time.
                    </p>
                    <p className="text-sm mt-3 font-semibold">
                      Are you sure you want to exit?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-apple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-apple-500 sm:ml-3 sm:w-auto"
                  onClick={() => {
                    navigate(`/kyb`);
                  }}
                >
                  Exit
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-apple-600 ring-2 ring-inset ring-apple-600 hover:bg-apple-100 sm:mt-0 sm:w-auto"
                  onClick={() => setExit(false)}
                >
                  Go Back
                </button>
              </div>
            </Warning>
          }
          {<OverlayLoading open={saving || kybLoading} />}
          {
            <ErrorDialog
              showError={showError}
              setshowError={setShowError}
              errorMessage={message}
            />
          }
          {
            <SuccessDialog
              showSuccess={showSuccess}
              setshowSuccess={setShowSuccess}
              successMessage="KYB application successfully submitted."
              onPressOK={() => navigate(`/feedback`)}
            />
          }
          {kyb ? RenderComponent : <PageNotFound />}
        </div>
        <div className="flex flex-col items-center mx-0 lg:mx-40">
          <div className="flex flex-row items-center gap-8 my-8">
            {currentStep !== 0 || activeChildComponentIndex !== 0 ? (
              <Button variant="outline" onClick={handlePrevious}>
                Previous
              </Button>
            ) : (
              <Button variant="outline" onClick={() => navigate("/kyb")}>
                Exit
              </Button>
            )}
            {shouldContinue ? (
              <Button onClick={determineContinue}>Continue</Button>
            ) : !determineDisabled(kyb?.kybStatus) ? (
              <Button onClick={determineContinue}>Submit</Button>
            ) : null}
          </div>
        </div>
        <div className="mt-4 border-t border-grey/10 py-8 lg:px-16 sm:mt-4 md:flex md:flex-col md:items-center lg:mt-4">
          <div className="flex flex-row w-full items-center justify-center">
            <a
              href="https://www.loambio.com/privacy"
              target="_blank"
              className="text-xs font-medium text-apple-500 hover:text-apple-900"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <p className="text-xs font-medium text-gray-500 mx-2">|</p>

            <p
              className="text-xs font-medium text-apple-500 hover:text-apple-900 cursor-pointer"
              onClick={() => setShowDisclaimer(true)}
            >
              Disclaimer
            </p>
            <p className="text-xs font-medium text-gray-500 mx-2">|</p>
            <p
              className="text-xs font-medium text-apple-500 hover:text-apple-900 cursor-pointer"
              onClick={() => setShowNeedHelp(true)}
            >
              Need Help? Contact Us
            </p>
          </div>
        </div>
      </div>
    </ComponentContext.Provider>
  );
}
