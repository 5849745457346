import { useEffect, useMemo, useState } from "react";
import Checkbox from "../../../components/checkbox-input";
import TextInput from "../../../components/text-input";
import {
  EmissionAttachmentType,
  EmissionVariant,
  MINIMUM_INPUT_WARNING,
  PastureCrops,
} from "../../../constants";
import useEmissionData from "../../../hooks/useEmissionData";
import { CoverCropData, CoverCrop as iCoverCrop } from "../../../types";
import { api } from "../../../api";
import { formatMonthYear } from "../../../helpers/format-month-year";
import useFarmInfo from "../../../hooks/emission/useFarmInfo";
import { useParams } from "react-router-dom";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";
import EmissionAttachments from "../emission-attachments";
import useEmissionActivity from "../../../hooks/emission/useEmissionActivity";
import formatDate from "../../../helpers/format-date";
import Divider from "../../../components/divider";

interface Props {
  year: number;
  variant?: EmissionVariant;
}

export default function CoverCrop({ year, variant }: Props) {
  const emissionData = useEmissionData(year);
  const farmInfo = useFarmInfo();
  const { projectId, emissionActivityId } = useParams();
  const emissionActivity = useEmissionActivity();
  const [coverCropData, setCoverCropData] = useState<iCoverCrop[]>([]);
  const [yearCoverCrop, setYearCoverCrop] = useState<iCoverCrop | undefined>(
    undefined
  );
  const { projectAttachment, mutate } = useProjectAttachment(
    projectId,
    emissionActivityId,
    EmissionAttachmentType.CoverCrop
  );

  useEffect(() => {
    if (!emissionData || !emissionData.coverCrop) {
      setCoverCropData([]);
      return;
    }
    setCoverCropData(emissionData.coverCrop);
  }, [emissionData]);

  useEffect(() => {
    if (!projectId || !emissionActivityId) return;
    if (
      !coverCropData.length ||
      !coverCropData.find((covercrop) => covercrop.activityYear === year)
    ) {
      setYearCoverCrop({
        projectId: +projectId,
        emissionActivityId: +emissionActivityId,
        activityYear: year,
        coverCropData: [] as CoverCropData[],
      });
      return;
    }

    setYearCoverCrop(
      coverCropData.find((covercrop) => covercrop.activityYear === year)
    );
  }, [coverCropData, year, emissionData, projectId, emissionActivityId]);

  useEffect(() => {
    function saveCoverCrop() {
      if (!yearCoverCrop) return;
      api.saveCoverCrop(yearCoverCrop);
    }
    saveCoverCrop();
  }, [yearCoverCrop]);

  const requiredAttachmentTypes = useMemo(() => {
    if (!yearCoverCrop?.coverCropData) return [];
    return yearCoverCrop.coverCropData
      .map((covercrop) => `${covercrop.pastureCrop} - Area Sown`)
      .filter(Boolean);
  }, [yearCoverCrop]);

  return (
    <div className="flex flex-col items-center w-full" key={year}>
      {!yearCoverCrop ||
      !projectId ||
      !emissionActivityId ||
      !emissionActivity ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p>No Data</p>
        </div>
      ) : (
        <div className="w-full">
          <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
            {variant !== EmissionVariant.OnGoing ? (
              formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
            ) : (
              <span>
                {formatDate(emissionActivity.activityStartDate)} -{" "}
                {formatDate(emissionActivity.activityEndDate)}
              </span>
            )}{" "}
            Cover Crops
          </h2>
          <Divider />

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block w-full text-sm font-medium leading-6 text-gray-900">
                Please select all the pasture and cover crops you planted in the
                project area between{" "}
                <span className="font-semibold text-apple-700">
                  {variant !== EmissionVariant.OnGoing ? (
                    formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
                  ) : (
                    <span>
                      {formatDate(emissionActivity.activityStartDate)} -{" "}
                      {formatDate(emissionActivity.activityEndDate)}
                    </span>
                  )}
                </span>
                .
                <div className="mt-1 text-sm leading-6 text-gray-600">
                  When selecting from the list below, please consider:
                  <ul className="list-disc list-inside">
                    <li>
                      <strong className="text-gray-800">Cover crops</strong> are
                      not harvested or grazed.
                    </li>
                    <li>
                      <strong className="text-gray-800">Foraged crops</strong>{" "}
                      are not harvested and are grazed directly by livestock.
                    </li>
                  </ul>
                </div>
              </label>
              {(!yearCoverCrop.coverCropData ||
                yearCoverCrop.coverCropData.length === 0) &&
              variant === EmissionVariant.OnGoing ? (
                <p className="pt-2 text-xs text-red-600" id="error">
                  This field is required!
                </p>
              ) : null}
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-3 lg:gap-4 mt-3">
                {Object.entries(PastureCrops).map(([key, value]) => (
                  <div
                    key={key}
                    className="flex flex-row items-center w-full col-span-1"
                  >
                    <Checkbox
                      checked={
                        yearCoverCrop.coverCropData?.find(
                          (covercrop) => covercrop.pastureCrop === value
                        )
                          ? true
                          : false
                      }
                      value={key}
                      label={value}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setYearCoverCrop({
                            ...yearCoverCrop,

                            coverCropData: [
                              ...(yearCoverCrop.coverCropData ?? []),
                              {
                                pastureCrop: value,
                                areaSown: undefined,
                              },
                            ],
                          });
                        } else {
                          setYearCoverCrop({
                            ...yearCoverCrop,
                            coverCropData: [
                              ...(yearCoverCrop.coverCropData ?? []).filter(
                                (covercrop) => covercrop.pastureCrop !== value
                              ),
                            ],
                          });
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {yearCoverCrop.coverCropData && yearCoverCrop.coverCropData.length ? (
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
              <div className="col-span-full">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Please tell us about the area for each pasture or cover crop
                  between{" "}
                  <span className="font-semibold text-apple-700">
                    {variant !== EmissionVariant.OnGoing ? (
                      formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
                    ) : (
                      <span>
                        {formatDate(emissionActivity.activityStartDate)} -{" "}
                        {formatDate(emissionActivity.activityEndDate)}
                      </span>
                    )}
                  </span>
                  .
                </label>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full ">
                        <thead>
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                            ></th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                            >
                              Area Sown
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-0.5 text-center px-5 text-sm font-normal text-gray-900 "
                            ></th>

                            <th
                              scope="col"
                              className="py-0.5 pl-4 pr-4  text-xs font-normal text-gray-900 sm:pr-0 text-center bg-apple-50"
                            >
                              Hectares Sown
                            </th>
                          </tr>
                          {yearCoverCrop.coverCropData.map((covercrop, i) => (
                            <tr key={i} className="divide-x divide-gray-200">
                              <td className="whitespace-wrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center">
                                {covercrop.pastureCrop as PastureCrops}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={covercrop.areaSown ?? ""}
                                  onChange={(e) => {
                                    if (+e.target.value < 0) {
                                      alert(MINIMUM_INPUT_WARNING);
                                    } else {
                                      const newCoverCrop = [
                                        ...(yearCoverCrop.coverCropData ?? []),
                                      ];
                                      newCoverCrop[i].areaSown = e.target.value
                                        .length
                                        ? parseFloat(e.target.value)
                                        : undefined;
                                      setYearCoverCrop({
                                        ...yearCoverCrop,
                                        coverCropData: newCoverCrop,
                                      });
                                    }
                                  }}
                                  error={
                                    variant === EmissionVariant.OnGoing &&
                                    (covercrop.areaSown === undefined ||
                                      covercrop.areaSown === null)
                                      ? 1
                                      : 0
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <Divider />
          {(variant === EmissionVariant.Baseline ||
            requiredAttachmentTypes.length > 0) && (
            <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
              Attachments
            </h2>
          )}
          <div className="m-1">
            <div className="mt-1 ">
              <EmissionAttachments
                variant={variant}
                emissionActivity={emissionActivity}
                emissionActivityId={+emissionActivityId}
                projectId={+projectId}
                month={farmInfo.carbonRegMonth}
                year={year}
                attachments={projectAttachment}
                title="Cover Crop"
                attachmentKind={EmissionAttachmentType.CoverCrop}
                mutateAttachments={mutate}
                attachmentTypes={requiredAttachmentTypes}
                uploadRequired={
                  variant === EmissionVariant.Baseline ||
                  requiredAttachmentTypes.length > 0
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
