import { getAuth } from "firebase/auth";
import fetch from "isomorphic-fetch";
import firebaseApp from "../firebaseConfig";

export class FetchError<T = unknown> extends Error {
  public status: number;

  public error: T;

  public constructor(status: number, error: T) {
    super(`Fetch ERROR: received status: ${status}`);
    this.status = status;
    this.error = error;
  }
}

export type FetcherRequest = Omit<RequestInit, "body"> & {
  body?: unknown;
  tryToRefresh?: boolean;
  url: string;
  route: string;
};

type Awaitable<T> = T | Promise<T>;

type FetcherInterceptor<SuccessType, ErrorType = unknown> = (
  request: FetcherRequest,
  response: Response
) => (() => Awaitable<SuccessType | FetchError<ErrorType>>) | null;

export default class Fetcher {
  protected baseUrl: string;

  private getAccessToken?: () => string | null;

  private requestSource: string;

  private interceptors: FetcherInterceptor<unknown>[] = [];

  public constructor({
    baseUrl,
    getAccessToken,
  }: {
    baseUrl: string;
    getAccessToken?: () => string | null;
  }) {
    this.baseUrl = baseUrl;
    this.getAccessToken = getAccessToken;
    this.requestSource = "GROWERS_UI";
  }

  private encodeQueryString(query: Record<string, string | string[]>) {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(query)) {
      if (value === undefined) {
        continue;
      }
      if (Array.isArray(value)) {
        for (const elem of value) {
          params.append(key, elem.toString());
        }
      } else {
        params.set(key, value.toString());
      }
    }
    return params.toString();
  }

  private defaultInterceptor: FetcherInterceptor<unknown> =
    (_: FetcherRequest, response: Response) => async () => {
      let json!: unknown;
      try {
        json = await response.json();
      } catch {
        json = {
          info: "Response from the server was empty",
        };
      }
      if (!response.ok) {
        return new FetchError(response.status, json);
      }
      return json;
    };

  private getInterceptorMethod<SuccessType, ErrorType = unknown>(
    request: FetcherRequest,
    response: Response
  ): () => Awaitable<SuccessType | FetchError<ErrorType>> {
    for (const candidateInterceptor of this.interceptors) {
      const interceptorMethod = candidateInterceptor(request, response);
      if (interceptorMethod !== null) {
        return interceptorMethod as () => Awaitable<
          SuccessType | FetchError<ErrorType>
        >;
      }
    }
    return this.defaultInterceptor(request, response) as () => Awaitable<
      SuccessType | FetchError<ErrorType>
    >;
  }

  public addInterceptor(interceptor: FetcherInterceptor<unknown>): void {
    this.interceptors.push(interceptor);
  }

  public async fetch<SuccessType, ErrorType = unknown>(
    route: string,
    init: Omit<RequestInit, "body"> & {
      query?: unknown;
      body?: unknown;
      tryToRefresh?: boolean;
    } = {}
  ): Promise<SuccessType | FetchError<ErrorType>> {
    const accessToken = await getAuth(firebaseApp).currentUser?.getIdToken();

    const token = accessToken;
    const url = `${this.baseUrl}/${
      route.startsWith("/") ? route.slice(1) : route
    }${
      init.query
        ? `?${this.encodeQueryString(init.query as Record<string, string[]>)}`
        : ""
    }`;
    const config = {
      ...init,
      body: init.body ? JSON.stringify(init.body) : undefined,
      headers: {
        ...init?.headers,
        authorization: token ? `Bearer ${token}` : "",
        "x-request-source": this.requestSource,
        ...(init.body ? { "content-type": "application/json" } : {}),
      },
    };
    const response = await fetch(url, config);

    const handler = this.getInterceptorMethod(
      {
        route,
        url,
        ...config,
      },
      response
    );
    return (await handler()) as SuccessType | FetchError<ErrorType>;
  }
}