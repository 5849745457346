import { useEffect, useState } from "react";
import useAuth from "./hooks/useAuth";
import { AuthContext } from "./AuthContext";
import Loading from "./components/loading";
import Login from "./views/login";
import Home from "./views/home";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import KYCWelcome from "./views/welcome";
import Header from "./components/header";
import SideBar from "./components/sidebar";
import Projects from "./views/projects";
import SoleTrader from "./views/kyb-kyc-components/sole-trader";
import Project from "./views/projects/project";
import Logout from "./views/logout";
import KYBs from "./views/kybs";
import Feedback from "./views/feedback";
import OngoingEmissionRouter from "./views/emission-calculator/ongoing/ongoing-emission";
import EmissionCalculator from "./views/emission-calculator";
import EmissionCalculatorRouter from "./views/emission-calculator/emission-calculator-router";
import KYBKYC from "./views/kyb-kyc";

import * as Sentry from "@sentry/react";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const { user, loading } = useAuth();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("src", "https://analytics.us.umami.is/script.js");
    script.setAttribute(
      "data-website-id",
      "13eb4adc-4ad9-46e7-aa51-8985ecd6b925"
    );
    script.setAttribute("defer", "true");

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="flex min-h-full flex-1 w-full">
      <AuthContext.Provider
        value={{
          user: null,
          setUser(user) {
            this.setUser(user);
          },
        }}
      >
        <BrowserRouter>
          {loading ? (
            <Loading />
          ) : user ? (
            <div className="flex w-full flex-row">
              <div className="hidden lg:flex lg:w-1/5 xl:w-1/6">
                <SideBar sidebarOpen={open} setSidebarOpen={setOpen} />
              </div>
              <div className="flex flex-col w-full lg:w-4/5 xl:w-5/6 bg-white text-gray-800 ">
                <Header user={user} setSidebarOpen={setOpen} />
                <main className="flex-1">
                  <SentryRoutes>
                    <Route path="/" element={<Home />} />
                    <Route path="/feedback" element={<Feedback />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/projects/:projectId" element={<Project />} />
                    <Route
                      path="/projects/:projectId/emission-calculator"
                      element={<EmissionCalculator />}
                    />
                    <Route
                      path="/projects/:projectId/emission-calculator/:emissionActivityId/*"
                      element={<EmissionCalculatorRouter />}
                    />
                    <Route
                      path="/projects/:projectId/ongoing-emission-calculator/*"
                      element={<OngoingEmissionRouter />}
                    />
                    <Route path="/kyb" element={<KYBs />} />
                    <Route path="/kyb/:kybId/kyb-kyc" element={<KYBKYC />} />
                    <Route path="/logout" element={<Logout />} />

                    <Route
                      path="/kyb-kyc/sole-trader"
                      element={<SoleTrader />}
                    />
                  </SentryRoutes>
                </main>
              </div>
            </div>
          ) : (
            <SentryRoutes>
              <Route path="/" element={<KYCWelcome />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/" />} />
            </SentryRoutes>
          )}
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
