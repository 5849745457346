import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";

interface DisclaimerSideBarProps {
  showDisclaimer: boolean;
  setShowDisclaimer: (value: boolean) => void;
}

export default function DisclaimerSideBar({
  showDisclaimer,
  setShowDisclaimer,
}: DisclaimerSideBarProps) {
  return (
    <Transition.Root show={showDisclaimer} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setShowDisclaimer}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full mt-16 lg:mt-0 pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen lg:max-w-2xl xl:max-w-4xl">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setShowDisclaimer(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white">
                    <div className="flex flex-col items-start">
                      <div className="px-4 py-6 sm:px-6 text-gray-700">
                        <h1 className="font-bold tracking-tight text-gray-900 sm:text-3xl py-3">
                          Disclaimer
                        </h1>
                        <p className="text-sm lg:text-base leading-6 text-gray-700">
                          In this portal, unless the context otherwise requires,
                          ‘Loam’ or ‘Loam Carbon’ refers to “Loam Carbon Pty Ltd
                          ACN 662 279 861”, which is a wholly-owned subsidiary
                          of Loam Bio Pty Ltd ACN 636 407 639 and has prepared
                          the information in this document.
                        </p>

                        <p className="mt-4 text-sm lg:text-base leading-6 text-gray-700">
                          This important notice is being provided to you (“You”)
                          because You have received, or will in the future
                          receive, information from Loam Carbon, including the
                          information in this document and any other information
                          from time to time concerning Loam Carbon Projects
                          (“Loam Information”). This important notice should be
                          read in conjunction with Loam Information.
                        </p>

                        <p className="mt-4 text-sm lg:text-base leading-6 text-gray-700">
                          Only Loam Carbon authorised representatives may give
                          information and/or make representation in connection
                          with a Loam Carbon Project.
                        </p>
                        <p className="mt-4 text-sm lg:text-base leading-6 text-gray-700">
                          Loam Information is general in nature. Loam
                          Information contains factual information only and does
                          not constitute or include financial product advice, or
                          tax or investment advice. By providing Loam
                          Information, Loam Carbon does not intend to provide or
                          imply financial product advice or any financial
                          recommendation or opinion about any financial product
                          including a recommendation to enter into a Carbon
                          Project Contract relating to a Loam Carbon Project.
                          Loam Information is not intended to, nor should it be
                          construed as, an offer, invitation, solicitation or
                          recommendation with respect to any financial products.
                        </p>
                        <p className="mt-4 text-sm lg:text-base leading-6 text-gray-700">
                          Loam Information has been prepared to the best of the
                          knowledge and belief of Loam Carbon. If it comprises
                          third party information, Loam Carbon has not verified
                          the accuracy of that information . To the maximum
                          extent permitted by law, Loam Carbon, its related
                          bodies corporate and their respective directors,
                          officers, employees or agents excludes and disclaims
                          all liability, including (without limitation) for
                          negligence or for any expenses, losses, damages or
                          costs incurred (directly or indirectly) by You in
                          respect of any Loam Information or by any action taken
                          by You or any other person on the basis of any Loam
                          Information.
                        </p>
                        <p className="mt-4 text-sm lg:text-base leading-6 text-gray-700">
                          Loam Carbon does not hold an Australian Financial
                          Services Licence ("AFSL") and is not required to hold
                          an AFSL.
                        </p>
                        <p className="mt-4 text-sm lg:text-base leading-6 text-gray-700">
                          There are a number of eligibility criteria which must
                          be satisfied before Loam Carbon will proceed with a
                          Loam Carbon Project with a grower. One of the
                          eligibility criteria that must be met before Loam
                          Carbon enters into a Carbon Project Contract with You
                          is to confirm that You are a wholesale client as
                          defined in the Corporations Act. Only wholesale
                          clients as defined under the Corporations Act are
                          eligible to participate in a Loam Carbon Project with
                          Loam Carbon.
                        </p>
                        <p className="mt-4 text-sm lg:text-base leading-6 text-gray-700">
                          Neither Loam Carbon nor its related bodies corporate,
                          and/or their respective directors, officers, employees
                          or agents acts as the adviser of or owes any fiduciary
                          or other duties to You in connection with a Carbon
                          Project Contract, except as expressly provided for in
                          the Carbon Project Contract if entered into between
                          Loam Carbon and You.
                        </p>
                        <p className="mt-4 text-sm lg:text-base leading-6 text-gray-700">
                          Accordingly, if You are contemplating entering into a
                          Carbon Project Contract, then You should conduct Your
                          own independent investigation and due diligence
                          enquiries into the terms of the proposed Carbon
                          Project Contract and seek such independent advice as
                          you deem appropriate.
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
