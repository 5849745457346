import { FolderIcon, HomeIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";

interface MenuItemsProps {
  setSidebarOpen: (sidebarOpen: boolean) => void;
}

export default function MenuItems({ setSidebarOpen }: MenuItemsProps) {
  const navigation = [
    { name: "Home", href: "/", icon: HomeIcon },
    { name: "Know Your Business", href: "/kyb", icon: FolderIcon },
    { name: "Projects", href: "/projects", icon: FolderIcon },
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const location = useLocation().pathname.split("/")[1];

  return (
    <nav className="flex flex-1 flex-col">
      <div className="flex flex-1 flex-col gap-y-5">
        {navigation.map((item, i) => (
          <Link
            key={i}
            to={item.href}
            onClick={() => setSidebarOpen(false)}
            className={classNames(
              item.href.split("/")[1].toLowerCase() === location
                ? "bg-apple-600 text-white"
                : "text-gray-400 hover:text-white hover:bg-apple-400",
              "group flex gap-x-1 rounded-md py-2 px-2 text-xxs leading-6 font-semibold items-center"
            )}
          >
            <item.icon className="h-5 w-5 shrink-0 mr-2" aria-hidden="true" />
            {item.name}
          </Link>
        ))}
      </div>
    </nav>
  );
}
