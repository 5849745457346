import { useEffect, useState } from "react";
import { EmissionAttachment } from "../../types";
import { api } from "../../api";
import { FetchError } from "../../lib/fetcher";

export default function useProjectAttachment(
  projectId?: string,
  emissionActivityId?: string,
  attachmentType?: string
) {
  const [projectAttachment, setNewData] = useState<EmissionAttachment[]>([]);
  function mutate(attachment: EmissionAttachment) {
    setNewData((prev) => [...prev, attachment]);
    localStorage.setItem("NumOfAttachments", `${projectAttachment.length + 1}`);
  }

  useEffect(() => {
    async function fetchProjectAttachment() {
      if (!projectId || !attachmentType || !emissionActivityId) return;
      const response = await api.getEmissionAttachment(
        +projectId,
        +emissionActivityId,
        attachmentType
      );
      if (response instanceof FetchError) {
        return;
      }
      setNewData(response.attachments);
      localStorage.setItem(
        "NumOfAttachments",
        `${response.attachments.length}`
      );
    }

    fetchProjectAttachment();
  }, [attachmentType, emissionActivityId, projectId]);

  return { projectAttachment, mutate };
}
