import { useState, useEffect } from "react";
import firebaseApp from "../firebaseConfig";
import { getAuth, signOut } from "firebase/auth";
import { User } from "../types";
import { api } from "../api";
import { FetchError } from "../lib/fetcher";

export default function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth(firebaseApp);

    const unsubscribe = auth.onAuthStateChanged(
      async (firebaseUser) => {
        if (firebaseUser && firebaseUser.email) {
          const response = await api.initializeUser(firebaseUser.email);
          if (response instanceof FetchError) {
            await signOut(auth).then(() => {
              setUser(null);
            });
          } else {
            setUser(response.user);
          }
        } else {
          await signOut(auth).then(() => {
            setUser(null);
          });
          setUser(null);
        }
        setLoading(false);
      },
      (error) => {
        alert(error);
        setLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);
  return { user, loading };
}