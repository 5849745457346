import { useEffect } from "react";
import Loading from "../components/loading";
import { getAuth, signOut } from "firebase/auth";
import firebaseApp from "../firebaseConfig";

export default function Logout() {
  useEffect(() => {
    window.localStorage.clear();
    localStorage.setItem("token", "");
    const auth = getAuth(firebaseApp);
    signOut(auth).then(() => {
      localStorage.removeItem("token");
    });
  }, []);

  return <Loading />;
}