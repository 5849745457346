import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import TextInput from "./text-input";
import SelectInput from "./select-input";
import TextArea from "./text-area";
import Button from "./button";
import Divider from "./divider";
import {
  CustomerSupportEmail,
  CustomerVerificationEmail,
  ReportIssueOptions,
  ReportIssueVariant,
} from "../constants";
import { ReportIssueType } from "../types";
import useMe from "../hooks/useMe";
import { api } from "../api";
import { FetchError } from "../lib/fetcher";

interface ReportIssueSideBarProps {
  showReportIssue: boolean;
  setShowReportIssue: (value: boolean) => void;
  variant: ReportIssueVariant;
}

export default function ReportIssueSidebar({
  showReportIssue,
  setShowReportIssue,
  variant,
}: ReportIssueSideBarProps) {
  const [newIssue, setNewIssue] = useState<ReportIssueType>();
  const me = useMe();

  useEffect(() => {
    if (!me) return;
    setNewIssue({
      toEmail:
        variant === ReportIssueVariant.KYB
          ? CustomerVerificationEmail
          : CustomerSupportEmail,
      user: me,
    });
    return;
  }, [me, variant]);

  async function submitIssue() {
    if (
      !newIssue ||
      !newIssue.issueDate ||
      !newIssue.issueType ||
      !newIssue.issueDescription
    ) {
      alert("Please fill in all fields");
      return;
    }
    const response = await api.postReportIssue(newIssue);
    if (response instanceof FetchError) {
      alert("An error occurred while submitting your request");
      return;
    }
    alert("Your issue has been submitted successfully");
    setNewIssue(undefined);
    setShowReportIssue(false);
  }

  return (
    <Transition.Root show={showReportIssue} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setShowReportIssue}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            onClick={() => setShowReportIssue(false)}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full lg:mt-0 pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen lg:max-w-2xl">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setShowReportIssue(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white">
                    <div className="flex flex-col items-start">
                      <div className="px-4 py-3 sm:px-6 w-full">
                        <h1 className="font-bold tracking-tight text-apple-600 sm:text-3xl pb-3">
                          Report an issue
                        </h1>
                        <div className="mt-1 grid  gap-x-6 gap-y-0 grid-cols-12 px-2 sm:px-4">
                          <div className="col-span-full ">
                            <TextInput
                              label="When did you notice the issue?"
                              type="date"
                              max={new Date().toISOString().split("T")[0]}
                              value={newIssue?.issueDate || ""}
                              onChange={(e) => {
                                setNewIssue({
                                  ...newIssue,
                                  issueDate: e.target.value,
                                });
                              }}
                              error={newIssue?.issueDate ? 0 : 1}
                            />
                          </div>
                          <div className="col-span-full sm:mt-4">
                            <SelectInput
                              value={newIssue?.issueType || ""}
                              onChange={(e) => {
                                setNewIssue({
                                  ...newIssue,
                                  issueType: e.target
                                    .value as ReportIssueOptions,
                                });
                              }}
                              error={newIssue?.issueType ? 0 : 1}
                              label="Please select an option that best describes the issue. If you don't see an option that fits, please select 'Other' and provide a brief description."
                            >
                              <option></option>
                              {Object.entries(ReportIssueOptions).map(
                                ([key, value]) => (
                                  <option key={key} value={value}>
                                    {value}
                                  </option>
                                )
                              )}
                            </SelectInput>
                          </div>
                          <div className="col-span-full sm:mt-4">
                            <TextArea
                              label="Please describe the issue in detail. Include any relevant information that may help us understand the issue."
                              rows={5}
                              value={newIssue?.issueDescription || ""}
                              error={newIssue?.issueDescription ? 0 : 1}
                              onChange={(e) => {
                                setNewIssue({
                                  ...newIssue,
                                  issueDescription: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex justify-end mt-4 w-full px-2 sm:px-4 pb-6">
                          <Button onClick={submitIssue}>Submit</Button>
                        </div>
                        <Divider />
                        <div className="px-2 sm:px-4 py-3">
                          <p className="text-md font-medium text-gray-900 text-center">
                            Alternatively, you can{" "}
                            {variant === ReportIssueVariant.KYB ? (
                              <span>
                                email us at{" "}
                                <a
                                  href={`mailto:${CustomerVerificationEmail}?subject=KYB%20Application%20Support`}
                                  className="text-apple-600"
                                >
                                  {CustomerVerificationEmail}
                                </a>
                              </span>
                            ) : (
                              <span>
                                reach out to your Loam Carbon Agronomist.
                              </span>
                            )}
                          </p>
                          <p className="text-xs font-medium text-gray-600 text-center mt-3">
                            Please provide as much detail as possible in your
                            email to help us understand the issue and resolve it
                            as quickly as possible.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
