import { SetStateAction } from "react";
import { Attachment, KybNode } from "../types";
import { api } from "../api";
import { FetchError } from "../lib/fetcher";
import {
  AlternatePartnershipAttachmentType,
  KYBStatus,
  OtherAttachmentType,
  PartnershipAttachmentType,
  TrustAttachmentType,
  UnlistedCompanyAttachmentType,
} from "../constants";
import {
  ExclamationCircleIcon,
  PaperClipIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import Modal from "./modal";
import SelectInput from "./select-input";
import EmptyPlaceholder from "./empty-placeholder";
import determineDisabled from "../helpers/kyb-kyc/determine-disabled";

interface AttachmentsProps {
  nodeData: KybNode;
  openAttachmentModal: boolean;
  setOpenAttachmentModal: (value: SetStateAction<boolean>) => void;
  setAttachmentLoading: (value: SetStateAction<boolean>) => void;
  areyousure: boolean;
  setAreyousure: (value: SetStateAction<boolean>) => void;
  attachment: Attachment | undefined;
  setAttachment: (value: SetStateAction<Attachment | undefined>) => void;
  mutateNodeAttachments: (value: Attachment) => void;
  removeAttachment: (id?: number) => void;
  kybStatus?: KYBStatus;
  AttachmentType:
    | typeof PartnershipAttachmentType
    | typeof TrustAttachmentType
    | typeof UnlistedCompanyAttachmentType
    | typeof OtherAttachmentType
    | typeof AlternatePartnershipAttachmentType
    | undefined;
}

export default function AttachmentsModals({
  nodeData,
  openAttachmentModal,
  setOpenAttachmentModal,
  setAttachmentLoading,
  areyousure,
  setAreyousure,
  attachment,
  setAttachment,
  mutateNodeAttachments,
  removeAttachment,
  AttachmentType,
  kybStatus,
}: AttachmentsProps) {
  const filteredAttachmentsType = Object.entries(AttachmentType ?? {});

  async function handleUploadAttachment() {
    if (!attachment) return;

    if (
      !attachment.attachmentType ||
      !attachment.attachmentUrl ||
      !nodeData ||
      !nodeData.kybId ||
      !nodeData.id
    )
      return;

    setAttachmentLoading(true);

    const response = await api.postAttachDocumentInitial(
      nodeData.kybId,
      nodeData.id,
      attachment
    );

    if (response instanceof FetchError) {
      setOpenAttachmentModal(false);
      setAttachmentLoading(false);
      alert(
        (response.error as { message: string }).message ??
          "There was an error uploading the file"
      );
      setAttachment(undefined);

      return;
    } else {
      setOpenAttachmentModal(false);
      setAttachmentLoading(false);
      mutateNodeAttachments(response.attachment);
      setAttachment(undefined);
    }
  }

  function UploadAttachmentModel() {
    return (
      <Modal
        open={areyousure}
        setOpen={setAreyousure}
        dontShowHeader
        title="Upload File"
        onPressCancel={() => {
          setAttachment(undefined);
          setOpenAttachmentModal(false);
          setAreyousure(false);
        }}
        actionButtonName="Upload"
        onPressAdd={() => {
          setOpenAttachmentModal(false);
          setAreyousure(false);
          handleUploadAttachment();
        }}
      >
        <div className="sm:flex sm:items-start ">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-xl bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationCircleIcon
              className="h-5 w-5 text-yellow-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <div className="h3 text-lg font-semibold leading-6 text-gray-700">
              Attention!
            </div>
            <div className="mt-2 text-gray-500">
              <p className="text-sm">
                Are you sure you want to upload this file as a{" "}
                <span className="font-bold text-gray-700">
                  {attachment?.attachmentType}
                </span>{" "}
                attachment for this entity?
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  function AddAttachmentModal() {
    return (
      <Modal
        open={openAttachmentModal}
        setOpen={setOpenAttachmentModal}
        title="Upload File"
        actionButtonName="Upload"
        actionButtonType={
          !attachment || !attachment.attachmentType || !attachment.attachmentUrl
            ? "disabled"
            : "primary"
        }
        onPressCancel={() => {
          setOpenAttachmentModal(false);
          setAttachment(undefined);
        }}
        onPressAdd={() => {
          if (!attachment) return;

          if (!attachment.attachmentType || !attachment.attachmentUrl) return;
          setOpenAttachmentModal(false);
          setAreyousure(true);
        }}
      >
        <div className="flex flex-1 flex-col w-full">
          <div className="">
            <SelectInput
              label="File Type"
              onChange={(e) => {
                setAttachment({
                  ...attachment,
                  attachmentType: e.target.value,
                });
              }}
              error={!attachment?.attachmentType ? 1 : undefined}
            >
              <option value={""}></option>
              {filteredAttachmentsType.map(([key, value]) => (
                <option key={key} value={value}>
                  {value}
                </option>
              ))}
            </SelectInput>
          </div>
          {AttachmentType === AlternatePartnershipAttachmentType ? (
            <p className="mt-1 text-xs text-apple-600">
              Please upload a copy of a partnership evidence document (e.g.
              Utility bills, Rates Notices, or Bank Statements, etc. in the
              partnership name).
            </p>
          ) : null}
          <div className="mt-2">
            <label
              htmlFor="cover-photo"
              className="block text-xs font-medium text-gray-900"
            >
              File
            </label>

            {attachment && attachment.attachmentUrl ? (
              <div className="divide-y divide-gray-100 rounded-md border border-gray-200">
                <div className="flex items-center justify-between p-2 text-sm leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex flex-col">
                      <div className="flex min-w-0 flex-1 gap-2">
                        <span className="truncate font-medium">
                          {attachment?.attachmentType}
                        </span>
                      </div>
                      <p className="text-xs text-gray-500">
                        {attachment.attachmentName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="mt-1 flex justify-center rounded-xl border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center items-center">
                    <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 flex flex-row justify-center items-center text-sm leading-6 text-gray-600 text-center">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer text-center rounded-md bg-white font-semibold text-apple-600 py-1 px-2 border-2 border-apple-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-apple-600 focus-within:ring-offset-2 hover:text-apple-500"
                      >
                        <span className="text-center">Add a file</span>
                        <input
                          onChange={(e) => {
                            if (!e.target.files) {
                              alert("Please select a file");
                              return;
                            }
                            if (e.target.files.length > 1) {
                              alert("Please select only one file");
                              return;
                            }
                            if (!e.target.files[0]) {
                              alert("Please select a file");
                              return;
                            }
                            if (e.target.files[0].size > 5242880) {
                              alert("File size should be less than 5MB");
                              return;
                            }
                            const newFile = new FileReader();
                            const file = e.target.files[0];
                            newFile.readAsDataURL(file);
                            newFile.onload = () => {
                              setAttachment({
                                ...attachment,
                                attachmentName: file.name,
                                attachmentUrl: newFile.result as string,
                              });
                            };
                          }}
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          accept="image/*,.doc,.docx,.pdf"
                        />
                      </label>
                      {/* <p className="pl-1">or drag and drop</p> */}
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      Image, Word, PDF up to 5MB
                    </p>
                  </div>
                </div>
                <p className="mt-0.5 text-xs text-red-600" id="email-error">
                  Please add a file to upload!
                </p>
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div className="flex flex-col w-full">
      {AddAttachmentModal()}
      {UploadAttachmentModel()}
      <div className="flex flex-row justify-between items-center w-full pt-4">
        <div className="w-1/2">
          <p className="block text-md font-medium leading-6 text-apple-600 ">
            {`${nodeData.entityType} Validation`}
          </p>
          <p className="mt-1 text-xs text-gray-500">
            To validate your <strong>{`${nodeData.entityType}`}</strong>, we
            require that you upload a copy of your{" "}
            {Object.entries(AttachmentType ?? {}).map(([, value], i) => (
              <span key={i}>
                {i < Object.entries(AttachmentType ?? {}).length - 2 ? (
                  <strong>{value}, </strong>
                ) : (
                  <strong>
                    {value}
                    {i === Object.entries(AttachmentType ?? {}).length - 2
                      ? ", or"
                      : "."}{" "}
                  </strong>
                )}
              </span>
            ))}{" "}
            Please click the “Upload a file” button to share with us the
            required documents.
          </p>
        </div>

        <div>
          {!determineDisabled(kybStatus) ? (
            <button
              className="flex w-full rounded-xl hover:bg-apple-500 bg-transparent border-2 border-apple-600 py-0.5 hover:text-white hover:border-apple-500 px-4 text-sm font-semibold text-apple-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-600"
              onClick={() => setOpenAttachmentModal(true)}
            >
              Upload a file
            </button>
          ) : null}
        </div>
      </div>
      <div className="mt-4 w-full grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-12 px-2 sm:px-4">
        {!nodeData.attachments || nodeData.attachments.length < 1 ? (
          <div className="col-span-full">
            <EmptyPlaceholder>No file uploaded yet.</EmptyPlaceholder>
          </div>
        ) : (
          nodeData.attachments.map((attachment, index) => (
            <div
              key={index}
              className="sm:col-span-6 rounded-xl border border-gray-200"
            >
              <div className="flex items-center justify-between p-2 text-sm leading-6">
                <div className="flex w-0 flex-1 items-center">
                  <PaperClipIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <div className="ml-4 flex flex-col">
                    <div className="flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">
                        {attachment.attachmentType}
                      </span>
                    </div>
                    <p className="text-xs text-gray-500">
                      {attachment.attachmentName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
