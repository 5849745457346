import { useEffect, useState } from "react";
import { ActivityInfo } from "../../types";

export default function useActivityInfo() {
  const [newData, setNewData] = useState<ActivityInfo>({
    activityYears: [],
    irrigation: undefined,
    limeApplication: undefined,
    syntheticFertilizer: undefined,
    soilLandscape: undefined,
    growCrop: undefined,
    tillCrop: undefined,
    coverCrop: undefined,
    tillPasture: undefined,
    livestock: undefined,
    animalType: [],
    historicalStockRate: undefined,
    gridElectricity: undefined,

    carbonBuilderProduct: undefined,
  });

  useEffect(() => {
    const data = localStorage.getItem("ActivityInfo");

    if (data) {
      const parsedData = JSON.parse(data);
      const activityInfo = parsedData as ActivityInfo;

      setNewData(activityInfo);
    }
  }, []);

  return newData;
}
