import calculateAverageAnnualBaselineEmission from "../../../../helpers/calculateAverageAnnualBaselineEmission";
import calculateExpectedEmissionUsingDateProportion from "../../../../helpers/calculateExpectedEmission";
import formatDate from "../../../../helpers/format-date";
import { EmissionActivityInfo, SummaryEmissionData } from "../../../../types";
import { Chart } from "react-chartjs-2";

interface IrrigationChartProps {
  data: SummaryEmissionData;
  baselineCalculatedEmissionData?: SummaryEmissionData;
  emissionActivity?: EmissionActivityInfo;
}

export default function IrrigationChart({
  data: summaryEmissionData,
  baselineCalculatedEmissionData,
  emissionActivity,
}: IrrigationChartProps) {
  return (
    <>
      {summaryEmissionData.irrigationEmission && (
        <div className="col-span-full sm:col-span-3">
          <div className="h-[250px]">
            <Chart
              type="bar"
              options={{
                plugins: {
                  legend: {
                    display: true,
                  },
                  annotation: {
                    annotations: {
                      line1: {
                        yMin: calculateExpectedEmissionUsingDateProportion(
                          calculateAverageAnnualBaselineEmission(
                            baselineCalculatedEmissionData
                          ).irrigationEmission,
                          emissionActivity?.activityStartDate,
                          emissionActivity?.activityEndDate
                        ),
                        yMax: calculateExpectedEmissionUsingDateProportion(
                          calculateAverageAnnualBaselineEmission(
                            baselineCalculatedEmissionData
                          ).irrigationEmission,
                          emissionActivity?.activityStartDate,
                          emissionActivity?.activityEndDate
                        ),
                        borderColor: "#376b2a",
                        borderWidth: 2,
                        borderDash: [8, 8],
                        label: {
                          backgroundColor: "#4f9a3d",
                          content: `Expected Emission = ${calculateExpectedEmissionUsingDateProportion(
                            calculateAverageAnnualBaselineEmission(
                              baselineCalculatedEmissionData
                            ).irrigationEmission,
                            emissionActivity?.activityStartDate,
                            emissionActivity?.activityEndDate
                          )} tonnes of CO2-e.`,
                          position: "end",
                          display: true,
                        },
                      },
                    },
                  },
                  datalabels: {
                    display: false,
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: "tonnes of CO2-e",
                    },
                  },
                },
              }}
              data={{
                labels: ["Irrigation Emission"],
                datasets: [
                  {
                    label: `${
                      formatDate(emissionActivity?.activityStartDate) +
                      " - " +
                      formatDate(emissionActivity?.activityEndDate)
                    } Emission (tonnes of CO2-e)`,
                    data: [
                      summaryEmissionData.irrigationEmission?.reduce(
                        (acc, data) =>
                          acc +
                          (data.fuelEmission || 0) +
                          (data.electricityEmission || 0),
                        0
                      ),
                    ],
                    backgroundColor: ["#a8da9b"],
                  },
                  {
                    label: "Average Annual Baseline Emission (tonnes of CO2-e)",
                    data: [
                      calculateAverageAnnualBaselineEmission(
                        baselineCalculatedEmissionData
                      ).irrigationEmission,
                    ],
                    backgroundColor: ["#11260d"],
                  },
                ],
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
