import { useContext, useEffect, useMemo, useState } from "react";
import { EntityTypePerson, GrowerPEP, ToBeSavedData } from "../../types";
import { DataTypes } from "../../constants";
// import Button from "../../components/button";
import Modal from "../../components/modal";
import TextInput from "../../components/text-input";
import EmptyPlaceholder from "../../components/empty-placeholder";
import SelectInput from "../../components/select-input";
import { useParams } from "react-router-dom";
import usePEPDeclaration from "../../hooks/usePepDeclaration";
import { ComponentContext } from "../kyb-kyc";
import Loading from "../../components/loading";
import determineDisabled from "../../helpers/kyb-kyc/determine-disabled";

export default function PEPQuestion() {
  const { kybStatus } = useContext(ComponentContext);
  const [showPEPModal, setShowPEPModal] = useState(false);
  const [growersPEP, setGrowersPEP] = useState<GrowerPEP>({});
  const [entityTypePerson, setEntityTypePerson] = useState<EntityTypePerson>(
    {}
  );
  const { kybId } = useParams();
  const { pepDeclaration } = usePEPDeclaration(kybId);

  useEffect(() => {
    function handleChildSave() {
      const toBeSaveData: ToBeSavedData = {
        dataType: DataTypes.GrowerPEP,
        data: growersPEP,
      };
      localStorage.setItem("toBeSaved", JSON.stringify(toBeSaveData));
    }
    handleChildSave();
  }, [growersPEP]);

  useEffect(() => {
    if (!pepDeclaration) return;
    if (pepDeclaration) {
      setGrowersPEP(pepDeclaration);
      return;
    }
    setGrowersPEP({});
  }, [pepDeclaration]);

  const pepPeople = useMemo(() => {
    return growersPEP.entityPeople ?? [];
  }, [growersPEP]);

  function handleAddPEP() {
    if (
      !entityTypePerson.legalName ||
      !entityTypePerson.position ||
      !entityTypePerson.sourceOfFunds ||
      !entityTypePerson.sourceOfWealth
    ) {
      return;
    }
    const pep = growersPEP.entityPeople ?? [];
    setGrowersPEP({
      ...growersPEP,
      entityPeople: [...pep, entityTypePerson],
    });
    setEntityTypePerson({});
    setShowPEPModal(false);
  }

  function AddPEP() {
    return (
      <Modal
        open={showPEPModal}
        setOpen={setShowPEPModal}
        onPressCancel={() => {
          setShowPEPModal(false);
          setEntityTypePerson({});
        }}
        title="Add Politically Exposed Person (PEP)"
        onPressAdd={() => handleAddPEP()}
      >
        <div className="flex flex-1 flex-col w-full">
          <div className="mt-2 mb-4 bg-gray-100 p-3 border border-gray-400 rounded-xl">
            {growersPEP.isPep && (
              <>
                <p className="py-1 text-xs text-gray-500">
                  <strong>Source of Funds - </strong> How and where you obtained
                  the funds for a specific transaction or designated service
                  with Loam.
                </p>

                <p className="py-1 text-xs text-gray-500">
                  <strong>Source of Wealth - </strong> Where your entire body of
                  wealth and assets came from – not just what is involved in
                  your transaction or business relationship with Loam.
                </p>
              </>
            )}
          </div>
          <TextInput
            label="Full Legal Name"
            type="text"
            value={entityTypePerson.legalName ?? ""}
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                legalName: e.target.value,
              });
            }}
            error={!entityTypePerson.legalName ? 1 : undefined}
          />

          <TextInput
            label="Position"
            type="text"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                position: e.target.value,
              });
            }}
            error={!entityTypePerson.position ? 1 : undefined}
          />

          <TextInput
            label="Source of Funds"
            type="text"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                sourceOfFunds: e.target.value,
              });
            }}
            error={!entityTypePerson.sourceOfFunds ? 1 : undefined}
          />

          <TextInput
            label="Source of Wealth"
            type="text"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                sourceOfWealth: e.target.value,
              });
            }}
            error={!entityTypePerson.sourceOfWealth ? 1 : undefined}
          />
        </div>
      </Modal>
    );
  }
  return !kybStatus ? (
    <Loading />
  ) : (
    <div className="flex flex-col w-full flex-1 lg:p-4">
      {AddPEP()}

      <div className="flex flex-col px-1 lg:px-4">
        <p className="mt-2 py-1 text-xs text-gray-600">
          Part of Loam’s requirement as a reporting entity is to screen new and
          existing customers to identify any Politically Exposed Person (PEP).
          This questionnaire will help us understand the level of due diligence
          we need to perform if our customers have any PEP within their
          organization.
        </p>
        <div className="mt-2 bg-gray-100 p-3 border border-gray-400 rounded-xl">
          <p className="py-1 text-xs text-gray-500">
            A <strong>Politically Exposed Person(PEP)</strong> is an individual
            who holds a prominent public position or role in a government body
            or international organisation, either in Australia or overseas.
            Immediate family members and/or close associates of these
            individuals are also considered PEPs.
          </p>
        </div>
        <div className="col-span-full border-b border-gray-200 my-4 px-6" />

        <SelectInput
          disabled={determineDisabled(kybStatus)}
          label="To the best of your knowledge, are you aware of any Politically Exposed Person (PEP) within the company, any of the company’s Connected Parties, or Other Related Parties?"
          value={
            growersPEP.isPep === false ? 0 : growersPEP.isPep === true ? 1 : ""
          }
          onChange={(e) => {
            setGrowersPEP({
              ...growersPEP,
              isPep: !e.target.value
                ? undefined
                : e.target.value === "1"
                ? true
                : false,
              entityPeople:
                !e.target.value || e.target.value === "0"
                  ? []
                  : growersPEP.entityPeople,
            });
          }}
          error={growersPEP.isPep === undefined ? 1 : undefined}
        >
          <option value=""></option>
          <option value={1}>Yes</option>
          <option value={0}>No</option>
        </SelectInput>

        {growersPEP.isPep && (
          <>
            <div className="flex flex-row justify-between items-center w-full pt-4">
              <div className="w-1/2">
                <p className="block text-md font-medium leading-6 text-apple-600 ">
                  PEP People
                </p>
                <p className="mt-1 text-xs text-gray-500">
                  Please provide the full-name, position, source of funds and
                  source of wealth of all PEPs.
                </p>
              </div>
              <div>
                {!determineDisabled(kybStatus) ? (
                  <button
                    className="flex w-full rounded-xl hover:bg-apple-500 bg-transparent border-2 border-apple-500 py-0.5 hover:text-white hover:border-apple-500 px-4 text-sm font-semibold text-apple-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-600"
                    onClick={() => setShowPEPModal(true)}
                  >
                    Add PEP
                  </button>
                ) : null}
              </div>
            </div>
            <div className="flex flex-col w-full px-2 sm:px-4">
              {pepPeople.length < 1 ? (
                <EmptyPlaceholder>
                  No Politically Exposed Person (PEP) added yet. Please add all
                  Politically Exposed People.
                </EmptyPlaceholder>
              ) : (
                pepPeople.map((pep, index) => (
                  <div key={index} className="border-b border-gray-200">
                    <div className="flex flex-row w-full justify-between items-center py-4">
                      <div className="flex flex-row w-full justify-start items-center ">
                        <span className="inline-flex h-8 w-8 items-center justify-center rounded-xl bg-apple-200 mr-4">
                          <span className="text-xs font-medium leading-none text-apple-800">
                            {pep.legalName?.charAt(0).toUpperCase()}
                          </span>
                        </span>
                        <div className="w-4/5">
                          <div className="flex w-full items-start gap-x-3">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                              {pep.legalName}
                            </p>
                          </div>
                          <p className="truncate text-xs leading-5 text-gray-500">
                            <strong>Position - </strong>
                            {pep.position ?? ""}
                          </p>
                        </div>
                      </div>
                      {/* <div className="flex flex-row justify-end items-center">
                    <Badge>{person.entityType}</Badge>
                  </div> */}
                    </div>
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
