import { useState } from "react";
import Button from "../components/button";
import Loading from "../components/loading";
import TextArea from "../components/text-area";
import useMe from "../hooks/useMe";
import { UserFeedback } from "../types";
import OverlayLoading from "../components/overlay-loading";
import { api } from "../api";
import { FetchError } from "../lib/fetcher";
import SuccessDialog from "../components/success-dialog";
import { useNavigate } from "react-router-dom";

export default function Feedback() {
  const me = useMe();
  const [userFeedback, setUserFeedback] = useState<UserFeedback>({});
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();

  async function submitFeedback() {
    setLoading(true);

    if (!me || !me.id || !userFeedback.rating) {
      setLoading(false);
      return;
    }
    const response = await api.postUserFeedback(me.id, userFeedback);
    if (response instanceof FetchError) {
      setLoading(false);
      alert(
        (response.error as { message: string }).message ??
          "There was an error submitting your feedback. Please try again later."
      );
      return;
    } else {
      setLoading(false);
      setShowSuccess(true);
      return;
    }
  }

  return !me ? (
    <Loading />
  ) : (
    <div className="flex flex-1 flex-col m-4 sm:m-4 lg:m-8">
      {<OverlayLoading open={loading} />}
      <SuccessDialog
        showSuccess={showSuccess}
        setshowSuccess={setShowSuccess}
        successMessage="Thank you for your feedback!"
        onPressOK={() => navigate(`/kyb`)}
      />
      <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-8">
        <div>
          <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
            Feedback
          </h2>
          <p className="mt-1 text-xs text-gray-500">
            Your feedback powers our progress – share your experience and help
            us enhance this platform.
          </p>
        </div>
      </div>

      <div className="flex flex-col m-4 sm:m-8 lg:m-28 xl:mx-40">
        <div className="flex flex-col">
          <div className="">
            <p className="block text-base leading-6 text-gray-600 ">
              On a scale of 1-5, how would you rate your overall experience with
              this platform?
            </p>
            <div className="flex flex-row w-full justify-between mt-2 sm:mt-4">
              <p className="text-sm font-medium text-gray-900">Not that good</p>
              <p className="text-sm font-medium text-gray-900">Excellent</p>
            </div>
            <div className="flex flex-row flex-wrap w-full justify-between mt-1 sm:mt-2">
              {Array.from(Array(5).keys()).map((i) => (
                <div
                  key={i}
                  className=" inline-flex rounded-md shadow-sm m-1 cursor-pointer"
                  onClick={() => {
                    setUserFeedback({
                      ...userFeedback,
                      rating: i + 1,
                    });
                  }}
                >
                  <div
                    className={`flex items-center px-4 py-2 text-sm font-semibold ${
                      userFeedback.rating === i + 1
                        ? "bg-apple-600 text-white"
                        : "text-apple-700 ring-1 ring-inset ring-apple-300"
                    }  rounded  hover:bg-apple-300 focus:z-20 focus:outline-offset-0 `}
                  >
                    {i + 1}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-full border-b border-gray-200 py-4 px-6" />
          <div className="mt-8 ">
            <p className="block text-base leading-6 text-gray-600 mb-2">
              Please share any additional feedback you have about your
              experience with this platform.
            </p>
            <TextArea
              onChange={(e) => {
                setUserFeedback({
                  ...userFeedback,
                  details: e.target.value,
                });
              }}
              rows={6}
            />
          </div>

          <div className="flex flex-col items-center  mx-0 sm:mx-40 lg:mx-60 mt-8">
            <Button
              onClick={() => {
                submitFeedback();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
