import { useParams } from "react-router-dom";
import Illustration from "../../assets/image2.jpg";
import Loading from "../../components/loading";
import useKyb from "../../hooks/useKyb";
import { CustomerVerificationEmail } from "../../constants";

export default function Intro() {
  const { kybId } = useParams();
  const { kyb, loading } = useKyb(kybId);

  return (
    <div className="relative isolate overflow-hidden bg-white px-6 pt-16 pb-8 sm:pt-24 lg:overflow-visible lg:px-0">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="absolute inset-0 -z-10 overflow-hidden">
            <svg
              className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                  width={200}
                  height={200}
                  x="50%"
                  y={-1}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={-1} className="overflow-visible fill-apple-50">
                <path
                  d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
              />
            </svg>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <h1 className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                    Know Your Business {kyb?.kybYear}
                  </h1>
                  <p className="mt-6 text-base lg:text-lg leading-6 text-gray-700">
                    The following form is designed to collect information to
                    complete your Know Your Business check for the entity that
                    will sign the Grower Agreement with Loam Carbon. This could
                    be individuals or people who signed on behalf of an entity
                    (i.e. Trustee of a trust or Director of a company). As you
                    fill out this guided form, you may identify other entities
                    or individuals who we will also need to be in contact with
                    to complete the Know Your Business process. Depending on
                    your responses, you will be directed to fill out the
                    appropriate information, no guesswork required!
                  </p>
                  <p className="mt-6 text-base lg:text-lg leading-6 text-gray-700">
                    If you have any questions or concerns, please feel free to
                    reach out to the Loam Financial Compliance team at{" "}
                    <a
                      className="font-semibold text-apple-700"
              href={`mailto:${CustomerVerificationEmail}`}
                    >
                      {CustomerVerificationEmail}
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:-ml-12 lg:-mt-12 p-0 xl:pl-32 lg:p-12 place-items-center lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
              <img
                loading="lazy"
                className="w-full xl:w-[320px]  max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10"
                src={Illustration}
                alt=""
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
