import { useEffect, useState } from "react";
import TextInput from "../../../components/text-input";
import useEmissionData from "../../../hooks/useEmissionData";
import { SoilLandscape as iSoilLandscape } from "../../../types";
import { api } from "../../../api";
import { formatMonthYear } from "../../../helpers/format-month-year";
import {
  EmissionAttachmentType,
  EmissionVariant,
  MINIMUM_INPUT_WARNING,
} from "../../../constants";
import { useParams } from "react-router-dom";
import useActivityInfo from "../../../hooks/emission/useActivityInfo";
import useFarmInfo from "../../../hooks/emission/useFarmInfo";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";
import EmissionAttachments from "../emission-attachments";
import useEmissionActivity from "../../../hooks/emission/useEmissionActivity";
import formatDate from "../../../helpers/format-date";
import Divider from "../../../components/divider";

interface SoilLandscapeProps {
  variant?: EmissionVariant;
}

export default function SoilLandscape({ variant }: SoilLandscapeProps) {
  const emissionData = useEmissionData();
  const activityInfo = useActivityInfo();
  const farmInfo = useFarmInfo();
  const emissionActivity = useEmissionActivity();

  const { projectId, emissionActivityId } = useParams();
  const [soilLandscapeData, setSoilLandscapeData] = useState<iSoilLandscape[]>(
    []
  );
  const { projectAttachment, mutate } = useProjectAttachment(
    projectId,
    emissionActivityId,
    EmissionAttachmentType.SoilLandscape
  );
  const [inputCheck, setInputCheck] = useState<{
    showDieselError: boolean;
    dieselAttachment: boolean;
  }>({
    showDieselError: true,
    dieselAttachment: false,
  });

  useEffect(() => {
    if (!emissionData) {
      setSoilLandscapeData([]);
    }
    setSoilLandscapeData(emissionData?.soilLandscape ?? []);
  }, [emissionData]);

  useEffect(() => {
    if (!emissionActivity) {
      setInputCheck({
        showDieselError: false,
        dieselAttachment: false,
      });
      return;
    }

    const dieselData = soilLandscapeData.find(
      (data) =>
        data.activityYear ===
        new Date(emissionActivity.activityStartDate ?? new Date()).getFullYear()
    )?.dieselQuantity;

    setInputCheck({
      showDieselError: dieselData === undefined,
      dieselAttachment: dieselData !== undefined && dieselData > 0,
    });
  }, [emissionActivity, soilLandscapeData]);

  function fetchSoilLandscape(year: number) {
    const data = soilLandscapeData.find((data) => data.activityYear === year);

    if (data) {
      return data as iSoilLandscape;
    }

    return {} as iSoilLandscape;
  }

  return (
    <div className="flex flex-col items-center w-full">
      {!emissionData ||
      !activityInfo ||
      !farmInfo ||
      !projectId ||
      !emissionActivityId ||
      !emissionActivity ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p>No Data</p>
        </div>
      ) : (
        <div className="w-full">
          <div className="border-b border-gray-200 pb-4 lg:pb-8">
            <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
              Soil Landscape Modification
            </h2>
          </div>

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Please list the quantity of diesel fuel you used for any soil
                landscape modification activities carried out in your project
                area in the specified years.
              </label>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full ">
                      <thead>
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                          ></th>

                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                          >
                            Quantity of diesel fuel
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-0.5 text-center px-5 text-sm font-normal text-gray-900 "
                          ></th>
                          <th
                            scope="col"
                            className="py-0.5 pl-4 pr-4  text-xs font-normal text-gray-900 sm:pr-0 text-center bg-apple-50"
                          >
                            Kilolitres (kL)
                          </th>
                        </tr>
                        {variant !== EmissionVariant.OnGoing ? (
                          activityInfo.activityYears
                            .sort((a, b) => b - a)
                            .map((year, i) => (
                              <tr key={i} className="divide-x divide-gray-200">
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center ">
                                  {formatMonthYear(
                                    farmInfo.carbonRegMonth ?? 1,
                                    year
                                  )}
                                </td>
                                <td className="whitespace-wrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                                  <TextInput
                                    type="number"
                                    inputMode="decimal"
                                    defaultValue={
                                      fetchSoilLandscape(year).dieselQuantity
                                    }
                                    onInput={(e) => {
                                      if (
                                        +e.currentTarget.value < 0 ||
                                        !e.currentTarget.value
                                      ) {
                                        alert(MINIMUM_INPUT_WARNING);
                                        e.currentTarget.value = "";
                                        api.saveSoilLandscape(
                                          +emissionActivityId,
                                          +projectId,
                                          year,
                                          "dieselQuantity",
                                          undefined
                                        );
                                      } else {
                                        api.saveSoilLandscape(
                                          +emissionActivityId,
                                          +projectId,
                                          year,
                                          "dieselQuantity",
                                          +e.currentTarget.value
                                        );
                                      }
                                    }}
                                  />
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr className="divide-x divide-gray-200">
                            <td className="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center ">
                              {formatDate(emissionActivity.activityStartDate)} -{" "}
                              {formatDate(emissionActivity.activityEndDate)}
                            </td>
                            <td className="whitespace-wrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                              <TextInput
                                type="number"
                                inputMode="decimal"
                                defaultValue={
                                  fetchSoilLandscape(
                                    new Date(
                                      emissionActivity.activityStartDate ??
                                        new Date()
                                    ).getFullYear()
                                  ).dieselQuantity
                                }
                                onInput={(e) => {
                                  if (
                                    +e.currentTarget.value < 0 ||
                                    !e.currentTarget.value
                                  ) {
                                    alert(MINIMUM_INPUT_WARNING);
                                    e.currentTarget.value = "";
                                    api.saveSoilLandscape(
                                      +emissionActivityId,
                                      +projectId,
                                      new Date(
                                        emissionActivity.activityStartDate ??
                                          new Date()
                                      ).getFullYear(),
                                      "dieselQuantity",
                                      undefined
                                    );
                                    setInputCheck({
                                      ...inputCheck,
                                      showDieselError: true,
                                      dieselAttachment: false,
                                    });
                                  } else {
                                    api.saveSoilLandscape(
                                      +emissionActivityId,
                                      +projectId,
                                      new Date(
                                        emissionActivity.activityStartDate ??
                                          new Date()
                                      ).getFullYear(),
                                      "dieselQuantity",
                                      +e.currentTarget.value
                                    );
                                    setInputCheck({
                                      ...inputCheck,
                                      showDieselError: false,
                                      dieselAttachment:
                                        +e.currentTarget.value > 0,
                                    });
                                  }
                                }}
                                error={inputCheck.showDieselError ? 1 : 0}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Divider />
          {(variant === EmissionVariant.Baseline ||
            inputCheck.dieselAttachment) && (
            <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
              Attachments
            </h2>
          )}
          <div className="m-1">
            {variant !== EmissionVariant.OnGoing ? (
              activityInfo.activityYears
                .sort((a, b) => b - a)
                .map((year, i) => (
                  <div className="mt-1 " key={i}>
                    <EmissionAttachments
                      emissionActivityId={+emissionActivityId}
                      projectId={+projectId}
                      month={farmInfo.carbonRegMonth}
                      year={year}
                      attachments={projectAttachment}
                      title="Soil Landscape Modification"
                      attachmentKind={EmissionAttachmentType.SoilLandscape}
                      mutateAttachments={mutate}
                    />
                    {i < activityInfo.activityYears.length - 1 && (
                      <div className="border-b border-gray-200 pb-4 lg:pb-8" />
                    )}
                  </div>
                ))
            ) : (
              <EmissionAttachments
                variant={variant}
                emissionActivity={emissionActivity}
                emissionActivityId={+emissionActivityId}
                projectId={+projectId}
                month={farmInfo.carbonRegMonth}
                year={new Date(
                  emissionActivity.activityStartDate ?? new Date()
                ).getFullYear()}
                attachments={projectAttachment}
                title="Soil Landscape Modification"
                attachmentKind={EmissionAttachmentType.SoilLandscape}
                mutateAttachments={mutate}
                uploadRequired={inputCheck.dieselAttachment}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
