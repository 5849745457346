import { useEffect, useMemo, useState } from "react";
import Checkbox from "../../../components/checkbox-input";
import TextInput from "../../../components/text-input";
import {
  EmissionAttachmentType,
  EmissionVariant,
  MINIMUM_INPUT_WARNING,
  SyntheticFertilizerLocation,
} from "../../../constants";
import useEmissionData from "../../../hooks/useEmissionData";
import {
  SyntheticFertilizerLocationData,
  SyntheticFerterlizer as iSyntheticFerterlizer,
} from "../../../types";
import { api } from "../../../api";
import { formatMonthYear } from "../../../helpers/format-month-year";
import useFarmInfo from "../../../hooks/emission/useFarmInfo";
import { useParams } from "react-router-dom";
import useProjectAttachment from "../../../hooks/emission/useProjectAttachment";
import EmissionAttachments from "../emission-attachments";
import useEmissionActivity from "../../../hooks/emission/useEmissionActivity";
import formatDate from "../../../helpers/format-date";
import Divider from "../../../components/divider";

interface Props {
  year: number;
  variant?: EmissionVariant;
}

export default function SyntheticFertiliserData({ year, variant }: Props) {
  const emissionData = useEmissionData(year);
  const farmInfo = useFarmInfo();
  const emissionActivity = useEmissionActivity();
  const [syntheticFertilizerData, setSyntheticFertilizerData] = useState<
    iSyntheticFerterlizer[]
  >([]);
  const [yearSyntheticFertilizerData, setYearSyntheticFertilizerData] =
    useState<iSyntheticFerterlizer | undefined>(undefined);
  const { projectId, emissionActivityId } = useParams();
  const { projectAttachment, mutate } = useProjectAttachment(
    projectId,
    emissionActivityId,
    EmissionAttachmentType.SyntheticFerterlizer
  );
  const [inputCheck, setInputCheck] = useState<{
    showUreaError: boolean;
    showLocationOptionsError: boolean;
    showLocationTableError: boolean;
  }>({
    showUreaError: false,
    showLocationOptionsError: false,
    showLocationTableError: false,
  });

  useEffect(() => {
    if (!emissionData || !emissionData.syntheticFertilizer) {
      setSyntheticFertilizerData([]);
      return;
    }

    setSyntheticFertilizerData(emissionData.syntheticFertilizer);
  }, [emissionData]);

  useEffect(() => {
    if (!projectId || !emissionActivityId) return;
    if (
      !syntheticFertilizerData.length ||
      !syntheticFertilizerData.find((data) => data.activityYear === year)
    ) {
      setYearSyntheticFertilizerData({
        ureaQuantity: undefined,
        projectId: +projectId,
        emissionActivityId: +emissionActivityId,
        activityYear: year,
        ferterlizerLocationData: [] as SyntheticFertilizerLocationData[],
      } as iSyntheticFerterlizer);
      return;
    }
    setYearSyntheticFertilizerData(
      syntheticFertilizerData.find((data) => data.activityYear === year)
    );
  }, [
    syntheticFertilizerData,
    year,
    emissionData,
    projectId,
    emissionActivityId,
  ]);

  useEffect(() => {
    function saveSyntheticFertilizer() {
      if (!yearSyntheticFertilizerData) {
        setInputCheck({
          showUreaError: true,
          showLocationOptionsError: true,
          showLocationTableError: false,
        });
        return;
      }
      setInputCheck({
        showUreaError: yearSyntheticFertilizerData.ureaQuantity === undefined,
        showLocationOptionsError:
          yearSyntheticFertilizerData.ferterlizerLocationData.length === 0,
        showLocationTableError: false,
      });
      return api.saveSyntheticFertilizer(yearSyntheticFertilizerData);
    }
    saveSyntheticFertilizer();
  }, [yearSyntheticFertilizerData]);

  const requiredAttachmentTypes = useMemo(() => {
    return yearSyntheticFertilizerData?.ferterlizerLocationData
      .flatMap((location) =>
        location.locationData
          ? Object.entries(location.locationData).map(([key, value]) => {
              if (value.quantityApplied ?? 0 > 0)
                return `${location.location} - ${
                  key === "fertiliser1"
                    ? "Fertilizer 1"
                    : key === "fertiliser2"
                    ? "Fertilizer 2"
                    : "Fertilizer 3"
                }`;
            })
          : []
      )
      .filter(Boolean) as [];
  }, [yearSyntheticFertilizerData]);

  return (
    <div className="flex flex-col items-center w-full" key={year}>
      {!yearSyntheticFertilizerData ||
      !projectId ||
      !emissionActivityId ||
      !emissionActivity ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p>No Data</p>
        </div>
      ) : (
        <div className="w-full">
          <div className="border-b border-gray-200 pb-4 lg:pb-8">
            <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
              {variant !== EmissionVariant.OnGoing ? (
                formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
              ) : (
                <span>
                  {formatDate(emissionActivity.activityStartDate)} -{" "}
                  {formatDate(emissionActivity.activityEndDate)}
                </span>
              )}{" "}
              Synthetic Fertiliser.
            </h2>
          </div>

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2">
            <div className="col-span-full">
              <TextInput
                type="number"
                inputMode="decimal"
                label={
                  <span className="text-sm">
                    Please share the quantity of urea applied to the project
                    area as a synthetic fertilizer (tonnes) between{" "}
                    <span className="font-semibold text-apple-700">
                      {variant !== EmissionVariant.OnGoing ? (
                        formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
                      ) : (
                        <span>
                          {formatDate(emissionActivity?.activityStartDate)} -{" "}
                          {formatDate(emissionActivity?.activityEndDate)}
                        </span>
                      )}
                    </span>
                    .
                  </span>
                }
                value={yearSyntheticFertilizerData.ureaQuantity ?? ""}
                onChange={(e) => {
                  if (+e.target.value < 0) {
                    alert(MINIMUM_INPUT_WARNING);
                  } else {
                    setYearSyntheticFertilizerData({
                      ...yearSyntheticFertilizerData,
                      ureaQuantity: e.target.value.length
                        ? +e.target.value
                        : undefined,
                    });
                  }
                }}
                error={
                  inputCheck.showUreaError &&
                  variant === EmissionVariant.OnGoing
                    ? 1
                    : 0
                }
              />
            </div>
            <div className="col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Please select all locations you applied synthetic fertilizers,
                i.e., MAP, DAP, Single Super, Urea or blends, between{" "}
                <span className="font-semibold text-apple-700">
                  {variant !== EmissionVariant.OnGoing ? (
                    formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
                  ) : (
                    <span>
                      {formatDate(emissionActivity?.activityStartDate)} -{" "}
                      {formatDate(emissionActivity?.activityEndDate)}
                    </span>
                  )}
                </span>
                .
              </label>
              {inputCheck.showLocationOptionsError &&
              variant === EmissionVariant.OnGoing ? (
                <p className="pt-2 text-xs text-red-600" id="error">
                  This field is required!
                </p>
              ) : null}

              <div className="grid grid-cols-2  lg:grid-cols-3 gap-2 sm:gap-3 lg:gap-4 mt-3">
                {Object.entries(SyntheticFertilizerLocation).map(
                  ([key, value]) => (
                    <div
                      key={key}
                      className="flex flex-row items-center w-full col-span-1"
                    >
                      <Checkbox
                        checked={
                          yearSyntheticFertilizerData.ferterlizerLocationData.find(
                            (location) => location.location === value
                          )
                            ? true
                            : false
                        }
                        value={key}
                        label={value}
                        image={`${key}`}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setYearSyntheticFertilizerData({
                              ...yearSyntheticFertilizerData,
                              ferterlizerLocationData: [
                                ...yearSyntheticFertilizerData.ferterlizerLocationData,
                                {
                                  location: value,
                                },
                              ],
                            });
                          } else {
                            setYearSyntheticFertilizerData({
                              ...yearSyntheticFertilizerData,
                              ferterlizerLocationData: [
                                ...yearSyntheticFertilizerData.ferterlizerLocationData.filter(
                                  (location) => location.location !== value
                                ),
                              ],
                            });
                          }
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          {yearSyntheticFertilizerData.ferterlizerLocationData.map(
            (location, i) => (
              <div
                key={i}
                className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 px-2"
              >
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Please share the nitrogen content and volume of non-urea
                    synthetic fertilizer you applied to your{" "}
                    <span className="font-semibold text-apple-700">
                      {location.location}
                    </span>{" "}
                    in{" "}
                    <span className="font-semibold text-apple-700">
                      {variant !== EmissionVariant.OnGoing ? (
                        formatMonthYear(farmInfo?.carbonRegMonth ?? 1, year)
                      ) : (
                        <span>
                          {formatDate(emissionActivity?.activityStartDate)} -{" "}
                          {formatDate(emissionActivity?.activityEndDate)}
                        </span>
                      )}
                    </span>
                    .
                    <p className="text-gray-500 font-normal">
                      We have provided you with space to share information for 3
                      different fertilizers.
                    </p>
                  </label>
                  <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full ">
                          <thead>
                            <tr className="divide-x divide-gray-200">
                              <th
                                scope="col"
                                className="py-3.5 text-center px-5 text-sm font-semibold text-gray-900 "
                              ></th>

                              <th
                                colSpan={3}
                                className="py-3.5 pl-4 pr-4  text-sm font-semibold text-gray-900 sm:pr-0 text-center "
                              >
                                <div className="flex flex-col items-center justify-center">
                                  <img
                                    loading="lazy"
                                    className="w-auto h-12 sm:h-14 pb-2 text-center"
                                    src={require(`../../../assets/fertiliser-location/${
                                      Object.keys(SyntheticFertilizerLocation)[
                                        Object.values(
                                          SyntheticFertilizerLocation
                                        ).indexOf(
                                          location.location as unknown as SyntheticFertilizerLocation
                                        )
                                      ]
                                    }.png`)}
                                    alt={location.location}
                                  />
                                  {location.location}
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            <tr className="divide-x divide-gray-200">
                              <th
                                scope="col"
                                className="py-0.5 text-center px-5 text-sm font-normal text-gray-900 "
                              ></th>
                              <th
                                scope="col"
                                className="px-4 py-0.5  text-xs font-normal text-gray-900 text-center bg-apple-50"
                              >
                                Fertilizer 1
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-0.5  text-xs font-normal text-gray-900 text-center bg-apple-50"
                              >
                                Fertilizer 2
                              </th>
                              <th
                                scope="col"
                                className="py-0.5 pl-4 pr-4  text-xs font-normal text-gray-900 sm:pr-0 text-center bg-apple-50"
                              >
                                Fertilizer 3
                              </th>
                            </tr>

                            <tr className="divide-x divide-gray-200">
                              <td className="py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center">
                                Proportion of nitrogen content of fertilizer (%)
                              </td>
                              <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    location.locationData?.fertiliser1
                                      ?.nitrogenContent ?? ""
                                  }
                                  onChange={(e) => {
                                    if (
                                      +e.target.value > 100 ||
                                      +e.target.value < 0
                                    ) {
                                      alert(
                                        "Proprotion of nitrogen cannot be greater than 100%. Please enter a value between 0-100"
                                      );
                                    } else {
                                      setYearSyntheticFertilizerData({
                                        ...yearSyntheticFertilizerData,
                                        ferterlizerLocationData: [
                                          ...yearSyntheticFertilizerData.ferterlizerLocationData.map(
                                            (loc, index) => {
                                              if (index === i) {
                                                return {
                                                  ...loc,
                                                  locationData: {
                                                    ...loc.locationData,
                                                    fertiliser1: {
                                                      ...loc.locationData
                                                        ?.fertiliser1,
                                                      nitrogenContent: e.target
                                                        .value.length
                                                        ? +e.target.value
                                                        : undefined,
                                                    },
                                                  },
                                                };
                                              } else {
                                                return loc;
                                              }
                                            }
                                          ),
                                        ],
                                      });
                                    }
                                  }}
                                  error={
                                    yearSyntheticFertilizerData
                                      .ferterlizerLocationData[i].locationData
                                      ?.fertiliser1?.nitrogenContent ===
                                      undefined &&
                                    variant === EmissionVariant.OnGoing
                                      ? 1
                                      : 0
                                  }
                                  dontshowerrormesage={true}
                                />
                              </td>
                              <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    location.locationData?.fertiliser2
                                      ?.nitrogenContent ?? ""
                                  }
                                  onChange={(e) => {
                                    if (
                                      +e.target.value > 100 ||
                                      +e.target.value < 0
                                    ) {
                                      alert(
                                        "Proprotion of nitrogen cannot be greater than 100%. Please enter a value between 0-100"
                                      );
                                    } else {
                                      setYearSyntheticFertilizerData({
                                        ...yearSyntheticFertilizerData,
                                        ferterlizerLocationData: [
                                          ...yearSyntheticFertilizerData.ferterlizerLocationData.map(
                                            (loc, index) => {
                                              if (index === i) {
                                                return {
                                                  ...loc,
                                                  locationData: {
                                                    ...loc.locationData,
                                                    fertiliser2: {
                                                      ...loc.locationData
                                                        ?.fertiliser2,
                                                      nitrogenContent: e.target
                                                        .value.length
                                                        ? +e.target.value
                                                        : undefined,
                                                    },
                                                  },
                                                };
                                              } else {
                                                return loc;
                                              }
                                            }
                                          ),
                                        ],
                                      });
                                    }
                                  }}
                                  error={
                                    yearSyntheticFertilizerData
                                      .ferterlizerLocationData[i].locationData
                                      ?.fertiliser2?.nitrogenContent ===
                                      undefined &&
                                    variant === EmissionVariant.OnGoing
                                      ? 1
                                      : 0
                                  }
                                  dontshowerrormesage={true}
                                />
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    location.locationData?.fertiliser3
                                      ?.nitrogenContent ?? ""
                                  }
                                  onChange={(e) => {
                                    if (
                                      +e.target.value > 100 ||
                                      +e.target.value < 0
                                    ) {
                                      alert(
                                        "Proprotion of nitrogen cannot be greater than 100%. Please enter a value between 0-100"
                                      );
                                    } else {
                                      setYearSyntheticFertilizerData({
                                        ...yearSyntheticFertilizerData,
                                        ferterlizerLocationData: [
                                          ...yearSyntheticFertilizerData.ferterlizerLocationData.map(
                                            (loc, index) => {
                                              if (index === i) {
                                                return {
                                                  ...loc,
                                                  locationData: {
                                                    ...loc.locationData,
                                                    fertiliser3: {
                                                      ...loc.locationData
                                                        ?.fertiliser3,
                                                      nitrogenContent: e.target
                                                        .value.length
                                                        ? +e.target.value
                                                        : undefined,
                                                    },
                                                  },
                                                };
                                              } else {
                                                return loc;
                                              }
                                            }
                                          ),
                                        ],
                                      });
                                    }
                                  }}
                                  error={
                                    yearSyntheticFertilizerData
                                      .ferterlizerLocationData[i].locationData
                                      ?.fertiliser3?.nitrogenContent ===
                                      undefined &&
                                    variant === EmissionVariant.OnGoing
                                      ? 1
                                      : 0
                                  }
                                  dontshowerrormesage={true}
                                />
                              </td>
                            </tr>
                            <tr className="divide-x divide-gray-200">
                              <td className="py-2 pl-2 pr-2 text-xs font-medium text-gray-900 text-center">
                                Quantity applied to project area (tonnes)
                              </td>
                              <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    location.locationData?.fertiliser1
                                      ?.quantityApplied ?? ""
                                  }
                                  onChange={(e) => {
                                    if (+e.target.value < 0) {
                                      alert(MINIMUM_INPUT_WARNING);
                                    } else {
                                      setYearSyntheticFertilizerData({
                                        ...yearSyntheticFertilizerData,
                                        ferterlizerLocationData: [
                                          ...yearSyntheticFertilizerData.ferterlizerLocationData.map(
                                            (loc, index) => {
                                              if (index === i) {
                                                return {
                                                  ...loc,
                                                  locationData: {
                                                    ...loc.locationData,
                                                    fertiliser1: {
                                                      ...loc.locationData
                                                        ?.fertiliser1,
                                                      quantityApplied: e.target
                                                        .value.length
                                                        ? +e.target.value
                                                        : undefined,
                                                    },
                                                  },
                                                };
                                              } else {
                                                return loc;
                                              }
                                            }
                                          ),
                                        ],
                                      });
                                    }
                                  }}
                                  error={
                                    yearSyntheticFertilizerData
                                      .ferterlizerLocationData[i].locationData
                                      ?.fertiliser1?.quantityApplied ===
                                      undefined &&
                                    variant === EmissionVariant.OnGoing
                                      ? 1
                                      : 0
                                  }
                                  dontshowerrormesage={true}
                                />
                              </td>
                              <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    location.locationData?.fertiliser2
                                      ?.quantityApplied ?? ""
                                  }
                                  onChange={(e) => {
                                    if (+e.target.value < 0) {
                                      alert(MINIMUM_INPUT_WARNING);
                                    } else {
                                      setYearSyntheticFertilizerData({
                                        ...yearSyntheticFertilizerData,
                                        ferterlizerLocationData: [
                                          ...yearSyntheticFertilizerData.ferterlizerLocationData.map(
                                            (loc, index) => {
                                              if (index === i) {
                                                return {
                                                  ...loc,
                                                  locationData: {
                                                    ...loc.locationData,
                                                    fertiliser2: {
                                                      ...loc.locationData
                                                        ?.fertiliser2,
                                                      quantityApplied: e.target
                                                        .value.length
                                                        ? +e.target.value
                                                        : undefined,
                                                    },
                                                  },
                                                };
                                              } else {
                                                return loc;
                                              }
                                            }
                                          ),
                                        ],
                                      });
                                    }
                                  }}
                                  error={
                                    yearSyntheticFertilizerData
                                      .ferterlizerLocationData[i].locationData
                                      ?.fertiliser2?.quantityApplied ===
                                      undefined &&
                                    variant === EmissionVariant.OnGoing
                                      ? 1
                                      : 0
                                  }
                                  dontshowerrormesage={true}
                                />
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                                <TextInput
                                  type="number"
                                  inputMode="decimal"
                                  value={
                                    location.locationData?.fertiliser3
                                      ?.quantityApplied ?? ""
                                  }
                                  onChange={(e) => {
                                    if (+e.target.value < 0) {
                                      alert(MINIMUM_INPUT_WARNING);
                                    } else {
                                      setYearSyntheticFertilizerData({
                                        ...yearSyntheticFertilizerData,
                                        ferterlizerLocationData: [
                                          ...yearSyntheticFertilizerData.ferterlizerLocationData.map(
                                            (loc, index) => {
                                              if (index === i) {
                                                return {
                                                  ...loc,
                                                  locationData: {
                                                    ...loc.locationData,
                                                    fertiliser3: {
                                                      ...loc.locationData
                                                        ?.fertiliser3,
                                                      quantityApplied: e.target
                                                        .value.length
                                                        ? +e.target.value
                                                        : undefined,
                                                    },
                                                  },
                                                };
                                              } else {
                                                return loc;
                                              }
                                            }
                                          ),
                                        ],
                                      });
                                    }
                                  }}
                                  error={
                                    yearSyntheticFertilizerData
                                      .ferterlizerLocationData[i].locationData
                                      ?.fertiliser3?.quantityApplied ===
                                      undefined &&
                                    variant === EmissionVariant.OnGoing
                                      ? 1
                                      : 0
                                  }
                                  dontshowerrormesage={true}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}

          <Divider />
          {(variant === EmissionVariant.Baseline ||
            requiredAttachmentTypes.length > 0) && (
            <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
              Attachments
            </h2>
          )}
          <div className="mt-1 ">
            <EmissionAttachments
              variant={variant}
              emissionActivity={emissionActivity}
              emissionActivityId={+emissionActivityId}
              projectId={+projectId}
              month={farmInfo.carbonRegMonth}
              year={year}
              attachments={projectAttachment}
              title="Synthetic Fertilizer"
              attachmentKind={EmissionAttachmentType.SyntheticFerterlizer}
              mutateAttachments={mutate}
              attachmentTypes={requiredAttachmentTypes}
              uploadRequired={
                variant !== EmissionVariant.OnGoing ||
                requiredAttachmentTypes.length > 0
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
