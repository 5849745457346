import { useContext, useEffect, useMemo, useState } from "react";
import { ComponentContext } from "../kyb-kyc";
import Loading from "../../components/loading";
import SelectInput from "../../components/select-input";
import {
  DataTypes,
  EntityTypes,
  TrustAttachmentType,
  TrustPersonType,
  TrustType,
  TrusteeEntityType,
} from "../../constants";
import TextInput from "../../components/text-input";
import Modal from "../../components/modal";
import {
  Attachment,
  EntityTypePerson,
  KybNode,
  ToBeSavedData,
  TrustData,
} from "../../types";
import emailChecker from "../../helpers/email-checker";
import OverlayLoading from "../../components/overlay-loading";
import AttachmentsModals from "../../components/attachments";
import TextArea from "../../components/text-area";
import determineDisabled from "../../helpers/kyb-kyc/determine-disabled";
import EmptyPlaceholder from "../../components/empty-placeholder";

const filteredEntityTypes = Object.values(EntityTypes).filter(
  (value) => value !== EntityTypes.Other
);

export default function Trust() {
  const { nodeData, kybStatus } = useContext(ComponentContext);
  const [showAddBeneficiaryModal, setShowAddBeneficiaryModal] = useState(false);
  const [showAddTrusteeModal, setShowAddTrusteeModal] = useState(false);
  const [entityTypePerson, setEntityTypePerson] = useState<EntityTypePerson>(
    {}
  );
  const [trustData, setTrustData] = useState<TrustData>({});
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [areyousure, setAreyousure] = useState(false);
  const [attachment, setAttachment] = useState<Attachment | undefined>(
    undefined
  );
  const [data, setData] = useState<KybNode>();
  useMemo(() => {
    if (!nodeData) return;
    setData(nodeData);
  }, [nodeData]);

  function mutateNodeAttachments(attachment: Attachment) {
    if (!data) return;
    setData({
      ...data,
      attachments: [...(data.attachments ?? []), attachment],
    });
  }

  function removeAttachment(id?: number) {
    if (!data) return;
    const attachments = (data.attachments ?? []).filter(a => a.id != id);
    setData({
      ...data,
      attachments,
    });
  }

  useEffect(() => {
    if (!data) return;
    localStorage.setItem(
      "NumOfAttachments",
      data.attachments?.length.toString() ?? "0"
    );
  }, [data]);

  const trust = useMemo(() => {
    if (!nodeData || !nodeData.entityData) return {};
    return nodeData.entityData as TrustData;
  }, [nodeData]);

  useEffect(() => {
    if (!trust) return;
    setTrustData(trust);
  }, [trust]);

  useEffect(() => {
    if (!trustData) return;
    function handleChildSave() {
      const toBeSaveData: ToBeSavedData = {
        dataType: DataTypes.TrustData,
        data: trustData as TrustData,
      };
      localStorage.setItem("toBeSaved", JSON.stringify(toBeSaveData));
    }
    handleChildSave();
  }, [trustData]);

  const beneficiaries = useMemo(() => {
    if (!trustData || !trustData.entityPeople) return [];
    return trustData.entityPeople.filter(
      (person) => person.personType === TrustPersonType.Beneficiary
    );
  }, [trustData]);

  const trustees = useMemo(() => {
    if (!trustData || !trustData.entityPeople) return [];
    return trustData.entityPeople.filter(
      (person) => person.personType === TrustPersonType.Trustee
    );
  }, [trustData]);

  const settlor = useMemo(() => {
    if (!trustData || !trustData.entityPeople) return {} as EntityTypePerson;
    const person = trustData.entityPeople.filter(
      (person) => person.personType === TrustPersonType.Settlor
    );
    if (person.length < 1) return {} as EntityTypePerson;
    return person[0];
  }, [trustData]);

  function handleAddBeneficiary() {
    if (
      !entityTypePerson.legalName ||
      (entityTypePerson.email && !emailChecker(entityTypePerson.email)) ||
      (entityTypePerson.ownershipPercentage ?? 0) > 100 ||
      (entityTypePerson.ownershipPercentage ?? 0) < 0
    )
      return;
    setTrustData({
      ...trustData,
      entityPeople: [
        ...(trustData.entityPeople ?? []),
        { ...entityTypePerson, personType: TrustPersonType.Beneficiary },
      ],
    });
    setShowAddBeneficiaryModal(false);
    setEntityTypePerson({});
  }

  function handleAddTrustee() {
    if (
      !entityTypePerson.legalName ||
      !entityTypePerson.email ||
      !emailChecker(entityTypePerson.email) ||
      !entityTypePerson.entityType
    )
      return;
    setTrustData({
      ...trustData,
      entityPeople: [
        ...(trustData.entityPeople ?? []),
        { ...entityTypePerson, personType: TrustPersonType.Trustee },
      ],
    });
    setShowAddTrusteeModal(false);
    setEntityTypePerson({});
  }

  function handleRemovePerson(person: EntityTypePerson) {
    const people = trustData.entityPeople?.filter((p) => 
      p.personType !== person.personType ||
      p.legalName !== person.legalName
    ) ?? [];
    setTrustData({
      ...trustData,
      entityPeople: [...people],
    });
  }

  function AddBeneficiaries() {
    return (
      <Modal
        open={showAddBeneficiaryModal}
        setOpen={setShowAddBeneficiaryModal}
        onPressCancel={() => {
          setShowAddBeneficiaryModal(false);
          setEntityTypePerson({});
        }}
        title="Add Beneficiary"
        onPressAdd={() => handleAddBeneficiary()}
      >
        <div className="flex flex-1 flex-col w-full">
          <TextInput
            label="Full Legal Name"
            type="text"
            value={entityTypePerson.legalName ?? ""}
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                legalName: e.target.value,
              });
            }}
            error={!entityTypePerson.legalName ? 1 : undefined}
          />

          <TextInput
            label="Email"
            type="email"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                email: e.target.value,
              });
            }}
            error={
              entityTypePerson.email && !emailChecker(entityTypePerson.email)
                ? 1
                : undefined
            }
            errormesage="Please enter a valid email!"
          />

          <TextInput
            label="Ownership Percentage"
            type="number"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                ownershipPercentage: parseInt(e.target.value),
              });
            }}
            error={
              entityTypePerson.ownershipPercentage &&
              (entityTypePerson.ownershipPercentage > 100 ||
                entityTypePerson.ownershipPercentage < 1)
                ? 1
                : undefined
            }
            errormesage="Please enter a valid ownership percentage!"
          />
        </div>
      </Modal>
    );
  }

  function AddTrustee() {
    return (
      <Modal
        open={showAddTrusteeModal}
        setOpen={setShowAddTrusteeModal}
        onPressCancel={() => {
          setShowAddTrusteeModal(false);
          setEntityTypePerson({});
        }}
        title="Add Trustee"
        onPressAdd={() => handleAddTrustee()}
      >
        <div className="flex flex-1 flex-col w-full">
          <TextInput
            label="Full Legal Name"
            type="text"
            value={entityTypePerson.legalName ?? ""}
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                legalName: e.target.value,
              });
            }}
            error={!entityTypePerson.legalName ? 1 : undefined}
          />

          <TextInput
            label="Email"
            type="email"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                email: e.target.value,
              });
            }}
            error={
              !entityTypePerson.email || !emailChecker(entityTypePerson.email)
                ? 1
                : undefined
            }
            errormesage="Please enter a valid email!"
          />

          <SelectInput
            label="Entity Type"
            onChange={(e) => {
              setEntityTypePerson({
                ...entityTypePerson,
                entityType: e.target.value as TrusteeEntityType,
              });
            }}
            error={!entityTypePerson.entityType ? 1 : undefined}
          >
            <option></option>
            {Object.entries(filteredEntityTypes).map(([key, value]) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
          </SelectInput>
        </div>
      </Modal>
    );
  }

  return (
    <div className="flex flex-col w-full flex-1 lg:p-4">
      {<OverlayLoading open={attachmentLoading} />}
      {!trustData ? (
        <Loading />
      ) : (
        <>
          {AddBeneficiaries()}
          {AddTrustee()}
          <div className="flex flex-row justify-between items-center border-b border-gray-200 pb-8">
            <div className="">
              <h2 className="text-xl sm:text-2xl font-semibold leading-7 text-apple-700">
                Trust
              </h2>
              <p className="mt-1 text-xs text-gray-500">
                Please fill all the required information to proceed with your
                KYB application.
              </p>
            </div>
            {/*
            <div>
              <Button> Save Progress</Button>
            </div> */}
          </div>
          <div className="flex flex-col px-1 lg:px-4">
            <p className="block text-md font-medium leading-6 text-apple-600 pt-4">
              Trust Information
            </p>
            <div className="mt-1 grid grid-cols-1 gap-x-6 gap-y-0 sm:grid-cols-12 px-1 sm:px-2">
              <div className="sm:col-span-6">
                <SelectInput
                  label="Trust Type"
                  value={trustData.trustType ?? ""}
                  onChange={(e) => {
                    setTrustData({
                      ...trustData,
                      trustType: e.target.value as TrustType,
                    });
                  }}
                  error={!trustData.trustType ? 1 : undefined}
                >
                  <option value=""> </option>
                  {Object.entries(TrustType).map(([key, value]) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
                </SelectInput>
              </div>
              {trustData.trustType === TrustType.Other ? (
                <div className="sm:col-span-6">
                  <TextInput
                    label="Other Trust Type"
                    type="text"
                    value={trustData.trustTypeOther ?? ""}
                    onChange={(e) => {
                      setTrustData({
                        ...trustData,
                        trustTypeOther: e.target.value,
                      });
                    }}
                    error={
                      trustData.trustType === TrustType.Other &&
                      !trustData.trustTypeOther
                        ? 1
                        : undefined
                    }
                  />
                </div>
              ) : null}
              <div className="sm:col-span-8">
                <SelectInput
                  label="Is the Trust settled sum less than $10,000 AUD?"
                  value={
                    trustData.trustSettleSumOver10k === false
                      ? 0
                      : trustData.trustSettleSumOver10k === true
                      ? 1
                      : ""
                  }
                  onChange={(e) => {
                    setTrustData({
                      ...trustData,
                      trustSettleSumOver10k:
                        e.target.value === "1" ? true : false,
                    });
                  }}
                  error={
                    trustData.trustSettleSumOver10k === undefined
                      ? 1
                      : undefined
                  }
                >
                  <option></option>
                  <option value={1}>
                    The settled sum was less than $10,000 AUD
                  </option>
                  <option value={0}>
                    The settled sum was equal to or more than $10,000 AUD
                  </option>
                </SelectInput>
              </div>
            </div>

            {trustData.trustSettleSumOver10k === false ? (
              <>
                <div className="col-span-full border-b border-gray-200 my-4 px-6" />
                <p className="block text-md font-medium leading-6 text-apple-600">
                  Settlor Information
                </p>
                <p className="text-xs text-gray-500">
                  Please fill in the details of the settlor.
                </p>
                <div className="mt-1 grid grid-cols-1 gap-x-6 gap-y-0 sm:grid-cols-12 px-1 sm:px-2">
                  <div className="sm:col-span-8">
                    <SelectInput
                      label="Is the Trust settlor deceased?"
                      value={
                        trustData.trustSettlorDeceased === false
                          ? 0
                          : trustData.trustSettlorDeceased === true
                          ? 1
                          : ""
                      }
                      onChange={(e) => {
                        setTrustData({
                          ...trustData,
                          trustSettlorDeceased:
                            e.target.value === "1" ? true : false,
                        });
                      }}
                      error={
                        trustData.trustSettlorDeceased === undefined &&
                        trustData.trustSettleSumOver10k === false
                          ? 1
                          : undefined
                      }
                    >
                      <option></option>
                      <option value={1}>The trust settlor is deceased</option>
                      <option value={0}>The trust settlor is alive</option>
                    </SelectInput>
                  </div>
                  {trustData.trustSettlorDeceased === false ? (
                    <>
                      <div className="sm:col-span-6">
                        <TextInput
                          label="Settlor Full Legal Name"
                          type="text"
                          value={settlor?.legalName ?? ""}
                          onChange={(e) => {
                            const settlorId = trustData.entityPeople?.findIndex(
                              (person) =>
                                person.personType === TrustPersonType.Settlor
                            );
                            if (settlorId === undefined || settlorId < 0) {
                              setTrustData({
                                ...trustData,
                                entityPeople: [
                                  ...(trustData.entityPeople ?? []),
                                  {
                                    personType: TrustPersonType.Settlor,
                                    legalName: e.target.value,
                                  },
                                ],
                              });
                              return;
                            } else {
                              const newEntityPeople = [
                                ...(trustData.entityPeople ?? []),
                              ];
                              newEntityPeople[settlorId] = {
                                ...newEntityPeople[settlorId],
                                legalName: e.target.value,
                              };
                              setTrustData({
                                ...trustData,
                                entityPeople: newEntityPeople,
                              });
                            }
                          }}
                          error={
                            trustData.trustSettlorDeceased !== undefined &&
                            !settlor?.legalName
                              ? 1
                              : undefined
                          }
                        />
                      </div>
                      <div className="sm:col-span-6">
                        <TextInput
                          label="Settlor Email"
                          type="email"
                          value={settlor?.email ?? ""}
                          onChange={(e) => {
                            const settlorId = trustData.entityPeople?.findIndex(
                              (person) =>
                                person.personType === TrustPersonType.Settlor
                            );

                            if (settlorId === undefined || settlorId < 0) {
                              setTrustData({
                                ...trustData,
                                entityPeople: [
                                  ...(trustData.entityPeople ?? []),
                                  {
                                    personType: TrustPersonType.Settlor,
                                    email: e.target.value,
                                  },
                                ],
                              });
                              return;
                            } else {
                              const newEntityPeople = [
                                ...(trustData.entityPeople ?? []),
                              ];
                              newEntityPeople[settlorId] = {
                                ...newEntityPeople[settlorId],
                                email: e.target.value,
                              };
                              setTrustData({
                                ...trustData,
                                entityPeople: newEntityPeople,
                              });
                            }
                          }}
                          error={
                            trustData.trustSettlorDeceased !== undefined &&
                            (!settlor ||
                              !settlor.email ||
                              !emailChecker(settlor.email))
                              ? 1
                              : undefined
                          }
                          errormesage="Please enter a valid email!"
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            ) : null}

            <div className="col-span-full border-b border-gray-200 my-4 px-6" />
            <div className="flex flex-col w-full">
              <p className="block text-md font-medium leading-6 text-apple-600">
                Beneficiaries Information
              </p>
              <TextArea
                label="Are the beneficiaries identified by reference to a class? If so, please provide details."
                value={trustData.trustClassDetails ?? ""}
                onChange={(e) => {
                  setTrustData({
                    ...trustData,
                    trustClassDetails: e.target.value,
                  });
                }}
              />

              <div className="flex flex-row justify-between items-center w-full mt-4">
                <div className="w-1/2">
                  <p className="block text-md font-medium leading-6 text-apple-600 ">
                    Beneficiaries
                  </p>
                  <p className="mt-1 text-xs text-gray-500">
                    Please provide the full-name, email, and ownership
                    percentage for all beneficiaries.
                  </p>
                </div>
                {!determineDisabled(kybStatus) ? (
                  <div>
                    <button
                      className="flex w-full rounded-xl hover:bg-apple-500 bg-transparent border-2 border-apple-500 py-0.5 hover:text-white hover:border-apple-500 px-4 text-sm font-semibold text-apple-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-600"
                      onClick={() => setShowAddBeneficiaryModal(true)}
                    >
                      Add Beneficiary
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col w-full px-2 sm:px-4">
                {beneficiaries.length < 1 ? (
                  <EmptyPlaceholder>
                    No beneficiaries added yet. Please add beneficiary.
                  </EmptyPlaceholder>
                ) : (
                  <div className="w-full">
                    {beneficiaries.map((person, index) => (
                      <div
                        key={index}
                        className={
                          index < beneficiaries.length - 1
                            ? `border-b border-gray-200`
                            : ``
                        }
                      >
                        <div className="flex flex-row justify-between items-center py-4 w-full flex-wrap">
                          <div className="flex flex-row justify-start items-center py-4">
                            <span className="inline-flex h-8 w-8 items-center justify-center rounded-xl bg-apple-200 mr-4">
                              <span className="text-xs font-medium leading-none text-apple-800">
                                {person.legalName?.charAt(0).toUpperCase()}
                              </span>
                            </span>
                            <div className="">
                              <span className="flex flex-row items-center">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                  {person.legalName}
                                </p>
                                <p className=" pl-2 truncate text-xs leading-5 text-gray-500">
                                  {person.email && `(${person.email})`}
                                </p>
                              </span>
                              <p className="truncate text-xs leading-5 text-gray-500">
                                {person.ownershipPercentage &&
                                  `${person.ownershipPercentage}% Ownership`}
                              </p>
                            </div>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="flex w-full rounded-xl hover:bg-red-500 bg-transparent border-2 border-red-500 py-0.5 hover:text-white hover:border-red-500 px-4 text-sm font-semibold text-red-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                              onClick={() => {
                                if (confirm("Are you sure you want to delete this person?\n\nThis action cannot be undone. All the information related to this partner will be removed.")) {
                                  handleRemovePerson(person);
                                }
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-full border-b border-gray-200 my-4 px-6" />
            <div className="flex flex-col w-full">
              <div className="flex flex-row justify-between items-center w-full ">
                <div className="w-1/2">
                  <p className="block text-md font-medium leading-6 text-apple-600 ">
                    Trustees
                  </p>
                  <p className="mt-1 text-xs text-gray-500">
                    Please provide the full-name, email, and entity type for all
                    trustees.
                  </p>
                </div>
                {!determineDisabled(kybStatus) ? (
                  <div>
                    <button
                      className="flex w-full rounded-xl hover:bg-apple-500 bg-transparent border-2 border-apple-500 py-0.5 hover:text-white hover:border-apple-500 px-4 text-sm font-semibold text-apple-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-apple-600"
                      onClick={() => setShowAddTrusteeModal(true)}
                    >
                      Add Trustee
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col w-full px-2 sm:px-4">
                {trustees.length < 1 ? (
                  <EmptyPlaceholder>
                    No trustee added yet. Please add trustee.
                  </EmptyPlaceholder>
                ) : (
                  trustees.map((person, index) => (
                    <div
                      key={index}
                      className={
                        index < trustees.length - 1
                          ? `border-b border-gray-200`
                          : ``
                      }
                    >
                      <div className="flex flex-row justify-between items-center py-4 w-full flex-wrap">
                        <div className="flex flex-row justify-start items-center">
                          <span className="inline-flex h-8 w-8 items-center justify-center rounded-xl bg-apple-200 mr-4">
                            <span className="text-xs font-medium leading-none text-apple-800">
                              {person.legalName?.charAt(0).toUpperCase()}
                            </span>
                          </span>
                          <div>
                            <span className="flex items-center">
                              <p className="text-sm font-semibold leading-6 text-gray-900">
                                {person.legalName}
                              </p>
                              <p className=" pl-2 truncate text-xs leading-5 text-gray-500">
                                {person.email && `(${person.email})`}
                              </p>
                            </span>
                            <p className="truncate text-xs leading-5 text-gray-500">
                              {person.entityType}
                            </p>
                          </div>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="flex w-full rounded-xl hover:bg-red-500 bg-transparent border-2 border-red-500 py-0.5 hover:text-white hover:border-red-500 px-4 text-sm font-semibold text-red-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            onClick={() => {
                              if (confirm("Are you sure you want to delete this person?\n\nThis action cannot be undone. All the information related to this partner will be removed.")) {
                                handleRemovePerson(person);
                              }
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div className="w-full border-b border-gray-200 py-4 px-6" />
            <div className="w-full">
              {data ? (
                <div className="w-full">
                  {AttachmentsModals({
                    nodeData: data,
                    openAttachmentModal,
                    setOpenAttachmentModal,
                    setAttachmentLoading,
                    areyousure,
                    setAreyousure,
                    attachment,
                    setAttachment,
                    mutateNodeAttachments,
                    removeAttachment,
                    AttachmentType: TrustAttachmentType,
                    kybStatus,
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
